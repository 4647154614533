import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import withStyles from 'react-jss';
import './col.scss';

const spacer = [...Array(7).keys()].map(i => i * 5);
const styles = {
  col: {
    '& > * ': {
      marginBottom: props => spacer[props.spacing]
    }
  }
};
const Col = ({
  className,
  style,
  children,
  dense,
  fullWidth,
  align,
  justify,
  classes
}) => (
  <div
    className={cn(
      'd-flex flex-column',
      { [`col-wrapper ${classes.col}`]: !dense },
      { 'w-fill': fullWidth },
      { [`align-items-${align}`]: align },
      { [`justify-content-${justify}`]: justify },
      className
    )}
    style={style}
  >
    {children}
  </div>
);

Col.defaultProps = {
  className: '',
  style: {},
  children: null,
  dense: false,
  fullWidth: false,
  align: 'inherit',
  justify: 'inherit',
  spacing: 6
};

Col.propTypes = {
  /** Additional classes for row */
  className: PropTypes.string,
  /** Additional classes for row */
  align: PropTypes.string,
  /** Additional classes for row */
  justify: PropTypes.string,
  /** Additional styles for row */
  style: PropTypes.shape(),
  /** Children for row wrapper */
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  /** Boolean whether or not we are in dense (no margins) mode */
  dense: PropTypes.bool,
  /** Boolean whether or not we are in full width mode */
  fullWidth: PropTypes.bool,
  /** Spacing between components [0, 1, 2, 3, 4, 5, 6] -
   *  multiplies of 5 (1 => 5, 2=> 10). - defaults to 6
   */
  spacing: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6])
};

export const ColComponent = Col;
export default withStyles(styles)(Col);
