import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import noop from 'lodash/noop';
import withStyles from 'react-jss';
import ActionButton from '../../buttons/ActionButton';
import IconButton from '../../buttons/IconButton';
import PickerResetWrapper from '../PickerResetWrapper';
import Fade from '../../../utils/transiton/Fade';

const styles = {
  timeBtn: {
    borderLeftWidth: '0 !important',
    height: 46,
  },
  input: {
    height: 46,
  },
};
const TimePickerInputField = ({
  onReset, disabled, resetable, className, style, onClick, id, isOpen,
  value, placeholder, onClear, defaultValue, classes, name, noClear,
}) => (
  <PickerResetWrapper
    id={id}
    onReset={onReset}
    disabled={disabled || defaultValue === value}
    resetable={resetable}
    className={cn('flex-end-center', className)}
    style={style}
  >
    <div
      className={cn('d-flex flex-1 b-1 bg-white pl-4 border-gray-light align-items-center', classes.input)}
      onClick={disabled ? null : onClick}
      role="presentation"
    >
      <div className="flex-1 h-fill flex-start-center font-size-md" name={name} id={id}>
        {value ? (<span className="time-value">{value}</span>) : (
          <span className="time-placeholder text-gray-dark">{placeholder}</span>)}
      </div>
      {!noClear &&
      <Fade show={(!!value && !disabled)} className="h-fill flex-center">
        <IconButton key={1} className="pr-4" onClick={onClear} icon="BtnDelete" iconHeight={10} iconWidth={10} />
      </Fade>}
    </div>
    <ActionButton
      iconHeight={21}
      className={classes.timeBtn}
      iconColor={isOpen ? 'blue' : 'gray-dark'}
      iconWidth={21}
      onClick={onClick}
      disabled={disabled}
      id={id}
      icon="BtnTime"
      tooltip="Select time"
    />
  </PickerResetWrapper>
);

TimePickerInputField.defaultProps = {
  onReset: noop,
  disabled: false,
  resetable: false,
  className: '',
  style: {},
  value: '',
  placeholder: '',
  defaultValue: '',
  name: '',
  id: '',
  noClear: false,
  isOpen: false,
};
TimePickerInputField.propTypes = {
  onReset: PropTypes.func,
  disabled: PropTypes.bool,
  resetable: PropTypes.bool,
  noClear: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.shape(),
  onClick: PropTypes.func.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onClear: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  isOpen: PropTypes.bool,
};

export default withStyles(styles)(TimePickerInputField);
