import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import memoize from 'lodash/memoize';
import Input from '../../components/inputs/Input';
import Checkbox from '../../components/selectionControls/checkbox/Checkbox';
import NumberPicker from '../../components/pickers/number/NumberPicker';
import TimePicker from '../../components/pickers/time/TimePicker';
import Dropdown from '../../components/dropdowns/Dropdown';
import TextArea from '../../components/inputs/textArea/TextArea';
import Person from '../../components/avatarStructures/person/Person';
import InputLabel from '../../components/inputs/InputLabel';

const createPerson = data => <Person name={data.fullName} size="sm" />;

const getPersonOptions = (options = []) =>
  options.map(option => ({
    label: createPerson(option),
    value: option._id,
    name: option.fullName
  }));

const getOptions = (options = []) =>
  options.map(option => ({
    label: option,
    value: option
  }));

const memoizeGetOptions = memoize(getOptions);
const memoizeGetPersonOptions = memoize(getPersonOptions);

const getLabel = (type, label) =>
  type === 'boolean' ? 'A yes no issue' : label;

const getField = props => {
  const {
    type,
    onChange,
    value,
    name,
    id,
    label,
    options,
    onLoadMore,
    pageCount,
    isFetching
  } = props;
  switch (type) {
    case 'boolean':
      return (
        <div className="row w-100 pl-3">
          <Checkbox
            name={name}
            id={id}
            onChange={(val, nameVal) => onChange(!val, nameVal)}
            value={value}
            checked={!!value}
          >
            {label}
          </Checkbox>
        </div>
      );
    case 'number':
      return (
        <div className="row w-100 pl-3 d-block">
          <NumberPicker value={value} name={name} id={id} onChange={onChange} />
        </div>
      );
    case 'time':
      return (
        <div className="row w-100 pl-3 d-block">
          <TimePicker onChange={onChange} name={name} id={id} value={value} />
        </div>
      );
    case 'longtext':
      return (
        <div className="row w-100 pl-3 d-block">
          <TextArea onChange={onChange} name={name} id={id} value={value} />
        </div>
      );
    case 'enum': {
      const dropDownOptions = memoizeGetOptions(options);
      return (
        <div className="row w-100 pl-3 d-block">
          <Dropdown
            options={dropDownOptions}
            onSelect={onChange}
            name={name}
            id={id}
            defaultValue={value}
            onLoadMore={onLoadMore}
            pageCount={pageCount}
            isFetching={isFetching}
          />
        </div>
      );
    }
    case 'user':
    case 'contact': {
      const dropDownOptions = memoizeGetPersonOptions(options);
      return (
        <div className="row w-100 pl-3 d-block">
          <Dropdown
            options={dropDownOptions}
            onSelect={onChange}
            name={name}
            id={id}
            defaultValue={value}
            onLoadMore={onLoadMore}
            pageCount={pageCount}
            isFetching={isFetching}
          />
        </div>
      );
    }
    case 'text':
    default:
      return (
        <div className="row w-100 d-block pl-3">
          <Input
            value={value}
            name={name}
            id={id}
            onChange={onChange}
            className="pr-3"
          />
        </div>
      );
  }
};

const DynamicField = props => {
  const { type, label } = props;
  const fieldElement = getField(props);
  const inputLabel = getLabel(type, label);
  return (
    <div className="form-group-body">
      <div className="mb-6 form-field">
        <InputLabel className="dynamic-field-label" label={inputLabel} />
        {fieldElement}
      </div>
    </div>
  );
};

DynamicField.defaultProps = {
  onChange: noop,
  value: undefined,
  label: undefined,
  options: undefined,
  type: 'text',
  id: undefined,
  name: '',
  pageCount: 0,
  onLoadMore: noop,
  isFetching: false
};

DynamicField.propTypes = {
  /** Function to be called on value change */
  onChange: PropTypes.func,
  /** Element type to render */
  type: PropTypes.string,
  /** A label to show on top of the input */
  label: PropTypes.string,
  /** Controlled input value to be set to input */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ]),
  /** The dropdown options to show Can be array of strings or Objects Structure ({
   _id: user/contact id,
   fullName: user/contact full Name,
  }) */
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  ),
  /** Name indicator for field */
  name: PropTypes.string,
  /** id indicator for field */
  id: PropTypes.string,
  /** Callback to handle pagination fetch more */
  onLoadMore: PropTypes.func,
  /** total pages for pagination  */
  pageCount: PropTypes.number,
  /** Indicator whether or not we are in fetching mode in pagination */
  isFetching: PropTypes.bool
};

export default DynamicField;
