import intl from '@guestyci/react-intl-universal';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const FormattedMessage = ({ id, values, defaultMessage }) => (
  <Fragment>
    {intl.get(id, values).d(defaultMessage)}
  </Fragment>
);

FormattedMessage.defaultProps = {
  values: undefined,
};
FormattedMessage.propTypes = {
  /** The id for the message */
  id: PropTypes.string.isRequired,
  /** Dynamic values to set to the message string */
  values: PropTypes.shape(),
  /** Fallback message to localized message */
  defaultMessage: PropTypes.string.isRequired,
};
export default FormattedMessage;

