export const schema = {
  creditCardNumber: {
    stripeValidator: true,
  },
  creditCardExpiry: {
    stripeValidator: true,
  },
  creditCardCVC: {
    stripeValidator: true,
  },
};
