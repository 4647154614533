import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { StayStatus } from '../../../../constants/enums';

const { Complete, Reserved, Staying } = StayStatus;

const generateIndicatorColor = (status, isDark) => {
  const defaultColor = isDark ? 'black' : 'gray';
  switch (status) {
    case Complete:
      return {
        fillLeft: true, fillRight: true, leftColor: 'green', rightColor: 'green',
      };
    case Reserved:
      return { leftColor: 'yellow', rightColor: 'yellow' };
    case Staying:
      return { fillLeft: true, leftColor: 'green', rightColor: defaultColor };
    default:
      return { leftColor: defaultColor, rightColor: defaultColor };
  }
};

const BaseStayIndicator = ({
  status,
  className,
  dark,
  style,
  fullWdith,
}) => {
  const circleSize = 9;
  const lineWidth = 1;
  let lineLength = 7;
  if (fullWdith) {
    lineLength = `calc(50% - ${circleSize}px)`;
  }

  const {
    leftColor, rightColor, fillLeft, fillRight,
  } = generateIndicatorColor(status, dark);
  return (
    <div className={cn('flex-center', 'w-100', className)} style={style}>
      <div
        className={cn('stay-indicator-circle-1', { [`bg-${leftColor}`]: fillLeft }, 'b-1', `border-${leftColor}`)}
        style={{ width: circleSize, height: circleSize, borderRadius: '50%' }}
      />
      <div
        className={cn('stay-indicator-line-1', `border-${leftColor}`)}
        style={{ width: lineLength, borderTopWidth: lineWidth, borderTopStyle: 'solid' }}
      />
      <div
        className={cn('stay-indicator-line-2', `border-${rightColor}`)}
        style={{ width: lineLength, borderTopWidth: lineWidth, borderTopStyle: 'solid' }}
      />
      <div
        className={cn('stay-indicator-circle-2', { [`bg-${rightColor}`]: fillRight }, 'b-1', `border-${rightColor}`)}
        style={{ width: circleSize, height: circleSize, borderRadius: '50%' }}
      />
    </div>
  );
};
BaseStayIndicator.defaultProps = {
  status: StayStatus.Planned,
  className: '',
  style: {},
  dark: false,
  fullWdith: false,
};

BaseStayIndicator.propTypes = {
  /** Stay status - Enum (StayStatus- [Reserved, Planned, Staying, Complete,]) */
  status: PropTypes.oneOf([
    StayStatus.Planned,
    StayStatus.Staying,
    StayStatus.Complete,
    StayStatus.Reserved]),
  /** Additional class to add to StayIndicator */
  className: PropTypes.string,
  /** Additional style to add to StayIndicator */
  style: PropTypes.shape(),
  /** Set theme to dark */
  dark: PropTypes.bool,
  /** Set the stay indicator to fill space */
  fullWdith: PropTypes.bool,
};

export default BaseStayIndicator;
