import styled from 'styled-components';
import { mobile, tablet } from 'constants/css/breakpoints';

export const Amenity = styled.div`
  width: 30%;
`;

export const CloseButton = styled.div`
  background: var(--white);
  border-radius: 21px 0 0 21px;
  height: 37px;
  position: absolute;
  right: 0;
  top: 30px;
  width: 73px;
  z-index: 1;
`;

export const Listing = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  box-shadow: 0 20px 60px 0 rgba(39, 43, 45, 0.1);
  margin: 55px 60px 5px 120px;
  @media ${tablet} {
    margin: 55px 20px 5px 40px;
  };
  @media ${mobile} {
    margin: 55px 20px 5px 20px;
  };
`;

export const ListingWrapper = styled.div`
  margin-bottom: 120px;
  @media ${mobile} {
    margin-bottom: 148px;
  };
`;

export const MapWrapper = styled.div`
  @media ${mobile} {
    margin-left: -30px;
    margin-right: -30px;
  };
`;

export const Tag = styled.div`
  padding-top: 20px;
  letter-spacing: 0.2px;
  line-height: 1.21;
  :first-child {
    padding-top: 0;
  }
`;
