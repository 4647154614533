import React, { Component } from 'react';
import 'react-dates/initialize';
import moment from 'moment';
import PropTypes from 'prop-types';
import cn from 'classnames';
import isObject from 'lodash/isObject';
import withStyles from 'react-jss';
import { END_DATE, START_DATE } from 'react-dates/lib/constants';
import { DatePickerType } from '../../../../constants/enums';
import RangePicker from './RangePicker';
import SinglePicker from './SinglePicker';
import ActionButton from '../../buttons/ActionButton';
import { grayLight } from '../../../../themes/colors';
import NavButton from './DatePickerNavButton';
import './datePicker.scss';
import IconButton from '../../buttons/IconButton';
import Fade from '../../../utils/transiton/Fade';

const DATE_FORMAT = 'YYYY-MM-DD';
const containerSize = 46;

const styles = {
  container: {
    background: 'white',
    width: '100%',
    height: containerSize,
    display: 'flex',
    justifyContent: 'space-between',
    border: `1px solid ${grayLight}`,
    borderRadius: 2
  },
  iconContainer: {
    height: 44,
    borderLeft: `1px solid ${grayLight}`,
    borderTop: '0px',
    borderBottom: '0px',
    borderRight: '0px'
  },
  dateInputWrapper: {
    flex: 1,
    width: 'calc(100% - 85px)'
  }
};

const falseFunc = () => false;
const renderMonthElement = ({ month }) => (
  <span>{month.format('MMMM YYYY')}</span>
);

class DatePicker extends Component {
  constructor(props) {
    super(props);
    const { value, defaultValue, type, isAvailability, onMonthChange } = props;
    this.state = {
      ...this.setInitialValues(type, value || defaultValue)
    };
    this.blockedDates = {};
    this.boundaries = {
      start: null,
      end: null
    };
    if (isAvailability && !onMonthChange) {
      throw new Error('onMonthChange required with isAvailability');
    }
  }

  onFocus = focus => {
    let focusItem = focus;
    if (focusItem && focusItem.currentTarget) {
      focusItem = this.props.type === DatePickerType.Range ? START_DATE : true;
    } else if (isObject(focus)) {
      focusItem = focus.focused;
    }
    this.setState({ isFocused: !!focusItem, focused: focusItem });
    if (this.props.onFocusChange) {
      this.props.onFocusChange(focusItem);
    }
  };

  onDatesChange = ({ startDate, endDate }) => {
    const { onChange, blockedDates, isAvailability, id, name } = this.props;
    const blockedArray = Object.keys(blockedDates);
    const blockedEndDay = blockedArray[blockedArray.length - 1];
    const blockedStartDay = blockedArray[0];
    let _endDate = endDate;
    if (this.state.focused === START_DATE && endDate) {
      _endDate = null;
    }
    this.setState({ startDate, endDate: _endDate });
    onChange({ start: startDate, end: _endDate }, id || name);
    if (isAvailability) {
      this.boundaries.start = startDate;
      this.boundaries.end = _endDate;
      if ((startDate && _endDate) || (!startDate && !_endDate)) {
        return;
      }
      if (!_endDate && startDate.isBefore(blockedEndDay)) {
        let endBound = startDate.clone();
        for (
          endBound;
          endBound.isBefore(blockedEndDay);
          endBound = endBound.add(1, 'days')
        ) {
          if (blockedDates[endBound.format(DATE_FORMAT)]) {
            this.boundaries.end = endBound.subtract(1, 'days');
            break;
          }
        }
      } else if (!startDate && _endDate.isAfter(blockedStartDay)) {
        let startBound =
          _endDate.format(DATE_FORMAT) > blockedEndDay
            ? _endDate.clone()
            : moment(blockedEndDay);
        for (
          startBound;
          startBound.isAfter(blockedStartDay);
          startBound = startBound.subtract(1, 'days')
        ) {
          if (blockedDates[startBound.format(DATE_FORMAT)]) {
            this.boundaries.start = startBound;
            break;
          }
        }
      }
    }
  };

  onDateChange = date => {
    const { onChange, id, name } = this.props;
    this.setState({ date });
    onChange(date, id || name);
  };

  onMonthChange = month => {
    const start = moment(month)
      .startOf('month')
      .format(DATE_FORMAT);
    const end = moment(month)
      .endOf('month')
      .format(DATE_FORMAT);
    const { startDate, endDate } = this.state;
    const { blockedDates, onMonthChange } = this.props;

    const startDay =
      startDate && startDate.isBefore(start)
        ? startDate.format(DATE_FORMAT)
        : start;
    const endDay =
      endDate && endDate.isAfter(end) ? endDate.format(DATE_FORMAT) : end;
    if ((!blockedDates[startDay] || !blockedDates[endDay]) && onMonthChange) {
      onMonthChange(start, end);
    }
  };

  setInitialValues = (type, value) => {
    if (type === DatePickerType.Single) {
      return { date: value ? moment(value) : null };
    }
    return {
      startDate: isObject(value) && value.start ? moment(value.start) : null,
      endDate: isObject(value) && value.end ? moment(value.end) : null
    };
  };
  getTooltipByType = type =>
    `Select ${type === DatePickerType.Single ? 'a date' : 'dates'}`;

  initialVisibleMonth = () => {
    const { initialVisibleMonth } = this.props;
    const { startDate, endDate, focused } = this.state;
    if (focused === START_DATE) {
      if (startDate) {
        return moment(startDate);
      } else if (endDate) {
        return moment(endDate);
      }
    } else if (focused === END_DATE) {
      if (endDate) {
        return moment(endDate);
      } else if (startDate) {
        return moment(startDate);
      }
    }
    return initialVisibleMonth ? moment(initialVisibleMonth) : moment();
  };

  isDayBlocked = date => {
    const { isFetching, focused, endDate, startDate } = this.state;
    const { blockedDates, isAvailability } = this.props;
    const day = date.format('YYYY-MM-DD');
    if (!isAvailability) {
      return false;
    }
    if (isFetching) {
      return true;
    }
    if (!startDate || !endDate) {
      if (focused === END_DATE && this.boundaries.end) {
        return (
          moment(date.format(DATE_FORMAT)).isAfter(this.boundaries.end) ||
          moment(date.format(DATE_FORMAT)).isBefore(startDate)
        );
      } else if (focused === START_DATE && this.boundaries.start) {
        return (
          date.startOf('day').isBefore(this.boundaries.start) ||
          moment(date.format(DATE_FORMAT)).isAfter(endDate)
        );
      }
    }
    this.blockedDates[day] = blockedDates[day] || this.blockedDates[day];
    return this.blockedDates[day];
  };

  clearDates = () => {
    this.setState({ startDate: null, endDate: null, date: null });
    this.boundaries.start = null;
    this.boundaries.end = null;
    if (this.props.type === DatePickerType.Range) {
      this.onDatesChange({ startDate: null, endDate: null });
    } else {
      this.onDateChange(null);
    }
  };

  renderDayContents = date => {
    if (this.props.isFetching) {
      return (
        <span className="flex-center text-muted letter-spacing-2">...</span>
      );
    }
    return date.date();
  };

  render() {
    const {
      classes,
      className,
      style,
      type,
      enablePastDays,
      focus,
      sm,
      id,
      disabled,
      openDirection,
      anchorDirection
    } = this.props;
    const propsForPicker = {
      prevMonthButton: <NavButton direction="Left" sm={sm} type={type} />,
      nextMonthButton: <NavButton direction="Right" sm={sm} type={type} />,
      renderMonthElement,
      verticalSpacing: 4,
      readOnly: true,
      daySize: type === DatePickerType.Single || sm ? 40 : 50,
      numberOfMonths: type === DatePickerType.Single || sm ? 1 : 2
    };
    const { startDate, endDate, date, isFocused, focused } = this.state;
    if (enablePastDays) {
      propsForPicker.isOutsideRange = falseFunc;
    }
    return (
      <div
        className={cn(
          'relative',
          { sm },
          'flex-start-center',
          'date-picker',
          className,
          classes.container
        )}
        style={style}
      >
        <div className={cn('absolute', classes.dateInputWrapper)}>
          {type === DatePickerType.Range && (
            <RangePicker
              {...this.props}
              {...propsForPicker}
              disabled={disabled}
              startDate={startDate}
              startDateId="start_date_id"
              endDateId="end_date_id"
              endDate={endDate}
              focusedInput={focused}
              onFocus={this.onFocus}
              renderDayContents={day => this.renderDayContents(day)}
              onDatesChange={this.onDatesChange}
              isDayBlocked={day => this.isDayBlocked(day)}
              onMonthChange={this.onMonthChange}
              initialVisibleMonth={this.initialVisibleMonth}
              anchorDirection={anchorDirection}
              openDirection={openDirection}
            />
          )}
          {type === DatePickerType.Single && (
            <SinglePicker
              {...this.props}
              {...propsForPicker}
              disabled={disabled}
              date={date}
              onFocusChange={this.onFocus}
              isFocused={focus || !!focused}
              onDateChange={this.onDateChange}
              renderDayContents={day => this.renderDayContents(day)}
              isDayBlocked={day => this.isDayBlocked(day)}
              initialVisibleMonth={this.initialVisibleMonth}
              anchorDirection={anchorDirection}
              openDirection={openDirection}
            />
          )}
        </div>
        <div className="flex-fill" />
        <Fade show={!!(date || startDate || endDate)}>
          <IconButton
            className="pr-4"
            onClick={this.clearDates}
            icon="BtnDelete"
            iconHeight={10}
            iconWidth={10}
          />
        </Fade>
        <ActionButton
          className={cn('b-0 zindex-10', classes.iconContainer)}
          tooltip={this.getTooltipByType(type)}
          onClick={this.onFocus}
          id={id}
          icon="BtnCalendar"
          disabled={disabled}
          iconColor={isFocused ? 'blue' : 'gray-dark'}
        />
      </div>
    );
  }
}

DatePicker.defaultProps = {
  type: DatePickerType.Single,
  isAvailability: false,
  minimumNights: 1,
  blockedDates: {},
  disabled: false,
  initialVisibleMonth: null,
  value: null,
  defaultValue: null,
  onMonthChange: null,
  enablePastDays: false,
  focus: false,
  onFocusChange: null,
  sm: true,
  isFetching: false,
  anchorDirection: 'left',
  openDirection: 'down',
  id: undefined
};

DatePicker.propTypes = {
  /** Id for date picker */
  id: PropTypes.string,
  /** Picker type single/range */
  type: PropTypes.oneOf(Object.values(DatePickerType)),
  /** Minimium Nights, 0 to allow single day range */
  minimumNights: PropTypes.number,
  /** Boolean - if it's avalibilty picker or regular picker (without blocked days) */
  isAvailability: PropTypes.bool,
  /** unavailable object structure :
   *  {
   *   '2018-01-01': true,
   *    '2018-06-01': true,
   *    ...
   *  } */
  blockedDates: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  /** Boolean - disable the date input */
  disabled: PropTypes.bool,
  /** Set the initial month for the date picker */
  initialVisibleMonth: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string
  ]),
  /** initial value for date range picker - date - for single - {start, end } for range */
  value: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
    PropTypes.shape()
  ]),
  /** initial value for date range picker - date - for single - {start, end } for range */
  defaultValue: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
    PropTypes.shape()
  ]),
  /** A request to fetch the availability for star - end period (start, end) => {} */
  onMonthChange: PropTypes.func,
  /** callback when dates change : Range:  ({start, end})  Single: (date) */
  onChange: PropTypes.func.isRequired,
  /** Boolean - if should enable select past days */
  enablePastDays: PropTypes.bool,
  /** if input is focused (open picker) for single picker */
  focus: PropTypes.bool,
  /** focus change callback */
  onFocusChange: PropTypes.func,
  /** Set picker mode, sm or md */
  sm: PropTypes.bool,
  /** Indicator whether or not we are fetching availability */
  isFetching: PropTypes.bool,
  /** Set which direction the drop down will open options: [left|right]  */
  anchorDirection: PropTypes.string,
  /** Set which direction the drop down will open options: [up|down]  */
  openDirection: PropTypes.string
};
/** Work around to fix storybook HOC propTypes bug  - DO NOT IMPORT THIS */
export const DatePickerComponent = DatePicker;
export default withStyles(styles)(DatePicker);
