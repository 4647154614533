import React, { Component } from 'react';
/**
 * Create a wrapper compose to support Hover functionality
 * @param ComposedComponent
 * @returns {{Component}}
 */


function withHover(ComposedComponent) {
  class HoverWrapper extends Component {
    constructor(props) {
      super(props);
      this.state = { isHovering: false };
      this.hoverRef = React.createRef();
    }

    componentDidMount() {
      const node = this.hoverRef.current;
      if (node) {
        node.addEventListener('mouseover', this.onHoverEnter);
        node.addEventListener('mouseleave', this.onHoverLeave);
        node.addEventListener('blur', this.onHoverLeave);
      }
    }

    componentWillUnmount() {
      const node = this.hoverRef.current;
      if (node) {
        node.removeEventListener('mouseover', this.onHoverEnter);
        node.removeEventListener('mouseleave', this.onHoverLeave);
        node.removeEventListener('blur', this.onHoverLeave);
      }
    }

    onHoverLeave = () => {
      this.setState({ isHovering: false });
    };

    onHoverEnter = () => {
      this.setState({ isHovering: true });
    };

    render() {
      return <ComposedComponent
        {...this.props}
        isHovering={this.state.isHovering}
        hoverRef={this.hoverRef}
        onHoverLeave={this.onHoverLeave}
        onHoverEnter={this.onHoverEnter}
      />;
    }
  }

  return HoverWrapper;
}

export default withHover;
