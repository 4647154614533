import React from 'react';
import PropTypes from 'prop-types';

import useTranslations from '@guestyci/localize/useTranslations';
import { Row } from '@guestyci/foundation';
import TextField from '@guestyci/foundation/TextField';
import Icon from '@guestyci/foundation/legacy/Icon';

import { getAdjustmentDisplayValue } from 'utils/helpers';
import { invoicePageInvoiceIntlId } from 'utils/intlIds';

const Promotions = ({ promotions }) => {
  const {
    rule: {
      discountType,
      discountAmount,
      minStayDays,
      minStayType,
    },
  } = promotions;
  const value = getAdjustmentDisplayValue(discountAmount, discountType);

  const translations = [
    {
      id: invoicePageInvoiceIntlId('promo.title'),
      d: '{minStayDays} {minStayType} stay promotion',
      variables: {
        minStayDays,
        minStayType,
      },
    },
  ];

  const [
    title,
  ] = useTranslations(translations, [minStayDays, minStayType]);

  return (
    <Row justify="between">
      <TextField className="flex-start-center" color="default">
        <Icon
          className="mr-1"
          icon="BtnStarOff"
          height={14}
          width={14}
        />
        <span>{title}</span>
      </TextField>
      <TextField color="default">{value}</TextField>
    </Row>
  );
}

Promotions.propTypes = {
  promotions: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    rule: PropTypes.shape({
      discountType: PropTypes.string,
      discountAmount: PropTypes.number,
    }),
  }),
}

export default Promotions;
