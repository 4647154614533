import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../tooltip/Tooltip';
import Icon from '../icon/Icon';

const InputLabel = ({ label, tooltip }) => (
  <div className="d-flex-fill flex-space-between-center input-label">
    <div className="input-label-label text-muted">{label}</div>
    {tooltip &&
      <Tooltip body={tooltip}>
        <Icon icon="BtnHelp" color="muted" width={15} height={15} />
      </Tooltip>}
  </div>
);

InputLabel.defaultProps = {
  tooltip: undefined,
};

InputLabel.propTypes = {
  label: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
};
export default InputLabel;
