import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import withStyle from 'react-jss';

const styles = {
  stepDivider: {
    position: 'relative',
  },
  stepDividerLine: props => ({
    height: '1px',
    width: `${props.width}px`,
  }),
  stepDividerText: {
    position: 'absolute',
    bottom: '0',
    left: '50%',
    transform: 'translateX(-50%)',
    'text-align': 'center',
  },
};
const StepDivider = ({ text, color, classes }) => (
  <div className={('flex-1', classes.stepDivider)}>
    <span className={cn(classes.stepDividerText, `text-${color}`)}>{text}</span>
    <div className={cn(classes.stepDividerLine, `bg-${color}`)} />
  </div>
);

StepDivider.defaultProps = {
  text: '',
  color: 'gray',
  width: 19,
};

StepDivider.propTypes = {
  text: PropTypes.string,
  color: PropTypes.string,
  width: PropTypes.number,
};

/** Work around to fix storybook HOC propTypes bug  - DO NOT IMPORT THIS */
export const StepDividerComponent = StepDivider;
export default withStyle(styles)(StepDivider);
