export const schema = {
  firstName: {
    presence: {
      allowEmpty: false,
      message: 'Required',
    },
  },
  lastName: {
    presence: {
      allowEmpty: false,
      message: 'Required',
    },
  },
  streetAndNumber: {
    presence: {
      allowEmpty: false,
      message: 'Required',
    },
  },
  city: {
    presence: {
      allowEmpty: false,
      message: 'Required',
    },
  },
  country: {
    presence: {
      allowEmpty: false,
      message: 'Required',
    },
  },
  zipCode: {
    presence: {
      allowEmpty: false,
      message: 'Required',
    },
    length(value = '') {
      return value.length >= 20 ? { is: 10 } : false;
    },
  },
  email: {
    presence: {
      allowEmpty: false,
      message: 'Required',
    },
    email: true,
  },
  invoiceTel: {
    presence: {
      allowEmpty: false,
      message: 'Required',
    },
  },
};

export const schemaWithTos = {
  ...schema,
  termOfUse: {
    presence: { message: 'Required' },
    inclusion: {
      within: [true],
      message: 'Required',
    },
  },
};
