const allAmenities = [
  { icon: 'Longterm', text: 'Long Term Stays Allowed' },
  { icon: 'Elevator', text: 'Elevator' },
  { icon: 'Parking', text: 'Free Parking on Premises' },
  { icon: 'Parking', text: 'Paid Parking off Premises' },
  { icon: 'Parking', text: 'Free Parking on Street' },
  { icon: 'Wheelchair', text: 'Wheelchair Accessible' },
  { icon: 'Gym', text: 'Gym' },
  { icon: 'Pool', text: 'Swimming Pool' },
  { icon: 'Pool', text: 'Private Pool' },
  { icon: 'Pool', text: 'Indoor Pool' },
  { icon: 'Pool', text: 'Outdoor Pool' },
  { icon: 'Hottub', text: 'Hot Tub' },
  { icon: 'Hotwater', text: 'Hot Water' },
  { icon: 'Kitchen', text: 'Cooking Basics' },
  { icon: 'Aircondition', text: 'Air Conditioning' },
  { icon: 'Heating', text: 'Heating' },
  { icon: 'Fireplace', text: 'Indoor Fireplace' },
  { icon: 'Wifi', text: 'Wireless Internet' },
  { icon: 'Internet', text: 'Internet' },
  { icon: 'Laptop', text: 'Laptop Friendly Workspace' },
  { icon: 'Kitchen', text: 'Kitchen' },
  { icon: 'Washer', text: 'Washer' },
  { icon: 'Dryer', text: 'Dryer' },
  { icon: 'Hairdryer', text: 'Hair Dryer' },
  { icon: 'Iron', text: 'Iron' },
  { icon: 'Tv', text: 'Cable TV' },
  { icon: 'Tv', text: 'TV' },
  { icon: 'Keypad', text: 'Keypad' },
  { icon: 'Essentials', text: 'Essentials' },
  { icon: 'Firstaid', text: 'First Aid Kit' },
  { icon: 'Shampoo', text: 'Shampoo' },
  { icon: 'Hangers', text: 'Hangers' },
  { icon: 'Smokedetector', text: 'Smoke Detector' },
  { icon: 'Cmdetector', text: 'Carbon Monoxide Detector' },
  { icon: 'Fireextinguisher', text: 'Fire Extinguisher' },
  { icon: 'Safetycard', text: 'Safety Card' },
  { icon: 'Buzzer', text: 'Buzzer/Wireless Intercom' },
  { icon: 'Privateentrance', text: 'Private entrance' },
  { icon: 'Privatelivingroom', text: 'Private livingroom' },
  { icon: 'Locker', text: 'Lock on Bedroom Door' },
  { icon: 'Doorman', text: 'Doorman' },
  { icon: 'Lockbox', text: 'Lockbox' },
  { icon: 'Smartlock', text: 'Smartlock' },
  { icon: 'Familyfriendly', text: 'Family/Kid Friendly' },
  { icon: 'Smoking', text: 'Smoking Allowed' },
  { icon: 'Events', text: 'Suitable for Events' },
  { icon: 'Checkin', text: '24-Hour Check-in' },
  { icon: 'Checkin', text: 'Self Check-in' },
  { icon: 'Breakfast', text: 'Breakfast' },

  { icon: 'Petsallowed', text: 'Pets Allowed' },
  { icon: 'Petslive', text: 'Pets live on this property' },
  { icon: 'Baby', text: 'Baby Monitor' },
  { icon: 'Baby', text: 'Baby bath' },
  { icon: 'Baby', text: 'Babysitter recommendations' },
  { icon: 'Baby', text: 'Pack ’n Play/travel crib' },
  { icon: 'Baby', text: 'Crib' },
  { icon: 'Familyfriendly', text: 'Children’s books and toys' },
  { icon: 'Familyfriendly', text: 'Children’s dinnerware' },
  { icon: 'Bath', text: 'Bathtub' },
  { icon: 'Bath', text: 'Tub with shower bench' },
  { icon: 'Wheelchair', text: 'Accessible-height bed' },
  { icon: 'Wheelchair', text: 'Accessible-height toilet' },
  { icon: 'Bed', text: 'Bed linens' },
  { icon: 'Bed', text: 'Bed linens' },
  { icon: 'Bed', text: 'Wide clearance to bed' },
  { icon: 'Guard', text: 'Stair gates' },
  { icon: 'Guard', text: 'Window guards' },
  { icon: 'Guard', text: 'Table corner guards' },
  { icon: 'Guard', text: 'Fireplace guards' },
  { icon: 'Guard', text: 'Outlet covers' },
  { icon: 'Parking', text: 'Disabled parking spot' },
  { icon: 'Accessibility', text: 'Wide hallway clearance' },
  { icon: 'Accessibility', text: 'Step-free access' },
  { icon: 'Accessibility', text: 'Wide doorway' },
  { icon: 'Accessibility', text: 'Flat smooth pathway to front door' },
  { icon: 'Accessibility', text: 'Path to entrance lit at night' },
  { icon: 'Accessibility', text: 'Grab-rails for shower and toilet' },
  { icon: 'Accessibility', text: 'Roll-in shower with shower bench or chair' },
  { icon: 'Accessibility', text: 'Wide clearance to shower and toilet' },
  { icon: 'Table', text: 'Changing table' },
  { icon: 'Chair', text: 'High chair' },
  { icon: 'Shades', text: 'Room darkening shades' },
  { icon: 'Console', text: 'Game console' },
  { icon: 'Microwave', text: 'Microwave' },
  { icon: 'Coffee', text: 'Coffee maker' },
  { icon: 'Refrigerator', text: 'Refrigerator' },
  { icon: 'Dishwasher', text: 'Dishwasher' },
  { icon: 'Dishes', text: 'Dishes and silverware' },
  { icon: 'Kitchen', text: 'Cookware' },
  { icon: 'Oven', text: 'Oven' },
  { icon: 'Stove', text: 'Stove' },
  { icon: 'Bbq', text: 'BBQ frill' },
  { icon: 'Patio', text: 'Patio or balcony' },
  { icon: 'Garden', text: 'Garden or backyard' },
  { icon: 'Beach', text: 'Beach essentials' },
];

allAmenities.forEach(amenity => {
  amenity.lowerCase = amenity.text.toLowerCase();
});

export default allAmenities;

// const allAmenities = [
//   {
//     "icon": "essentials",
//     "text": "Essentials"
//   },
//   {
//     "icon": "tv",
//     "text": "TV"
//   },
//   {
//     "icon": "cable_tv",
//     "text": "Cable TV"
//   },
//   {
//     "icon": "air_condition",
//     "text": "Air conditioning"
//   },
//   {
//     "icon": "heating",
//     "text": "Heating"
//   },
//   {
//     "icon": "kitchen",
//     "text": "Kitchen"
//   },
//   {
//     "icon": "internet",
//     "text": "Internet"
//   },
//   {
//     "icon": "wireless_internet",
//     "text": "Wireless Internet"
//   },
//   {
//     "icon": "24-hour_check-in",
//     "text": "24-hour check-in"
//   },
//   {
//     "icon": "smartlock",
//     "text": "Smartlock"
//   },
//   {
//     "icon": "lockbox",
//     "text": "Lockbox"
//   },
//   {
//     "icon": "keypad",
//     "text": "Keypad"
//   },
//   {
//     "icon": "doorman_entry",
//     "text": "Doorman Entry"
//   },
//   {
//     "icon": "hot_tub",
//     "text": "Hot tub"
//   },
//   {
//     "icon": "washer",
//     "text": "Washer"
//   },
//   {
//     "icon": "pool",
//     "text": "Pool"
//   },
//   {
//     "icon": "dryer",
//     "text": "Dryer"
//   },
//   {
//     "icon": "breakfast",
//     "text": "Breakfast"
//   },
//   {
//     "icon": "parking",
//     "text": "Free parking on premises"
//   },
//   {
//     "icon": "gym",
//     "text": "Gym"
//   },
//   {
//     "icon": "elevator",
//     "text": "Elevator in building"
//   },
//   {
//     "icon": "indoor_fireplace",
//     "text": "Indoor fireplace"
//   },
//   {
//     "icon": "buzzer/wireless_intercom",
//     "text": "Buzzer/wireless intercom"
//   },
//   {
//     "icon": "doorman",
//     "text": "Doorman"
//   },
//   {
//     "icon": "shampoo",
//     "text": "Shampoo"
//   },
//   {
//     "icon": "hangers",
//     "text": "Hangers"
//   },
//   {
//     "icon": "hair_dryer",
//     "text": "Hair dryer"
//   },
//   {
//     "icon": "iron",
//     "text": "Iron"
//   },
//   {
//     "icon": "laptop_friendly_workspace",
//     "text": "Laptop friendly workspace"
//   },
//   {
//     "icon": "family/kid_friendly",
//     "text": "Family/kid friendly"
//   },
//   {
//     "icon": "smoking_allowed",
//     "text": "Smoking allowed"
//   },
//   {
//     "icon": "suitable_for_events",
//     "text": "Suitable for events"
//   },
//   {
//     "icon": "pets_allowed",
//     "text": "Pets allowed"
//   },
//   {
//     "icon": "pets_live_on_this_property",
//     "text": "Pets live on this property"
//   },
//   {
//     "icon": "wheelchair",
//     "text": "Wheelchair accessible"
//   },
//   {
//     "icon": "tv",
//     "text": "tv"
//   },
//   {
//     "icon": "cable_tv",
//     "text": "cable tv"
//   },
//   {
//     "icon": "internet",
//     "text": "internet"
//   },
//   {
//     "icon": "wireless_internet",
//     "text": "wireless internet"
//   },
//   {
//     "icon": "wheelchair_accessible",
//     "text": "wheelchair accessible"
//   },
//   {
//     "icon": "pool",
//     "text": "pool"
//   },
//   {
//     "icon": "kitchen",
//     "text": "kitchen"
//   },
//   {
//     "icon": "free_parking_on_premises",
//     "text": "free parking on premises"
//   },
//   {
//     "icon": "doorman",
//     "text": "doorman"
//   },
//   {
//     "icon": "gym",
//     "text": "gym"
//   },
//   {
//     "icon": "breakfast",
//     "text": "breakfast"
//   },
//   {
//     "icon": "pets_live_on_this_property",
//     "text": "pets live on this property"
//   },
//   {
//     "icon": "dog(s)",
//     "text": "dog(s)"
//   },
//   {
//     "icon": "cat(s)",
//     "text": "cat(s)"
//   },
//   {
//     "icon": "other_pet(s)",
//     "text": "other pet(s)"
//   },
//   {
//     "icon": "elevator_in_building",
//     "text": "elevator in building"
//   },
//   {
//     "icon": "hot_tub",
//     "text": "hot tub"
//   },
//   {
//     "icon": "indoor_fireplace",
//     "text": "indoor fireplace"
//   },
//   {
//     "icon": "heating",
//     "text": "heating"
//   },
//   {
//     "icon": "washer",
//     "text": "washer"
//   },
//   {
//     "icon": "dryer",
//     "text": "dryer"
//   },
//   {
//     "icon": "smoke_detector",
//     "text": "smoke detector"
//   },
//   {
//     "icon": "carbon_monoxide_detector",
//     "text": "carbon monoxide detector"
//   },
//   {
//     "icon": "first_aid_kit",
//     "text": "first aid kit"
//   },
//   {
//     "icon": "fire_extinguisher",
//     "text": "fire extinguisher"
//   },
//   {
//     "icon": "essentials",
//     "text": "essentials"
//   },
//   {
//     "icon": "shampoo",
//     "text": "shampoo"
//   },
//   {
//     "icon": "hangers",
//     "text": "hangers"
//   },
//   {
//     "icon": "hair_dryer",
//     "text": "hair dryer"
//   },
//   {
//     "icon": "iron",
//     "text": "iron"
//   },
//   {
//     "icon": "laptop_friendly_workspace",
//     "text": "laptop friendly workspace"
//   },
//   {
//     "icon": "private_entrance",
//     "text": "private entrance"
//   },
//   {
//     "icon": "baby_monitor",
//     "text": "baby monitor"
//   },
//   {
//     "icon": "outlet_covers",
//     "text": "outlet covers"
//   },
//   {
//     "icon": "bathtub",
//     "text": "bathtub"
//   },
//   {
//     "icon": "baby_bath",
//     "text": "baby bath"
//   },
//   {
//     "icon": "changing_table",
//     "text": "changing table"
//   },
//   {
//     "icon": "high_chair",
//     "text": "high chair"
//   },
//   {
//     "icon": "stair_gates",
//     "text": "stair gates"
//   },
//   {
//     "icon": "window_guards",
//     "text": "window guards"
//   },
//   {
//     "icon": "table_corner_guards",
//     "text": "table corner guards"
//   },
//   {
//     "icon": "fireplace_guards",
//     "text": "fireplace guards"
//   },
//   {
//     "icon": "babysitter_recommendations",
//     "text": "babysitter recommendations"
//   },
//   {
//     "icon": "crib",
//     "text": "crib"
//   },
//   {
//     "icon": "room-darkening_shades",
//     "text": "room-darkening shades"
//   },
//   {
//     "icon": "game_console",
//     "text": "game console"
//   },
//   {
//     "icon": "hot_water",
//     "text": "hot water"
//   },
//   {
//     "icon": "bed_linens",
//     "text": "bed linens"
//   },
//   {
//     "icon": "extra_pillows_and_blankets",
//     "text": "extra pillows and blankets"
//   },
//   {
//     "icon": "ethernet_connection",
//     "text": "ethernet connection"
//   },
//   {
//     "icon": "pocket_wifi",
//     "text": "pocket wifi"
//   },
//   {
//     "icon": "microwave",
//     "text": "microwave"
//   },
//   {
//     "icon": "coffee_maker",
//     "text": "coffee maker"
//   },
//   {
//     "icon": "refrigerator",
//     "text": "refrigerator"
//   },
//   {
//     "icon": "dishwasher",
//     "text": "dishwasher"
//   },
//   {
//     "icon": "dishes_and_silverware",
//     "text": "dishes and silverware"
//   },
//   {
//     "icon": "cooking_basics",
//     "text": "cooking basics"
//   },
//   {
//     "icon": "oven",
//     "text": "oven"
//   },
//   {
//     "icon": "stove",
//     "text": "stove"
//   },
//   {
//     "icon": "ev_charger",
//     "text": "ev charger"
//   },
//   {
//     "icon": "single_level_home",
//     "text": "single level home"
//   },
//   {
//     "icon": "bbq_grill",
//     "text": "bbq grill"
//   },
//   {
//     "icon": "patio_or_balcony",
//     "text": "patio or balcony"
//   },
//   {
//     "icon": "garden_or_backyard",
//     "text": "garden or backyard"
//   },
//   {
//     "icon": "beach_essentials",
//     "text": "beach essentials"
//   },
//   {
//     "icon": "luggage_dropoff_allowed",
//     "text": "luggage dropoff allowed"
//   },
//   {
//     "icon": "long_term_stays_allowed",
//     "text": "long term stays allowed"
//   },
//   {
//     "icon": "cleaning_before_checkout",
//     "text": "cleaning before checkout"
//   },
//   {
//     "icon": "wide_hallway_clearance",
//     "text": "wide hallway clearance"
//   },
//   {
//     "icon": "step-free_access",
//     "text": "step-free access"
//   },
//   {
//     "icon": "wide_doorway",
//     "text": "wide doorway"
//   },
//   {
//     "icon": "flat_smooth_pathway_to_front_door",
//     "text": "flat smooth pathway to front door"
//   },
//   {
//     "icon": "path_to_entrance_lit_at_night",
//     "text": "path to entrance lit at night"
//   },
//   {
//     "icon": "disabled_parking_spot",
//     "text": "disabled parking spot"
//   },
//   {
//     "icon": "wide_clearance_to_bed",
//     "text": "wide clearance to bed"
//   },
//   {
//     "icon": "accessible-height_bed",
//     "text": "accessible-height bed"
//   },
//   {
//     "icon": "firm_mattress",
//     "text": "firm mattress"
//   },
//   {
//     "icon": "grab-rails_for_shower_and_toilet",
//     "text": "grab-rails for shower and toilet"
//   },
//   {
//     "icon": "tub_with_shower_bench",
//     "text": "tub with shower bench"
//   },
//   {
//     "icon": "roll-in_shower_with_shower_bench_or_chair",
//     "text": "roll-in shower with shower bench or chair"
//   },
//   {
//     "icon": "accessible-height_toilet",
//     "text": "accessible-height toilet"
//   },
//   {
//     "icon": "wide_clearance_to_shower_and_toilet",
//     "text": "wide clearance to shower and toilet"
//   }
// ];

// const amenitites = {
//   elevator: "Elevator in building",
//   parking: "Free Parking on Premises/street",
//   wheelchair: "Wheelchair Accessible",
//   gym: "Gym",
//   pool: "Pool",
//   hot_tub: "Hot tub",
//   air_condition: "Air conditioning",
//   heating: "Heating",
//   fireplace: "Indoor fireplace",
//   wifi: "Wireless Internet",
//   internet: "Internet",
//
//
//   tv: "TV",
//   "Cable TV",
//   "Internet",
//   kitchen: "Kitchen",
//   doorman: "Doorman",
//   breakfast: "Breakfast",
//   pets_live: "Pets live on this property",
//   "Dog(s)",
//   "Cat(s)",
//   "Other pet(s)",
//   washer: "Washer",
//   dryer: "Dryer",
//   smoke_detector: "Smoke detector",
//   CM_detector: "Carbon monoxide detector",
//   first_aid: "First aid kit",
//   fire_extinguisher: "Fire extinguisher",
//   essentials: "Essentials",
//   shampoo: "Shampoo",
//   hangers: "Hangers",
//   hair_dryer: "Hair dryer",
//   iron: "Iron",
//   laptop: "Laptop friendly workspace",
//   private_entrance: "Private entrance",
//   "Baby monitor",
//   "Outlet covers",
//   "Bathtub",
//   "Baby bath",
//   "Changing table",
//   "High chair",
//   "Stair gates",
//   "Children’s books and toys",
//   "Window guards",
//   "Table corner guards",
//   "Fireplace guards",
//   "Babysitter recommendations",
//   "Crib",
//   "Pack ’n Play/travel crib",
//   "Room-darkening shades",
//   "Children’s dinnerware",
//   "Game console",
//   "Hot water",
//   "Bed linens",
//   "Extra pillows and blankets",
//   "Ethernet connection",
//   "Pocket wifi",
//   "Microwave",
//   "Coffee maker",
//   "Refrigerator",
//   "Dishwasher",
//   "Dishes and silverware",
//   "Cooking basics",
//   "Oven",
//   "Stove",
//   "EV charger",
//   "Single level home",
//   "BBQ grill",
//   "Patio or balcony",
//   "Garden or backyard",
//   "Beach essentials",
//   "Luggage dropoff allowed",
//   "Long term stays allowed",
//   "Cleaning before checkout",
//   "Wide hallway clearance",
//   "Step-free access",
//   "Wide doorway",
//   "Flat smooth pathway to front door",
//   "Path to entrance lit at night",
//   "Disabled parking spot",
//   "Accessible-height bed",
//   "Firm mattress",
//   "Grab-rails for shower and toilet",
//   "Wide clearance to bed",
//   "Grab-rails for shower and toilet",
//   "Tub with shower bench",
//   "Roll-in shower with shower bench or chair",
//   "Accessible-height toilet",
//   "Wide clearance to shower and toilet"
// };
