import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../icon/Icon';

const CalendarTooltipNote = ({ note }) => (
  <div className="flex-start-center text-left">
    <div className="flex-1">
      <Icon icon="IcoNotice" color="gray-lighter" />
    </div>
    <div className="text-white font-size-md font-weight-bold ml-2">{note}</div>
  </div>
);

CalendarTooltipNote.propTypes = {
  note: PropTypes.string.isRequired
};
export default CalendarTooltipNote;
