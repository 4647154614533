import React from 'react';
import Checkbox from '@guestyci/foundation/Checkbox';
import FormField from '@guestyci/foundation/FormField';
import TextField from '@guestyci/foundation/TextField';

import {invoicePageInvoiceIntlId} from 'utils/intlIds';
import useTranslations from '@guestyci/localize/useTranslations';

const translations = [
  {
    id: invoicePageInvoiceIntlId('reuse_checkbox_label'),
    d: 'Save payment information to my account for future reservations',
  },
];

const ReuseCheckbox = () => {
  const [
    reuseLabel,
  ] = useTranslations(translations);

  return (
    <FormField
      name="reusePaymentMethod"
      type="checkbox"
      style={{ marginLeft: -10 }}
    >
      <Checkbox>
        <TextField color="default">{reuseLabel}</TextField>
      </Checkbox>
    </FormField>
  );
};

export default ReuseCheckbox;
