import { put, take, fork, call } from 'redux-saga/effects';
import Resource from '@guestyci/agni';
import ActionTypes from 'redux/actions';

import { QUOTES_URL, getQuotesListingUrl, geBaseUrl } from 'constants/url.constants';

const {
  FETCH_QUOTES_REQUEST,
  FETCH_QUOTES_SUCCESS,
  FETCH_QUOTES_FAILURE,
  FETCH_LISTING_REQUEST,
  FETCH_LISTING_SUCCESS,
  FETCH_LISTING_FAILURE,
} = ActionTypes;

const { api } = Resource.create({
  baseURL : geBaseUrl(),
})

export function* takeOneAndBlock(pattern, worker, ...args) {
  const task = yield fork(function* () {
    while (true) {
      const action = yield take(pattern)
      yield call(worker, ...args, action)
    }
  })
  return task
}

export function* fetchQuotes(action) {
  const { id } = action.payload;

  if (!id) yield put({ type: FETCH_QUOTES_FAILURE });
  try {
    const { data } = yield call(api.get, `${QUOTES_URL}/${id}`);

    yield put({ type: FETCH_QUOTES_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: FETCH_QUOTES_FAILURE });
  }
}

export function* fetchListing(action) {
  const { quotesId, listingId } = action.payload;

  if (!quotesId || !listingId) yield put({ type: FETCH_LISTING_FAILURE });

  try {
    const { data } = yield call(
      api.get,
      `${getQuotesListingUrl({ quotesId, listingId })}`,
    );

    yield put({ type: FETCH_LISTING_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: FETCH_LISTING_FAILURE });
  }
}

export default [
  takeOneAndBlock(FETCH_QUOTES_REQUEST, fetchQuotes),
  takeOneAndBlock(FETCH_LISTING_REQUEST, fetchListing),
];
