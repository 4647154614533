import React from 'react';

import { Row } from '@guestyci/foundation/Layout';

import Step from '../Step/Step';

const Steps = ({ stepsList, activeStep }) => (
  <Row justify="center">
    {stepsList.map((step, index) => {
      const isDone = activeStep > index;
      const isActive = activeStep === index;
      return (
        <Step
          key={`step-item-${step}`}
          label={step}
          isDone={isDone}
          isActive={isActive}
          stepIndex={index}
        />
      );
    })}
  </Row>
);
export default Steps;
