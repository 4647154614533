import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import Divider from './Divider';

const TextDivider = ({
  children, style, textStyle, color, textColor, width, className, textClassName,
}) => (
  <div className={cn('flex-center w-100', className)}>
    <Divider className="flex-1" width={width} style={style} color={color} />
    <span className={cn('nowrap m-2', { [`text-${textColor}`]: textColor }, textClassName)} style={textStyle}>{children}</span>
    <Divider className="flex-1" width={width} style={style} color={color} />
  </div>
);

TextDivider.defaultProps = {
  color: 'gray-light',
  textColor: 'muted',
  className: '',
  textStyle: {},
  style: {},
};

TextDivider.propTypes = {
  /** Set divider line color */
  color: PropTypes.string,
  /** Set divider text color */
  textColor: PropTypes.string,
  /** Set additional class names to divider */
  className: PropTypes.string,
  /** Set style to divider text */
  textStyle: PropTypes.shape(),
  /** Set style to divider line */
  style: PropTypes.shape(),
  /** The divider message body */
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default TextDivider;
