import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ActionTooltipType } from '../../../../constants/enums';
import RaisedButton from '../../buttons/RaisedButton';
import Link from '../../buttons/Link';

class ActionTooltipButton extends Component {
  getActionSettings() {
    const { actionType } = this.props;
    const buttonComponents = { RaisedButton, Link };
    let ActionComponent = buttonComponents.RaisedButton;
    let className = '';
    if (actionType !== ActionTooltipType.Button) {
      ActionComponent = buttonComponents.Link;
      className = 'text-hover-blue-dark-theme';
    }
    return ({
      ActionComponent,
      className,
    });
  }

  handleClick = (e) => {
    const { actionHref, onAction } = this.props;
    if (actionHref) {
      window.open(actionHref, '_blank');
    }
    onAction(e);
  };
  render() {
    const { actionText } = this.props;
    const { ActionComponent, className } = this.getActionSettings();

    return (
      <ActionComponent
        className={className}
        onClick={this.handleClick}
      >
        <div className="flex-center">
          <span className="">{actionText}</span>
        </div>
      </ActionComponent>
    );
  }
}

ActionTooltipButton.defaultProps = {
  actionHref: '',
  onAction: (() => {}),
};
ActionTooltipButton.propTypes = {
  /** Sets the type of the action */
  actionType: PropTypes.oneOf([ActionTooltipType.Button, ActionTooltipType.Link]).isRequired,
  /** Sets the text of the action */
  actionText: PropTypes.string.isRequired,
  /** Sets the Href of the action */
  actionHref: PropTypes.string,
  /** Callback(optional) to be executed when the action is clicked */
  onAction: PropTypes.func,
};

export default ActionTooltipButton;
