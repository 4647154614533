import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import withStyles from 'react-jss';

const styles = {
  bullet: {
    width: 4,
    height: 4,
    borderRadius: '50%',
  },
};
const Bullet = ({
  classes, color, className, style,
}) => (
  <div className={cn(classes.bullet, { [`bg-${color}`]: color }, className)} style={style} />
);

Bullet.defaultProps = {
  color: 'muted',
  className: '',
  style: {},
};

Bullet.propTypes = {
  /** Bullet color */
  color: PropTypes.string,
  /** Additional class for bullet root level */
  className: PropTypes.string,
  /** Additional style to bullet root level */
  style: PropTypes.shape(),
};

export const BulletComponent = Bullet;
export default withStyles(styles)(Bullet);
