import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import cn from 'classnames';
import BaseButton from './BaseButton';

const RaisedButton = ({
  className, children, onClick, style, disabled, isLoading, ...props
}) => (
  <BaseButton
    isLoading={isLoading}
    className={cn('font-size-md', className)}
    onClick={onClick}
    variant="raised"
    color="primary"
    style={style}
    disabled={disabled}
    {...props}
  >
    {children}
  </BaseButton>
);

RaisedButton.defaultProps = {
  className: '',
  style: {},
  disabled: false,
  isLoading: false,
  onClick: noop,
};

RaisedButton.propTypes = {
  /** Button click callback function */
  onClick: PropTypes.func,
  /** Button inner children */
  children: PropTypes.node.isRequired,
  /** Button indicator whether disabled or not */
  disabled: PropTypes.bool,
  /** Button additional classes */
  className: PropTypes.string,
  /** Button additional styling */
  style: PropTypes.shape(),
  /** Indicator for  button loading state  */
  isLoading: PropTypes.bool,
};
export default RaisedButton;
