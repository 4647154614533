import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isUndefined from 'lodash/isUndefined';
import cn from 'classnames';
import Popover from '../../popover/Popover';
import withPopover from '../../popover/withPopover';
import { getPlaceholder } from '../formUtility';

class FormFieldComponent extends Component {
  componentDidMount() {
    const { input, children } = this.props;
    const propsValue = this.getChildValue(children.props);
    if (!input.value && propsValue) {
      input.onChange(propsValue);
    }
  }

  onChangeValue = (...args) => {
    const {
      input,
      children: {
        props: { onChange, onSelect }
      }
    } = this.props;
    if (onChange) {
      onChange(...args);
    }
    if (onSelect) {
      onSelect(...args);
    }
    input.onChange(...args);
  };

  getChildValue = props => props.value || props.defaultValue;

  getFieldValue = (propsValue, inputValue, isVisited) => {
    if (isUndefined(propsValue)) {
      return inputValue;
    }
    if (isVisited) {
      return inputValue;
    }
    return propsValue;
  };

  handleFocus = () => {
    const { input, openPopover, meta } = this.props;
    if (!meta.visited) {
      input.onFocus();
      input.onChange(input.value);
    }
    if (meta.touched && meta.error) {
      input.onFocus();
      console.log('error', meta.error);
      openPopover();
    }
  };

  handleBlur = () => {
    const { input, closePopover } = this.props;
    input.onBlur();
    closePopover();
  };

  render() {
    const {
      isPopoverOpen,
      className,
      children,
      meta,
      input,
      style,
      popoverId,
      required
    } = this.props;
    const { value, placeholder } = children.props;
    return (
      <Popover
        isOpen={isPopoverOpen}
        body={meta.error}
        id={popoverId}
        theme="error"
        containerClassName="d-flex-fill"
        className="font-weight-bold"
      >
        <div
          className={cn(
            className,
            'form-field-component-wrapper',
            'transition-borderColor',
            'w-fill',
            'b-1',
            `border-${meta.touched && meta.error ? 'red' : 'transparent'}`
          )}
          onBlur={this.handleBlur}
          onFocus={this.handleFocus}
          tabIndex={-1}
          style={style}
        >
          {React.cloneElement(children, {
            ...input,
            onChange: this.onChangeValue,
            required,
            placeholder: getPlaceholder(placeholder, required),
            value: this.getFieldValue(value, input.value, meta.visited)
          })}
        </div>
      </Popover>
    );
  }
}

FormFieldComponent.defaultProps = {
  className: '',
  style: {},
  popoverId: null,
  required: false
};

FormFieldComponent.propTypes = {
  children: PropTypes.node.isRequired,
  required: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.shape(),
  input: PropTypes.shape().isRequired,
  meta: PropTypes.shape().isRequired,
  popoverId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default withPopover(FormFieldComponent);
