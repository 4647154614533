import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { filter, identity } from 'ramda';

import {
  getFormattedTimeDifference,
  constructDateRange,
  pluralize,
} from 'utils/helpers';
import { EXPIRED_PAGE } from 'constants/route.constants';
import useTranslations from '@guestyci/localize/useTranslations';
import { headerIntlId } from 'utils/intlIds';
import { guestsTranslation, offerExpiresTranslation } from 'utils/translations';
import { useExpirationDate } from 'hooks/useExpirationDate';
import {
  ExpiresTag,
  GuestTag,
  HeaderTag,
  MessageTag,
  Tag,
} from './Header.component.styled';

const Header = ({
  expireAt,
  filters: { accommodates, checkInDateLocalized, checkOutDateLocalized },
  guest: { firstName },
  history,
  guestsCount
}) => {
  const liveExpiration = useExpirationDate(expireAt, () => history.push(EXPIRED_PAGE));
  const translations = [
    {
      id: headerIntlId('hi_title'),
      d: 'Hi { firstName },',
      variables: { firstName },
    },
    {
      id: headerIntlId('have_look_message'),
      d: 'Have a look at the best options we found for you',
    },
    offerExpiresTranslation,
    guestsTranslation,
  ];
  
  const [hiTitle, haveLookMessage, offerExpiresCaption, guests] =
    useTranslations(translations, [firstName]);
  const tags = useMemo(
    () => filter(identity, [
      constructDateRange(checkInDateLocalized, checkOutDateLocalized),
      pluralize(guests, guestsCount),
    ]),
    [checkInDateLocalized, checkOutDateLocalized, guests, guestsCount],
  );

  return (
    <HeaderTag className="w-100">
      <div className="d-flex flex-column w-100">
        <GuestTag className="color-grayish-blue">{hiTitle}</GuestTag>
        <MessageTag className="pt-4 pb-2">{haveLookMessage}</MessageTag>
        <div className="d-flex flex-wrap justify-content-between">
          <div className="d-flex flex-wrap align-items-start mt-2">
            {tags.map((tag, i) => (
              <Tag
                key={i}
                className="d-flex flex-shrink-0 font-size-lg border-radius-5 p-2 mr-2 mb-2"
              >
                {tag}
              </Tag>
            ))}
          </div>
          {expireAt && (
            <div className="d-flex align-items-center">
              <div className="d-flex flex-wrap align-items-baseline mb-2 mt-2">
                <div className="mr-2">{offerExpiresCaption}</div>
                <ExpiresTag className="font-size-21">
                  {getFormattedTimeDifference(liveExpiration)}
                </ExpiresTag>
              </div>
            </div>
          )}
        </div>
      </div>
    </HeaderTag>
  );
};

Header.propTypes = {
  expireAt: PropTypes.string,
  filters: PropTypes.shape({
    accommodates: PropTypes.number.isRequired,
    checkInDateLocalized: PropTypes.string.isRequired,
    checkOutDateLocalized: PropTypes.string.isRequired,
  }).isRequired,
  guest: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.object,
  guestsCount: PropTypes.number,
};

export default Header;
