import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import cn from 'classnames';
import withStyles from 'react-jss';
import ActionButton from '../buttons/ActionButton';
import IconButton from '../buttons/IconButton';
import withFocus from '../../compose/withFocus';
import Fade from '../../utils/transiton/Fade';

const styles = {
  input: {
    '&:focus': {
      borderColor: 'transparent',
      borderRadius: 0,
      boxShadow: 'none'
    }
  },
  dropDownInputField: {
    '&.disabled-lite': { cursor: 'not-allowed' }
  }
};
const DropdownInputField = ({
  onQueryChange,
  placeholder,
  onIconClick,
  noAction,
  inputClassName,
  onClearQuery,
  isOpen,
  searchable,
  value,
  classes,
  onClearSelected,
  query,
  className,
  name,
  onFocus,
  onBlur,
  isFocused,
  noClear,
  disabled
}) => (
  <div
    className={cn(
      'b-1 border-gray-light flex-start-center pos-relative font-size-md d-flex border-radius-2',
      className,
      classes.dropDownInputField,
      { 'bg-white': !disabled },
      { 'bg-gray-lighter': disabled },
      { 'text-muted': disabled },
      { 'disabled-lite': disabled }
    )}
  >
    {searchable && (
      <input
        value={isFocused ? query : value}
        onFocus={onFocus}
        onBlur={onBlur}
        type="text"
        name={name}
        placeholder={placeholder}
        onChange={onQueryChange}
        className={cn(
          'input-element pl-4 flex-1 height-46 border-0 b-0 bg-transparent border-gray-light br-1',
          classes.input,
          inputClassName
        )}
        disabled={disabled}
      />
    )}
    {!searchable && (
      <div className="flex-1 px-4 font-size-md" name={name}>
        {value ? (
          <div> {value} </div>
        ) : (
          <div className="text-gray-dark">{placeholder}</div>
        )}
      </div>
    )}
    {(!noClear || searchable) && (
      <Fade show={!!value || !!query} speed="quick">
        <IconButton
          className="mr-3"
          onClick={query ? onClearQuery : onClearSelected}
          icon="BtnDelete"
          iconHeight={10}
          iconWidth={10}
        />
      </Fade>
    )}
    {!noAction && (
      <ActionButton
        className={cn('bl-1 b-0 p-0', classes.actionBtn)}
        iconClassName={cn('transition-transform', { 'transform-180': isOpen })}
        icon="BtnArrowDown"
        iconWidth={9}
        iconHeight={9}
        iconColor={isOpen ? 'blue' : 'gray-dark'}
        onClick={onIconClick}
        disabled={disabled}
      />
    )}
  </div>
);

DropdownInputField.defaultProps = {
  placeholder: 'Select',
  value: '',
  searchable: false,
  onClearQuery: noop,
  onClearSelected: noop,
  onIconClick: noop,
  query: '',
  noAction: false,
  isOpen: false,
  className: '',
  inputClassName: '',
  name: '',
  noClear: false,
  disabled: false
};

DropdownInputField.propTypes = {
  /** Function to be called on input value changed */
  onQueryChange: PropTypes.func.isRequired,
  /** String to populate the placeholder */
  placeholder: PropTypes.string,
  /** Function to be called when clicking the input side icon */
  onIconClick: PropTypes.func,
  /** Boolean to control the dropdown list state */
  isOpen: PropTypes.bool,
  /** String, search value or input */
  value: PropTypes.string,
  /** Indicator whether or not the input field is searchable */
  searchable: PropTypes.bool,
  /** Callback for clear field query */
  onClearQuery: PropTypes.func,
  /** Callback for clear field selected value */
  onClearSelected: PropTypes.func,
  /** The query search for input */
  query: PropTypes.string,
  /** Remove action button from input field */
  noAction: PropTypes.bool,
  /** Indicator whether or not we are disabling the clear button */
  noClear: PropTypes.bool,
  /** Additional class for root level */
  className: PropTypes.string,
  /** Additional class for input level */
  inputClassName: PropTypes.string,
  /** item name */
  name: PropTypes.string,
  /** Indicator for disabled status */
  disabled: PropTypes.bool
};

export default withStyles(styles)(withFocus(DropdownInputField));
