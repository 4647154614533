import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

const InfoLoadingState = ({ byUs }) => (
  <div
    className={cn('d-flex align-items-center bg-gray-lightest text-muted', {
      'justify-content-end': byUs,
      'pr-4': byUs,
      'pl-4': !byUs,
    })}
    style={{ minHeight: '10px', width: '40%' }}
  />
);

const MessageBodyLoadingState = ({ byUs }) => (
  <div className={cn(
    'align-items-center bg-gray-lightest mb-2 p-4',
    { 'by-guest': !byUs },
    { 'by-us': byUs },
  )}
  />
);

const ThreadMessageIconLoadingState = ({ byUs }) => (
  <div
    className={cn('bg-gray-lightest', { 'mr-3': !byUs, 'ml-3': byUs })}
    style={{
      height: '40px',
      width: '40px',
      borderRadius: '50%',
    }}
  />
);

const ThreadMessageLoadingState = ({ byUs, className }) => (
  <div className={cn('threadMessageBubble d-flex', { 'flex-row-reverse by-us': byUs }, className)}>
    <ThreadMessageIconLoadingState byUs={byUs} />
    <div className="d-flex flex-column flex-1">
      <MessageBodyLoadingState byUs={byUs} />
      <InfoLoadingState byUs={byUs} />
    </div>
  </div>
);

ThreadMessageLoadingState.defaultProps = {
  byUs: false,
  className: 'null',
};

ThreadMessageLoadingState.propTypes = {
  /**
   * Bool, decide the render side of the message,
   */
  byUs: PropTypes.bool,
  /**
   * className. extra classes
   */
  className: PropTypes.string,
};

export default ThreadMessageLoadingState;
