import styled from 'styled-components';
import { mobile } from 'constants/css/breakpoints';

export const Dot = styled.div`
  background: var(--black);
  border-radius: 50%;
  height: 3px;
  width: 3px;
`;

export const ListingTag = styled.div`
  background: var(--white);
  box-shadow: 0 20px 60px 0 rgba(39, 43, 45, 0.2);
  margin-top: 30px;
  @media ${mobile} {
    flex-direction: column;
    height: auto;
    :first-child {
      margin-top: 0;
    }
  }
`;

export const ImgTag = styled.div`
  background-image: url(${props => props.background});
  background-repeat: no-repeat;
  background-size: cover;
  width: 300px;
  height: 100%;
  @media ${mobile} {
    background-position: 50%;
    height: 200px;
    width: 100%;
  }
`;

export const MainContentTag = styled.div`
  width: 100%;
  padding: 47px 40px;
  @media ${mobile} {
    padding: 20px;
  }
`;

export const Title = styled.div`
  @media ${mobile} {
    border-bottom: 1px solid var(--gray-light);
  }
`;

export const ListingName = styled.div`
  border-bottom: 1px solid var(--gray-light);
  line-height: 1;
  @media ${mobile} {
    border-bottom: none;
  }
`;

export const Tag = styled.div`
  letter-spacing: 0.2px;
  line-height: 1.21;
`;

export const RightPartTag = styled.div`
  min-width: 150px;
  width: 225px;
`;

export const TotalPriceTag = styled.div`
  line-height: 1;
`;

export const TwoLinedText = styled.div`
  white-space: pre-wrap;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  width: 100%;
  overflow: hidden;
  -webkit-line-clamp: 2;
`;
