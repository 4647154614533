import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Row from '../../../layout/row/Row';
import Icon from '../../icon/Icon';
import TextField from '../../textField/TextField';

const isStart = position => position === 'start';
const iconForType = (type) => {
  switch (type) {
    case 'warning':
      return <Icon icon="IcoNotice" color="yellow" />;
    case 'success':
      return <Icon icon="IcoApproved" color="green" />;
    case 'error':
      return <Icon icon="IcoDeclined" color="red" />;
    default:
      return null;
  }
};

const Alert = ({ message, iconPosition, type }) => {
  const isIconStart = isStart(iconPosition);

  return (
    <Fragment>
      {message ? (
        <Row spacing="sm">
          {isIconStart && iconForType(type)}
          <TextField>{message}</TextField>
          {!isIconStart && iconForType(type)}
        </Row>
      ) : null}
    </Fragment>
  );
};

Alert.defaultProps = {
  message: '',
  iconPosition: 'start',
  type: 'warning',
};

Alert.propTypes = {
  /** Message for Alert, if none provided will not render anything */
  message: PropTypes.string,
  /** Position for the icon - start or end */
  iconPosition: PropTypes.oneOf(['start', 'end']),
  /** Type for the icon - success, warning, danger */
  type: PropTypes.oneOf(['success', 'warning', 'error']),
};

export default Alert;
