import { connectRouter } from 'connected-react-router';

const req = require.context('redux/reducers', false, /\.js$/);

const reducers = req.keys().reduce((acc, path) => {
  acc[path.replace('.reducer.js', '').replace(/[^\w]/g, '').replace('reducers', '')] = req(path).default;
  return acc;
}, {});

export default history => ({
  router: connectRouter(history),
  ...reducers,
});
