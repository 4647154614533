import styled from 'styled-components';
import { mobile, tablet } from 'constants/css/breakpoints';
import { Icon } from '@guestyci/atomic-design/dist/v2/components';

export const StyledImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 450px;

  @media ${mobile} {
    height: auto;
  }
`;

export const ImageBackground = styled.img`
  object-fit: ${({ isSingleImage }) => isSingleImage ? 'contain' : 'fill '};
  width: 100%;
  height: 450px;
  object-fit: contain;

  @media ${mobile} {
    aspect-ratio: 1/0.5;
    height: auto;
    object-fit: cover;
  }

  @media ${tablet} {
    object-fit: cover;
  }
`;

export const Arrow = styled.div`
  min-width: 10px;
  pointer-events: none;
  top: 0;
  width: 20%;
  z-index: 1;
`;

export const StyledIcon = styled(Icon)`
  fill: white;
  height: 72px;
  width: 40px;

  @media ${mobile} {
    height: 18px;
    width: 10px;
  }

  @media ${tablet} {
    height: 18px;
    width: 10px;
  }
`;
