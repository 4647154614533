import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'react-jss';
import IconDropdown from '../../dropdowns/iconDropdown/IconDropdown';
import { colors, fontSize } from './richTextEditorConf';
import ToolbarActionButton from './RichTextEditorToolbarActionButton';
import { grayLight, grayLightest } from '../../../../themes/colors';
import { Theme } from '../../../../constants/enums';
import Row from '../../../layout/row/Row';
import RichTextEditorToolbarItem from './RichTextEditorToolbarItem';

const getBorderFromProps = props =>
  `1px solid ${props.theme === Theme.Light ? grayLightest : grayLight}`;

const styles = {
  icon: {
    width: 25,
    height: 25,
    borderColor: grayLightest,
    border: 'none',
    padding: '5px 0 !important',
    '&:hover': {
      border: getBorderFromProps,
    },
  },
};

const colorArray = colors.map(color => ({
  label: <div style={{ width: 50, height: 16, background: color }} />,
  value: color,
}));

const Toolbar = ({
  toggleInlineType,
  toggleBlockType,
  editorState,
  style,
  classes,
  theme,
}) => {
  const currentStyles = editorState.getCurrentInlineStyle();

  return (
    <div
      className={`rich-text-toolbar px-1 d-flex-fill min-height-35 bb-1 ${
        theme === Theme.Light
          ? 'border-gray-lightest bg-white '
          : 'border-gray-light bg-gray-lightest'
      }`}
      style={{ ...style, userSelect: 'none' }}
    >
      <Row dense align="center">
        <RichTextEditorToolbarItem>
          <IconDropdown
            color="transparent"
            icon="BtnRichTextSize"
            id="font-size-control"
            containerClassName="flex-center"
            persistSelection
            width={12}
            height={8}
            onSelect={toggleInlineType}
            options={fontSize}
            iconClassName={classes.icon}
          />
        </RichTextEditorToolbarItem>
        <ToolbarActionButton
          onToggle={toggleInlineType}
          type="BOLD"
          icon="BtnRichBold"
          iconWidth={8}
          iconHeight={9}
          active={currentStyles.has('BOLD')}
          theme={theme}
        />
        <ToolbarActionButton
          onToggle={toggleInlineType}
          type="ITALIC"
          icon="BtnRichItali"
          iconWidth={6}
          iconHeight={9}
          active={currentStyles.has('ITALIC')}
          theme={theme}
        />
        <ToolbarActionButton
          onToggle={toggleInlineType}
          type="UNDERLINE"
          icon="BtnRichUnderline"
          iconWidth={7}
          iconHeight={9}
          active={currentStyles.has('UNDERLINE')}
          theme={theme}
        />
        <RichTextEditorToolbarItem>
          <IconDropdown
            color="transparent"
            onSelect={toggleInlineType}
            id="color-control"
            icon="BtnRichTextColor"
            width={13}
            height={12}
            persistSelection
            iconClassName={classes.icon}
            options={colorArray}
          />
        </RichTextEditorToolbarItem>
        <ToolbarActionButton
          onToggle={toggleBlockType}
          icon="BtnRichBullets"
          iconWidth={11}
          iconHeight={9}
          type="unordered-list-item"
          theme={theme}
        />

        <ToolbarActionButton
          onToggle={toggleBlockType}
          icon="BtnRichNumbers"
          iconWidth={12}
          iconHeight={10}
          type="ordered-list-item"
          theme={theme}
        />
      </Row>
    </div>
  );
};

Toolbar.defaultProps = {
  style: {},
};

Toolbar.propTypes = {
  style: PropTypes.shape(),
  theme: PropTypes.string.isRequired,
};

export default withStyles(styles)(Toolbar);
