export const localeDropdownMenu = [
  { value: 'en-US', label: 'English' },
  { value: 'fr', label: 'French (Français)' },
  { value: 'de', label: 'German (Deutsch)' },
  { value: 'it', label: 'Italian (Italiano)' },
  { value: 'es-ES', label: 'Spanish (Español)' },
  { value: 'nl', label: 'Dutch (Nederlands)' },
  { value: 'ja', label: 'Japanese (日本語)' },
  { value: 'cs', label: 'Czech (Čeština)' },
  { value: 'da', label: 'Danish (Dansk)' },
  { value: 'el', label: 'Greek (ελληνικά)' },
  { value: 'hr', label: 'Croatian (Hrvatski jezik)' },
  { value: 'hu', label: 'Hungarian (Magyar)' },
  { value: 'pl', label: 'Polish (Polszczyzna)' },
  { value: 'ru', label: 'Russian (русский)' },
  { value: 'sk', label: 'Slovak (Slovenčina)' },
  { value: 'pt-BR', label: 'Portuguese Brazil (Português)' },
  { value: 'zh-CN', label: 'Chinese (简体中文)' }
];

export const currencyTypes = [
  'eur',
  'usd',
  'gbp',
  'ils',
  'krw',
  'jpy',
  'hkd',
  'brl',
  'czk'
];

export const currencyIdsMap = new Map(
  currencyTypes.map(curr => [curr, `common:formatted_currency.${curr}`])
);

export const dateIdsMap = new Map([
  ['short', 'common:formatted_date.short'],
  ['medium', 'common:formatted_date.medium'],
  ['long', 'common:formatted_date.long'],
  ['full', 'common:formatted_date.full']
]);

export const timeIdsMap = new Map([
  ['short', 'common:formatted_time.short'],
  ['medium', 'common:formatted_time.medium'],
  ['long', 'common:formatted_time.long'],
  ['full', 'common:formatted_time.full']
]);

export const MomentFormatTypes = {
  ShortDate: 'shortDate',
  FullDate: 'fullDate',
  Date: 'date',
  MMMM_YYYY: 'mmmm yyyy',
  MMM_YYYY: 'mmm yyyy',
  ShortMonthYear: 'mmm yy',
  Time: 'time'
};
