import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'react-jss';
import cn from 'classnames';

import Icon from '../../icon/Icon';
import withHover from '../../../compose/withHover';

const styles = {
  button: {
    padding: 0,
    '&:active': {
      border: 'none',
      boxShadow: 'inset 0 1px 3px 0 rgba(39, 43, 45, 0.2)',
    },
  },
  size: ({ lg }) => ({
    width: lg ? 36 : 27,
    height: lg ? 36 : 27,
  }),
  shadow: { 'box-shadow': 'inset 0 1px 3px 0 rgba(39, 43, 45, 0.2)' },
};

class RoundedIconCheckbox extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isChecked: this.props.checked || false,
    };
  }

  onChange = () => {
    const {
      value, id, onChange, name,
    } = this.props;
    const { isChecked } = this.state;

    onChange(value || !isChecked, id || name, !isChecked);
    this.setState({ isChecked: !isChecked });
  };

  changeIconColor() {
    const { isChecked } = this.state;
    const { isHovering } = this.props;

    if (isHovering && isChecked) {
      return 'muted';
    } else if (isChecked || isHovering) {
      return 'blue';
    }

    return 'muted';
  }

  render() {
    const {
      icon, onHoverLeave, onHoverEnter, classes: { button, size, shadow },
      className, iconHeight, iconWidth,
    } = this.props;
    const { isChecked } = this.state;
    return (
      <button
        className={cn(
          'flex-center', 'border-radius-4', 'border-gray-light', 'clickable',
          isChecked ? 'bg-gray-light' : 'bg-gray-lightest', { [shadow]: isChecked }, button, size, className,
        )}
        onClick={this.onChange}
        onMouseEnter={onHoverEnter}
        onMouseLeave={onHoverLeave}
      >
        <Icon
          icon={icon}
          height={iconHeight}
          width={iconWidth}
          color={this.changeIconColor()}
        />
      </button>
    );
  }
}

RoundedIconCheckbox.defaultProps = {
  className: '',
  id: undefined,
  value: '',
  name: '',
  iconHeight: 20,
  iconWidth: 20,
  checked: false,
  lg: false,
};

RoundedIconCheckbox.propTypes = {
  /** A specified Icon from the Guesty Icons bank */
  icon: PropTypes.string.isRequired,
  /** Function to toggle the check box click the cb is (value, id || name, state) */
  onChange: PropTypes.func.isRequired,
  /** A unique id */
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** An indicator */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** A name */
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Additional class to add to the checkbox */
  className: PropTypes.string,
  /** Icon Height */
  iconHeight: PropTypes.number,
  /** Icon Width */
  iconWidth: PropTypes.number,
  /** checked indicator */
  checked: PropTypes.bool,
  /** Indicator whether to use large size - 36px or md -25px */
  lg: PropTypes.bool,
};

export const RoundedCheckboxComponent = RoundedIconCheckbox;
export default withHover(withStyles(styles)(RoundedIconCheckbox));
