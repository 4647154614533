import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import withSelectionGroup from '../withSelectionGroup';

class RadioGroup extends Component {
  changeRadio = (value, childName, id, generatedChildId) => {
    this.props.onOptionClicked(generatedChildId);
    this.props.onChange(value, childName || id);
  };

  render() {
    const {
      options, center, className, fill, style, col,
    } = this.props;


    return (
      <div
        className={cn('d-flex-fill', 'flex-column', className)}
        style={style}
      >
        <div className={cn('d-flex h-fill')}>
          {
            <div className={cn('relative', { 'w-100': fill })}>
              <div
                className={cn(
                  'radioGroup-wrapper d-flex text-gray-dark',
                  { 'flex-column': col },
                  { 'flex-center': center },
                  { 'flex-space-between-center': fill },
                )}
              >
                {Array.from(options.values(), obj => (React.cloneElement(obj.child, {
                    key: obj.uid,
                    onChange: this.changeRadio,
                    checked: obj.checked,
                    id: obj.uid,
                    generatedChildId: obj.uid,
                    name: obj.child.props.name,
                    value: obj.child.props.value,
                    className: cn(obj.child.props.className, { 'height-45': col, 'px-4': col, 'py-3': col }),
                  })))
                }
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
}

RadioGroup.defaultProps = {
  center: false,
  fill: false,
  className: '',
  style: {},
  col: false,
  name: undefined,
  id: undefined,
};

RadioGroup.propTypes = {
  /** The radioGroup children to render */
  children: PropTypes.node.isRequired,
  /** Callback for on radio change */
  onChange: PropTypes.func.isRequired,
  /** Indicator whether or not to align radioGroup to center or space between */
  center: PropTypes.bool,
  /** Indicator whether or not to make radioGroup space between */
  fill: PropTypes.bool,
  /** Additional class to radioGroup root */
  className: PropTypes.string,
  /** Additional style to radioGroup root */
  style: PropTypes.shape(),
  /** Select view mode: column view if true  */
  col: PropTypes.bool,
  /** value will be return when click on this item */
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Identifier will be return when click on this element */
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export const RadioGroupComponent = RadioGroup;
export default withSelectionGroup(RadioGroup);
