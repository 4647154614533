import React, { useState } from "react";
import PropTypes from "prop-types";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import {
  StyledImageContainer,
  ImageBackground,
  Arrow,
  StyledIcon,
} from "./ListingCarousel.component.styled";

const imageSizeHelper = ({
  isTablet,
  isMobile,
  isSingleImage,
  isLaptop,
  isLandscapeMode,
}) => {
  if (isTablet) return 100;
  if (isLandscapeMode) return 50;
  if (isMobile || isSingleImage || isLaptop) return 100;

  return 35;
};

const CarouselComponent = ({
  images,
  showArrows,
  showThumbs,
  isMobile,
  isTablet,
  isLaptop,
  isLandscapeMode,
}) => {
  const [selectedItem, setSelectedItem] = useState(0);
  const nextItem = (selectedItem + 1) % images.length;
  const prevItem = selectedItem - 1 < 0 ? images.length - 1 : selectedItem - 1;
  const isSingleImage = images.length === 1;
  const isCenterSlidePercentage = imageSizeHelper({
    isTablet,
    isMobile,
    isSingleImage,
    isLaptop,
    isLandscapeMode,
  });

  return (
    images.length && (
      <div className="w-100 pos-relative">
        {showArrows && (
          <Arrow
            className="pos-absolute d-flex justify-content-center align-items-center h-100"
            style={{ left: 0 }}
          >
            <div
              onClick={() => {
                setSelectedItem(prevItem);
              }}
              className="cursor-pointer p-2 pointer-events-all"
            >
              <StyledIcon icon="BtnLeft" />
            </div>
          </Arrow>
        )}
        <Carousel
          infiniteLoop
          centerMode={isCenterSlidePercentage}
          centerSlidePercentage={isCenterSlidePercentage || isTablet}
          onChange={(index) => setSelectedItem(index)}
          selectedItem={selectedItem}
          showArrows={false}
          showStatus={false}
          showThumbs={showThumbs}
        >
          {images.map((imgSrc, index) => (
            <StyledImageContainer key={`${imgSrc}-${index}`}>
              <ImageBackground src={imgSrc} isSingleImage={isSingleImage} />
            </StyledImageContainer>
          ))}
        </Carousel>
        {showArrows && (
          <Arrow
            className="pos-absolute d-flex justify-content-center align-items-center h-100"
            style={{ right: 0 }}
          >
            <div
              onClick={() => {
                setSelectedItem(nextItem);
              }}
              className="cursor-pointer p-2 pointer-events-all"
            >
              <StyledIcon icon="BtnRight" />
            </div>
          </Arrow>
        )}
      </div>
    )
  );
};

CarouselComponent.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string),
  showArrows: PropTypes.bool,
  showThumbs: PropTypes.bool,
  isMobile: PropTypes.bool,
  isTablet: PropTypes.bool,
  isLandscapeMode: PropTypes.bool,
};

CarouselComponent.defaultProps = {
  images: [],
  showArrows: true,
  showThumbs: false,
  isMobile: false,
  isTablet: false,
  isLandscapeMode: false,
};

export default CarouselComponent;
