import styled from 'styled-components';

export const LogoTagContainer = styled.div`
  min-height: 60px;
  max-height: 60px;
  margin-top: 55px;
  text-align: center;
  img {
    height: 60px;
  }
`;
