import React from 'react';

const withOverflowPopoverState = (WrappedComponent) => {
  class WithOverflowState extends React.Component {
    constructor(props) {
      super(props);
      this.state = { showPopover: false };

      this.ref = React.createRef();
    }

    onMouseEnter = () => {
      const { clientWidth, scrollWidth } = this.ref.current;
      if (scrollWidth > clientWidth) {
        this.setState({ showPopover: true });
      }
    };

    onMouseLeave = () => {
      this.setState({ showPopover: false });
    };

    render() {
      const { showPopover } = this.state;

      return (
        <div
          className="w-fill"
          ref={this.ref}
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
        >
          <WrappedComponent showPopover={showPopover} {...this.props} />
        </div>
      );
    }
  }

  WithOverflowState.displayName = `WithOverflowState(${WrappedComponent.displayName ||
    WrappedComponent.name ||
    'Component'})`;

  return WithOverflowState;
};

export default withOverflowPopoverState;
