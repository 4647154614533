import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';

import {
  checkReservationDetails,
  formatCalendarDay,
  formatCalendarMonth,
  getReservationTitle,
  getStayDateTime,
  isInvalidPlannedDate
} from './calendarHelper';
import withHover from '../../compose/withHover';
import Tooltip from '../tooltip/Tooltip';
import { CalendarDayStatus } from '../../../constants/enums';
import { link as linkTo } from '../../../utils/navigationUtility';
import CalendarTooltip from './CalendarTooltip';
import CalendarDaySMBody from './CalendarDaySMBody';
import CalendarDayLGBody from './CalendarDayLGBody';

class CalendarDay extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      d: formatCalendarDay(props.day, true),
      m: formatCalendarMonth(props.day, true),
      calendarDayData: {}
    };
  }

  componentDidMount() {
    const { calendar, day, activeDates } = this.props;
    this.initCalendarDay(calendar, day, activeDates);
  }

  componentDidUpdate(prevProps) {
    if (
      (isEmpty(prevProps.calendar) && !isEmpty(this.props.calendar)) ||
      prevProps.day !== this.props.day
    ) {
      this.initCalendarDay(
        this.props.calendar,
        this.props.day,
        this.props.activeDates
      );
    }
  }

  initCalendarDay(calendar, day, activeDates) {
    const calendarDayData = checkReservationDetails(calendar, day, activeDates);
    this.setState({
      calendarDayData
    });
  }

  goToLink = () => {
    const { calendarDayData } = this.state;
    if (
      (calendarDayData.dayStatus === CalendarDayStatus.Booked ||
        calendarDayData.dayStatus === CalendarDayStatus.Reserved ||
        (calendarDayData.blocks &&
          (calendarDayData.blocks.sr || calendarDayData.blocks.o))) &&
      calendarDayData.link
    ) {
      linkTo(calendarDayData.link);
    }
  };

  serializeReservationData = (
    reservation,
    status,
    isReserved,
    isOwner,
    isSR
  ) => {
    const data = { ...reservation };
    if (!isEmpty(data)) {
      data.plannedArrival = getStayDateTime(data.checkIn, data.plannedArrival);
      if (isReserved) {
        data.title = getReservationTitle(
          data.confirmationCode,
          data.source,
          isOwner,
          isSR
        );
      }
      data.plannedDeparture = getStayDateTime(
        data.checkOut,
        data.plannedDeparture
      );
      data.isValidCheckIn = isInvalidPlannedDate(
        data.plannedArrival,
        data.checkIn
      );
      data.isValidCheckOut = isInvalidPlannedDate(
        data.checkOut,
        data.plannedDeparture
      );
    }
    return data;
  };

  showTooltip = (sm, cost, availableNotes, unavailableBlocks, isReserved) =>
    (sm && !isEmpty(cost)) ||
    !isEmpty(availableNotes) ||
    !isEmpty(unavailableBlocks) ||
    isReserved;

  renderCalendarTooltip = () => {
    const { calendarDayData } = this.state;
    const { sm } = this.props;
    const {
      cost,
      availableNotes,
      dayStatus,
      isReserved,
      unavailableBlocks,
      isOwner,
      isSR,
      reservation
    } = calendarDayData;
    const data = this.serializeReservationData(
      reservation,
      dayStatus,
      isReserved,
      isOwner,
      isSR
    );
    if (
      this.showTooltip(sm, cost, availableNotes, unavailableBlocks, isReserved)
    ) {
      return (
        <CalendarTooltip
          data={data}
          blocks={unavailableBlocks}
          isReserved={isReserved}
          price={sm ? cost : undefined}
          note={availableNotes}
        />
      );
    }
    return null;
  };

  render() {
    const {
      onHoverEnter,
      onHoverLeave,
      isHovering,
      sm,
      isFetching,
      tooltipBoundariesElement
    } = this.props;
    const { d, m, calendarDayData } = this.state;
    const {
      availableNotes,
      startStyles,
      endStyles,
      endOpacity,
      startOpacity,
      startOfBlock,
      color,
      link,
      guest,
      showPrice,
      cost,
      isAvailable
    } = calendarDayData;
    return (
      <div className={cn('DayPicker-Day--wrap', { lg: !sm })}>
        {!isFetching && startStyles && (
          <div
            className={cn('DayPicker-Day--range', startStyles)}
            style={{ opacity: startOpacity }}
          />
        )}
        {!isFetching && endStyles && (
          <div
            className={cn('DayPicker-Day--range', endStyles)}
            style={{ opacity: endOpacity }}
          />
        )}
        <Tooltip
          boundariesElement={tooltipBoundariesElement}
          id={`cell-${sm ? 'sm' : 'lg'}-${d}-${m}`}
          containerClassName="w-fill h-fill"
          body={this.renderCalendarTooltip()}
          delay={{ show: 300, hide: 250 }}
        >
          <div
            role="presentation"
            onClick={this.goToLink}
            onMouseEnter={onHoverEnter}
            onMouseLeave={onHoverLeave}
            className={cn(
              'flex-center flex-column font-size-xs calendar-day-content w-fill h-fill',
              { 'text-hover-blue': isAvailable },
              `text-${color}`,
              { clickable: link }
            )}
          >
            {sm ? (
              <CalendarDaySMBody
                isHovering={isAvailable && isHovering}
                hasNote={availableNotes}
                day={isFetching ? '-' : d}
              />
            ) : (
              <CalendarDayLGBody
                isHovering={isAvailable && isHovering}
                hasNote={availableNotes}
                day={isFetching ? '-' : d}
                price={showPrice ? cost : undefined}
                personOpacity={startOpacity}
                startOfBlock={startOfBlock}
                guest={guest}
              />
            )}
          </div>
        </Tooltip>
      </div>
    );
  }
}

CalendarDay.defaultProps = {
  isFetching: false,
  sm: false,
  tooltipBoundariesElement: undefined
};

CalendarDay.propTypes = {
  day: PropTypes.instanceOf(Date).isRequired,
  calendar: PropTypes.shape().isRequired,
  activeDates: PropTypes.shape().isRequired,
  sm: PropTypes.bool,
  isFetching: PropTypes.bool,
  tooltipBoundariesElement: PropTypes.string
};

export default withHover(CalendarDay);
