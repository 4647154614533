import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import RaisedButton from '../buttons/RaisedButton';
import FlatButton from '../buttons/FlatButton';
import Row from '../../layout/row/Row';

const DialogFooter = ({
  onConfirm, onCancel, confirmTitle, cancelTitle, leftComponent,
  className, style, disabled, showCancel, showConfirm, isSubmitting,
}) => (
  <div className={cn('flex-space-between-center w-fill py-6', className)} style={style}>
    {leftComponent && leftComponent}
    <Row justify="end" align="center" className="flex-1">
      {showCancel && <FlatButton className="dialog-footer-cancel" onClick={onCancel}>{cancelTitle}</FlatButton>}
      {showConfirm && <RaisedButton className="dialog-footer-confirm" disabled={disabled} onClick={onConfirm} isLoading={isSubmitting}>{confirmTitle}</RaisedButton>}
    </Row>
  </div>
);

DialogFooter.defaultProps = {
  cancelTitle: 'Cancel',
  confirmTitle: 'Save',
  onConfirm: undefined,
  onCancel: undefined,
  leftComponent: null,
  className: '',
  style: {},
  disabled: false,
  showCancel: true,
  showConfirm: true,
  isSubmitting: false,
};
DialogFooter.propTypes = {
  cancelTitle: PropTypes.string,
  confirmTitle: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  leftComponent: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.shape(),
  disabled: PropTypes.bool,
  showCancel: PropTypes.bool,
  showConfirm: PropTypes.bool,
  isSubmitting: PropTypes.bool,
};

export default DialogFooter;
