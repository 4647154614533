import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'react-jss';
import cn from 'classnames';
import ActionButton from '../buttons/ActionButton';

const styles = {
  resetBtn: {
    borderLeftWidth: '0 !important',
    height: 46,
  },
};

const PickerResetWrapper = ({
  resetable, className, style, disabled, onReset, children, classes, id,
}) => (
  <div className={cn('picker-wrapper flex-end d-flex-fill', className)} style={style}>
    {children}
    {resetable &&
    <ActionButton
      tooltip="Reset"
      id={id}
      icon="BtnReset"
      className={classes.resetBtn}
      disabled={disabled}
      onClick={onReset}
    />
    }
  </div>

);

PickerResetWrapper.defaultProps = {
  className: '',
  style: {},
  disabled: false,
  id: '',
};
PickerResetWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  resetable: PropTypes.bool.isRequired,
  onReset: PropTypes.func.isRequired,
  className: PropTypes.string,
  style: PropTypes.shape(),
  disabled: PropTypes.bool,
  id: PropTypes.string,
};

export default withStyles(styles)(PickerResetWrapper);
