import React from 'react';
import PropTypes from 'prop-types';

import TextField from '@guestyci/foundation/TextField';
import FormField from '@guestyci/foundation/FormField';
import Dropdown, { Option } from '@guestyci/foundation/Dropdown';

import RateInfo from './RateInfo';

const RatePlans = ({
  ratePlan,
  ratePlanTitle,
  channel,
  options,
  onRatePlanChange,
}) => {
  const {
    cancellationPolicy,
    cancellationFee,
    isCancellationPolicyByChannels,
    cancellationPolicyByChannels,
    mealPlans = []
  } = ratePlan?.data || {};

  const hasSeveralRP = options.length > 1;
  const hasChanel =
    isCancellationPolicyByChannels &&
    cancellationPolicyByChannels[channel];
  const policy = hasChanel
    ? cancellationPolicyByChannels[channel].name
    : cancellationPolicy;
  const fee = hasChanel
    ? cancellationPolicyByChannels[channel].fee
    : cancellationFee;
  const showInfo = policy || fee || mealPlans.length;

  return (hasSeveralRP || showInfo) ? (
    <>
      <TextField variant="h3" className="mt-6 mb-3">{ratePlanTitle}</TextField>
      {
        hasSeveralRP ? (
          <FormField
            name="ratePlan"
            required
          >
            <Dropdown
              renderSelected="label"
              className="mx-0 mt-0 mb-3 bg-white"
              inputProps={{
                noClear: true,
              }}
              onChange={onRatePlanChange}
            >
              {options.map((option) => (
                <Option value={option} key={option.value}>
                  {option.label}
                </Option>
              ))}
            </Dropdown>
          </FormField>)
        : (
          <TextField variant="h4" color="default" className="mb-2">
            {ratePlan?.label}
          </TextField>
        )
      }
      <RateInfo
        channel={channel}
        policy={policy}
        fee={fee}
        mealPlans={mealPlans}
      />
    </>
  ) : null;
};

RatePlans.propTypes = {
  ratePlan: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
  }),
  ratePlanTitle: PropTypes.string.isRequired
}

export default RatePlans;
