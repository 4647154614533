import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import noop from 'lodash/noop';
import SearchBar from './SearchBar';
import IconButton from '../buttons/IconButton';
import Fade from '../../utils/transiton/Fade';

class ExpandingSearchBar extends Component {
  constructor(props) {
    super(props);
    this.inputWrapper = React.createRef();
    this.state = { isShowSearch: false };
  }

  closeSearchBar = () => {
    this.setState({ isShowSearch: false });
    if (this.props.onToggleSearch) {
      this.props.onToggleSearch(false);
    }
  };

  showSearch = () => {
    this.setState({ isShowSearch: true });
    if (this.props.onToggleSearch) {
      this.props.onToggleSearch(true);
    }
    setTimeout(() => {
      this.inputWrapper.current.getElementsByClassName('search-bar-input')[0].focus();
    }, 300);
  };

  render() {
    const { isShowSearch } = this.state;
    const {
      onSubmit, onChange, className, style, noBorder,
      containerClassName, containerStyle, placeholder,
    } = this.props;
    return (
      <div className={cn('d-flex-fill', 'align-items-center', 'relative', 'expandable-search-bar-wrapper', containerClassName)} style={containerStyle}>
        <div ref={this.inputWrapper} className={`d-flex-fill expandable-search-bar slide-open ${isShowSearch ? 'search-expand' : 'search-collapse'}`}>
          <SearchBar
            showDelete
            placeholder={placeholder}
            onSubmit={onSubmit}
            onChange={onChange}
            onClear={this.closeSearchBar}
            style={style}
            className={cn('mt-1', className)}
            noBorder={noBorder}
          />
        </div>
        <div className="absolute right-0">
          <Fade timeout={{ enter: 225, leave: 225 }} speed="slow" show={!isShowSearch} >
            <IconButton iconHeight={18} iconWidth={17} onClick={this.showSearch} icon="BtnSearch" />
          </Fade>
        </div>
      </div>
    );
  }
}

ExpandingSearchBar.defaultProps = {
  onChange: noop,
  onToggleSearch: noop,
  onSubmit: noop,
  className: '',
  style: {},
  noBorder: false,
  containerClassName: '',
  containerStyle: {},
  placeholder: '',
};

ExpandingSearchBar.propTypes = {
  /** Callback function to handle search bar submit */
  onSubmit: PropTypes.func,
  /** Callback function to handle search bar open and close toggle */
  onToggleSearch: PropTypes.func,
  /** Callback function to handle searchbar value change */
  onChange: PropTypes.func,
  /** Additional class for searchbar root */
  className: PropTypes.string,
  /** Additional style for searchbar root */
  style: PropTypes.shape(),
  /** Indicator whether or not to show search border bottom */
  noBorder: PropTypes.bool,
  /** Additional class or root level */
  containerClassName: PropTypes.string,
  /** Additional style for root level */
  containerStyle: PropTypes.shape(),
  /** placeholder for search bar */
  placeholder: PropTypes.string,

};

export default ExpandingSearchBar;
