import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import Icon from '../icon/Icon';

const CalendarTooltipReservedItem = ({ title, value, color, alert }) => (
  <React.Fragment>
    {value && (
      <div className="d-flex-fill flex-start-center py-1 text-left text-white font-size-md font-weight-bold">
        <div className="width-75">{title}</div>
        <div className={cn('flex-1 text-left', { [`text-${color}`]: color })}>
          {value}
        </div>
        {alert && <Icon color="yellow" icon="IcoNotice" className="ml-2" />}
      </div>
    )}
  </React.Fragment>
);

CalendarTooltipReservedItem.defaultProps = {
  alert: false,
  color: 'white',
  value: ''
};

CalendarTooltipReservedItem.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  alert: PropTypes.bool,
  color: PropTypes.string
};
export default CalendarTooltipReservedItem;
