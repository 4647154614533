/* eslint-disable react/jsx-max-props-per-line */
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import BaseButton from './BaseButton';

const FixedRaisedButton = ({
  className, children, onClick, style, disabled, color, isLoading, ...props
}) => (
  <BaseButton
    className={cn(className, 'overflow-ellipsis', 'min-width-200', 'btn-raised-lg', 'font-size-lg')}
    variant="raised"
    onClick={onClick}
    style={style}
    disabled={disabled}
    color={color}
    isLoading={isLoading}
    {...props}
  >
    {children}
  </BaseButton>
);

FixedRaisedButton.defaultProps = {
  className: '',
  color: 'primary',
  style: {},
  disabled: false,
  isLoading: false,
};

FixedRaisedButton.propTypes = {
  /** Button click callback function */
  onClick: PropTypes.func.isRequired,
  /** Button inner children */
  children: PropTypes.node.isRequired,
  /** Button color - allowing primary, danger and success */
  color: PropTypes.oneOf(['primary', 'success', 'danger']),
  /** Button indicator whether disabled or not */
  disabled: PropTypes.bool,
  /** Button additional classes */
  className: PropTypes.string,
  /** Button additional styling */
  style: PropTypes.shape(),
  /** Indicator for  button loading state  */
  isLoading: PropTypes.bool,
};
export default FixedRaisedButton;

