import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import noop from 'lodash/noop';
import debounce from 'lodash/debounce';
import IconButton from '../buttons/IconButton';
import Icon from '../icon/Icon';
import './searchBar.scss';
import Row from '../../layout/row/Row';
import Fade from '../../utils/transiton/Fade';

class SearchBar extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { searchValue: '' };
  }
  onInputChange = debounce(value => this.props.onChange(value), 500);

  handleInputChange = (e) => {
    const { value } = e.currentTarget;
    this.setState({ searchValue: value });
    if (this.props.onChange) {
      this.onInputChange(value);
    }
  };

  submit = (event) => {
    event.preventDefault();
    this.props.onSubmit(this.state.searchValue);
  };

  clearSearch = () => {
    this.setState({ searchValue: '' });
    this.props.onSubmit('');
    if (this.props.onClear) {
      this.props.onClear();
    }
  };

  render() {
    const {
      className, showDelete, noBorder, placeholder,
    } = this.props;
    const { searchValue } = this.state;
    return (
      <form onSubmit={this.submit} className={cn('search-bar', 'w-fill', 'pb-1', 'border-gray-light', 'relative', { 'bb-1': !noBorder }, className)}>
        <Row spacing="sm" align="center" justify="start" fullWidth>
          <Icon icon="BtnSearch" color={searchValue ? 'black' : 'gray-dark'} height={18} width={17} />
          <input className="b-0 font-size-lg search-bar-input" placeholder={placeholder} onChange={this.handleInputChange} value={searchValue} />
          <Fade mountOnEnter show={!!(searchValue || showDelete)}>
            <IconButton icon="BtnDelete" iconWidth={10} iconHeight={10} onClick={this.clearSearch} />
          </Fade>
        </Row>
      </form>
    );
  }
}

SearchBar.defaultProps = {
  onChange: noop,
  onClear: noop,
  className: '',
  style: {},
  noBorder: false,
  placeholder: 'Type in to Search...',
};

SearchBar.propTypes = {
  /** Callback function to handle search bar submit */
  onSubmit: PropTypes.func.isRequired,
  /** Callback function to handle searchbar value change */
  onChange: PropTypes.func,
  /** Additional class for searchbar root */
  className: PropTypes.string,
  /** Additional style for earchbar root */
  style: PropTypes.shape(),
  /** Callback method to handle clear search */
  onClear: PropTypes.func,
  /** Indicator whether or not to show search border bottom */
  noBorder: PropTypes.bool,
  /** Placeholder for search  */
  placeholder: PropTypes.string,
};

export default SearchBar;
