import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { generate } from 'shortid';
import './loader.scss';

class Spinner extends React.Component {
  constructor(props) {
    super(props);
    const size = props.small ? 30 : props.size || 60;
    this.state = {
      size,
      id: props.id || generate(),
      strokeWidth: this.setStrokeWidth(props.size, props.strokeWidth),
    };
  }

  setStrokeWidth = (size, strokeWidth) => {
    let sWidth = strokeWidth;
    if (size > 64) {
      sWidth = 8;
    } else if (size > 32) {
      sWidth = 6;
    }
    return sWidth;
  };

  render() {
    const {
      color, className, style, svgClassName, svgStyle,
    } = this.props;
    const { size, id, strokeWidth } = this.state;
    return (
      <div className={cn('flex-center w-fill relative', className)} style={style} id={id}>
        <svg className={cn('spinner', 'absolute', { [`height-${size} width-${size}`]: size }, svgClassName)} viewBox="25 25 50 50" style={svgStyle}>
          <circle
            className={cn('path', `stroke-${color}`)}
            cx="50"
            cy="50"
            r="20"
            fill="none"
            strokeWidth={strokeWidth}
            strokeMiterlimit="10"
          />
        </svg>
      </div>
    );
  }
}

Spinner.defaultProps = {
  color: 'blue',
  size: 32,
  small: false,
  id: undefined,
  className: '',
  style: {},
  svgClassName: '',
  svgStyle: {},
  strokeWidth: 4,
};

Spinner.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
  small: PropTypes.bool,
  id: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.shape(),
  strokeWidth: PropTypes.number,
  svgClassName: PropTypes.string,
  svgStyle: PropTypes.shape(),
};
export default Spinner;
