import React, { useMemo, useState } from 'react';
import Row from '@guestyci/foundation/Layout/Row';
import Col from '@guestyci/foundation/Layout/Col';
import TextField from '@guestyci/foundation/TextField';
import Checkbox from '@guestyci/foundation/Checkbox';
import FormGroup from '@guestyci/foundation/FormGroup';
import FormField from '@guestyci/foundation/FormField';
import { FORM_FIELDS } from '@guestyci/guesty-pay-widget';
import { LOCATIONS } from '@guestyci/shared-enums';
import Input from '@guestyci/foundation/Input';
import useMediaQuery from '@guestyci/foundation/useMediaQuery';
import Dropdown, { Option } from '@guestyci/foundation/Dropdown';

const BillingAddress = ({
  change,
  initialValues,
  toggleLabelText,
  streetText,
  cityText,
  countryText,
  zipCodeText,
  stateText,
  title
}) => {
  const [useInitialValues, setUseInitialValues] = useState(true);
  const [selectedCountry, setSelectedCountry] = useState(initialValues[FORM_FIELDS.COUNTRY]);
  const [countrySearchQuery, setCountrySearchQuery] = useState('');

  const filteredCountries = useMemo(
    () => LOCATIONS.countries.filter(({ text }) => text.toLowerCase().includes(countrySearchQuery.toLowerCase())),
    [countrySearchQuery]
  );

  const uncheckToggle = () => {
    setUseInitialValues(false)
  }

  const setValues = () => {
    change(FORM_FIELDS.STREET, initialValues[FORM_FIELDS.STREET]);
    change(FORM_FIELDS.CITY, initialValues[FORM_FIELDS.CITY]);
    change(FORM_FIELDS.STATE, initialValues[FORM_FIELDS.STATE]);
    change(FORM_FIELDS.ZIP_CODE, initialValues[FORM_FIELDS.ZIP_CODE]);
    change(FORM_FIELDS.COUNTRY, initialValues[FORM_FIELDS.COUNTRY]);

    setSelectedCountry(initialValues[FORM_FIELDS.COUNTRY]);
  }

  const resetValues = () => {
    change(FORM_FIELDS.STREET, '');
    change(FORM_FIELDS.CITY, '');
    change(FORM_FIELDS.ZIP_CODE, '');
    change(FORM_FIELDS.STATE, '');
    change(FORM_FIELDS.COUNTRY, null);

    setSelectedCountry(null);
  }

  const toggleInitialValues = () => {
    if (useInitialValues) {
      resetValues();
    } else {
      setValues();
    }
    setUseInitialValues(!useInitialValues);
  }

  const handleCountrySelect = (e) => {
    const { value } = e.target;
    setSelectedCountry(value);
    uncheckToggle();
  };

  const handleCountrySearch = (e) => {
    const { value } = e.target;
    setCountrySearchQuery(value?.trim());
  };

  const isSmallScreen = useMediaQuery({ maxWidth: 'sm' });

  return (
    <Col spacing={3}>
      <Row justify="between" align="center">
        <TextField variant="h3">{title}</TextField>
        <Checkbox checked={useInitialValues} onChange={toggleInitialValues}>
          {toggleLabelText}
        </Checkbox>
      </Row>
      <Col spacing={4}>
        <FormGroup horizontal={!isSmallScreen}>
          <Col breakpoints={{ lg: 6 }}>
            <FormField
              label={streetText}
              name={FORM_FIELDS.STREET}
              initialValue={initialValues[FORM_FIELDS.STREET]}
              required
            >
              <Input
                autoComplete="street-address"
                placeholder={streetText}
                onChange={uncheckToggle}
              />
            </FormField>
          </Col>
          <Col breakpoints={{ lg: 6 }}>
            <FormField
              label={cityText}
              name={FORM_FIELDS.CITY}
              initialValue={initialValues[FORM_FIELDS.CITY]}
              required
            >
              <Input
                autoComplete="address-level2"
                placeholder={cityText}
                onChange={uncheckToggle}
              />
            </FormField>
          </Col>
        </FormGroup>
        <FormGroup horizontal={!isSmallScreen}>
          <Col breakpoints={{ lg: 4 }}>
            <FormField
              label={stateText}
              name={FORM_FIELDS.STATE}
              initialValue={initialValues[FORM_FIELDS.STATE]}
            >
              <Input
                autoComplete="address-level1"
                placeholder={stateText}
                onChange={uncheckToggle}
              />
            </FormField>
          </Col>
          <Col breakpoints={{ lg: 4 }}>
            <FormField
              label={zipCodeText}
              name={FORM_FIELDS.ZIP_CODE}
              initialValue={initialValues[FORM_FIELDS.ZIP_CODE]}
              required
            >
              <Input
                autoComplete="postal-code"
                placeholder={zipCodeText}
                onChange={uncheckToggle}
              />
            </FormField>
          </Col>
          <Col breakpoints={{ lg: 4 }}>
            <FormField
              label={countryText}
              name={FORM_FIELDS.COUNTRY}
              initialValue={initialValues[FORM_FIELDS.COUNTRY]}
              required
            >
              <Dropdown
                placeholder={countryText}
                value={selectedCountry}
                renderSelected="text"
                searchable
                onSearchQueryChange={handleCountrySearch}
                onChange={handleCountrySelect}
              >
                {filteredCountries.map((country) => (
                  <Option value={country} key={country.value}>
                    {country.text}
                  </Option>
                ))}
              </Dropdown>
            </FormField>
          </Col>
        </FormGroup>
      </Col>
    </Col>
  );
};

export default BillingAddress;
