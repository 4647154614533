import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

const getVariantClass = (variant) => {
  switch (variant) {
    case 'title':
      return { variantColor: 'muted', variantFont: 'md' };
    case 'label':
      return { variantColor: 'muted', variantFont: 'sm' };
    default:
      return {};
  }
};
const TextField = ({
  color,
  bgColor,
  children,
  className,
  style,
  onClick,
  name,
  id,
  size,
  variant,
}) => {
  const { variantColor = color, variantFont = size } = getVariantClass(variant);
  return (
    <div
      role="presentation"
      className={cn(
        'text-field-wrapper',
        { [`text-${variantColor} fill-${variantColor}`]: variantColor },
        { [`bg-${bgColor}`]: bgColor },
        { [`font-size-${variantFont}`]: variantFont },
        { clickable: onClick },
        className,
      )}
      style={style}
      onClick={onClick}
      name={name}
      id={id}
    >
      {children}
    </div>
  );
};

TextField.defaultProps = {
  color: null,
  bgColor: null,
  size: 'md',
  className: '',
  style: {},
  onClick: null,
  name: '',
  id: '',
  variant: null,
};
TextField.propTypes = {
  color: PropTypes.string,
  bgColor: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  style: PropTypes.shape(),
  onClick: PropTypes.func,
  name: PropTypes.string,
  id: PropTypes.string,
  size: PropTypes.oneOf(['xs', 'md', 'lg', 'lg-xl', 'xl', '2xl', '3xl']),
  variant: PropTypes.oneOf(['title', 'label']),
};

export default TextField;
