// eslint-disable-next-line import/prefer-default-export
export const loggerMiddleware = ({ getState }) => next => (action) => {
  const { CLUSTER } = process.env;

  if (CLUSTER !== 'production') {
    console.group(`${action.type}`);

    console.group('CURRENT STATE:');
    console.log(getState());
    console.groupEnd();

    next(action);

    console.group('NEXT STATE: ');
    console.log(getState());
    console.groupEnd();

    console.groupEnd();
  } else {
    next(action);
  }
};
