import React, { Component } from 'react';
import _ from 'lodash';
import cn from 'classnames';
import withStyle from 'react-jss';
import PropTypes from 'prop-types';

import Input from '../Input';
import IndicationIcon from './IndicationIcon';

const style = {
  fadeOut: {
    opacity: 0,
    width: 0,
    transition: 'width 0.45s 0.45s, height 0.45s 0.45s, opacity 0.5s',
    borderLeft: 0,
  },
  fadeIn: {
    opacity: 1,
    width: '46px',
    transition: 'width 0.45s, height 0.45s, opacity 0.5s',
  },
};
const DEBOUNCE_DELAY = 500;

class ProxyEmailInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputField: props.defaultInputValue,
    };

    this.handleValueChange = _.debounce(() => {
      this.props.onChange(this.state.inputField);
    }, DEBOUNCE_DELAY);
  }

  static getDerivedStateFromProps(props, state) {
    let newState = null;

    if (props.value && props.value !== state.inputField) {
      newState = { inputField: props.value };
    }

    return newState;
  }

  onInputValueChange = (value) => {
    const { inputField } = this.state;
    const trimmedValue = _.trim(value);

    if (inputField === trimmedValue) {
      return;
    }

    this.setState({ inputField: trimmedValue });
    this.handleValueChange();
  };

  render() {
    const {
      placeholder, classes: { fadeIn, fadeOut }, required,
      maxLength, minLength, domain, isValid, isValidating, name,
    } = this.props;
    const { inputField } = this.state;
    return (
      <div className={cn('d-flex-fill', 'border', 'border-gray-lighter', 'bg-gray-lightest')}>
        <Input
          onChange={this.onInputValueChange}
          name={name}
          value={inputField}
          containerClassName="border-0"
          placeholder={placeholder}
          minLength={minLength}
          maxLength={maxLength}
          required={required}
        />
        <div
          className={cn(
            'label', 'flex-center', 'bg-gray-lightest', 'px-4', 'font-size-md',
            'letter-spacing-sm', 'bl-1', 'border-gray-light',
          )}
        >
          {`@${domain}`}
        </div>
        <div className={cn(inputField.length > 0 ? fadeIn : fadeOut, 'd-flex-fill', 'bl-1', 'border-gray-light')}>
          <IndicationIcon isValidating={isValidating} isValid={isValid} />
        </div>
      </div>
    );
  }
}

ProxyEmailInput.defaultProps = {
  defaultInputValue: '',
  value: '',
  minLength: undefined,
  maxLength: undefined,
  placeholder: '5-30 characters, with no spaces',
  name: '',
};

ProxyEmailInput.propTypes = {
  /** An indicator for the icon indicator */
  isValid: PropTypes.bool.isRequired,
  /** A default input value */
  defaultInputValue: PropTypes.string,
  /** A value for controlled input field */
  value: PropTypes.string,
  /** An indicator if the req is validating */
  isValidating: PropTypes.bool.isRequired,
  /** Function to toggle the input changes */
  onChange: PropTypes.func.isRequired,
  /** A placeholder */
  placeholder: PropTypes.string,
  /** A domain placeholder */
  domain: PropTypes.string.isRequired,
  /** Min input length */
  minLength: PropTypes.number,
  /** Max input length */
  maxLength: PropTypes.number,
  /** A unique name */
  name: PropTypes.string,
};

export const ProxyEmailInputComponent = ProxyEmailInput;
export default withStyle(style)(ProxyEmailInput);
