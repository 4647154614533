import React, { Fragment, useMemo } from 'react';
import cn from 'classnames';
import { FixedRaisedButton } from '@guestyci/atomic-design/dist/v2/components';
import useTranslations from '@guestyci/localize/useTranslations';
import TextField from '@guestyci/foundation/TextField';
import Divider from '@guestyci/foundation/Divider';
import { Col, Row } from '@guestyci/foundation/Layout';
import { filter, identity, path } from 'ramda';
import {convertCurrencySymbol, getGuestsCountForReservation, getRates, pluralize} from 'utils/helpers';
import { MOBILE } from 'constants/css/breakpoints';
import { listingComponentIntlId } from 'utils/intlIds';
import {
  bathroomTranslation,
  bedTranslation,
  estPayoutTranslation,
  guestsTranslation,
  totalPriceTranslation,
} from 'utils/translations';

import {
  Dot,
  ImgTag,
  ListingTag,
  MainContentTag,
  RightPartTag,
  Tag,
  TotalPriceTag,
  TwoLinedText,
} from './Listing.component.styled';

export default ({
  breakpoint,
  listing: {
    bathrooms, beds, roomType, title, picture,
  },
  onClick,
  price: { currency, value: totalPrice },
  inquiry,
}) => {

  const guestsCount = getGuestsCountForReservation(inquiry)
  
  const isMobile = breakpoint === MOBILE;
  const translations = [
    {
      id: listingComponentIntlId('view_property_btn'),
      d: 'View property',
    },
    bedTranslation,
    bathroomTranslation,
    guestsTranslation,
    totalPriceTranslation,
    estPayoutTranslation,
  ];

  const [viewPropery, bedTag, bathroomTag, guestsTag, totalPriceCaption, estPayoutCaption] =
    useTranslations(translations);
  const tags = filter(identity, [
    roomType,
    guestsCount && pluralize(guestsTag, guestsCount),
    beds && pluralize(bedTag, beds),
    bathrooms && pluralize(bathroomTag, bathrooms),
  ]);

  const ratePlans = path(['rates', 'ratePlans'], inquiry) || [];
  const currentCurrency = currency || ratePlans?.[0]?.quote.money.currency;
  const convertedCurrency = convertCurrencySymbol(currentCurrency);
  const estPayoutRange = useMemo(() => getRates(ratePlans, convertedCurrency), [ratePlans, convertedCurrency]);
  const hasEstPayout = estPayoutRange && estPayoutRange.includes('-');
  const payoutValue = !ratePlans?.length ? `${convertedCurrency}${totalPrice}` : estPayoutRange;
  const payoutCaption = hasEstPayout ? estPayoutCaption : totalPriceCaption;

  return (
    <ListingTag
      onClick={isMobile ? onClick : undefined}
      className="d-flex overflow-hidden border-radius-10"
    >
      <ImgTag background={picture} />
      <MainContentTag>
        <Row fullWidth spacing={7}>
          <Col dense fullWidth>
            <TextField
              overflowEllipsis
              variant="h2"
              className={cn('mr-8 mt-2', {
                'pb-2': isMobile,
                'pb-4': !isMobile,
              })}
            >
              <TwoLinedText>
                {title}
              </TwoLinedText>
            </TextField>
            {isMobile && (
              <Row align="end" className="mb-2" spacing="sm">
                <TextField variant="h2">
                  {payoutValue}
                </TextField>
                <TextField className="align-self-end pb-1">
                  {payoutCaption}
                </TextField>
              </Row>
            )}
            <Divider />
            <Row
              className={cn('mr-8', {
                'pt-5 align-items-end': !isMobile,
                'pt-3 align-items-center': isMobile,
              })}
              fullWidth
              wrap
            >
              {tags.map((tag, i) => (
                <Fragment key={i}>
                  <Tag
                    className={cn('d-flex pt-1 font-size-lg', {
                      'pr-4': !isMobile,
                    })}
                  >
                    {tag}
                  </Tag>
                  {isMobile && i !== tags.length - 1 && (
                    <Dot className="mr-1 ml-1 mt-1" />
                  )}
                </Fragment>
              ))}
            </Row>
          </Col>

            {!isMobile && (
              <Col dense>
                <RightPartTag>
                  <TotalPriceTag
                    className={cn(
                      'd-flex flex-column align-items-center justify-content-center flex-wrap mb-5',
                      { 'pb-2': isMobile },
                    )}
                  >
                    <TextField className="font-weight-normal" align="center">
                      {payoutCaption}
                    </TextField>
                    <TextField variant="h2" align="center">
                      {payoutValue}
                    </TextField>
                  </TotalPriceTag>
                  <FixedRaisedButton
                    style={{ width: '100%', minWidth: '100%' }}
                    onClick={onClick}
                  >
                    {viewPropery}
                  </FixedRaisedButton>
                </RightPartTag>
              </Col>
            )}
        </Row>
      </MainContentTag>
    </ListingTag>
  );
};
