import React from 'react';
import PropTypes from 'prop-types';
import isBoolean from 'lodash/isBoolean';
import Tooltip from '../../components/tooltip/Tooltip';
import Icon from '../../components/icon/Icon';
import { firstLetterUppercase } from '../../../utils/commonUtility';

function humaniseValue(value) {
  if (isBoolean(value)) {
    return value ? 'Yes' : 'No';
  }
  return value;
}

const KeyValueField = ({ title, value, valueColor, tooltip }) => (
  <div className="flex-1 flex-column">
    <div className="font-size-md title-field text-muted d-flex">
      {firstLetterUppercase(title)}
      {tooltip && (
        <Tooltip body={tooltip}>
          <Icon
            className="ml-1"
            color="muted"
            width={13}
            height={13}
            icon="BtnHelp"
          />
        </Tooltip>
      )}
    </div>
    <div
      className={`font-size-lg mt-2 value-field text-${
        value ? valueColor : 'muted'
      }`}
    >
      {humaniseValue(value) || 'Not defined'}
    </div>
  </div>
);

KeyValueField.defaultProps = {
  tooltip: null,
  valueColor: 'black',
  value: ''
};

KeyValueField.propTypes = {
  /** The label of the value */
  title: PropTypes.string.isRequired,
  /** The value of the key */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
    PropTypes.bool
  ]),
  /** The tooltip's body */
  tooltip: PropTypes.node,
  /** The color of the value */
  valueColor: PropTypes.string
};
export default KeyValueField;
