import React from 'react';
import PropTypes from 'prop-types';
import { ActionTooltipType } from '../../../../constants/enums';
import ActionTooltipButton from './ActionTooltipButton';
import Col from '../../../layout/col/Col';

const ActionTooltipBody = ({
  actionType, actionText, actionHref, onAction, body,
}) => (
  <Col spacing={2}>
    <div className="action-tooltip-text letter-spacing-lg text-left">{body}</div>
    <div className="flex-end">
      <ActionTooltipButton
        actionType={actionType}
        actionText={actionText}
        actionHref={actionHref}
        onAction={onAction}
      />
    </div>
  </Col>
);

ActionTooltipBody.defaultProps = {
  actionHref: '',
  onAction: (() => {}),
};

ActionTooltipBody.propTypes = {
  /** Sets the type of the action */
  actionType: PropTypes.oneOf([ActionTooltipType.Button, ActionTooltipType.Link]).isRequired,
  /** Sets the text of the action */
  actionText: PropTypes.string.isRequired,
  /** Sets the href of the action */
  actionHref: PropTypes.string,
  /** Callback function - called when clicking the tooltip action */
  onAction: PropTypes.func,
  /** Sets the body (content) of the tooltip F.E: text to be diplayed */
  body: PropTypes.node.isRequired,
};

export default ActionTooltipBody;
