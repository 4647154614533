/* eslint-disable */
/**
 *  Get valid placeholder for input
 * @param placeholder {String}  Passed placeholder
 * @param required  {Boolean}  is the field required
 * @return {String} The final placeholder formatted
 */

export const getPlaceholder = (placeholder, required) => {
  if (!required) {
    return placeholder ? `${placeholder} (Optional)` : '(Optional)';
  }
  return placeholder;
};
