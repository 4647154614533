export default {
  USD: { symbol: '$' }, // US Dollar
  ARS: { symbol: '$' }, // Argentine peso
  CLP: { symbol: '$' }, // Chilean peso
  COP: { symbol: '$' }, // Colombian peso
  NZD: { symbol: '$' }, // New Zealand dollar
  SGD: { symbol: '$' }, // Singapore Dollar
  AUD: { symbol: 'A$' }, // Australian dollar
  JMD: { symbol: 'J$' }, // Jamaican Dollar
  BRL: { symbol: 'R$' }, // Brazilian real
  HKD: { symbol: 'HK$' }, // Honk kong dollar
  TWD: { symbol: 'NT$' }, // New Taiwan dollar
  MXN: { symbol: 'MEX$' }, // Mexican peso
  CAD: { symbol: 'C$' }, // Canadian Dollar
  EUR: { symbol: '€' }, // Euro
  HRK: { symbol: 'kn' }, // Croatia kuna
  SEK: { symbol: 'kr', right: true }, // Swedish krona
  RON: { symbol: 'lei' }, // Romanian leu
  CZK: { symbol: 'Kč' }, // Czech koruna
  DKK: { symbol: 'Kr.', right: true }, // Danish krone
  NOK: { symbol: 'Kr.', right: true }, // Norwegian krone
  CHF: { symbol: 'Fr.', right: true }, // Swiss franc
  HUF: { symbol: 'Ft' }, // Hungarian forint
  MYR: { symbol: 'RM' }, // Malaysian ringgit
  ZAR: { symbol: 'R' }, // South african rand
  PEN: { symbol: 'S/' }, // Peruvian sol
  MAD: { symbol: 'DH' }, // Moroccan dirham
  CRC: { symbol: '₡' }, // Costa Rican Colón
  GBP: { symbol: '£' }, // British Pound Sterling
  ILS: { symbol: '₪' }, // Israeli New Sheqel
  INR: { symbol: '₹' }, // Indian Rupee
  JPY: { symbol: '¥' }, // Japanese Yen
  CNY: { symbol: '¥' }, // Chinese yuan
  KRW: { symbol: '₩' }, // South Korean Won
  NGN: { symbol: '₦' }, // Nigerian Naira
  PHP: { symbol: '₱' }, // Philippine Peso
  RUB: { symbol: '₽', right: true }, // Russian ruble
  PLN: { symbol: 'zł', right: true }, // Polish Zloty
  PYG: { symbol: '₲' }, // Paraguayan Guarani
  THB: { symbol: '฿' }, // Thai Baht
  UAH: { symbol: '₴' }, // Ukrainian Hryvnia
  VND: { symbol: '₫' }, // Vietnamese Dong
  TRY: { symbol: '₺', right: true }, // Turkish lira
  AFN: { symbol: '؋' }, // Afghan afghani
  AED: { symbol: 'د.إ' }, // Emirati dirham
  SAR: { symbol: 'ر.س' }, // Saudi Arabian riyal
  BGN: { symbol: 'Лв.' }, // Bulgarian lev
  IDR: { symbol: 'Rp' }, // Rupiah
};
