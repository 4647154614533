let prefix = '/apps/send-quotes';
if (process.env.REACT_APP_CLUSTER === 'production') {
  prefix = '';
}

export const MAIN_PAGE = `${prefix}/:id?`;
export const LISTING_PAGE = `${prefix}/:quotesId/listing/:listingId?`;
export const INVOICE_PAGE = `${prefix}/:quotesId/listing/:listingId/invoice`;
export const EXPIRED_PAGE = `${prefix}/expired`;
export const SUCCESS_PAID_BOOKING_PAGE = `${prefix}/success-paid`;
export const SUCCESS_UNPAID_BOOKING_PAGE = `${prefix}/success-unpaid`;
