import keyBy from 'lodash/keyBy';

export const colors = [
  'black',
  '#444',
  '#666',
  '#999',
  '#ccc',
  '#eee',
  '#f3f3f3',
  'white',
  'red',
  'orange',
  'yellow',
  'lime',
  'cyan',
  'blue',
  'purple',
  'magenta',
];

export const customStyleMap = {
  'small-text': {
    fontSize: '9px',
  },
  'normal-text': {
    fontSize: '12px',
  },
  'large-text': {
    fontSize: '24px',
  },
  'huge-text': {
    fontSize: '36px',
  },
  ...keyBy(colors.map(color => ({ color })), 'color'),
};

export const fontSize = [
  {
    label: 'Small',
    value: 'small-text',
  },
  {
    label: 'Normal',
    value: 'normal-text',
  },
  {
    label: 'Large',
    value: 'large-text',
  },
  {
    label: 'Huge',
    value: 'huge-text',
  },
];
