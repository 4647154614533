import React from 'react';
import cn from 'classnames';

const TextAvatar = ({
  text, isUser, size = 'md', disabled,
}) => {
  let avatarText = text.trim();
  avatarText = (avatarText.length < 1) || (avatarText.length > 2) || (avatarText === '') ? '- -' : text;
  const textClasses = 'text-avatar text-uppercase text-white d-flex align-items-center justify-content-center';
  const bgColor = isUser ? 'blue-dark' : 'gray-darkest';
  return (
    <div className={cn(textClasses, 'flex-center', `avatar avatar-${size}`, `bg-${bgColor}`, { disabled })}>
      <span>{avatarText}</span>
    </div>
  );
};

export default TextAvatar;
