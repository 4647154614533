import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import debounce from 'lodash/debounce';
import isNumber from 'lodash/isNumber';
import Spinner from '../loaders/Spinner';
import CSSTransition from '../../utils/transiton/CSSTransition';

class InfiniteScroll extends Component {
  constructor(props) {
    super(props);
    this.state = { currentPage: this.props.initialPage || 0, fetching: false };
    this.scrollRef = React.createRef();
  }

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.pageCount && this.props.pageCount) {
      this.init();
    }
    if (isNumber(this.props.page) &&
      prevProps.page !== this.props.page &&
      this.state.currentPage !== this.props.page) {
      this.setCurrentPage(this.props.page);
    }
    if (prevProps.isFetching && !this.props.isFetching && this.state.fetching) {
      this.stopFetching();
    }
  }

  componentWillUnmount() {
    if (this.scrollableEl) {
      this.scrollableEl.removeEventListener('scroll', this.handleScrollEvent);
    }
  }

  setCurrentPage(page) {
    this.setState({ currentPage: page });
  }
  init() {
    const { pageCount, windowScroll, controlNode } = this.props;
    if (pageCount) {
      this.scrollableEl = (controlNode || windowScroll) ?
        document.getElementById(controlNode) || window : this.scrollRef.current;
      this.scrollableEl.removeEventListener('scroll', this.handleScrollEvent);
      this.scrollableEl.addEventListener('scroll', this.handleScrollEvent);
      this.setState({ currentPage: this.props.initialPage || 0, fetching: false });
    } else if (this.scrollableEl) {
      this.scrollableEl.removeEventListener('scroll', this.handleScrollEvent);
    }
  }

  stopFetching() {
    this.setState({ fetching: false });
    this.scrollableEl.addEventListener('scroll', this.handleScrollEvent);
  }

  fetchNextPage() {
    this.scrollableEl.removeEventListener('scroll', this.handleScrollEvent);
    this.setState(prevState => ({
      currentPage: prevState.currentPage + 1,
      fetching: true,
    }), () => this.props.onLoadMore(this.state.currentPage));
  }

  handleScrollEvent = debounce(async () => {
    const { pageCount, offset, scrollUp } = this.props;
    if (pageCount && !this.state.fetching && pageCount > this.state.currentPage) {
      const element = this.scrollableEl;
      if (scrollUp) {
        if (this.isAtTop(element)) {
          this.fetchNextPage();
        }
      } else if (this.isAtBottom(element, offset)) {
        this.fetchNextPage();
      }
    }
  }, 150);

  isAtBottom = (element) => {
    const el = !this.props.windowScroll ? element : document.documentElement;
    return (el.scrollHeight - el.scrollTop - el.clientHeight <= this.props.offset);
  };

  isAtTop = (element) => {
    const { offset } = this.props;
    return (element ? element.scrollTop <= offset : window.scrollY <= offset);
  };

  isInternalScroll = () => {
    const { controlNode, windowScroll } = this.props;
    return !controlNode && !windowScroll;
  };
  render() {
    const {
      className, children, style, scrollUp, id, height, animation,
    } = this.props;
    const { currentPage, fetching } = this.state;
    return (
      <div
        id={id}
        ref={this.scrollRef}
        className={cn('w-fill', 'flex-column', 'h-fill', className, { 'overflow-y-auto': this.isInternalScroll() })}
        style={Object.assign({}, style, { maxHeight: height })}
      >
        {scrollUp && fetching && currentPage > 0 && <Spinner className="up w-fill height-30" small />}
        <CSSTransition animation={animation}>
          {children}
        </CSSTransition>
        {!scrollUp && fetching && currentPage > 0 && <Spinner className="down w-fill height-30" small />}
      </div>
    );
  }
}
InfiniteScroll.defaultProps = {
  initialPage: 0,
  id: '',
  scrollUp: false,
  offset: 200,
  className: '',
  style: {},
  height: undefined,
  controlNode: undefined,
  windowScroll: false,
  isFetching: false,
  page: undefined,
  animation: 'fade',
};

InfiniteScroll.propTypes = {
  /** Infinite scroll child components */
  children: PropTypes.node.isRequired,
  /** Additional class to infinite scroll root level */
  className: PropTypes.string,
  /** Height for infinite scroll (making it internal scroll) */
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** Additional style for infinite scroll */
  style: PropTypes.shape(),
  /** Controller node id to handle the scroll (making scroll on the control element level) */
  controlNode: PropTypes.string,
  /** Initial page count */
  initialPage: PropTypes.number,
  /** Callback for load more elements - IMPORTANT  -->
   CB awaits api result, you must return after api call to clear fetching mode */
  onLoadMore: PropTypes.func.isRequired,
  /** Total page count (limit * skip) */
  pageCount: PropTypes.number.isRequired,
  /** Id for the root of the infinite scroll */
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** Indicator whether or not to fetch on scroll up or down */
  scrollUp: PropTypes.bool,
  /** Offset bottom before re-fetch */
  offset: PropTypes.number,
  /** Indicator whether or not we want scroll on the window level or the component level */
  windowScroll: PropTypes.bool,
  /** Indicator showing if fetching more */
  isFetching: PropTypes.bool,
  /** Prop to make infinite scroll controlled */
  page: PropTypes.number,
  /** control infinite scroll animation */
  animation: PropTypes.string,
};

export default InfiniteScroll;
