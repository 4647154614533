import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Label, Input } from 'reactstrap';
import { noop } from 'lodash/noop';

class Radio extends Component {
  onRadioClick = () => {
    const {
      value, name, id, generatedChildId,
    } = this.props;
    this.props.onChange(value, name, id, generatedChildId);
  };

  render() {
    const {
      className, disabled, checked, children, id,
    } = this.props;
    return (
      <div
        className={cn(
          className,
          'radio-item',
          'font-size-md',
          'height-25',
          'text-gray-dark',
          'flex-center',
          'pr-4',
        )}
        onClick={this.onRadioButtonClick}
        onKeyDown={this.onRadioButtonClick}
        role="button"
        disabled={disabled}
      >
        <Label
          htmlFor={id}
          className={cn('font-size-md m-0 text-capitalize clickable', {
            'text-blue': checked,
          })}
        >
          <Input
            type="radio"
            id={id}
            onChange={this.onRadioClick}
            checked={checked}
            disabled={disabled}
            className="ml-0"
          />
          {children}
        </Label>
      </div>
    );
  }
}

Radio.defaultProps = {
  children: null,
  disabled: false,
  checked: false,
  name: undefined,
  id: undefined,
  onChange: noop,
  className: '',
};

Radio.propTypes = {
  /** Set radioButton content to show when active */
  children: PropTypes.node,
  /** Indicator whether or not the radioButton is disabled */
  disabled: PropTypes.bool,
  /** The radioButton value to be passed when clicked */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.shape()]).isRequired,
  /** Set the radioButton as active manually */
  checked: PropTypes.bool,
  /** will triggered when the value is changed */
  onChange: PropTypes.func,
  /** value will be return when click on this item */
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** identifier will be return when click on this element */
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** classes to be added to the component */
  className: PropTypes.string,
};

export default Radio;
