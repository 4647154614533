import { path } from 'ramda';
import React, { useMemo } from 'react';

import { convertCurrencySymbol, getListingPageUrl, getRates } from '../../../../../utils/helpers';
import { SimplifiedListing, SimplifiedListingContent } from './SimplifiedListings.styled';

export const SimplifiedListingComponent = ({
  picture,
  history,
  quotesId,
  roomType,
  title,
  listingId,
  price,
  inquiry,
}) => {
  const {
    value: totalPrice,
    currency,
  } = price;
  const ratePlans = path(['rates', 'ratePlans'], inquiry) || [];
  const currentCurrency = currency || ratePlans?.[0]?.quote.money.currency;
  const convertedCurrency = convertCurrencySymbol(currentCurrency);
  const estPayoutRange = useMemo(() => getRates(ratePlans, convertedCurrency),
    [ratePlans, convertedCurrency]);
  const payoutValue = !ratePlans?.length ? `${convertedCurrency}${totalPrice}` : estPayoutRange;

  return (
    <SimplifiedListing
      img={picture}
      className="mt-6 overflow-hidden"
      onClick={() => history.push(getListingPageUrl({
        quotesId,
        listingId,
      }))}
    >
      <SimplifiedListingContent className="d-flex flex-column justify-content-end w-100 h-100 p-3 ">
        <div className="d-flex justify-content-start font-weight-bold">
          <div className="text-truncate">{title}</div>
        </div>
        <div className="d-flex justify-content-start align-items-baseline">
          <div className="color-blue">{payoutValue}</div>
          <div className="font-size-md text-truncate">&nbsp;/&nbsp;{roomType}</div>
        </div>
      </SimplifiedListingContent>
    </SimplifiedListing>
  );
};
