import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import withStyles from 'react-jss';

const colors = {
  primary: 'blue',
  success: 'green',
  warning: 'yellow',
  error: 'red',
};

const styles = {
  header: {
    height: 100,
  },
};

const DialogHeader = ({
  title, rightComponent, className, style, classes, type,
}) => {
  console.log(type);
  return (
    <div className={cn('dialog-header flex-space-between-center w-fill', classes.header, className)} style={style}>
      <div className={cn('font-size-lg-xl', { [`text-${colors[type]}`]: type })}>{title}</div>
      <div className="flex-end-center">
        {rightComponent}
      </div>
    </div>
  );
};

DialogHeader.defaultProps = {
  rightComponent: null,
  className: '',
  style: {},
  type: 'primary',
};
DialogHeader.propTypes = {
  title: PropTypes.string.isRequired,
  rightComponent: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.shape(),
  type: PropTypes.string,
};

export default withStyles(styles)(DialogHeader);
