const AIRBNB_REDIRECT_URL = 'http://localhost:3002/proxy/integration/integration/airbnb/redirect';
const MAILER_URL = 'http://localhost:3002/api/v2';
const API_URL = 'http://localhost:3002/api/v2';
const INTEGRATION_SERVICE_URL = 'http://localhost:3002/proxy/integration';
const COMMUNICATION_SERVICE_URL = 'http://localhost:3002/proxy/communication/v2';

export default {
  AIRBNB_REDIRECT_URL,
  MAILER_URL,
  INTEGRATION_SERVICE_URL,
  API_URL,
  COMMUNICATION_SERVICE_URL,
};
