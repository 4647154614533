import React from 'react';
import cn from 'classnames';
import withStyles from 'react-jss';

const styles = {
  border: {
    transition: 'left 225ms cubic-bezier(0, 0, 0.2, 1) 0ms, width 225ms cubic-bezier(0, 0, 0.2, 1)',
  },
};
const ActiveTabBottomBorder = ({
  classes, width, position, style,
}) => (
  <div className={cn('absolute bottom-0 bb-2 transition-left border-blue text-blue', classes.border)} style={Object.assign({}, { width, left: position }, style)} />
);

export default withStyles(styles)(ActiveTabBottomBorder);
