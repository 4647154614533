import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icon from '../icon/Icon';

const AccordionChevron = ({ isOpen, isActive, className }) => (
  <Icon icon="BtnArrowDown" className={cn('relative', className, { 'transform-180': isOpen })} height={9} width={9} color={isOpen || isActive ? 'blue' : 'gray-dark'} />
);

AccordionChevron.defaultProps = {
  isActive: false,
  className: '',
};
AccordionChevron.propTypes = {
  /** Indicator whether or not collapse should be open */
  isOpen: PropTypes.bool.isRequired,
  /** Indicator whether or not we are in active state */
  isActive: PropTypes.bool,
  /** Additional class name to chevron */
  className: PropTypes.string,
};

export default AccordionChevron;
