import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Fade as StrapFade } from 'reactstrap';

const Fade = ({
  className, timeout, children, tag, show, speed, mountOnEnter,
}) => (
  <StrapFade
    in={!!show}
    tag={tag}
    baseClass={`fade-${speed}`}
    baseClassActive="show"
    className={cn('fade-transition', className)}
    timeout={timeout}
    unmountOnExit={mountOnEnter}
    mountOnEnter={mountOnEnter}
  >
    {children}
  </StrapFade>
);
Fade.defaultProps = {
  className: '',
  tag: 'span',
  speed: 'reg',
  children: null,
  timeout: 0,
  mountOnEnter: false,
};
Fade.propTypes = {
  /** Bool whether or not to show (fade in) children */
  show: PropTypes.bool.isRequired,
  /** Additional class for root level */
  className: PropTypes.string,
  /** children to render for the fade component */
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  /** Timeout for fade animation */
  timeout: PropTypes.oneOfType([
    PropTypes.shape({
      enter: PropTypes.number.isRequired,
      exit: PropTypes.number.isRequired,
    }), PropTypes.number]),
  /** Tag to render for fade */
  tag: PropTypes.string,
  /** Custom fade speed */
  speed: PropTypes.oneOf(['quick', 'reg', 'slow']),
  /** Mount and unmount on show/hide (true) or keep mounted -default */
  mountOnEnter: PropTypes.bool,
};

export default Fade;
