import React, { Component } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import CSSTransition from '../../utils/transiton/CSSTransition';

class Drawer extends Component {
  constructor(props) {
    super(props);
    this.drawerRef = React.createRef();
  }
  componentDidMount() {
    if (this.props.overlay) {
      this.setParentClass();
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.overlay !== this.props.overlay) {
      this.setParentClass();
    }
  }

  setParentClass() {
    const el = this.drawerRef.current;
    const parent = el.parentElement;
    parent.classList.toggle('relative');
  }
  render() {
    const {
      className, style, isOpen, children, width, overlay,
      right, transform, bgColor, containerClassName, fixed,
    } = this.props;
    return (
      <div
        ref={this.drawerRef}
        className={cn(
          isOpen ? `min-width-${width} width-${width}` : 'min-width-0 width-0',
          'zindex-5 bs-mid-light d-flex transition-all', { 'right-0': overlay && right },
          { 'absolute h-fill': overlay }, { [`bg-${bgColor}`]: bgColor }, { fixed }, containerClassName,
        )}
        style={style}
      >
        <CSSTransition animation={transform} className="w-fill">
          {isOpen &&
          <div className={cn('d-flex-fill h-fill flex-column overyflow-y-auto', className)}>
            {children}
          </div>}
        </CSSTransition>
      </div>
    );
  }
}

Drawer.defaultProps = {
  className: '',
  style: {},
  width: 250,
  overlay: false,
  right: false,
  transform: '',
  bgColor: '',
  containerClassName: '',
  fixed: false,
};

Drawer.propTypes = {
  /** Indicator whether or not to show drawer */
  isOpen: PropTypes.bool.isRequired,
  /** Child elements inside drawer */
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  /** Additional classes to drawer level */
  className: PropTypes.string,
  /** Additional style to root level */
  style: PropTypes.shape(),
  /** drawer width */
  width: PropTypes.number,
  /** indicator whether or not drawer should overlay over content (position absolute) or static
   * *Note - overlay auto toggles parent component with position relative
   * */
  overlay: PropTypes.bool,
  /** Only applicable for overlay = true -
   *  Indicator whether or not we want to open drawer on right - default left
   *  */
  right: PropTypes.bool,
  /** Animation for the content close and open -
   * Note: this is not for the drawer animation but the internal content */
  transform: PropTypes.string,
  /** Background color for drawer */
  bgColor: PropTypes.string,
  /** Add class to root level */
  containerClassName: PropTypes.string,
  /** Set drawer as fixed */
  fixed: PropTypes.bool,
};
export default Drawer;
