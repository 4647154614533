import useTranslations from '@guestyci/localize/useTranslations';
import { validationsIntlId } from 'utils/intlIds';

const translations = [
  {
    id: validationsIntlId('processing_error'),
    d: 'Please try again or try with another credit card.',
  },
  {
    id: validationsIntlId('expired_card'),
    d: 'Your credit card has expired, please try with another one.',
  },
  {
    id: validationsIntlId('card_declined'),
    d: 'Your credit card was declined, please try with another one.',
  },
  {
    id: validationsIntlId('invalid_number'),
    d: 'Invalid number',
  },
  {
    id: validationsIntlId('incorrect_number'),
    d: 'Incorrect number',
  },
  {
    id: validationsIntlId('invalid_expir_date'),
    d: 'Invalid expiration date',
  },
  {
    id: validationsIntlId('invalid_cvc'),
    d: 'Invalid CVV',
  },
  {
    id: validationsIntlId('incorrect_cvc'),
    d: 'Incorrect CVV',
  },
  {
    id: validationsIntlId('default'),
    d: 'Please try again or try with another credit card.',
  },
  {
    id: validationsIntlId('required'),
    d: 'Required',
  },
];

export const useErrorMessages = () => {
  const [
    processingErrorText,
    expiredCardText,
    cardDeclinedText,
    invalidNumberText,
    incorrectNumberText,
    invalidExpDateText,
    invalidCVVText,
    incorrectCVVText,
    tryAgainText,
    requiredText,
  ] = useTranslations(translations);

  const ERROR_MESSAGES = {
    processing_error: processingErrorText,
    expired_card: expiredCardText,
    card_declined: cardDeclinedText,
    invalid_number: invalidNumberText,
    incorrect_number: incorrectNumberText,
    incomplete_number: incorrectNumberText,
    invalid_expiry_month: invalidExpDateText,
    invalid_expiry_year: invalidExpDateText,
    invalid_cvc: invalidCVVText,
    incorrect_cvc: incorrectCVVText,
    DEFAULT: tryAgainText,
    required: requiredText,
  };

  return ERROR_MESSAGES;
};
