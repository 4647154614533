import React from 'react';
import PropTypes from 'prop-types';
import CalendarTooltipReservedItem from './CalendarTooltipReservedItem';
import { getCurrencySymbol, colorFromStatus } from './calendarHelper';

const CalendarTooltipReserved = ({ data }) => (
  <div className="flex-start d-flex-fill text-white flex-column">
    <div className="text-left font-weight-bold bb-2 w-100 border-black-dark pb-2 mb-1">
      {data.title}
    </div>
    <CalendarTooltipReservedItem
      title="Status"
      value={data.status}
      color={colorFromStatus(data.status)}
    />
    <CalendarTooltipReservedItem
      title="ETA"
      value={data.plannedArrival}
      alert={data.isValidCheckIn}
    />
    <CalendarTooltipReservedItem
      title="ETD"
      value={data.plannedDeparture}
      alert={data.isValidCheckOut}
    />
    <CalendarTooltipReservedItem title="Guests" value={data.guestsCount} />
    {data.money && (
      <CalendarTooltipReservedItem
        title="Payout"
        value={getCurrencySymbol(data.money.currency, data.money.hostPayout)}
      />
    )}
  </div>
);

CalendarTooltipReserved.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    status: PropTypes.string,
    plannedArrival: PropTypes.string.isRequired,
    plannedDeparture: PropTypes.string.isRequired,
    isValidCheckIn: PropTypes.bool,
    isValidCheckOut: PropTypes.bool,
    guestsCount: PropTypes.number,
    money: PropTypes.shape()
  }).isRequired
};
export default CalendarTooltipReserved;
