import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import Bullet from '../indicators/bullet/Bullet';
import Icon from '../icon/Icon';

const ThreadMessageInfo = ({
  byUs,
  fullName,
  date,
  moduleType,
  isErrorMessage
}) => (
  <div
    className={cn('d-flex align-items-center text-muted', {
      'justify-content-end': byUs,
      'pr-4': byUs && !isErrorMessage,
      'pl-4': !byUs && !isErrorMessage
    })}
  >
    {fullName && (
      <div
        className={cn('text-capitalize font-size-md', {
          'text-white': isErrorMessage
        })}
      >
        {fullName}
      </div>
    )}
    <Bullet className="mx-1" />
    <div className={cn('font-size-md', { 'text-white': isErrorMessage })}>
      {date}
    </div>
    <Bullet className="mx-1" />
    {moduleType === 'airbnb' || moduleType === 'AirbnbChannel' ? (
      <Icon
        icon="IcoAirbnb"
        className={`message-info-icon ${
          isErrorMessage ? 'fill-white' : 'fill-gray-dark'
        }`}
        width={12}
      />
    ) : (
      <div
        className={cn('text-capitalize font-size-md', {
          'text-white': isErrorMessage
        })}
      >
        {moduleType}
      </div>
    )}
  </div>
);

ThreadMessageInfo.defaultProps = {
  fullName: 'N/A',
  date: 'N/A',
  isErrorMessage: false
};

ThreadMessageInfo.propTypes = {
  byUs: PropTypes.bool.isRequired,
  fullName: PropTypes.string,
  date: PropTypes.string,
  moduleType: PropTypes.string.isRequired,
  isErrorMessage: PropTypes.bool
};

export default ThreadMessageInfo;
