import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

class Link extends PureComponent {
  handleClick = () => {
    if (this.props.href) {
      window.open(this.props.href, '_blank');
    }
  };
  render() {
    const {
      className, style, children, color, ...props
    } = this.props;
    return (
      <div
        role="presentation"
        className={cn('text-hover-blue', 'd-flex', 'align-items-center', 'fill-hover-blue', 'transition-all', 'clickable', { [`text-${color} fill-${color}`]: color }, className)}
        onClick={this.handleClick}
        style={style}
        {...props}
      >{children}
      </div>
    );
  }
}

Link.defaultProps = {
  className: '',
  style: {},
  href: '',
  color: 'white',
};

Link.propTypes = {
  /** Additional class to add to the link component */
  className: PropTypes.string,
  /** Additional styles to add to the link component  */
  style: PropTypes.shape(),
  /** Url to redirect on click */
  href: PropTypes.string,
  /** The item to render inside the link */
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  /** Color for link text */
  color: PropTypes.string,
};

export default Link;
