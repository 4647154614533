import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { filter, head, isString, map, size, isEqual, find, noop } from 'lodash';
import DropdownInputField from '../DropdownInputField';
import DropdownMenu from '../DropdownMenu';
import MultiSelectDropdownMenuItem from './MultiSelectDropdownMenuItem';
import withDropdown from '../withDropdown';
import withCollapse from '../../collapsibles/withCollapse';
import MultiSelectDropdownSelectedMenu from './MultiSelectDropdownSelectedMenu';

class MultiSelectDropdown extends Component {
  constructor(props) {
    super(props);
    const initialSelectedItems = this.getSelectedItems(
      props.options,
      props.values
    );
    this.state = {
      unselectedOptions: this.getUnselectedItems(props.options, props.values),
      selectedOptions: initialSelectedItems,
      searchInputValue: this.getPlaceholder(
        initialSelectedItems,
        props.single,
        props.plural
      )
    };
  }

  componentDidUpdate(prevProps) {
    const { values, options } = this.props;
    if (
      options.length !== prevProps.options.length &&
      !isEqual(options, prevProps.options)
    ) {
      this.setNewOptions(options, values);
      this.setValues(values);
    } else if (!isEqual(values, prevProps.values)) {
      this.setValues(values);
    }
  }

  getSelectedItems = (options, selected) => {
    if (!size(selected)) return [];

    const checkedItemsSet = new Set(selected);
    return filter(options, option => checkedItemsSet.has(option.value));
  };

  getUnselectedItems = (options, selected) => {
    if (!size(selected)) return options;

    const checkedItemsSet = new Set(selected);
    return filter(options, option => !checkedItemsSet.has(option.value));
  };

  getPlaceholder = (options, single, plural) => {
    if (!options || !size(options)) return '';

    let tmpOptions = options;
    let firstItem;
    firstItem = head(options).label;
    if (!isString(firstItem)) {
      tmpOptions = map(options, 'name');
      firstItem = head(tmpOptions);
    }
    if (tmpOptions.length === 1) return firstItem;
    const type = tmpOptions.length === 2 ? single : plural;
    return `${firstItem} + ${tmpOptions.length - 1} ${type}`;
  };

  setValues = values => {
    const { single, plural, options } = this.props;
    const unselectedOptions = this.getUnselectedItems(options, values);
    const selectedOptions = this.getSelectedItems(options, values);
    const searchInputValue = this.getPlaceholder(
      selectedOptions,
      single,
      plural
    );
    this.setState({ searchInputValue, selectedOptions, unselectedOptions });
  };

  setNewOptions = (options, selectedOptions) => {
    const unselectedOptions = this.getUnselectedItems(options, selectedOptions);
    this.setState({ unselectedOptions });
  };

  clearSelected = () => {
    const { onChange, id, name } = this.props;
    this.setState({
      searchInputValue: '',
      selectedOptions: [],
      unselectedOptions: this.props.options
    });
    onChange([], id || name);
  };

  handleItemChange = item => {
    let { selectedOptions, unselectedOptions } = this.state;
    const { single, plural, id, name } = this.props;
    const isChecked = find(selectedOptions, { value: item.value });
    if (isChecked) {
      unselectedOptions.push(item);
      selectedOptions = filter(
        selectedOptions,
        option => option.value !== item.value
      );
    } else {
      selectedOptions.push(item);
      unselectedOptions = filter(
        unselectedOptions,
        option => option.value !== item.value
      );
    }
    const searchInputValue = this.getPlaceholder(
      selectedOptions,
      single,
      plural
    );
    this.setState({ searchInputValue, selectedOptions, unselectedOptions });
    this.props.onChange(map(selectedOptions, 'value'), id || name);
  };

  render() {
    const {
      toggleCollapse,
      isCollapsed,
      name,
      className,
      onSearchChange,
      clearQuery,
      closeDropdown,
      style,
      isFetching,
      fetchNextPage,
      pageCount,
      currentPage,
      query,
      placeholder
    } = this.props;
    const { searchInputValue, selectedOptions, unselectedOptions } = this.state;

    return (
      <div
        className={cn('d-flex-fill relative flex-column', className)}
        style={style}
        name={name}
      >
        <DropdownInputField
          query={query}
          name={name}
          value={searchInputValue}
          onQueryChange={onSearchChange}
          onIconClick={toggleCollapse}
          isOpen={!isCollapsed}
          searchable
          onClearQuery={clearQuery}
          onClearSelected={this.clearSelected}
          placeholder={placeholder}
        />
        <DropdownMenu
          pageCount={pageCount}
          onDismiss={closeDropdown}
          isEmptyState={!unselectedOptions.length}
          currentPage={currentPage}
          onLoadMore={fetchNextPage}
          isFetching={isFetching}
          isCollapsed={isCollapsed}
        >
          <MultiSelectDropdownSelectedMenu
            options={selectedOptions}
            onChange={this.handleItemChange}
          />
          {unselectedOptions.map((option, key) => {
            const iteratorId = key;
            return (
              <MultiSelectDropdownMenuItem
                key={`MultiSelectDropdownMenuItem-${iteratorId}`}
                checked={false}
                onChange={this.handleItemChange}
                value={option}
              >
                {option.label}
              </MultiSelectDropdownMenuItem>
            );
          })}
        </DropdownMenu>
      </div>
    );
  }
}

MultiSelectDropdown.defaultProps = {
  values: [],
  options: [],
  single: 'more',
  plural: 'more',
  className: '',
  id: '',
  name: '',
  onLoadMore: noop,
  isFetching: false,
  pageCount: 0,
  placeholder: 'Select or type',
  onChange: noop,
  onSelect: noop
};

MultiSelectDropdown.propTypes = {
  /** Callback function to be called on input value changed - Deprecated */
  onSelect: PropTypes.func,
  /** Callback function to be called on input value changed */
  onChange: PropTypes.func,
  /** Array to populate the dropdown un selected list  */
  values: PropTypes.arrayOf(PropTypes.string),
  /** Array, current selected values - structure :
   *  { label: 'component' ? name prop is mandatory / 'String'.
   *    value: the value return to the parent */
  options: PropTypes.arrayOf(PropTypes.object),
  /** Callback function to be called on input value changed */
  onSearchQueryChange: PropTypes.func.isRequired,
  /** String, to display placeholder with 2 values */
  single: PropTypes.string,
  /** String, to display placeholder with more than 2 values */
  plural: PropTypes.string,
  /** String, classes to pass */
  className: PropTypes.string,
  /** String, identifier */
  name: PropTypes.string,
  /** String, identifier */
  id: PropTypes.string,
  /** Callback function to fetch more items - required for infinite scroll */
  onLoadMore: PropTypes.func,
  /** Total page count for pagination - required to allow infinite scroll */
  pageCount: PropTypes.number,
  /** Indicator whether or not we are currently fetching value for infinite scroll */
  isFetching: PropTypes.bool,
  /** Placeholder for multi select dropdown */
  placeholder: PropTypes.string
};

export const MultiSelectDropdownComponent = MultiSelectDropdown;
export default withCollapse(withDropdown(MultiSelectDropdown));
