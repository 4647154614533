import useTranslations from '@guestyci/localize/useTranslations';
import { path } from 'ramda';
import { useMemo } from 'react';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import { listingPageIntlId } from '../../../../../utils/intlIds';
import ChildrenIcon from '../../assets/house_rules/children.svg';
import PetsIcon from '../../assets/house_rules/pets.svg';
import NoPetsIcon from '../../assets/house_rules/no_pets.svg';
import NoSmokingIcon from '../../assets/house_rules/no_smoking.svg';
import NoPartyIcon from '../../assets/house_rules/no_party.svg';
import QuietHoursIcon from '../../assets/house_rules/quiet_hours.svg';

const convertTime = (str) => {
  if (str) {
    const value = str.toLowerCase();
    try {
      if (value.includes('pm') || value.includes('am')) {
        return format(parse(value, 'hh:mm aaa', new Date()), 'hh:mm aaa');
      }
      if (value) {
        return format(parse(value, 'HH:mm', new Date()), 'hh:mm aaa');
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Failed to parse sting', str);
    }
  }
  return '';
};

const translations = (hours) => [
  {
    id: listingPageIntlId('children'),
    d: 'Children',
  },
  {
    id: listingPageIntlId('children_not_suitable'),
    d: 'Not suitable for children and infants',
  },
  {
    id: listingPageIntlId('children_under_two'),
    d: 'Not suitable for infants (0-2 years)',
  },
  {
    id: listingPageIntlId('children_above_two'),
    d: 'Not suitable for children (2-12 years)',
  },
  {
    id: listingPageIntlId('pets_not_allowed'),
    d: 'No pets',
  },
  {
    id: listingPageIntlId('pets_allowed'),
    d: 'Pets are allowed but charges may be applied',
  },
  {
    id: listingPageIntlId('smoking_not_allowed'),
    d: 'No smoking',
  },
  {
    id: listingPageIntlId('parties_not_allowed'),
    d: 'No parties or events',
  },
  {
    id: listingPageIntlId('quiet_hours'),
    d: 'Quiet hours',
  },
  {
    id: listingPageIntlId('quiet_between_v2'),
    d: 'Guests need to be quiet between {start} and {end}',
    variables: { start: convertTime(hours.start), end: convertTime(hours.end) },
  },
];

export const useHouseRulesData = (data) => {
  const childrenRules = path(['childrenRules'], data);
  const petsAllowed = path(['petsAllowed'], data);
  const smokingAllowed = path(['smokingAllowed'], data);
  const suitableForEvents = path(['suitableForEvents'], data);
  const quietBetween = path(['quietBetween'], data);

  const { suitableForInfants = false, suitableForChildren = false, reason = '' } = childrenRules || {};
  const { enabled: petsEnabled = false, chargeType = '' } = petsAllowed || {};
  const { enabled: smokingEnabled = false } = smokingAllowed || {};
  const { enabled: eventsEnabled = false } = suitableForEvents || {};
  const { enabled: quietBetweenEnabled = false, hours = {} } = quietBetween || {};

  const [
    childrenText, childrenNotSuitableText, childrenUnderTwoText, childrenAboveTwoText,
    petsNotAllowedText, petsAllowedText,
    smokingNotAllowedText,
    partiesNotAllowedText,
    quietHoursText, quietBetweenText,
  ] = useTranslations(translations(hours), [hours]);

  const childrenDescText = useMemo(() => {
    let text = '';

    if (!suitableForChildren && !suitableForInfants) {
      text = childrenNotSuitableText;
    }

    if (suitableForChildren && !suitableForInfants) {
      text = childrenUnderTwoText;
    }

    if (!suitableForChildren && suitableForInfants) {
      text = childrenAboveTwoText;
    }

    if (reason.length > 0) {
      text += ` - ${reason}`;
    }

    return text;
  }, [
    suitableForChildren, suitableForInfants, reason,
    childrenNotSuitableText, childrenUnderTwoText, childrenAboveTwoText,
  ]);

  return [
    {
      key: 'children',
      title: childrenText,
      desc: childrenDescText,
      icon: ChildrenIcon,
      show: !suitableForInfants || !suitableForChildren,
    },
    {
      key: 'pets',
      title: petsEnabled ? petsAllowedText : petsNotAllowedText,
      icon: petsEnabled ? PetsIcon : NoPetsIcon,
      show: !petsEnabled || chargeType === 'petStayCharge',
    },
    {
      key: 'smoking',
      title: smokingNotAllowedText,
      icon: NoSmokingIcon,
      show: !smokingEnabled,
    },
    {
      key: 'party',
      title: partiesNotAllowedText,
      icon: NoPartyIcon,
      show: !eventsEnabled,
    },
    {
      key: 'quiet_hours',
      title: quietHoursText,
      desc: quietBetweenText,
      icon: QuietHoursIcon,
      show: quietBetweenEnabled,
    },
  ];
};
