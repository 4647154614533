import base from './base';
import development from './development';
import staging from './staging';
import staging2 from './staging2';
import staging3 from './staging3';
import staging4 from './staging4';
import staging5 from './staging5';
import staging6 from './staging6';
import staging7 from './staging7';
import production from './production';
import production2 from './production-2';
import preprod from './preprod';
import feature from './feature';
import local from './local';

const env = window.env || 'development';

export const allConfigs = {
  base,
  development,
  staging,
  staging2,
  staging3,
  staging4,
  staging5,
  staging6,
  staging7,
  production,
  production2,
  preprod,
  feature,
  local
};

export default { ...base, ...allConfigs[env] };
