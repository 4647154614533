import React, { Component } from 'react';
import cn from 'classnames';
import debounce from 'lodash/debounce';
import moment from 'moment';
import DayPicker from 'react-day-picker';
import PropTypes from 'prop-types';
import CalendarDay from './CalendarDay';
import CalendarNavBar from './CalendarNavBar';
import './calendar.scss';
import CalendarWeekDayBar from './CalendarWeekDayBar';
import memoize from '../../../utils/memoize';

class Calendar extends Component {
  getAccurateMonth = memoize(month => {
    if (month) {
      return moment
        .utc(month)
        .startOf('month')
        .add('14', 'days')
        .toDate();
    }
    return month;
  });

  handleMonthChange = debounce(month => {
    this.props.onMonthChange(month);
  }, 500);

  renderDay = day => {
    const {
      activeDates,
      calendar,
      sm,
      isFetching,
      tooltipBoundariesElement
    } = this.props;
    return (
      <CalendarDay
        tooltipBoundariesElement={tooltipBoundariesElement}
        calendar={calendar}
        day={day}
        isFetching={isFetching}
        activeDates={activeDates}
        sm={sm}
      />
    );
  };

  render() {
    const { month, initialMonth, sm } = this.props;
    const calendarInitialMonth = this.getAccurateMonth(initialMonth);
    const calendarMonth = this.getAccurateMonth(month);

    return (
      <div className={cn('relative calendar', { lg: !sm })}>
        <DayPicker
          month={calendarMonth}
          initialMonth={calendarInitialMonth}
          showOutsideDays
          onMonthChange={this.handleMonthChange}
          renderDay={this.renderDay}
          navbarElement={<CalendarNavBar />}
          weekdayElement={
            <CalendarWeekDayBar
              className={cn({ 'pb-3': !sm })}
              dayCharCount={sm ? 1 : 3}
            />
          }
        />
      </div>
    );
  }
}

Calendar.defaultProps = {
  activeDates: {
    start: null,
    end: null
  },
  initialMonth: undefined,
  month: undefined,
  sm: false,
  isFetching: false,
  tooltipBoundariesElement: undefined
};

Calendar.propTypes = {
  /** The Calendar Month data
   Structure:{ reservation: {
   guest, money, blocks, checkIn, checkOut, guestsCount, status
   }, listingId, accountId, date, currency, status, price }
   */
  calendar: PropTypes.shape({
    listingId: PropTypes.string,
    accountId: PropTypes.string,
    date: PropTypes.string,
    currency: PropTypes.string,
    status: PropTypes.string,
    price: PropTypes.number,
    reservation: PropTypes.shape({
      _id: PropTypes.string.isRequired,
      guestId: PropTypes.string.isRequired,
      confirmationCode: PropTypes.string.isRequired,
      guest: PropTypes.shape({
        _id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        avatar: PropTypes.string
      }),
      money: PropTypes.shape({
        currency: PropTypes.string.isRequired,
        hostPayout: PropTypes.number.isRequired
      }),
      blocks: PropTypes.shape().isRequired,
      checkIn: PropTypes.string,
      checkOut: PropTypes.string,
      guestsCount: PropTypes.number,
      status: PropTypes.string.isRequired
    })
  }).isRequired,
  /** Initial month - for none async actions
   *  (accepts - date string, unix and date object)
   *  */
  initialMonth: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
    PropTypes.number
  ]),
  /** controlled change month manually
   * (accepts - date string, unix and date object)
   * */
  month: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
    PropTypes.number
  ]),
  /** The dates that are active for the reservation
   - structure: {start: {String} - check in date string, end: {String} - checkout date string}
   */
  activeDates: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string
  }),
  /** Callback function to handle month change */
  onMonthChange: PropTypes.func.isRequired,
  /** Indicator whether or not we want the small calendar or regular sized */
  sm: PropTypes.bool,
  /** Indicator whether or not we are in fetching mode */
  isFetching: PropTypes.bool,
  tooltipBoundariesElement: PropTypes.string
};

export default Calendar;
