/* eslint-disable global-require */
import queryParser from 'querystring';
import reactIntl from '@guestyci/react-intl-universal';
import { http } from '../resource';

const localeDataURL = lang =>
  `https://d2g7j5hs6q3xyb.cloudfront.net/static/1/${lang}.js`;

const localeJsonURL = lang =>
  `https://d2g7j5hs6q3xyb.cloudfront.net/production/atomic-design/localesInit/locales1/${lang}.json`;

export const warningHandler = (message, detail) => {
  console.warn('warningHandler:   ', message, detail);
  try {
    const messageArr = message.split(' ');
    const body = { fields: {} };
    if (window.localStorage.getItem('checkLanguages')) {
      body.fields.message = { stringValue: '' };
      const httpService = http.create();

      delete httpService.defaults.headers.common['g-aid-cs'];
      httpService.patch(
        `https://firestore.googleapis.com/v1beta1/projects/guesty-18n/databases/(default)/documents/${
          messageArr[6]
        }/${JSON.parse(messageArr[2].trim())}`,
        body
      );
    }
  } catch (e) {
    console.error('warningHandler', message, e);
  }
};

export const getLanguage = () => {
  const query = window.location.search.split('?');
  let queryLang;

  if (query.length >= 2) {
    const params = queryParser.parse(query[1]);
    queryLang = params && params.lang ? params.lang : undefined;
  }
  return queryLang || window.localStorage.getItem('lang') || 'en-US';
};

const {
  get,
  getHTML,
  formatHTMLMessage,
  formatMessage,
  determineLocale,
  load,
  loadRemoteScript,
  getLocaleFromCookie,
  getDescendantProp,
  getLocaleFromURL,
  getLocaleFromBrowser
} = reactIntl;

export const init = async options => {
  const { fallbackLocale = 'en-US', currentLocale: locale } = options;
  const langs = [...new Set([locale, fallbackLocale])];
  const promises = langs.map(lang => http.get(localeJsonURL(lang)));

  try {
    const responses = await Promise.all(promises);
    const locales = langs.reduce((acc, lang, index) => {
      acc[lang] = responses[index].data;
      return acc;
    }, {});

    return reactIntl.init({
      locales,
      warningHandler,
      fallbackLocale,
      currentLocale: locale,
      commonLocaleDataUrls: langs.reduce((acc, lang) => {
        const shortLang = lang.split('-')[0].split('_')[0];
        acc[shortLang] = localeDataURL(shortLang);
        return acc;
      }, {})
    });
  } catch (e) {
    return e;
  }
};

export const intl = {
  init,
  get: get.bind(reactIntl),
  getHTML: getHTML.bind(reactIntl),
  formatHTMLMessage: formatHTMLMessage.bind(reactIntl),
  formatMessage: formatMessage.bind(reactIntl),
  determineLocale: determineLocale.bind(reactIntl),
  load: load.bind(reactIntl),
  loadRemoteScript: loadRemoteScript.bind(reactIntl),
  getLocaleFromCookie: getLocaleFromCookie.bind(reactIntl),
  getDescendantProp: getDescendantProp.bind(reactIntl),
  getLocaleFromURL: getLocaleFromURL.bind(reactIntl),
  getLocaleFromBrowser: getLocaleFromBrowser.bind(reactIntl)
};

export const locales = {};
