import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import useTranslations from '@guestyci/localize/useTranslations';

import TechnicalPage from 'App/pages/TechnicalPage/Technical.page';
import { MOBILE } from 'constants/css/breakpoints';
import ExpiredIcon from 'assets/expired.svg';
import { expiredPageIntlId } from 'utils/intlIds';

const ExpiredPage = ({ breakpoint }) => {
  const translations = [
    {
      id: expiredPageIntlId('mobile_title'),
      d: 'You just missed it!',
    },
    {
      id: expiredPageIntlId('desktop_title'),
      d: 'Sorry, you missed it!',
    },
    {
      id: expiredPageIntlId('mobile_sub_title'),
      d: 'Sorry, but the offer has expired',
    },
    {
      id: expiredPageIntlId('desktop_sub_title'),
      d: 'The offer has expired',
    },
  ];

  const [mobileTitle, desktopTitle, mobileSubTitle, desktopSubTitle] =
    useTranslations(translations);

  return (
    <TechnicalPage
      breakpoint={breakpoint}
      Img={ExpiredIcon}
      title={breakpoint === MOBILE ? mobileTitle : desktopTitle}
      subTitle={breakpoint === MOBILE ? mobileSubTitle : desktopSubTitle}
    />
  );
};

ExpiredPage.propTypes = {
  breakpoint: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  breakpoint: state.layout.breakpoint,
});

export default connect(mapStateToProps)(ExpiredPage);
