import React, { PureComponent } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import Step from './Step';
import Icon from '../../icon/Icon';
import StepDivider from './StepDivider';

class Steps extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { steps: this.getInitialStepArray(props) };
  }
  componentDidUpdate(prevProps) {
    if (prevProps.stepsCount !== this.props.stepsCount) {
      this.setStepArray(this.props);
    }
  }

  setStepArray(props) {
    this.setState({
      steps: this.getInitialStepArray(props),
    });
  }

  getInitialStepArray = props => [...new Array(props.stepsCount)].map((v, i) => i + 1);

  render() {
    const {
      className, style, current, color,
    } = this.props;
    const { steps } = this.state;
    return (
      <div className={cn('flex-center', className)} style={{ ...style }}>
        {steps.map((item, index, array) =>
          (array.length - 1 !== index ? (
            [
              index + 1 < current ? (
                <Icon
                  key={`ic-${index + 1}`}
                  icon="IcoApproved"
                  height={21}
                  width={21}
                  color={color}
                />
              ) : (
                <Step color={color} active={current >= index + 1} key={`s-${index + 1}`}>
                  {item}
                </Step>
              ),
              <StepDivider color={color} key={`sd-${index + 1}`} />,
            ]
          ) : (
            <Step color={color} active={current >= index + 1} key={`s-${index + 1}`}>
              {item}
            </Step>
          )))}
      </div>
    );
  }
}

Steps.defaultProps = {
  current: 1,
  color: 'blue',
};

Steps.propTypes = {
  /** Array of steps as a property */
  stepsCount: PropTypes.number.isRequired,
  /** color for step */
  color: PropTypes.oneOf(['blue', 'gray']),
  /** Current shows current selected step */
  current: PropTypes.number,
};

export default Steps;
