import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import StrapButton from 'reactstrap/lib/Button';
import './button.scss';
import Spinner from '../loaders/Spinner';

const colors = {
  primary: 'blue',
  success: 'green',
  warning: 'yellow',
  danger: 'red',
  secondary: 'plain',
};

const BaseButton = ({
  classes, color, children, className, style, variant, isLoading, disabled,
  loaderColor, type, childrenClassName, ...otherProps
}) => (
  <StrapButton
    className={cn({ 'btn-loading': isLoading }, 'transition-all', 'base-btn', { [`btn-${colors[color]}`]: colors[color] }, { [`btn-${variant}`]: variant }, className)}
    {...otherProps}
    style={style}
    type={type}
    disabled={disabled}
    color={colors[color]}
  >
    <div className="flex-center h-fill">
      <div className={cn(childrenClassName, 'overflow-ellipsis', { invisible: isLoading })}>{children}</div>
      {isLoading && <Spinner className="absolute" size={15} strokeWidth={6} color={loaderColor} />}
    </div>
  </StrapButton >
);

BaseButton.defaultProps = {
  color: 'secondary',
  className: '',
  variant: 'flat',
  isLoading: false,
  disabled: false,
  loaderColor: 'white',
  type: 'button',
};

BaseButton.propTypes = {
  /** children passed to the button - string or Node */
  children: PropTypes.node.isRequired,
  /**  Color for button  - accept (primary, success, warning, danger) */
  color: PropTypes.string,
  /**  Variant of button  - accept (raised, flat, outline, icon) */
  variant: PropTypes.oneOf(['raised', 'flat', 'outline', 'icon']),
  /** Class for button base class */
  className: PropTypes.string,
  /** Indicator for loading to show spinner */
  isLoading: PropTypes.bool,
  /** Indicator whether the button is disabled */
  disabled: PropTypes.bool,
  /** Color for loading spinner */
  loaderColor: PropTypes.string,
  /** The button type */
  type: PropTypes.string,
};
export default BaseButton;
