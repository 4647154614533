import React, { Component } from 'react';
/**
 * Create a wrapper compose to support focus functionality
 * @param ComposedComponent
 * @returns {{Component}}
 */


function withFocus(ComposedComponent) {
  class FocusWrapper extends Component {
    constructor(props) {
      super(props);
      this.state = { isFocus: false };
    }
    onBlur = () => {
      this.setState({ isFocus: false });
    };
    onFocus = () => {
      this.setState({ isFocus: true });
    };
    render() {
      return <ComposedComponent
        {...this.props}
        isFocused={this.state.isFocus}
        onBlur={this.onBlur}
        onFocus={this.onFocus}
      />;
    }
  }

  return FocusWrapper;
}

export default withFocus;
