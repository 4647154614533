import React from 'react';
import Bullet from '../indicators/bullet/Bullet';
import Person from '../avatarStructures/person/Person';

const CalendarDayLGBody = ({
  day,
  isHovering,
  hasNote,
  guest,
  startOfBlock,
  personOpacity: opacity,
  price
}) => (
  <div className="d-flex-fill flex-space-between-center h-fill flex-column pt-1">
    <div className="w-100 flex-end-base h-fill pr-3">
      {hasNote && (
        <Bullet
          className="calendar-day-bullet"
          color={isHovering ? 'blue' : 'muted'}
          style={{ height: 6, width: 6 }}
        />
      )}
      <div className="day pl-1">{day}</div>
    </div>
    <div className="w-100 pl-7 pb-1 flex-start-center">
      {startOfBlock && guest && (
        <Person
          style={{ opacity }}
          isUser
          name={guest.name}
          img={guest.avatar}
          size="sm"
        />
      )}
    </div>
    <div className="w-100 flex-end pr-3">{price && <div> {price} </div>}</div>
  </div>
);

export default CalendarDayLGBody;
