import React from 'react';
import withStyle from 'react-jss';
import cn from 'classnames';
import PropTypes from 'prop-types';
import _ from 'lodash';
import ActionButton from '../../buttons/ActionButton';
import { DatePickerType } from '../../../../constants/enums';

const styles = {
  button: ({ sm, type }) => ({
    position: 'absolute',
    top: '20px',
    width: (type === DatePickerType.Single || sm) ? 40 : undefined,
    height: (type === DatePickerType.Single || sm) ? 40 : undefined,
  }),
  nextButton: {
    right: '22px',
  },
  prevButton: {
    left: '22px',
  },
};

const DatePickerNavButton = ({ direction, classes }) => (
  <ActionButton
    onClick={_.noop}
    icon={`Btn${direction}Small`}
    iconHeight={11}
    iconWidth={6}
    className={cn(classes.button, { [classes.nextButton]: direction === 'Right', [classes.prevButton]: direction === 'Left' })}
  />
);

DatePickerNavButton.defaultProps = {
  sm: false,
};
DatePickerNavButton.propTypes = {
  direction: PropTypes.oneOf(['Right', 'Left']).isRequired,
  sm: PropTypes.bool,
  type: PropTypes.oneOf([DatePickerType.Single, DatePickerType.Range]).isRequired,
};

export default withStyle(styles)(DatePickerNavButton);
