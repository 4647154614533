import React, { Fragment } from 'react';
import MultiSelectDropdownMenuItem from './MultiSelectDropdownMenuItem';
import Divider from '../../divider/Divider';

const MultiSelectDropdownSelectedMenu = ({ options, onChange }) => (
  <Fragment>
    {!!options.length &&
    <div className="checked-items flex-column" >
      {options.map(option =>
        <MultiSelectDropdownMenuItem
          key={option.value}
          checked
          onChange={onChange}
          value={option}
        >
          {option.label}
        </MultiSelectDropdownMenuItem>)}
      <Divider className="pt-3" color="gray-lighter" />
    </div>}
  </Fragment>
);

export default MultiSelectDropdownSelectedMenu;
