import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import withStyles from 'react-jss';
import { AnchorDirectionType, MenuSlideAnchorDirectionType } from '../../../constants/enums';
import { memoize } from '../../../utils/commonUtility';
import Portal from '../../utils/portal/Portal';

const styles = {
  blocker: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1300,
    position: 'fixed',
    touchAction: 'none',
    backgroundColor: 'transparent',
  },
  menu: {
    zIndex: 1301,
  },
};
class MenuAutoPosition extends PureComponent {
  constructor(props) {
    super(props);
    this.autoPositionRef = React.createRef();
    this.childRef = React.createRef();
    this.state = { position: {}, direction: MenuSlideAnchorDirectionType.Top };
  }

  componentDidUpdate(prevProps) {
    if (this.props.trigger && prevProps.trigger !== this.props.trigger) {
      this.calculatePosition();
    }
  }

  getAnchorDirection = memoize((anchor, parentWidth) => {
    const childWidth = this.childRef.current.offsetWidth;
    switch (anchor) {
      case AnchorDirectionType.Left:
        return -(childWidth);
      case AnchorDirectionType.Right:
        return parentWidth;
      case AnchorDirectionType.EndCenter:
        return -(childWidth - (parentWidth / 2));
      case AnchorDirectionType.StartCenter:
        return parentWidth / 2;
      case AnchorDirectionType.Center:
        return -(childWidth - parentWidth) / 2;
      case AnchorDirectionType.End:
        return -(childWidth - parentWidth);
      default:
        return 0;
    }
  });

  isInViewPort = offsetHeight =>
    offsetHeight <= (window.scrollY
   + (window.innerHeight || document.documentElement.clientHeight));

  calculateComponentPosition = memoize((left, parentWidth, top, parentHeight) => {
    const { offsetHeight, offsetWidth } = this.childRef.current;
    let direction = MenuSlideAnchorDirectionType.Top;
    const offsetTop = top + window.scrollY;
    const width = offsetWidth >= parentWidth ? offsetWidth : parentWidth;
    const position = { left, top: offsetTop, width };
    if (!this.isInViewPort(offsetTop + offsetHeight)) {
      position.top -= (offsetHeight);
      direction = MenuSlideAnchorDirectionType.Bottom;
    } else {
      position.top += parentHeight;
    }
    return { position, direction };
  });

  calculatePosition() {
    const el = this.autoPositionRef.current;
    if (el) {
      const parent = el.parentElement;
      const { offsetHeight } = parent;
      const { left, width, top } = parent.getBoundingClientRect();
      const archorDirection = this.getAnchorDirection(this.props.anchorDirection, width);
      console.log('archorDirection', archorDirection);
      const { position, direction } = this.calculateComponentPosition(
        left + archorDirection,
        width,
        top,
        offsetHeight,
      );
      this.setState({ position, direction });
    }
  }
  render() {
    const {
      children, className, style, trigger, classes,
    } = this.props;
    const { position, direction } = this.state;
    return (
      <div ref={this.autoPositionRef}>
        <Portal withState animation="scale" isOpen={trigger}>
          <div className={classes.blocker} />
          <div
            className={cn('absolute menu-auto-position', `transform-center-${direction}`, classes.menu, className)}
            style={Object.assign({}, style, position)}
            ref={this.childRef}
          >
            {children}
          </div>
        </Portal>
      </div>
    );
  }
}

MenuAutoPosition.defaultProps = {
  className: '',
  style: {},
  anchorDirection: AnchorDirectionType.Start,
};

MenuAutoPosition.propTypes = {
  /** Child node for the auto position to reference and wrap */
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  /** Indicator when to re calculate the position */
  trigger: PropTypes.bool.isRequired,
  /** Additional class to root level */
  className: PropTypes.string,
  /** Additional style to root level */
  style: PropTypes.shape(),
  /** Direction to open the menu (center, left, right, end ,start, start center, end center) */
  anchorDirection: PropTypes.oneOfType([
    AnchorDirectionType.Center,
    AnchorDirectionType.Left,
    AnchorDirectionType.Right,
    AnchorDirectionType.StartCenter,
    AnchorDirectionType.EndCenter,
    AnchorDirectionType.Start,
    AnchorDirectionType.End,
  ]),
};

export default withStyles(styles)(MenuAutoPosition);
