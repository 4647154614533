import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'react-jss';
import cn from 'classnames';
import Icon from '../icon/Icon';
import withHover from '../../compose/withHover';
import Tooltip from '../tooltip/Tooltip';

const styles = {
  actionButton: {
    width: 45,
    borderBottomRightRadius: 2,
    borderTopRightRadius: 2,
    height: 45,
    '&:disabled': {
      opacity: 1,
      cursor: 'not-allowed',
    },
  },
};

class ActionButton extends Component {
  getIconColor = () => {
    const { disabled, isHovering, iconColor } = this.props;

    if (disabled) {
      return 'gray-light';
    }

    return isHovering ? 'blue' : iconColor;
  };

  render() {
    const {
      classes,
      className,
      disabled,
      onClick,
      onMouseDown,
      icon,
      iconWidth,
      id,
      iconHeight,
      style,
      iconClassName,
      hoverRef,
      tooltip,
      tooltipPlacement,
    } = this.props;

    return (
      <Tooltip body={tooltip} placement={tooltipPlacement} id={id}>
        <button
          ref={hoverRef}
          className={cn(
            'border-gray-light',
            'flex-center',
            'p-6-10',
            'b-1',
            'clickable',
            'transition-opacity',
            className,
            classes.actionButton,
          )}
          disabled={disabled}
          onMouseDown={onMouseDown}
          onClick={onClick}
          style={style}
          type="button"
        >
          <Icon
            icon={icon}
            height={iconHeight}
            width={iconWidth}
            color={this.getIconColor()}
            className={iconClassName}
          />
        </button>
      </Tooltip>
    );
  }
}

ActionButton.defaultProps = {
  className: '',
  disabled: false,
  iconWidth: 20,
  iconHeight: 20,
  iconColor: 'gray-dark',
  iconClassName: '',
  style: {},
  tooltip: null,
  tooltipPlacement: 'top',
  id: undefined,
};

ActionButton.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  iconWidth: PropTypes.number,
  iconHeight: PropTypes.number,
  iconColor: PropTypes.string,
  style: PropTypes.shape(),
  iconClassName: PropTypes.string,
  tooltip: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.number,
  ]),
  tooltipPlacement: PropTypes.string,
  id: PropTypes.string,
};

export default withStyles(styles)(withHover(ActionButton));
