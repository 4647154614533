import { invoicePageInvoiceIntlId } from 'utils/intlIds';
import { guestsTranslation, nightTranslation } from 'utils/translations';
import { TAX_NORMAL_TYPES, TAX_TYPES } from '@guestyci/shared-enums';
import { sortBy } from 'ramda';

export const translations = [
  {
    id: invoicePageInvoiceIntlId('fill_details'),
    d: 'Fill-in your details',
  },
  {
    id: invoicePageInvoiceIntlId('property'),
    d: 'Property',
  },
  {
    id: invoicePageInvoiceIntlId('address'),
    d: 'Address',
  },
  {
    id: invoicePageInvoiceIntlId('check-in'),
    d: 'Check-in',
  },
  {
    id: invoicePageInvoiceIntlId('check-out'),
    d: 'Check-out',
  },
  {
    id: invoicePageInvoiceIntlId('guest_info'),
    d: 'Guest information',
  },
  {
    id: invoicePageInvoiceIntlId('rate_plan'),
    d: 'Rate Plan',
  },
  {
    id: invoicePageInvoiceIntlId('invoice'),
    d: 'Summary',
  },
  { id: invoicePageInvoiceIntlId('taxes'), d: 'Taxes' },
  { id: invoicePageInvoiceIntlId('fees'), d: 'Fees' },
  { id: invoicePageInvoiceIntlId('cleaning_fee'), d: 'Cleaning fee' },
  { id: invoicePageInvoiceIntlId('subtotal'), d: 'Subtotal' },
  { id: invoicePageInvoiceIntlId('subtotal_before_taxes'), d: 'Subtotal before taxes' },
  { id: invoicePageInvoiceIntlId('total_payout'), d: 'Total' },
  {
    id: invoicePageInvoiceIntlId('total'),
    d: 'Payment amount',
  },
  guestsTranslation,
  nightTranslation,
];

// Rate plans
export const CANCELLATION_POLICY_PREFIX = 'cancellation_policy_';
export const CANCELLATION_FEE_PREFIX = 'cancellation_fee_';
export const MEALS_PREFIX = 'meals_';
export const CANCEL_FEE_DYNAMIC = 'any';
export const CANCEL_FEE_100 = 100;
export const CANCEL_FEE_0 = 0;
export const CANCEL_FEE_FIRST_NIGHT = '';
export const BREAKFAST = 'breakfast';
export const LUNCH = 'lunch';
export const DINNER = 'dinner';
export const ALL_INCLUSIVE = 'all_inclusive';
export const BREAKFAST_LUNCH = 'breakfast_lunch';
export const BREAKFAST_LUNCH_DINNER = 'breakfast_lunch_dinner';
export const LUNCH_DINNER = 'lunch_dinner';
export const BREAKFAST_DINNER = 'breakfast_dinner';

export const NON_REFUNDABLE = 'non_refundable';
export const WITH_DAYS = 'with_days';
export const WITH_TIME = 'with_time';

export const FIRM = 'firm';
export const FLEX = 'flex';
export const FREE = 'free';
export const MODERATE = 'moderate';
export const STRICT = 'strict';
export const SEMI_STRICT = 'semi_strict';
export const SEMI_MODERATE = 'semi_moderate';
export const SUPER_STRICT = 'super_strict';
export const STRICT_30 = 'strict_30';
export const STRICT_90 = 'strict_90';
export const STRICT_60 = 'strict_60';
export const SEMI_FLEXIBLE = 'semi_flexible';
export const SPECIAL = 'special';
export const LONG_STAY = 'direct_reservation_long_stay';

export const policiesTranslationValues = {
  [FREE]: {
    id: invoicePageInvoiceIntlId(`${CANCELLATION_POLICY_PREFIX}${WITH_TIME}`),
    d: 'Cancellation policy: 2:00 PM on arrival date',
  },
  [SPECIAL]: {
    id: invoicePageInvoiceIntlId(`${CANCELLATION_POLICY_PREFIX}${SPECIAL}`),
    d: 'Cancellation policy: Special - Set fees manually if needed',
  },
  [FIRM]: {
    id: invoicePageInvoiceIntlId(`${CANCELLATION_POLICY_PREFIX}${WITH_DAYS}`),
    d: 'Cancellation policy: {num} {num, plural, =1 {day} other {days}} prior to arrival',
    variables: { num: 14 }
  },
  [MODERATE]: {
    id: invoicePageInvoiceIntlId(`${CANCELLATION_POLICY_PREFIX}${WITH_DAYS}`),
    d: 'Cancellation policy: {num} {num, plural, =1 {day} other {days}} prior to arrival',
    variables: { num: 7 }
  },
  [SEMI_MODERATE]: {
    id: invoicePageInvoiceIntlId(`${CANCELLATION_POLICY_PREFIX}${WITH_DAYS}`),
    d: 'Cancellation policy: {num} {num, plural, =1 {day} other {days}} prior to arrival',
    variables: { num: 5 }
  },
  [FLEX]: {
    id: invoicePageInvoiceIntlId(`${CANCELLATION_POLICY_PREFIX}${WITH_DAYS}`),
    d: 'Cancellation policy: {num} {num, plural, =1 {day} other {days}} prior to arrival',
    variables: { num: 1 }
  },
  [SEMI_FLEXIBLE]: {
    id: invoicePageInvoiceIntlId(`${CANCELLATION_POLICY_PREFIX}${WITH_DAYS}`),
    d: 'Cancellation policy: {num} {num, plural, =1 {day} other {days}} prior to arrival',
    variables: { num: 2 }
  },
  [STRICT]: {
    id: invoicePageInvoiceIntlId(`${CANCELLATION_POLICY_PREFIX}${WITH_DAYS}`),
    d: 'Cancellation policy: {num} {num, plural, =1 {day} other {days}} prior to arrival',
    variables: { num: 30 }
  },
  [SEMI_STRICT]: {
    id: invoicePageInvoiceIntlId(`${CANCELLATION_POLICY_PREFIX}${WITH_DAYS}`),
    d: 'Cancellation policy: {num} {num, plural, =1 {day} other {days}} prior to arrival',
    variables: { num: 30 }
  },
  [STRICT_30]: {
    id: invoicePageInvoiceIntlId(`${CANCELLATION_POLICY_PREFIX}${WITH_DAYS}`),
    d: 'Cancellation policy: {num} {num, plural, =1 {day} other {days}} prior to arrival',
    variables: { num: 30 }
  },
  [STRICT_60]:  {
    id: invoicePageInvoiceIntlId(`${CANCELLATION_POLICY_PREFIX}${WITH_DAYS}`),
    d: 'Cancellation policy: {num} {num, plural, =1 {day} other {days}} prior to arrival',
    variables: { num: 60 }
  },
  [STRICT_90]:  {
    id: invoicePageInvoiceIntlId(`${CANCELLATION_POLICY_PREFIX}${WITH_DAYS}`),
    d: 'Cancellation policy: {num} {num, plural, =1 {day} other {days}} prior to arrival',
    variables: { num: 90 }
  },
  [SUPER_STRICT]: {
    id: invoicePageInvoiceIntlId(`${CANCELLATION_POLICY_PREFIX}${NON_REFUNDABLE}`),
    d: 'Cancellation policy: Non-refundable',
  },
  [LONG_STAY]: {
    id: invoicePageInvoiceIntlId(`${CANCELLATION_POLICY_PREFIX}${LONG_STAY}`),
    d: 'Cancellation policy: Long Stay',
  }
}

export const getCancellationFeeTranslationValue = (value) => {
  if (value === CANCEL_FEE_0) {
    return {
      id: invoicePageInvoiceIntlId(`${CANCELLATION_FEE_PREFIX}`),
      d: '',
    }
  }
  if (!value) {
    return {
      id: invoicePageInvoiceIntlId(`${CANCELLATION_FEE_PREFIX}${CANCEL_FEE_FIRST_NIGHT}`),
      d: 'Cancellation fee: First night rate',
    };
  }

  return {
    id: invoicePageInvoiceIntlId(`${CANCELLATION_FEE_PREFIX}${CANCEL_FEE_DYNAMIC}`),
    d: 'Cancellation fee: {fee}% of total price',
    variables: { fee: value }
  };
};

export const mealsTranslations = {
  [BREAKFAST]: {
    id: invoicePageInvoiceIntlId(`${MEALS_PREFIX}${BREAKFAST}`),
    d: 'Meals: Breakfast',
  },
  [LUNCH]: {
    id: invoicePageInvoiceIntlId(`${MEALS_PREFIX}${LUNCH}`),
    d: 'Meals: Lunch',
  },
  [DINNER]: {
    id: invoicePageInvoiceIntlId(`${MEALS_PREFIX}${DINNER}`),
    d: 'Meals: Dinner',
  },
  [ALL_INCLUSIVE]: {
    id: invoicePageInvoiceIntlId(`${MEALS_PREFIX}${ALL_INCLUSIVE}`),
    d: 'Meals: All inclusive',
  },
  [BREAKFAST_LUNCH]: {
    id: invoicePageInvoiceIntlId(`${MEALS_PREFIX}${BREAKFAST_LUNCH}`),
    d: 'Meals: Breakfast, lunch',
  },
  [BREAKFAST_LUNCH_DINNER]: {
    id: invoicePageInvoiceIntlId(`${MEALS_PREFIX}${BREAKFAST_LUNCH_DINNER}`),
    d: 'Meals: Breakfast, lunch, dinner',
  },
  [LUNCH_DINNER]: {
    id: invoicePageInvoiceIntlId(`${MEALS_PREFIX}${LUNCH_DINNER}`),
    d: 'Meals: Lunch, dinner',
  },
  [BREAKFAST_DINNER]: {
    id: invoicePageInvoiceIntlId(`${MEALS_PREFIX}${BREAKFAST_DINNER}`),
    d: 'Meals: Breakfast, dinner',
  },
};

export const getMealsTranslationValue = (values) => {
  const valuesCount = values.length;
  if (!valuesCount) {
    return {
      id: invoicePageInvoiceIntlId(`${MEALS_PREFIX}`),
      d: '',
    }
  }
  if (valuesCount === 1) {
    return mealsTranslations[BREAKFAST];
  }
  const order = {
    breakfast: 0,
    lunch: 1,
    dinner: 2,
    all_inclusive: 3,
  };
  const valuesSort = sortBy(item => order[item], values);
  const stringValue = valuesSort.join('_');

  return mealsTranslations[stringValue];
};

export const ratesTranslations = (policy, fee, mealPlans) => ([
    policiesTranslationValues[policy],
    getCancellationFeeTranslationValue(fee),
    getMealsTranslationValue(mealPlans),
]);

export const taxesTranslations = [
  {
    id: invoicePageInvoiceIntlId('taxes.city_tax'),
    d: 'City tax',
  },
  {
    id: invoicePageInvoiceIntlId('taxes.goods_and_services_tax'),
    d: 'Goods and services tax',
  },
  {
    id: invoicePageInvoiceIntlId('taxes.local_tax'),
    d: 'Local tax',
  },
  {
    id: invoicePageInvoiceIntlId('taxes.tourism_tax'),
    d: 'Tourism tax',
  },
  {
    id: invoicePageInvoiceIntlId('taxes.vat'),
    d: 'VAT',
  },
  {
    id: invoicePageInvoiceIntlId('taxes.other'),
    d: 'Other',
  },
  {
    id: invoicePageInvoiceIntlId('taxes.state_tax'),
    d: 'State tax',
  },
  {
    id: invoicePageInvoiceIntlId('taxes.county_tax'),
    d: 'County tax',
  },
  {
    id: invoicePageInvoiceIntlId('taxes.occupancy_tax'),
    d: 'Occupancy tax',
  },
  {
    id: invoicePageInvoiceIntlId('taxes.transient_occupancy_tax'),
    d: 'Transient Occupancy tax',
  },
  {
    id: invoicePageInvoiceIntlId('taxes.home_sharing_tax'),
    d: 'Home Sharing tax',
  },
  {
    id: invoicePageInvoiceIntlId('taxes.harmonized_sales_tax'),
    d: 'Harmonized Sales tax',
  },
  {
    id: invoicePageInvoiceIntlId('taxes.minimum_alternate_tax'),
    d: 'Minimum Alternate tax',
  }
];

export const getTaxesTypes = (translations) => {
  const [
    city_tax,
    goods_and_services_tax,
    local_tax,
    tourism_tax,
    vat,
    other,
    state_tax,
    county_tax,
    occupancy_tax,
    transient_occupancy_tax,
    home_sharing_tax,
    harmonized_sales_tax,
    minimum_alternate_tax,
  ] = translations;
  return {
    [TAX_TYPES.CITY_TAX]: city_tax,
    [TAX_TYPES.GOODS_AND_SERVICES_TAX]: goods_and_services_tax,
    [TAX_TYPES.LOCAL_TAX]: local_tax,
    [TAX_TYPES.TOURISM_TAX]: tourism_tax,
    [TAX_TYPES.VAT]: vat,
    [TAX_TYPES.OTHER]: other,
    [TAX_TYPES.STATE_TAX]: state_tax,
    [TAX_TYPES.COUNTY_TAX]: county_tax,
    [TAX_TYPES.OCCUPANCY_TAX]: occupancy_tax,
    [TAX_TYPES.TRANSIENT_OCCUPANCY_TAX]: transient_occupancy_tax,
    [TAX_TYPES.HOME_SHARING_TAX]: home_sharing_tax,
    [TAX_TYPES.HARMONIZED_SALES_TAX]: harmonized_sales_tax,
    [TAX_TYPES.MINIMUM_ALTERNATE_TAX]: minimum_alternate_tax,
  };
};

export const TAXES_NORMAL_TYPES = ['LT', 'CT', 'TT', 'GST', 'VAT', 'TAX', 'ST', 'COT', 'OCT', 'TOT', 'HSHAT', 'HST', 'MAT'];

export const toTaxType = normalType => {
  const taxTypesMap = {
    [TAX_NORMAL_TYPES.CT]: TAX_TYPES.CITY_TAX,
    [TAX_NORMAL_TYPES.GST]: TAX_TYPES.GOODS_AND_SERVICES_TAX,
    [TAX_NORMAL_TYPES.LT]: TAX_TYPES.LOCAL_TAX,
    [TAX_NORMAL_TYPES.TT]: TAX_TYPES.TOURISM_TAX,
    [TAX_NORMAL_TYPES.VAT]: TAX_TYPES.VAT,
    [TAX_NORMAL_TYPES.ST]: TAX_TYPES.STATE_TAX,
    [TAX_NORMAL_TYPES.COT]: TAX_TYPES.COUNTY_TAX,
    [TAX_NORMAL_TYPES.OCT]: TAX_TYPES.OCCUPANCY_TAX,
    [TAX_NORMAL_TYPES.TOT]: TAX_TYPES.TRANSIENT_OCCUPANCY_TAX,
    [TAX_NORMAL_TYPES.HSHAT]: TAX_TYPES.HOME_SHARING_TAX,
    [TAX_NORMAL_TYPES.HST]: TAX_TYPES.HARMONIZED_SALES_TAX,
    [TAX_NORMAL_TYPES.MAT]: TAX_TYPES.MINIMUM_ALTERNATE_TAX,
    [TAX_NORMAL_TYPES.TAX]: TAX_TYPES.OTHER,
  };

  return taxTypesMap[normalType];
}

export const getNumberOfGuestsTranslations = ({
  numberOfAdults,
  numberOfChildren,
  numberOfInfants
}) => ([
  {
    id: invoicePageInvoiceIntlId('number_of_guest_title'),
    d: 'Number of guests'
  },
  {
    id: invoicePageInvoiceIntlId('number_of_guest_split'),
    d: '{numberOfAdults} {numberOfAdults, plural, =1 {Adult} other {Adults}}, ' +
      '{numberOfChildren} {numberOfChildren, plural, =1 {Child} other' +
      ' {Children}}, ' +
      '{numberOfInfants} {numberOfInfants, plural, =1 {Infant} other {Infants}}',
    variables: { numberOfAdults, numberOfChildren, numberOfInfants }
  },
])
