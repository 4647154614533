import { all } from 'redux-saga/effects';
import { flatten } from 'ramda';

const req = require.context('redux/sagas', false, /\.js$/);
const sagas = flatten(req.keys().map(path => req(path).default));

export default function* rootSaga() {
  yield all([
    ...sagas,
  ]);
}
