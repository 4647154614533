import React from 'react';
import { DateRangePicker } from 'react-dates';
import { DateDisplayFormat } from '../../../../constants/enums';

const RangePicker = ({
  startDate,
  startDateId,
  endDateId,
  style,
  prevMonthButton,
  nextMonthButton,
  renderMonthElement,
  enableOutsideDays,
  isDayBlocked,
  isOutsideRange,
  verticalSpacing,
  disabled,
  endDate,
  readOnly,
  daySize,
  focusedInput,
  onFocus,
  numberOfMonths,
  minimumNights,
  onMonthChange,
  renderDayContents,
  initialVisibleMonth,
  onDatesChange,
  anchorDirection,
  openDirection,
  weekDayFormat
}) => (
  <div style={style}>
    <DateRangePicker
      startDatePlaceholderText="Start date"
      endDatePlaceholderText={startDate ? '' : 'End date'}
      startDate={startDate}
      startDateId={startDateId}
      endDate={endDate}
      endDateId={endDateId}
      onDatesChange={onDatesChange}
      isDayBlocked={isDayBlocked}
      enableOutsideDays={enableOutsideDays}
      daySize={daySize}
      disabled={disabled}
      noBorder
      onNextMonthClick={onMonthChange}
      onPrevMonthClick={onMonthChange}
      numberOfMonths={numberOfMonths}
      hideKeyboardShortcutsPanel
      verticalSpacing={verticalSpacing}
      customArrowIcon={
        <div className={startDate || endDate ? 'text-black' : 'text-gray'}>
          {' '}
          -{' '}
        </div>
      }
      navPrev={prevMonthButton}
      navNext={nextMonthButton}
      renderMonthElement={renderMonthElement}
      focusedInput={focusedInput}
      onFocusChange={onFocus}
      readOnly={readOnly}
      minimumNights={minimumNights}
      renderDayContents={renderDayContents}
      isOutsideRange={isOutsideRange}
      initialVisibleMonth={initialVisibleMonth}
      anchorDirection={anchorDirection}
      openDirection={openDirection}
      weekDayFormat={weekDayFormat}
      displayFormat={DateDisplayFormat}
    />
  </div>
);

export default RangePicker;
