import React from 'react';
import { SingleDatePicker } from 'react-dates';
import { DateDisplayFormat } from '../../../../constants/enums';

const SinglePicker = ({
  style,
  prevMonthButton,
  nextMonthButton,
  renderMonthElement,
  verticalSpacing,
  disabled,
  date,
  readOnly,
  daySize,
  numberOfMonths,
  isOutsideRange,
  isDayBlocked,
  initialVisibleMonth,
  onDateChange,
  isFocused,
  onFocusChange,
  renderDayContents,
  anchorDirection,
  openDirection,
  weekDayFormat
}) => (
  <div style={style}>
    <SingleDatePicker
      date={date}
      placeholder="Select"
      id="single_date_id"
      onDateChange={onDateChange}
      noBorder
      daySize={daySize}
      numberOfMonths={numberOfMonths}
      verticalSpacing={verticalSpacing}
      navPrev={prevMonthButton}
      navNext={nextMonthButton}
      renderMonthElement={renderMonthElement}
      focused={isFocused}
      onFocusChange={onFocusChange}
      disabled={disabled}
      hideKeyboardShortcutsPanel
      readOnly={readOnly}
      renderDayContents={renderDayContents}
      isOutsideRange={isOutsideRange}
      isDayBlocked={isDayBlocked}
      initialVisibleMonth={initialVisibleMonth}
      anchorDirection={anchorDirection}
      openDirection={openDirection}
      weekDayFormat={weekDayFormat}
      displayFormat={DateDisplayFormat}
    />
  </div>
);

export default SinglePicker;
