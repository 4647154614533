import { createBrowserHistory } from 'history';
import queryParser from 'querystring';
import isEmpty from 'lodash/isEmpty';

let baseUrl = '';
let isFragment = false;
let history = createBrowserHistory();

function updateFragmentParent(route) {
  if (isFragment) {
    window.parent.history.pushState('', '', route);
  }
}

/**
 * Set navigation baseUrl
 * @param url {String}  App base url
 */
export function setBaseUrl(url = '') {
  baseUrl = url.startsWith('/') ? url : `${baseUrl}${url}`;
}

/**
 * Set routing as fragment (iframe) instead of regular routing
 */
export function setAsFragment() {
  isFragment = true;
}

/**
 * Set routing as fragment (iframe) instead of regular routing
 */
export function setIsFragment(val) {
  isFragment = val;
}
/**
 * Get application base url
 * @return {string} The base url;
 */
export function getBaseUrl() {
  return baseUrl;
}

/** get browser history to init react router */
export function getHistory() {
  return history;
}

/**
 * Turn a string query param to an object
 * @param string {String}   The query param string
 */
export function queryParamStringToObject(string) {
  if (isEmpty(string)) {
    return {};
  }
  const queryParams = string.split('&');
  return queryParams.reduce((obj, queryParam) => {
    const [key, val] = queryParam.split('=');
    return { ...obj, [key]: val };
  }, {});
}
/**
 * Get the url query params
 * @returns {*}
 */
export function getQueryParams() {
  let { search } = window.location;
  search = search.startsWith('?') ? search.substring(1) : search;
  return queryParser.parse(search);
}

/**
 * Get the current url name without the base url
 * @returns {*}
 */
export function getPathName() {
  try {
    return window.location.pathname.replace(baseUrl, '');
  } catch (e) {
    return e;
  }
}

/**
 * Check if url has a specific parameter in it
 * @param param {String || Number}  The url parameter to check if exists
 * @return {*}
 */
export function hasParamInPathName(param) {
  try {
    const path = getPathName() || '';
    const set = new Set(path.split('/'));
    return set.has(param);
  } catch (e) {
    return e;
  }
}

/**
 * Build the query params to be set on the url
 * @param params    {Object}  The payload object
 * @returns {string}    the formatted query params
 */
function buildURLQueryParams(params) {
  return queryParser.stringify(params);
}

/**
 * Build search query params from params and preserve query
 * @param params
 * @param preserveQueryParams
 * @returns {string}
 */
function buildSearchQueryParam(params = {}, preserveQueryParams = false) {
  let newSearch = '';
  let { search } = window.location;
  search = search.startsWith('?') ? search.substring(1) : search;
  const isParamsEmpty = isEmpty(params);
  if (preserveQueryParams && isParamsEmpty) {
    newSearch = search;
  } else if (preserveQueryParams && !isParamsEmpty) {
    const newQuery = { ...params, ...getQueryParams(search) };
    newSearch = buildURLQueryParams(newQuery);
  } else if (!preserveQueryParams && !isParamsEmpty) {
    newSearch = buildURLQueryParams(params);
  }

  return !isEmpty(newSearch) ? `?${newSearch}` : newSearch;
}

/**
 * Create a new browser history and init with params
 * @param params  {Object}  Params for history
 * @return {{createHref, goBack, length, replace, go, action, location, goForward, block, push, listen}}
 */
export function createHistory(params = {}) {
  history = createBrowserHistory({ basename: baseUrl });
  setIsFragment(params.isFragment);
  return history;
}

/**
 * Link to url
 * @param location  {String}  The full path for the link
 * @param isNewTab  {Boolean} Indicator whether or not to show in new tab ( auto - true)
 */
export function link(location, isNewTab = true) {
  if (isNewTab) {
    window.open(location, '_blank');
  } else {
    window.location.replace(location);
  }
}

/**
 * Set query params into the url
 * @param params  {Object} the query param object to set
 * @param preservePrevious  {Boolean}  indicator whether or not to preserve the previous query params
 */
export function setQueryParams(params, preservePrevious) {
  const search = buildSearchQueryParam(params, preservePrevious);
  history.push({ search });
  updateFragmentParent(search);
}
/**
 * Navigate to new container
 * @param route {String} The route to nav to
 * @param params {Object} The query params to pass as object
 * @param preserveQueryParams
 */
export function go(route, params = {}, preserveQueryParams = false) {
  if (!isEmpty(route)) {
    const search = buildSearchQueryParam(params, preserveQueryParams);
    const pathname = baseUrl + (route.startsWith('/') ? route : `/${route}`);
    history.push({ pathname, search });
    updateFragmentParent(`${pathname}${search}`);
  }
}

export const unlisten = history.listen((location, action) => {
  console.log('HISTORY', location, action);
  if (action === 'PUSH') {
    window.parent.postMessage(
      {
        action: 'INNER_APP_ROUTE_CHANGE',
        payload: {
          location: location.pathname.substr(5),
          search: location.search
        }
      },
      '*'
    );
  }
});
