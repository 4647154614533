import React, { useState } from 'react';
import Row from '@guestyci/foundation/Layout/Row';
import TextField from '@guestyci/foundation/TextField';
import Checkbox from '@guestyci/foundation/Checkbox';
import FormGroup from '@guestyci/foundation/FormGroup';
import Col from '@guestyci/foundation/Layout/Col';
import FormField from '@guestyci/foundation/FormField';
import { FORM_FIELDS } from '@guestyci/guesty-pay-widget';
import Input from '@guestyci/foundation/Input';
import useMediaQuery from '@guestyci/foundation/useMediaQuery';

const CardholderName = ({
  toggleLabelText,
  initialValues,
  change,
  firstNameText,
  lastNameText,
  title
}) => {
  const [useInitialValues, setUseInitialValues] = useState(true);

  const uncheckToggle = () => {
    setUseInitialValues(false)
  }

  const setValues = () => {
    change(FORM_FIELDS.FIRST_NAME, initialValues[FORM_FIELDS.FIRST_NAME]);
    change(FORM_FIELDS.LAST_NAME, initialValues[FORM_FIELDS.LAST_NAME]);
  }

  const resetValues = () => {
    change(FORM_FIELDS.FIRST_NAME, '');
    change(FORM_FIELDS.LAST_NAME, '');
  }

  const toggleInitialValues = () => {
    if (useInitialValues) {
      resetValues();
    } else {
      setValues();
    }
    setUseInitialValues(!useInitialValues);
  }

  const isSmallScreen = useMediaQuery({ maxWidth: 'sm' });

  return (
    <Col spacing={3}>
      <Row justify="between" align="center">
        <TextField variant="h3">{title}</TextField>
        <Checkbox checked={useInitialValues} onChange={toggleInitialValues}>
          {toggleLabelText}
        </Checkbox>
      </Row>
      <FormGroup horizontal={!isSmallScreen}>
        <Col breakpoints={{ xs: 12, lg: 6 }}>
          <FormField
            label={firstNameText}
            name={FORM_FIELDS.FIRST_NAME}
            initialValue={initialValues[FORM_FIELDS.FIRST_NAME]}
            required
          >
            <Input
              autoComplete="cc-given-name"
              placeholder={firstNameText}
              onChange={uncheckToggle}
            />
          </FormField>
        </Col>
        <Col breakpoints={{ xs: 12, lg: 6 }}>
          <FormField
            label={lastNameText}
            name={FORM_FIELDS.LAST_NAME}
            initialValue={initialValues[FORM_FIELDS.LAST_NAME]}
            required
          >
            <Input
              autoComplete="cc-family-name"
              placeholder={lastNameText}
              onChange={uncheckToggle}
            />
          </FormField>
        </Col>
      </FormGroup>
    </Col>
  );
};

export default CardholderName;
