const AIRBNB_REDIRECT_URL = 'https://mailer.production-2.guesty.com/proxy/integration/integration/airbnb/redirect';
const MAILER_URL = 'https://mailer.production-2.guesty.com/api/v2';
const INTEGRATION_SERVICE_URL = 'https://mailer.production-2.guesty.com/proxy/integration';
const API_URL = 'https://mailer.production-2.guesty.com/api/v2';
const LEAGCY_URL = '//dashboard-production-2.firebaseapp.com';
const FEATUER_TOGGLE = {
  core: {
    authorizationKey: 'hhq5f6gnsj75jqe12ov0k0r536o1ucg2f3b0',
    labelsEnabled: true,
  },
  scheduler: {
    featuresRefreshRate: 180, // 180 sec
    segmentsRefreshRate: 360, // 360 sec
    metricsRefreshRate: 600, // 60 sec
    impressionsRefreshRate: 360, // 60 sec
    eventsPushRate: 360, // 60 sec
    eventsQueueSize: 500, // 500 items
  },
  startup: {
    requestTimeoutBeforeReady: 1.5, // 1500 ms
    retriesOnFailureBeforeReady: 3, // 3 times
    readyTimeout: 5, // 5 sec
  },
  debug: false,
};

export default {
  AIRBNB_REDIRECT_URL,
  MAILER_URL,
  INTEGRATION_SERVICE_URL,
  API_URL,
  LEAGCY_URL,
  FEATUER_TOGGLE,
};
