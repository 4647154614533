import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import withStyle from 'react-jss';
import { noop } from 'lodash';

const styles = {
  current: {
    boxShadow: '0 0 20px 0 rgba(39, 43, 45, 0.15)',
    zIndex: 1,
  },
};

class RadioButton extends Component {
  onRadioButtonClick = () => {
    if (this.props.disabled) {
      return;
    }
    const {
      value, name, id, generatedChildId,
    } = this.props;
    this.props.onChange(value, name, id, generatedChildId);
  };

  render() {
    const {
      className, disabled, checked, classes, children,
    } = this.props;
    return (
      <div
        className={cn(
          className,
          'flex-space-between-center',
          'radioButton-item',
          'font-size-md',
          'px-2',
          'height-25',
          'clickable',
          'border-gray-lighter',
          'text-gray-dark',
          'bg-white',
          'by-1',
          'bx-1',
          {
            'text-blue': checked,
            [classes.current]: checked,
            'text-hover-blue': !disabled,
          },
        )}
        onClick={this.onRadioButtonClick}
        onKeyDown={this.onRadioButtonClick}
        role="button"
        disabled={disabled}
      >
        {children}
      </div>
    );
  }
}

RadioButton.defaultProps = {
  disabled: false,
  checked: false,
  onChange: noop,
  name: undefined,
  id: undefined,
  className: '',
};

RadioButton.propTypes = {
  /** Indicator whether or not the radioButton is disabled */
  disabled: PropTypes.bool,
  /** Set the radioButton checked attribute to show it as current */
  checked: PropTypes.bool,
  /** children will be rendered inside the radio item */
  children: PropTypes.node.isRequired,
  /** The radioButton value to be passed when clicked */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.shape()]).isRequired,
  /** value will be return when click on this item */
  onChange: PropTypes.func,
  /** value will be return when click on this item */
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** identifier will be return when click on this element */
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** identifier will be return when click on this element */
  /** classes to be added to the component */
  className: PropTypes.string,
};

/** Work around to fix storybook HOC propTypes bug  - DO NOT IMPORT THIS */
export const RadioButtonComponent = RadioButton;
export default withStyle(styles)(RadioButton);
