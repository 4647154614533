import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@guestyci/foundation/TextField';
import Dialog, { DialogContent } from '@guestyci/foundation/Dialog';
import { Col } from '@guestyci/foundation/Layout';
import useTranslations from '@guestyci/localize/useTranslations';
import { errorDialogIntlId } from 'utils/intlIds';
import actions from 'redux/actions';
import { PAYMENT_METHOD_FAILED_DIALOG } from 'constants/dialogs.constants';
import { ReactComponent as FailedImg } from 'assets/failed.svg';

const translations = [
    {
        id: errorDialogIntlId('payment_error_title'),
        d: 'Payment method didn’t save',
    },
    {
        id: errorDialogIntlId('payment_error_message'),
        d:
          'Please try again or provide a different payment method.',
    },
];

const PaymentMethodFailedDialog = () => {
    const [titleText, messageText] = useTranslations(translations);
    const dispatch = useDispatch();
    const showDialog = useSelector(state => state.dialog.show);
    const isOpen = showDialog === PAYMENT_METHOD_FAILED_DIALOG;

    const closeDialog = () => dispatch({ type: actions.HIDE_DIALOG });

    return (
      <Dialog onClose={closeDialog} open={isOpen}>
          <DialogContent className="max-width-500 flex-center">
              <Col align="center">
                  <Col spacing={3} className="mb-6">
                      <FailedImg />
                  </Col>
                  <Col spacing={3} className="text-center mb-6">
                      <TextField variant="h2" color="dark">
                          {titleText}
                      </TextField>
                  </Col>
                  <Col spacing={3} className="text-center">
                      <TextField variant="h4" color="default">
                          {messageText}
                      </TextField>
                  </Col>
              </Col>
          </DialogContent>
      </Dialog>
    );
};

export default PaymentMethodFailedDialog;
