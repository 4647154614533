import { pluralizeTagsIntlId } from './intlIds';

export const guestsTranslation = {
  id: pluralizeTagsIntlId('guests'),
  d: 'Guests',
};

export const bedTranslation = {
  id: pluralizeTagsIntlId('bed'),
  d: 'Bed',
};

export const bathroomTranslation = {
  id: pluralizeTagsIntlId('bathroom'),
  d: 'Bathroom',
};

export const bedroomTranslation = {
  id: pluralizeTagsIntlId('bedroom'),
  d: 'Bedroom',
};

export const nightTranslation = {
  id: pluralizeTagsIntlId('night'),
  d: 'Night',
};

export const totalPriceTranslation = {
  id: 'send-quotes:total_price',
  d: 'total price',
};
export const estPayoutTranslation = {
  id: 'send-quotes:estimated_payout',
  d: 'Est. payout',
};

export const paymentAmountTranslation = {
  id: 'send-quotes:payment_amount',
  d: 'Payment amount',
};

export const offerExpiresTranslation = {
  id: 'send-quotes:offer_expires',
  d: 'Offer expires in',
};
