import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import BaseButton from './BaseButton';
import { Theme } from '../../../constants/enums';

const lightThemeClasses = 'text-white text-hover-blue-dark-theme';
const FlatButton = ({
  children, onClick, className, style, disabled, isLoading, theme, ...props
}) => (
  <BaseButton
    isLoading={isLoading}
    loaderColor="blue"
    onClick={onClick}
    className={cn('font-size-md', className, { [lightThemeClasses]: theme === Theme.Light })}
    variant="flat"
    style={style}
    disabled={disabled}
    {...props}
  >
    {children}
  </BaseButton>
);

FlatButton.defaultProps = {
  className: '',
  style: {},
  disabled: false,
  isLoading: false,
  theme: 'dark',
};

FlatButton.propTypes = {
  /** Button click callback function */
  onClick: PropTypes.func.isRequired,
  /** Button inner children */
  children: PropTypes.node.isRequired,
  /** Button indicator whether disabled or not */
  disabled: PropTypes.bool,
  /** Button additional classes */
  className: PropTypes.string,
  /** Button additional styling */
  style: PropTypes.shape(),
  /** Button additional styling */
  isLoading: PropTypes.bool,
  /** Defined the theme for the button component */
  theme: PropTypes.oneOf(['dark', 'light']),
};

export default FlatButton;
