import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import moment from 'moment';
import { memoize } from '../../../utils/commonUtility';
import { MomentFormatTypes } from '../../../tools/i18n/localeData';
import LocalizationContext from '../../../tools/i18n/LocalizationContext';
import { FormattedDate, FormattedTime } from '../../../tools/i18n';

moment.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'seconds',
    ss: '%ss',
    m: 'a minute',
    mm: '%dm',
    h: '1h',
    hh: '%dh',
    d: '1d',
    dd: '%dd',
    M: 'a month',
    MM: '%dm',
    y: 'a year',
    yy: '%dy'
  }
});

const dateToFromNow = memoize((date, isEnglish) => {
  if (isEnglish) {
    const diff = moment().diff(date, 'days');
    if (Math.abs(diff) !== 1) {
      return moment(date).fromNow();
    }
    return diff > 0 ? 'Yesterday' : 'Tomorrow';
  }
  return moment(date).fromNow();
});

const Moment = ({
  date,
  format,
  fromNow,
  className,
  style,
  color,
  humanize
}) => (
  <div
    className={cn(
      'd-flex moment-wrapper',
      { [`text-${color}`]: color },
      className
    )}
    style={style}
  >
    {fromNow || humanize ? (
      <LocalizationContext.Consumer>
        {({ isEnglish }) => dateToFromNow(date, isEnglish)}
      </LocalizationContext.Consumer>
    ) : (
      <>
        {format === MomentFormatTypes.Time ? (
          <FormattedTime time={date} />
        ) : (
          <FormattedDate date={date} format={format} />
        )}
      </>
    )}
  </div>
);

Moment.defaultProps = {
  format: MomentFormatTypes.Date,
  humanize: false,
  fromNow: false,
  className: '',
  style: {},
  color: 'muted'
};

Moment.propTypes = {
  /** Date to process by moment - Unix/ISO/Date */
  date: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date)
  ]).isRequired,
  /** Date format to custom show moment (shortDate, date, fullDate, mmmm yyyy, mmm yyyy, time) */
  format: PropTypes.oneOf([
    MomentFormatTypes.ShortDate,
    MomentFormatTypes.Date,
    MomentFormatTypes.FullDate,
    MomentFormatTypes.MMMM_YYYY,
    MomentFormatTypes.MMM_YYYY,
    MomentFormatTypes.Time
  ]),
  /** Indicator whether or not moment should show time from now (humanized)
   * - Deprecated use humanize
   * */
  fromNow: PropTypes.bool,
  /** Indicator whether or not moment should show time from now (humanized) */
  humanize: PropTypes.bool,
  /** Additional class name to root level */
  className: PropTypes.string,
  /** Additional style name to root level */
  style: PropTypes.shape(),
  /** text color for the moment component */
  color: PropTypes.string
};
export default Moment;
