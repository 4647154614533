import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

const CalendarWeekDayBar = ({
  weekday,
  locale,
  localeUtils,
  className,
  style,
  dayCharCount
}) => {
  const weekdayName = localeUtils.formatWeekdayLong(weekday, locale);
  return (
    <div
      className={cn(
        'text-muted font-size-md text-capitalize pt-0 pb-2',
        className
      )}
      style={style}
    >
      {weekdayName.slice(0, dayCharCount)}
    </div>
  );
};

CalendarWeekDayBar.defaultProps = {
  className: '',
  style: {},
  weekday: 0,
  localeUtils: {},
  locale: '',
  dayCharCount: 3
};

CalendarWeekDayBar.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape(),
  weekday: PropTypes.number,
  localeUtils: PropTypes.shape(),
  locale: PropTypes.string,
  dayCharCount: PropTypes.number
};

export default CalendarWeekDayBar;
