import intl from '@guestyci/react-intl-universal';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { isNumber } from '../../../utils/commonUtility';
import { currencyIdsMap, currencyTypes } from '../localeData';

const FormattedCurrency = ({ currency, value }) =>
  isNumber(value) ? (
    <Fragment>
      {intl.get(currencyIdsMap.get(currency), { price: value })}
    </Fragment>
  ) : (
    <Fragment>Invalid Currency Value</Fragment>
  );

FormattedCurrency.defaultProps = {
  currency: 'usd'
};

FormattedCurrency.propTypes = {
  /** date value is required */
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  /** currency type (usd,eur,...) */
  currency: PropTypes.oneOf(currencyTypes)
};
export default FormattedCurrency;
