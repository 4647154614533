import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import IconButton from '../buttons/IconButton';

const DialogXHeader = ({
  onClick, className, style,
}) => (
  <div className={cn('dialog-x-header flex-end-center height-10 w-fill', className)} style={style}>
    <IconButton onClick={onClick} icon="BtnDelete" iconHeight={10} iconWidth={10} />
  </div>
);

DialogXHeader.defaultProps = {
  className: '',
  style: {},
};
DialogXHeader.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  style: PropTypes.shape(),
};

export default DialogXHeader;
