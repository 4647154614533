import React from 'react';
import Icon from '../../icon/Icon';
import Pill from '../../indicators/pill/Pill';
import Row from '../../../layout/row/Row';
import TextField from '../../textField/TextField';

const PersonReturningGuestPill = ({ className }) => (
  <Pill color="white" className={className} bgColor="black" >
    <Row spacing="sm" align="center">
      <Icon icon="BtnStarOn" color="white" width={12} height={12} style={{ marginBottom: 2 }} />
      <TextField size="md">Returning guest</TextField>
    </Row>
  </Pill>
);
export default PersonReturningGuestPill;
