import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';

const FormattedTime = ({ time }) => {
  const momentTime = moment(time, 'hh:mm');
  return momentTime.isValid() ? (
    <Fragment>{momentTime.format('LT')}</Fragment>
  ) : (
    <Fragment>Invalid date</Fragment>
  );
};

FormattedTime.propTypes = {
  /** time value is required */
  time: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};
export default FormattedTime;
