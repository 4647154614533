import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import cn from 'classnames';
import InputStarDeleteActionAddOn from '../InputStarDeleteActionAddon';
import Input from '../Input';
import withHover from '../../../compose/withHover';

class EmailInput extends PureComponent {
  static getDerivedStateFromProps(props, state) {
    if (props.value && props.value !== state.fieldValue) {
      return { fieldValue: props.value };
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.state = { fieldValue: props.value };
  }

  handleInputChange = (value, target) => {
    if (this.props.onChange) {
      this.props.onChange(value, target);
    }
    this.setState({ fieldValue: value });
  };


  render() {
    const {
      inputStyle, inputClassName, disabled, name, withActions, className, id, placeholder,
      onStarClick, onTrashClick, isPrimary, onHoverEnter, onHoverLeave, isHovering, style, required,
    } = this.props;
    const { fieldValue } = this.state;
    return (
      <div onMouseEnter={onHoverEnter} onMouseLeave={onHoverLeave} className={cn('d-flex', 'action-input', 'email-input', 'height-46', className)} style={style}>
        <Input
          onChange={this.handleInputChange}
          style={inputStyle}
          className={inputClassName}
          disabled={disabled}
          value={fieldValue}
          type="email"
          placeholder={placeholder}
          name={name}
          id={id}
          required={required}
        />
        <InputStarDeleteActionAddOn
          disabled={disabled}
          show={withActions && isHovering}
          isPrimary={isPrimary}
          onTrash={onTrashClick}
          onStar={onStarClick}
          name={name}
          id={id}
        />
      </div>
    );
  }
}

EmailInput.defaultProps = {
  onChange: noop,
  onTrashClick: noop,
  onStarClick: noop,
  withActions: false,
  isPrimary: false,
  inputClassName: '',
  inputStyle: {},
  value: '',
  name: '',
  placeholder: '',
  disabled: false,
  className: '',
  style: {},
};

EmailInput.propTypes = {
  /** Cb for input change
   *  passing : value: {String}, target: {id or name defined}
   *  */
  onChange: PropTypes.func,
  /**  Cb for clicking on the trash icon */
  onTrashClick: PropTypes.func,
  /** Cb for clicking on the star (set primary) icon */
  onStarClick: PropTypes.func,
  /** Indicator whether to show the star/trash actions */
  withActions: PropTypes.bool,
  /** Boolean setting the email as primary */
  isPrimary: PropTypes.bool,
  /** Inout level additional style */
  inputStyle: PropTypes.shape(),
  /** Input level addition class */
  inputClassName: PropTypes.string,
  /** Input level name property */
  name: PropTypes.string,
  /** Email value - setting as controlled */
  value: PropTypes.string,
  /** Placeholder for the email */
  placeholder: PropTypes.string,
  /** Boolean to set the email and component as disabled */
  disabled: PropTypes.bool,
  /** Additional class to the top component level */
  className: PropTypes.string,
  /** Additional style to the top component level */
  style: PropTypes.shape(),
};
/** Work around to fix storybook HOC propTypes bug  - DO NOT IMPORT THIS */
export const EmailComponent = EmailInput;
export default withHover(EmailInput);
