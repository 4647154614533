import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import withSelectionGroup from '../withSelectionGroup';

class CheckboxGroup extends Component {
  componentDidUpdate() {
    this.props.onChange(this.props.selectedCheckboxesWhenMulti);
  }

  changeCheckboxStatus = (value, childName, checkedRadioId) => {
    this.props.onOptionClicked(checkedRadioId);
  };

  addPropsToChild = (child, hasRightBorder) => (React.cloneElement(child.child, {
    key: child.uid,
    onChange: this.changeCheckboxStatus,
    checked: child.checked,
    id: child.uid,
    className: child.child.props.className,
    borderRight: hasRightBorder,
  }));

  wrapCheckedChildrenWithDivs(childrenStructure) {
    const indexes = childrenStructure.map(child => (child.checked ? 1 : 0));

    let checkedChildren = [];
    const fineStructure = [];
    indexes.forEach((value, index) => {
      if (checkedChildren.length > 0 && value === 0) {
        fineStructure.push(<div className={cn('d-flex', 'box-shadow', 'z-index-1')} key={`CheckboxGroup_${childrenStructure[index].uid}`}>{checkedChildren}</div>);
        fineStructure.push(this.addPropsToChild(
          childrenStructure[index],
          index !== indexes.length - 1,
        ));
        checkedChildren = [];
      } else if (value === 0) {
        fineStructure.push(this.addPropsToChild(
          childrenStructure[index],
          index !== indexes.length - 1,
        ));
      } else {
        checkedChildren.push(this.addPropsToChild(
          childrenStructure[index],
          index !== indexes.length - 1,
        ));
        if (indexes.length - 1 === index) {
          fineStructure.push(<div className={cn('d-flex', 'box-shadow', 'z-index-1')} key={`CheckboxGroup_${childrenStructure[index].uid}`}>{checkedChildren}</div>);
        }
      }
    });

    return fineStructure;
  }

  render() {
    const { options, className } = this.props;

    const children = Array.from(options.values());

    return (
      <div className={className}>
        <div className={cn('row', 'border', 'border-radius-2')} >
          {this.wrapCheckedChildrenWithDivs(children)}
        </div>
      </div>
    );
  }
}

CheckboxGroup.propType = {
  /** children will be rendered inside the CheckboxGroup */
  children: PropTypes.objectOf().isRequired,
  /** classes to be added to the component */
  className: PropTypes.string,
  /** callback for on checkboxGroup change */
  onChange: PropTypes.func.isRequired,
};

export const CheckboxGroupComponent = CheckboxGroup;
export default withSelectionGroup(CheckboxGroup);
