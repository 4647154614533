import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Collapse from './Collapse';
import AccordionTitle from './AccordionTitle';

class Accordion extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { isOpen: props.isOpen };
  }

  componentDidUpdate(prevProps, prevState) {
    this.getIsOpen(prevProps, prevState);
  }

  getIsOpen(prevProps) {
    if (
      this.props.isOpen !== prevProps.isOpen &&
      this.props.isOpen !== this.state.isOpen
    ) {
      this.setState({ isOpen: this.props.isOpen });
    }
  }

  handleTitleClick = () => {
    this.setState(
      prevState => ({ isOpen: !prevState.isOpen }),
      () => {
        if (this.props.onChange) {
          this.props.onChange(this.state.isOpen);
        }
      }
    );
  };

  render() {
    const { children, title } = this.props;
    const { isOpen } = this.state;
    return (
      <div
        className={cn(
          'flex-center d-flex-fill bb-1 flex-column border-gray-light',
          { 'pb-6': isOpen }
        )}
      >
        <AccordionTitle
          isOpen={isOpen}
          onClick={this.handleTitleClick}
          title={title}
        />
        <Collapse isOpen={isOpen}>{children}</Collapse>
      </div>
    );
  }
}

Accordion.defaultProps = {
  onChange: null,
  isOpen: false
};

Accordion.propTypes = {
  /** The accordion component children */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  /** The accordion title element */
  title: PropTypes.node.isRequired,
  /** CallBack for on click (for controlled mode ) */
  onChange: PropTypes.func,
  /** Indicator whether or not to open accordion (for controlled mode ) */
  isOpen: PropTypes.bool
};

export default Accordion;
