import styled from 'styled-components';

import { mobile, tablet } from 'constants/css/breakpoints';

export const HeaderTag = styled.div`
  box-sizing: border-box;
  padding: 55px 120px 0 120px;
  @media ${tablet} {
    padding: 65px 40px 0 40px;
  };
  @media ${mobile} {
    padding: 65px 20px 0 20px;
  };
`;

export const Tag = styled.div`
  background-color: #d1d8db;
  letter-spacing: 0.2px;
  line-height: 1.21;
`;

export const MessageTag = styled.div`
  line-height: 1.06;
  letter-spacing: 0.23px;
`;

export const GuestTag = styled.div`
  font-size: 30px;
  @media ${mobile} {
    font-size: 24px;
  }
`;

export const ExpiresTag = styled.div`
  letter-spacing: 0.26px;
  text-decoration: underline;
`;
