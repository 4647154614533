import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import withStyle from 'react-jss';
import ImageAvatar from './ImageAvatar';
import IconAvatar from './IconAvatar';
import TextAvatar from './TextAvatar';
import './avatar.scss';

const styles = {
  badge: {
    left: '55%',
    top: '55%',
    position: 'absolute',
  },
};

const Avatar = ({
  classes,
  listing,
  isUser,
  bordered,
  disabled,
  text,
  isGSC,
  src,
  size,
  className,
  style,
  badge,
}) => (
  <div className={cn('relative transition-all', { disabled }, className)} style={style}>
    {src && <ImageAvatar
      src={src}
      bordered={listing && bordered}
      text={text}
      size={size}
    />}
    {!src && listing && !isGSC &&
    <IconAvatar icon={`IcoAvatarListing${size.toUpperCase()}`} size={size} />}
    {!src && !listing && isGSC &&
    <IconAvatar color="blue-dark" icon="IcoAvatarGSC" size={size} />}
    {!src && !listing && !isGSC &&
    <TextAvatar size={size} text={text} isUser={isUser} />}
    {badge &&
    <div className={classes.badge}>
      {badge}
    </div>}
  </div>
);

Avatar.defaultProps = {
  listing: false,
  isUser: false,
  src: undefined,
  text: '',
  size: 'md',
  bordered: false,
  disabled: false,
  className: '',
  style: {},
  badge: null,
  isGSC: false,
};

Avatar.propTypes = {
  /** Define if avatar is listing avatar */
  listing: PropTypes.bool,
  /** Set whether or not the user avatar is user or not (guest, owner, contact => not users) */
  isUser: PropTypes.bool,
  /** The Image avatar image src */
  src: PropTypes.string,
  /** The text/ alt for text avatar or image avatar - Accepting two letters or nothing */
  text: PropTypes.string,
  /** Avatar size - accepting only sm,md,lg */
  size: PropTypes.oneOf(['sm', 'md']),
  /** Boolean - show/hide image avatar border */
  bordered: PropTypes.bool,
  /** Boolean - Set whether or not the avatar should be transparent */
  disabled: PropTypes.bool,
  /** Add className to avatar */
  className: PropTypes.string,
  /** Add style to avatar */
  style: PropTypes.shape(),
  /** Add Badge to avatar */
  badge: PropTypes.node,
  isGSC: PropTypes.bool,
};
/** Work around to fix storybook HOC propTypes bug  - DO NOT IMPORT THIS */
export const AvatarComponent = Avatar;
export default withStyle(styles)(Avatar);
