import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import injectSheet from 'react-jss';
import CSSTransition from '../../utils/transiton/CSSTransition';

const menuStyle = {
  menu: {
    overflowX: 'hidden',
    overflowY: 'auto',
    padding: 0,
    marginBottom: 0,
    listStyleType: 'none',
  },
};

const Menu = ({
  classes, children, className, style, maxHeight, row, animation, collapse, innerClassName, dense,
}) => {
  const propStyle = maxHeight ? { maxHeight: collapse ? 0 : maxHeight, opacity: collapse ? 0 : 1 } : { maxHeight: 'auto', overflowY: 'hidden' };
  return (
    <ul className={cn(className, classes.menu, 'box-shadow-mid-lighter', 'w-fill', { 'p-4': !dense })} style={Object.assign({}, style, propStyle)}>
      <CSSTransition animation={animation}>
        {!collapse &&
        <div className={cn('d-flex-fill', !row ? 'flex-column' : 'flex-row-wrap', innerClassName)}>
          {children}
        </div>
        }
      </CSSTransition>
    </ul>
  );
};

Menu.defaultProps = {
  className: '',
  animation: null,
  style: {},
  maxHeight: null,
  row: false,
  collapse: false,
  innerClassName: '',
  dense: false,
};

Menu.propTypes = {
  /** Child items in the menu component */
  children: PropTypes.node.isRequired,
  /** Additional class for root level */
  className: PropTypes.string,
  /** Additional style for root level */
  style: PropTypes.shape(),
  /** Max height for menu component - overflowY added */
  maxHeight: PropTypes.number,
  /** Indicator whether or not we want menu as row or column */
  row: PropTypes.bool,
  /** Animation to apply to menu level */
  animation: PropTypes.string,
  /** Indicator whether or not menu should be collapsed */
  collapse: PropTypes.bool,
  /** Menu inner class */
  innerClassName: PropTypes.string,
  /** Remove padding on menu */
  dense: PropTypes.bool,
};

/** Work around to fix storybook HOC propTypes bug  - DO NOT IMPORT THIS */
export const MenuComponent = Menu;
export default injectSheet(menuStyle)(Menu);
