import React, { Component } from 'react';
/**
 * Create a wrapper compose to support click outside functionality functionality
 * @param ComposedComponent
 * @returns {{Component}}
 */

class ClickOutsideListener extends Component {
  constructor(props) {
    super(props);
    this.clickOutsideRef = React.createRef();
  }
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

    handleClickOutside = (event) => {
      const { onClickOutside } = this.props;
      if (onClickOutside) {
        const isCurrentTarget = this.clickOutsideRef.current.contains(event.target);
        if (this.clickOutsideRef && !isCurrentTarget) {
          onClickOutside();
        }
      }
    };

    render() {
      const { className, children, style } = this.props;
      return (
        <div ref={this.clickOutsideRef} className={className} style={style}>
          {children}
        </div>
      );
    }
}

export default ClickOutsideListener;
