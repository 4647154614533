import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  intl,
  locales,
  warningHandler,
} from '@guestyci/atomic-design/dist/i18n/';
import { noop } from 'utils/helpers';

import ExpiredPage from 'App/pages/Expired/Expired.page';
import SuccessPaidBookingPage from 'App/pages/SuccessPaidBookingPage/SuccessPaidBookingPage.page';
import SuccessUnpaidBookingPage from 'App/pages/SuccessUnpaidBookingPage/SuccessUnpaidBookingPage.page';
import Main from 'App/pages/Main/Main.page';
import Listing from 'App/pages/Listing/Listing.page';
import Invoice from 'App/pages/Invoice/Invoice.page';
import Oops from 'App/components/Oops/Oops.component';

import {
  EXPIRED_PAGE,
  INVOICE_PAGE,
  LISTING_PAGE,
  MAIN_PAGE,
  SUCCESS_UNPAID_BOOKING_PAGE,
  SUCCESS_PAID_BOOKING_PAGE,
} from 'constants/route.constants';

import './App.css';

function App() {
  const [initDone, setInitDone] = useState(false);
  useEffect(() => {
    const lang = window.localStorage.getItem('lang') || 'en-US';
    intl
      .init({
        currentLocale: lang,
        locales,
        warningHandler,
        fallbackLocale: 'en-US',
      })
      .then(() => setInitDone(true))
      .catch(noop);
  }, []);

  if (!initDone) return null;

  return (
    <div className="overflow-hidden">
      <Switch>
        <Route path={EXPIRED_PAGE} component={ExpiredPage} exact />
        <Route path={SUCCESS_PAID_BOOKING_PAGE} component={SuccessPaidBookingPage} exact />
        <Route path={SUCCESS_UNPAID_BOOKING_PAGE} component={SuccessUnpaidBookingPage} exact />
        <Route path={MAIN_PAGE} component={Main} exact />
        <Route path={INVOICE_PAGE} component={Invoice} exact />
        <Route path={LISTING_PAGE} component={Listing} exact />
      </Switch>
      <Oops />
    </div>
  );
}

export default App;
