import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import withStyle from 'react-jss';
import CSSTransition from '../../utils/transiton/CSSTransition';

const listStyle = {
  list: {
    width: '100%',
    overflowX: 'hidden',
    overflowY: 'auto',
    padding: 0,
    listStyleType: 'none',
    height: '100%',
  },
};

const List = ({
  classes, children, className, style, maxHeight, transition, row, innerClassName, ...props
}) => (
  <ul className={cn(classes.list, className, 'mb-0')} style={Object.assign({}, style, { maxHeight })} {...props}>
    <div className={cn('d-flex-fill', !row ? 'flex-column' : 'flex-row-wrap', innerClassName)}>
      <CSSTransition animation={transition} className="inherit-all">
        {children}
      </CSSTransition>
    </div>
  </ul>
);
List.defaultProps = {
  className: '',
  style: {},
  maxHeight: null,
  transition: null,
  row: false,
  innerClassName: '',
};

List.propTypes = {
  /** List of elements under the list item */
  children: PropTypes.node.isRequired,
  /** Additional class for list component */
  className: PropTypes.string,
  /** Additional style for the list component */
  style: PropTypes.shape(),
  /** Maximum height for the component */
  maxHeight: PropTypes.number,
  /** Transition for add/remove child elements */
  transition: PropTypes.string,
  /** Indicator whether or not we want list as row or column */
  row: PropTypes.bool,
  /** List inner class */
  innerClassName: PropTypes.string,
};
/** Work around to fix storybook HOC propTypes bug  - DO NOT IMPORT THIS */
export const ListComponent = List;
export default withStyle(listStyle)(List);
