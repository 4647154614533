import React from 'react';
import Checkbox from '../../selectionControls/checkbox/Checkbox';
import MenuItem from '../../menu/MenuItem';

const MultiSelectDropdownMenuItem = ({
  checked, onChange, value, children,
}) => (
  <MenuItem isSelected={checked} value={value}>
    <Checkbox checked={checked} value={value} onChange={onChange} className="w-fill">
      {children}
    </Checkbox>
  </MenuItem>
);

export default MultiSelectDropdownMenuItem;
