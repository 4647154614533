import ActionTypes from 'redux/actions';

const { SHOW_DIALOG, HIDE_DIALOG } = ActionTypes;

export const initState = {
    show: null,
    payload: null,
};

export default (state = initState, { type, name, payload }) => {
    switch (type) {
        case SHOW_DIALOG:
            return ({
                show: name,
                payload,
            });
        case HIDE_DIALOG:
            return initState;
        default:
            return state;
    }
};
