import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import Popover from './Popover';
import ActionPopoverBody from './ActionPopoverBody';

const ActionPopover = ({
  children, onClose, isOpen, body, className, containerClassName,
  placement, id, offset, onClick, actionText,
}) => (
  <Popover
    onClose={onClose}
    isOpen={isOpen}
    body={<ActionPopoverBody onClick={onClick} buttonText={actionText} body={body} />}
    className={className}
    containerClassName={containerClassName}
    placement={placement}
    offset={offset}
    id={id}
  >
    {children}
  </Popover>
);

ActionPopover.defaultProps = {
  className: '',
  id: undefined,
  placement: 'top',
  offset: undefined,
  containerClassName: '',
  body: null,
  actionText: 'Got it',
  onClose: noop,
};
ActionPopover.propTypes = {
  /** Bool - Boolean defining whether or not to toggle the popover */
  isOpen: PropTypes.bool.isRequired,
  /** Node - the component wrapped by the popover */
  children: PropTypes.node.isRequired,
  /** String || React Element - The popover body */
  body: PropTypes.node,
  /** onClose  - Function to support the popover toggle */
  onClose: PropTypes.func,
  /** String - Set the popover trigger id */
  id: PropTypes.string,
  /** String - Additional popover component class */
  className: PropTypes.string,
  /** Popover placement supporting (top, bottom, left, right ) => <pos>-start, <pos>-end */
  placement: PropTypes.string,
  /** set custom offset to popover arrow */
  offset: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Add class to popover root level */
  containerClassName: PropTypes.string,
  /** Callback function for action click */
  onClick: PropTypes.func.isRequired,
  /** Custom action button text */
  actionText: PropTypes.string,
};
export default ActionPopover;
