import { merge } from 'ramda';
import ActionTypes from 'redux/actions';

const {
  PROCESS_INVOICE_INPROGRESS,
} = ActionTypes;

const initialState = {
  isProcessingInvoice: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PROCESS_INVOICE_INPROGRESS:
      return merge(state, {
        isProcessingInvoice: !!action.payload,
      });

    default:
      return state;
  }
};
