import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { CSSTransitionGroup } from 'react-transition-group';
import isEmpty from 'lodash/isEmpty';

const CSSTransition = ({
  animation, className, timeout, children,
}) => {
  const transitionTimeout = isEmpty(timeout) ? { enter: 0, leave: 0 } : timeout;
  return animation ? (
    <CSSTransitionGroup
      transitionName={animation}
      className={cn('css-transition', className)}
      transitionEnterTimeout={transitionTimeout.enter}
      transitionLeaveTimeout={transitionTimeout.leave}
    >
      {children}
    </CSSTransitionGroup>
  ) : children;
};
CSSTransition.defaultProps = {
  className: '',
  children: null,
  timeout: { enter: 0, leave: 0 },
  animation: '',
};
CSSTransition.propTypes = {
  /* Animation for css transition from animation scss */
  animation: PropTypes.string,
  /* Additional class name for css transition root */
  className: PropTypes.string,
  /* Child components for css transition */
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  /* timeout between animations */
  timeout: PropTypes.shape({
    enter: PropTypes.number.isRequired,
    leave: PropTypes.number.isRequired,
  }),
};

export default CSSTransition;
