import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import StrapCollapse from 'reactstrap/lib/Collapse';

const Collapse = ({
  children, isOpen, className, ...props
}) => (
  <StrapCollapse className={cn('w-fill', className)} isOpen={isOpen} {...props}>
    {children}
  </StrapCollapse>
);

Collapse.defaultProps = {
  className: '',
};
Collapse.propTypes = {
  /** Indicator whether or not collapse should be open */
  isOpen: PropTypes.bool.isRequired,
  /** Children to render when collapse is open */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
};

export default Collapse;
