import Resource from '@guestyci/agni';

import { geBaseUrl, STRIPE_SETUP_INTENTS_URL } from 'constants/url.constants';

const { api } = Resource.create({
  baseURL : geBaseUrl(),
})

export const getClientSecret = ({ accountId, listingId }) =>
  api.post(`${STRIPE_SETUP_INTENTS_URL}`, { accountId, listingId });

export const handleCardSetup = ({
  clientSecret,
  stripe,
  billingDetails
}) => {
  const {
    firstName,
    lastName,
    city,
    country,
    street,
    state,
    zipCode
  } = billingDetails;

  return stripe.handleCardSetup(clientSecret, {
    payment_method_data: {
      billing_details: {
        name: `${firstName} ${lastName}`,
        address: {
          city,
          country: country.value,
          line1: street,
          state,
          postal_code: zipCode,
        },
      },
    },
  });
}

export const createToken = ({ stripe, billingDetails }) => {
  const { firstName, lastName } = billingDetails;

  return stripe.createToken({ type: 'card', name: `${firstName} ${lastName}` });
}
