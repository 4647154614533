import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import StrapTooltip from 'reactstrap/lib/Tooltip';
import { uuid } from '../../../utils/commonUtility';
import { Theme } from '../../../constants/enums';
import './tooltip.scss';

class Tooltip extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false, id: props.id || uuid() };
  }

  onToggle = () => {
    this.setState(prevState => ({ isOpen: !prevState.isOpen }));
  };

  render() {
    const {
      children,
      className,
      body,
      style,
      autoHide,
      theme,
      placement,
      offset,
      containerClassName,
      delay,
      fullWidth,
      ...props
    } = this.props;

    const { id, isOpen } = this.state;
    return body ? (
      <Fragment>
        <span className={containerClassName} id={`Tooltip-${id}`}>
          {children}
        </span>
        <StrapTooltip
          innerClassName={cn('p-2 font-size-md text-left border-radius-2', {
            'mw-100': fullWidth
          })}
          target={`Tooltip-${id}`}
          isOpen={isOpen && !!body}
          toggle={this.onToggle}
          className={cn(
            { 'light-theme': theme === Theme.Light },
            { 'error-theme': theme === Theme.Error },
            className
          )}
          style={style}
          autohide={autoHide}
          placement={placement}
          offset={offset}
          delay={delay}
          {...props}
        >
          {body}
        </StrapTooltip>
      </Fragment>
    ) : (
      children
    );
  }
}

Tooltip.defaultProps = {
  className: '',
  id: undefined,
  autoHide: true,
  theme: 'dark',
  placement: 'top',
  offset: undefined,
  containerClassName: '',
  body: null,
  delay: { show: 250, hide: 0 },
  fullWidth: false
};
Tooltip.propTypes = {
  /** Node - the component wrapped by the tooltip */
  children: PropTypes.node.isRequired,
  /** String || React Element - The tooltip body */
  body: PropTypes.node,
  /** String - Set the tooltip trigger id */
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** String - Additional tooltip component class */
  className: PropTypes.string,
  /** String - Additional tooltip component class */
  containerClassName: PropTypes.string,
  /** Set tooltip to not hide on mouseleave tooltip element */
  autoHide: PropTypes.bool,
  /** Set the theme for the tooltip */
  theme: PropTypes.oneOf(['dark', 'light', 'error']),
  /** Popover placement supporting (top, bottom, left, right ) => <pos>-start, <pos>-end */
  placement: PropTypes.string,
  /** set custom offset to tooltip arrow */
  offset: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Set show/hide delay for dialog - structure {show, hide} or number */
  delay: PropTypes.oneOfType([
    PropTypes.shape({ show: PropTypes.number, hide: PropTypes.number }),
    PropTypes.number
  ]),
  /** Bool, if the tooltip with take max width or be contained */
  fullWidth: PropTypes.bool
};

export default Tooltip;
