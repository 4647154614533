import React from 'react';
import cn from 'classnames';

const ImageAvatar = ({
  text, src, size = 'md', bordered, disabled,
}) => (
  <img
    className={cn(`img-avatar avatar avatar-${size}`, 'd-inline-block', { 'avatar-border': bordered }, { disabled })}
    src={src}
    alt={text ? text.trim() : ''}
  />
);

export default ImageAvatar;
