const HELP_CENTER = 'https://support.guesty.com/hc/en-us';
const API_URL = 'https://mailer.staging.guesty.com/api/v2';
const API_KEY = 'T26830axRZk77paW4p7u22ypRUhZ7F3m';
const STRIPE_API_KEY = 'pk_test_ESWRIhPFbWs35vmzXZXdyXN5';
const AUTH_LOGIN_URL = '/auth/login';
const AUTH_LOGOUT_URL = '/auth/logout';
const AIRBNB_CLIENT_ID = 'd636szql89j7n22i786h6zjv';
const AIRBNB_SCOPE = ['vr', 'messages_read', 'calendars_read', 'messages_write'].join();
const API_GATEWAY = 'https://staging-apigw.guesty.com';
const FEATUER_TOGGLE = {
  core: {
    authorizationKey: 'u8gt16ks3c8jo6lhin0qfuvmh87bb6004129',
    labelsEnabled: true,
  },
  scheduler: {
    featuresRefreshRate: 30, // 30 sec
    segmentsRefreshRate: 60, // 60 sec
    metricsRefreshRate: 60, // 60 sec
    impressionsRefreshRate: 60, // 60 sec
    eventsPushRate: 60, // 60 sec
    eventsQueueSize: 500, // 500 items
  },
  startup: {
    requestTimeoutBeforeReady: 1.5, // 1500 ms
    retriesOnFailureBeforeReady: 3, // 3 times
    readyTimeout: 5, // 5 sec
  },
  debug: true,
};

export default {
  HELP_CENTER,
  API_URL,
  API_KEY,
  AUTH_LOGIN_URL,
  AUTH_LOGOUT_URL,
  AIRBNB_CLIENT_ID,
  AIRBNB_SCOPE,
  FEATUER_TOGGLE,
  STRIPE_API_KEY,
  API_GATEWAY,
};
