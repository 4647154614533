import _escapeRegExp from 'lodash/escapeRegExp';
import { decode } from 'jsonwebtoken';

import isFinite from 'lodash/isFinite';
import _isNumber from 'lodash/isNumber';
import _isEmpty from 'lodash/isEmpty';

import currencySymbolsMapper from '../utils/currencySymbols';

const shallowEqual = (newVal, oldVal) => newVal === oldVal;
const simpleEqual = (newArgs = [], oldArgs = []) =>
  newArgs.length === oldArgs.length &&
  newArgs.every((arg, index) => shallowEqual(arg, oldArgs[index]));

/**
 * Generate a GUID
 * @returns {string}
 */
export const uuid = () => {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
  }

  return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
};

/**
 * Set a name to a pair on initials
 * @param name {String} The name to manipulate
 * @returns {String}
 */
export function nameToInitials(name) {
  return name && name.replace(/[\W_][ ]+/g, '')
    .split(' ')
    .slice(0, 2)
    .map(word => word.charAt(0))
    .join('');
}

/**
 * Check if string value is a valid number
 * @param str {String | Number}  The string to test
 * @returns {Boolean}
 */
export function isNumber(str = '') {
  if (_isNumber(str)) {
    return true;
  }
  if (_isEmpty(str)) {
    return false;
  }
  if (str.length === 1) {
    return RegExp(/^[0-9.]+$/).test(str);
  }
  return isFinite(+str);
}

/**
 * Convert a string into Number (float)
 * @param str {String|Number}  The value to parse
 * @param catchValue {String | Number} value to return if cast to number fails, defaults to NaN
 * @returns {number} Number if valid number string otherwise NaN
 */
export function parseNumber(str, catchValue = NaN) {
  if (isNumber(str)) {
    return +str;
  }
  return Number(str) || catchValue;
}


/**
 * Convert and array to a Map
 * @param array {Array} an array to map
 * @param key {*} the value to key by
 * @returns {Map} a new map
 */
export function toMap(array = [], key) {
  return new Map(array.map(item => [(item[key] || item), item]));
}

/**
 * Create a delay mechanism
 * @param ms  {Number}  Milliseconds to delay
 * @return {Promise<any>}
 */
export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

/**
 * Escape string for RegExp
 * uses lodash escapeRegExp util
 * @param str  {String}  string to escape
 * @return {String}
 */
export const escapeRegExp = str => _escapeRegExp(str);

/**
 * First letter to uppercase
 * @param text {String}  string to manipulate
 * @return {String}
 */
export const firstLetterUppercase =
    text => text.slice(0, 1).toUpperCase() + text.slice(1, text.length);

/**
 * Currency symbols mapper
 */
export const currencySymbols = currencySymbolsMapper;


/**
 * Memoize function to store previous value
 * @param fn  {Function} call back function to call and check memoize change
 * @param equalizer {Function}  Custom Equal function - defaults to shallow equals
 * @return {Function}
 */
export const memoize = (fn, equalizer = simpleEqual) => {
  let cachedArgs = [];
  let lastValue;
  let isFirstCall = true;
  return (...args) => {
    if (!isFirstCall && equalizer(args, cachedArgs)) {
      return lastValue;
    }
    lastValue = fn(...args);
    cachedArgs = args;
    isFirstCall = false;
    return lastValue;
  };
};

/**
 * Perform async debounce
 * @param f {Function}  Function to debounce
 * @param interval  {Number}  Milliseconds to interval
 * @return {function(...[*]=): Promise<any>}
 */
export function asyncDebounce(f, interval) {
  let timer = null;
  return (...args) => {
    clearTimeout(timer);
    return new Promise((resolve) => {
      timer = setTimeout(
        () => resolve(f(...args)),
        interval,
      );
    });
  };
}

/**
 * Get the user data from the JWT token
 * @returns {Object || null} The decoded token data
 */
export function getUserData() {
  const getToken = () => JSON.parse(window.localStorage.getItem('token'));
  try {
    return decode(getToken());
  } catch (err) {
    console.error('getUserData Failed to fetch userData from token', err.stack);
    return null;
  }
}

/**
 * Bullet proof is empty to also check numbers
 * @param value
 * @return {boolean}
 */
export function isEmpty(value) {
  return !_isNumber(value) && _isEmpty(value);
}
