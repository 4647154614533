import React, { useRef, useState, useEffect } from 'react';
import { useScroll } from 'react-use';
import {
  isEmpty,  map, path,  filter,
} from 'ramda';
import { StripeProvider, Elements } from 'react-stripe-elements';
import cn from 'classnames';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Section } from '@guestyci/foundation/Layout';
import Divider from '@guestyci/foundation/legacy/Divider';
import useTranslations from '@guestyci/localize/useTranslations';
import FeatureToggleProvider from '@guestyci/feature-toggle-fe/FeatureToggleProvider';

import { DESKTOP, MOBILE, TABLET } from 'constants/css/breakpoints';
import Gallery from 'App/components/Gallery/Gallery.component';
import Layout from 'App/components/Layout/Layout.component';
import ListingCarousel from 'App/components/ListingCarousel/ListingCarousel.component';

import { connect } from 'react-redux';
import actions from 'redux/actions';
import { invoicePageInvoiceIntlId } from 'utils/intlIds';
import { getListingPageUrl } from 'utils/helpers';
import ImagesPane from './components/ImagesPane/ImagesPane';
import InformationStep from './components/InformationStep/InformationStep';
import PaymentsStep from './components/PaymentsStep/PaymentsStep';
import Footer from './components/Footer/Footer';
import Steps from './components/Steps/Steps';

import { InvoiceContent, InvoiceContentWrapper, InvoiceTag } from './Invoice.page.styled';

import './Invoice.css';
import PaymentMethodFailedDialog from './components/PaymentMethodFailedDialog/PaymentMethodFailedDialog';

const { FETCH_INVOICE_DATA_REQUEST, PROCESS_INVOICE_REQUEST } = actions;

const translations = [
  {
    id: invoicePageInvoiceIntlId('details_step_label'),
    d: 'Details',
  },
  {
    id: invoicePageInvoiceIntlId('payments_step_label'),
    d: 'Payment info',
  },
];

const InvoicePage = ({
  match: { params },
  breakpoint,
  clientSecret,
  isProcessingInvoice,
  listing,
  processQuotes,
  quotes,
  history,
  getInvoiceData,
}) => {
  const [form, setForm] = useState({ disabled: false, guestInfoValues: {} });
  const [activeStep, setActiveStep] = useState(0);
  const [activeStepSubmit, setActiveStepSubmit] = useState();

  const { quotesId, listingId } = params;

  useEffect(() => {
    getInvoiceData({ quotesId, listingId });
  }, [quotesId, listingId, getInvoiceData]);

  const onBackClick = () => {
    if (activeStep === 1) {
      setActiveStep(0);
    } else {
      history.push(getListingPageUrl({
        quotesId,
        listingId,
      }));
    }
  };

  const formRef = useRef(null);

  const scrollRef = useRef(null);
  const { y: containerScrollTop } = useScroll(scrollRef);

  const { checkInDateLocalized: checkIn, checkOutDateLocalized: checkOut } = path(['filters'], quotes) || {};

  const accountId = path(['meta', 'account', '_id'], quotes);
  const bookerId = path(['meta', 'guest', '_id'], quotes);
  const expireAt = path(['expireAt'], quotes);
  const requireAcceptTerms = path(['requireAcceptTerms'], quotes);
  const termsAndConditionsUrl = path(['termsAndConditionsUrl'], quotes);

  const reservation =
    filter(
      res => res.listing._id === listingId,
      path(['meta', 'reservations'], quotes) || [],
    )[0];
  const reservationId = path(['_id'], reservation);

  const providerAccountId = reservation?.providerAccountId ? reservation?.providerAccountId : path(['meta', 'account', 'stripeAccountId'], quotes);
  const paymentProviderId = reservation?.paymentProviderId;
  const providerType = reservation?.providerType;
  const conversationId = reservation?.conversationId;

  const isProcessingActive = (providerType === 'stripe' && providerAccountId) || (providerType === 'amaryllis' && paymentProviderId);

  const inquiry = path(['inquiry'], reservation);
  const inquiryId = reservation?.inquiryId;
  const source = inquiry?.source;

  const hasInquiriesFlow = !!inquiry;
  const isPaymentRequired = path(['requirePaymentMethod'], quotes);

  const currentRatePlanQuote = path(['guestInfoValues', 'ratePlan', 'data', 'quote'], form) || [];

  const price = hasInquiriesFlow
    ? path(['fareAccommodation'], currentRatePlanQuote)
    : path(['price', 'value'], reservation);

  const totalPrice = hasInquiriesFlow ?
    path(['hostPayout'], currentRatePlanQuote) :
    path(['price', 'value'], reservation);

  const currency = hasInquiriesFlow
    ? path(['currency'], currentRatePlanQuote)
    : path(['price', 'currency'], reservation);

  const invoiceItems = hasInquiriesFlow
    ? path(['invoiceItems'], currentRatePlanQuote)
    : path(['price', 'invoiceItems'], reservation);

  const fullAddress = path(['publishedAddress', 'full'], listing) || path(['address', 'full'], listing) || '';
  const images = map(
    ({ original, thumbnail }) => original || thumbnail,
    path(['pictures'], listing) || [],
  );
  const isMobile = breakpoint === MOBILE;
  const isDesktop = breakpoint === DESKTOP;
  const isTablet = breakpoint === TABLET;
  const occupancy = path(['filters', 'accommodates'], quotes);
  const numberOfGuests = path(['filters', 'numberOfGuests'], quotes);
  const title = path(['title'], listing) || '';
  const isRequestToBook = !!path(['inquiry', 'requestToBook'], reservation);
  const [detailsStepLabel, paymentsStepLabel] = useTranslations(translations);

  const key = process.env.REACT_APP_CLUSTER !== 'production'
    ? 'pk_test_ESWRIhPFbWs35vmzXZXdyXN5'
    : 'pk_live_P0FSIEtbwU1GSvgvEM3DYuUZ';

  return (!isEmpty(quotes) && !isEmpty(listing)) ? (
    <>
      <PaymentMethodFailedDialog />
      <StripeProvider apiKey={key} stripeAccount={providerAccountId}>
        <FeatureToggleProvider accountId={accountId} env={process.env.REACT_APP_CLUSTER}>
          <Layout multipleBackground={false} withSizeTracker layoutRef={scrollRef}>
            <>
              <InvoiceContentWrapper>
                <InvoiceContent className="d-flex flex-column border-radius-10">
                  {isPaymentRequired && (
                    <Section justify="center" gutter={7}>
                      <Steps
                        stepsList={[detailsStepLabel, paymentsStepLabel]}
                        activeStep={activeStep}
                      />
                    </Section>
                  )}
                  <Divider />
                  <div className="pos-relative">
                    {!isMobile && (
                      <ImagesPane
                        images={images}
                        clientHeight={
                          scrollRef.current && scrollRef.current.clientHeight
                        }
                        scrollHeight={
                          scrollRef.current && scrollRef.current.scrollHeight
                        }
                        scrollTop={containerScrollTop}
                      />
                    )}
                    {isMobile && (
                      <>
                        <Gallery images={images} />
                        <ListingCarousel
                          images={images}
                          onShowGallery={!isMobile}
                          showArrows={false}
                        />
                      </>
                    )}
                    <Elements>
                      <InvoiceTag
                        className={cn('d-flex flex-column p-3', {
                          'flex-1 pb-18 pt-17 pr-18 pl-18': isDesktop,
                          'w-100 p-6': isMobile,
                          'p-6': isTablet,
                        })}
                      >
                        {
                          [
                            <InformationStep
                              address={fullAddress}
                              breakpoint={breakpoint}
                              checkIn={checkIn}
                              checkOut={checkOut}
                              currency={currency}
                              formRef={formRef}
                              name={title}
                              occupancy={occupancy}
                              price={price}
                              form={form}
                              setForm={setForm}
                              totalPrice={totalPrice}
                              invoiceItems={invoiceItems}
                              currentRatePlanQuote={currentRatePlanQuote}
                              setActiveStepSubmit={setActiveStepSubmit}
                              goNextStep={() => setActiveStep(activeStep + 1)}
                              hasInquiriesFlow={hasInquiriesFlow}
                              inquiry={inquiry}
                              processQuotes={processQuotes}
                              isPaymentRequired={isPaymentRequired}
                              accountId={accountId}
                              inquiryId={inquiryId}
                              bookerId={bookerId}
                              source={source}
                              quotesId={quotesId}
                              history={history}
                              conversationId={conversationId}
                              reservationId={reservationId}
                              requireAcceptTerms={requireAcceptTerms}
                              termsAndConditionsUrl={termsAndConditionsUrl}
                              numberOfGuests={numberOfGuests}
                              isRequestToBook={isRequestToBook}
                            />,
                            <PaymentsStep
                              setActiveStepSubmit={setActiveStepSubmit}
                              accountId={accountId}
                              checkIn={checkIn}
                              checkOut={checkOut}
                              listingId={listingId}
                              clientSecret={clientSecret}
                              history={history}
                              occupancy={occupancy}
                              processQuotes={processQuotes}
                              quotesId={quotesId}
                              reservationId={reservationId}
                              paymentProviderId={paymentProviderId}
                              formRef={formRef}
                              form={form}
                              setForm={setForm}
                              providerType={providerType}
                              hasInquiriesFlow={hasInquiriesFlow}
                              inquiryId={inquiryId}
                              bookerId={bookerId}
                              source={source}
                              isPaymentRequired={isPaymentRequired}
                              isRequestToBook={isRequestToBook}
                              totalPrice={totalPrice}
                              currency={currency}
                            />,
                          ][activeStep]
                        }
                      </InvoiceTag>
                    </Elements>
                  </div>
                </InvoiceContent>
              </InvoiceContentWrapper>
              <Footer
                currency={currency}
                disabled={form.disabled || isProcessingInvoice}
                isProcessingInvoice={isProcessingInvoice}
                expiration={expireAt}
                history={history}
                isMobile={isMobile}
                onNextClick={activeStepSubmit}
                onBackClick={onBackClick}
                activeStep={activeStep}
                totalPrice={totalPrice}
                isPaymentRequired={isPaymentRequired}
                isProcessingActive={isProcessingActive}
              />
            </>
          </Layout>
        </FeatureToggleProvider>
      </StripeProvider>
    </>
  ) : null;
};

InvoicePage.propTypes = {
  breakpoint: PropTypes.string.isRequired,
  clientSecret: PropTypes.string,
  isProcessingInvoice: PropTypes.bool.isRequired,
  processQuotes: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  breakpoint: state.layout.breakpoint,
  clientSecret: state.invoice.clientSecret,
  isProcessingInvoice: state.invoice.isProcessingInvoice,
  listing: state.quotes.listing,
  quotes: state.quotes.quotes,
});

const mapDispatchToProps = dispatch => ({
  getInvoiceData: ({
    quotesId, listingId,
  }) => dispatch({ type: FETCH_INVOICE_DATA_REQUEST, payload: { quotesId, listingId } }),
  processQuotes: ({
    accountId, data, history, quotesId, stripe, listingId, paymentProviderId, submitForm, billingDetails, reusePaymentMethod
  }) => dispatch({
    type: PROCESS_INVOICE_REQUEST,
    payload: {
      accountId, data, history, quotesId, stripe, listingId, paymentProviderId, submitForm, billingDetails, reusePaymentMethod
    },
  }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(InvoicePage));
