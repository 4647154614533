import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push as historyPush } from 'connected-react-router';

import { MOBILE } from 'constants/css/breakpoints';

import Layout from 'App/components/Layout/Layout.component';
import actions from 'redux/actions';

import Listing from './components/Listing/Listing';
import SimplifiedListings from './components/SimplifiedListings/SimplifiedListings';

const { FETCH_QUOTES_REQUEST, FETCH_LISTING_REQUEST } = actions;

const ListingPage = ({
  breakpoint,
  getListing,
  getQuotes,
  history,
  match: {
    params,
  },
}) => {
  const isMobile = breakpoint === MOBILE;
  const { quotesId, listingId } = params;

  useEffect(() => {
    getQuotes(quotesId);
    // Probably can be refactored
    getListing({ listingId, quotesId });
  }, [getQuotes, getListing, isMobile, quotesId, listingId]);

  return (
    <Layout multipleBackground={false} withSizeTracker isLogo={false}>
      <div className="d-flex pos-absolute w-100 h-100 justify-content-end">
        <Listing
          history={history}
          params={params}
        />
        {!isMobile && <SimplifiedListings
          quotesId={quotesId}
          listingId={listingId}
          history={history}
        />}
      </div>
    </Layout>
  );
};

ListingPage.propTypes = {
  getListing: PropTypes.func,
  getQuotes: PropTypes.func,
  push: PropTypes.func.isRequired,
  quotes: PropTypes.objectOf(),
};

const mapStateToProps = state => ({
  breakpoint: state.layout.breakpoint,
});

const mapDispatchToProps = dispatch => ({
  getListing: ({ quotesId, listingId }) => dispatch({
    type: FETCH_LISTING_REQUEST,
    payload: { quotesId, listingId },
  }),
  getQuotes: id => dispatch({ type: FETCH_QUOTES_REQUEST, payload: { id } }),
  push: _path => dispatch(historyPush(_path)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ListingPage));
