import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import withHover from '../../compose/withHover';
import AccordionChevron from './AccordionChevron';
import TextField from '../textField/TextField';

const AccordionTitle = ({
  title, isHovering, onHoverEnter, onHoverLeave, onClick, isOpen,
}) => (
  <div
    role="presentation"
    className={cn('w-fill clickable flex-space-between-center pt-3', `pb-${isOpen ? 6 : 3}`)}
    onClick={onClick}
    onMouseEnter={onHoverEnter}
    onMouseLeave={onHoverLeave}
  >
    <TextField size="lg" className="height-20 flex-start-center" color="blue">{title}</TextField>
    <AccordionChevron isOpen={isOpen} isActive={isHovering} />
  </div>
);

AccordionTitle.propTypes = {
  /** Indicator whether or not collapse should be open */
  isOpen: PropTypes.bool.isRequired,
  /** Title body */
  title: PropTypes.node.isRequired,
  /** Callback for title click */
  onClick: PropTypes.func.isRequired,
};

export default withHover(AccordionTitle);
