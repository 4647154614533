import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import withStyle from 'react-jss';
import TextField from '../../textField/TextField';
import Icon from '../../icon/Icon';

const styles = {
  badge: {
    minWidth: ({ sm }) => (sm ? 13 : 21),
    height: ({ sm }) => (sm ? 13 : 21),
    borderRadius: 10,
  },
  value: {
    padding: '3px 7px',
  },
};

const getIconSize = (width, height, sm) =>
  ({ height: height || (sm ? 13 : 21), width: width || (sm ? 13 : 21) });

const Badge = ({
  classes, value, bgColor, color, className, style, borderColor, sm,
  icon, iconHeight, iconWidth,
}) => {
  const { height, width } = getIconSize(iconWidth, iconHeight, sm);
  return (
    <div
      className={cn(
        'badge-wrapper flex-center',
        classes.badge,
        {
          [`bg-${bgColor}`]: bgColor,
          [`text-${color} fill-${color}`]: color,
          [`border-${borderColor}`]: borderColor,
          'b-1': borderColor,
        },
        className,
      )}
      style={style}
    >
      {icon ?
        (<Icon icon={icon} width={width} height={height} noFill />) :
        (<TextField className={classes.value} color={color} size={sm ? 'xs' : 'md'}>{value}</TextField>)
      }
    </div>
  );
};

Badge.defaultProps = {
  bgColor: 'blue',
  className: '',
  borderColor: '',
  style: {},
  color: 'white',
  sm: false,
  iconHeight: null,
  iconWidth: null,
  icon: null,
  value: null,
};

Badge.propTypes = {
  /** Background color for badge root */
  bgColor: PropTypes.string,
  /** Border color for badge root */
  borderColor: PropTypes.string,
  /** Color for badge child */
  color: PropTypes.string,
  /** Additional class for badge */
  className: PropTypes.string,
  /** Additional style for badge */
  style: PropTypes.shape(),
  /** Set badge as small */
  sm: PropTypes.bool,
  /** Icon to locate in badge */
  icon: PropTypes.string,
  /* Width for icon */
  iconWidth: PropTypes.number,
  /* Height for icon */
  iconHeight: PropTypes.number,
  /* Value to put in badge if counter type */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
/** Work around to fix storybook HOC propTypes bug  - DO NOT IMPORT THIS */
export const BadgeComponent = Badge;
export default withStyle(styles)(Badge);
