export const REQUIRED = 'This field is required';
export const INVALID_EMAIL = 'Invalid email address';
export const ONLY_ALPHA_NUMERIC = 'Only alphanumeric characters';
export const MAX_3_DIGITS = 'Max 3 digits after decimal point';
export const NOT_ZERO = 'Not zero';
export const INVALID_PHONE_10_DIGITS = 'Invalid phone number, must be 10 digits';
export const INVALID_PHONE = 'Invalid phone number';
export const INVALID_PHONE_NUMBER = 'Invalid phone number, check your number/country zone';
export const START_DATE_REQUIRED = 'Start date is required';
export const END_DATE_REQUIRED = 'End date is required';

export const maxLengthMessage = max => `Must be ${max} characters or less`;
export const minLengthMessage = min => `Must be ${min} characters or more`;

export const minMessage = minVal => `Must be at least ${minVal}`;
export const maxMessage = maxVal => `Must be at most ${maxVal}`;
