import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import withStyles from 'react-jss';
import noop from 'lodash/noop';
import IconButton from '../buttons/IconButton';
import RaisedButton from '../buttons/RaisedButton';
import FlatButton from '../buttons/FlatButton';
import Link from '../buttons/Link';
import Icon from '../icon/Icon';

const styles = {
  title: {
    fontSize: '16px',
    '&::first-letter': {
      textTransform: 'uppercase',
    },
  },
  innerWrapper: {
    '&::before': {
      content: '""',
      width: '100%',
      height: '100%',
      display: 'block',
      background: 'white',
      position: 'absolute',
      opacity: 0.7,
    },
  },
};

class Card extends Component {
  onSave = () => {
    const { onSave, onStatusChange } = this.props;
    onSave();
    onStatusChange('loading');
  };

  onCancel = () => {
    const { onCancel } = this.props;
    onCancel();
    this.toggleEdit();
  };

  toggleEdit = () => {
    const { onStatusChange, status } = this.props;
    const currentMode = (status === 'edit' || status === 'loading') ? 'view' : 'edit';
    onStatusChange(currentMode);
  };

  render() {
    const {
      children,
      editComponent,
      className,
      title,
      classes,
      noPadding,
      editable,
      disableEdit,
      link,
      headerRightComponent,
      status,
      disableSave,
      saveBtnText,
    } = this.props;
    const editMode = (status === 'edit' || status === 'loading');
    const isLoading = status === 'loading';
    return (
      <div className="card-component bg-white pb-6">
        <div className="px-9">
          <div className={cn('bb d-flex align-items-center justify-content-between height-50', { 'mb-6': !noPadding })} >
            <div className={cn(classes.title, 'text-blue')}>{title}</div>
            <div className="d-flex align-items-center">
              {(editable && !editMode) &&
              <IconButton
                icon="BtnEdit"
                onClick={this.toggleEdit}
                disabled={disableEdit}
                color="gray-dark"
                width={16}
                iconWidth={16}
                height={16}
                iconHeight={16}
              />}
              {(link && !editMode) &&
              <Link
                href={link}
                className="ml-2"
                width={16}
                height={16}
                color="gray-dark"
              ><Icon icon="BtnGoTo" noFill width={16} height={16} />
              </Link>}
              {(editable && editMode) &&
              <Fragment>
                <FlatButton className="mr-2" onClick={this.onCancel}>Cancel</FlatButton>
                <RaisedButton
                  onClick={this.onSave}
                  isLoading={isLoading}
                  disabled={disableSave}
                >{saveBtnText}
                </RaisedButton>
              </Fragment>}
              {headerRightComponent && headerRightComponent}
            </div>
          </div>
        </div>
        <div className={cn('bg-white card-wrapper', { 'px-9': !noPadding }, className)}>
          <div className={cn('w-100 h-100 pos-relative', { [`${classes.innerWrapper}`]: isLoading })}>
            { !editMode ? children : editComponent}
          </div>
        </div>
      </div>
    );
  }
}

Card.defaultProps = {
  editable: false,
  noPadding: false,
  disableEdit: false,
  disableSave: false,
  children: null,
  editComponent: null,
  className: null,
  title: '',
  link: null,
  headerRightComponent: null,
  onSave: noop,
  onStatusChange: noop,
  onCancel: noop,
  status: 'view',
  saveBtnText: 'Save',
};

Card.propTypes = {
  /** editable card, will display edit button & edit children */
  editable: PropTypes.bool,
  /** no left, right and bottom padding - add in className */
  noPadding: PropTypes.bool,
  /** disable edit button on view mode */
  disableEdit: PropTypes.bool,
  /** disable edit button on edit mode (when values are invalid) */
  disableSave: PropTypes.bool,
  /** children will be displayed in view mode */
  children: PropTypes.node,
  /** children will be displayed in edit mode */
  editComponent: PropTypes.node,
  /** Additional class to add to the card wrapper */
  className: PropTypes.string,
  /** Card title */
  title: PropTypes.string,
  /** Card link */
  link: PropTypes.string,
  /** Save button text */
  saveBtnText: PropTypes.string,
  /** On Save function */
  onSave: PropTypes.func,
  /** On Save function */
  onCancel: PropTypes.func,
  /** Will update the parent component with the card mode
   * (will be called onSave and when cancel is clicked) */
  onStatusChange: PropTypes.func,
  /** Add custom buttons to title */
  headerRightComponent: PropTypes.node,
  /** Card mode  */
  status: PropTypes.oneOf(['edit', 'view', 'loading']),
};

export default withStyles(styles)(Card);
