import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import withStyles from 'react-jss';

const styles = {
  pill: {
    height: 21,
    borderRadius: 10,
  },
};

const Pill = ({
  className, color, bgColor, borderColor, textHover,
  bgHover, borderHover, filter, children, classes,
}) => (
  <div
    className={cn(
      className,
      classes.pill,
      {
        [`text-${color}`]: color,
        [`border-${borderColor}`]: borderColor,
        [`bg-${bgColor}`]: bgColor,
        [`text-hover-${textHover}`]: textHover,
        [`border-hover-${borderHover}`]: borderHover,
        [`bg-hover-${bgHover}`]: bgHover,
        'b-1': borderColor,
        'bg-hover-blue box-shadow-hover-heavy box-shadow-active-0': filter,
      },
      'px-2  pill-element overflow-ellipsis w-fit-content',
    )}
  >
    {children}
  </div>
);

Pill.defaultProps = {
  className: '',
  color: 'white',
  bgColor: 'black',
  borderColor: '',
  textHover: '',
  bgHover: '',
  borderHover: '',
  filter: false,
};
Pill.propTypes = {
  /** ClassName url for pill */
  className: PropTypes.string,
  /** Text for pill */
  color: PropTypes.string,
  /** Background Color for pill */
  bgColor: PropTypes.string,
  /** borderColor for pill */
  borderColor: PropTypes.string,
  /** textHover for pill */
  textHover: PropTypes.string,
  /** Background on hover for pill */
  bgHover: PropTypes.string,
  /** borderHover for pill */
  borderHover: PropTypes.string,
  /** Is it a filter */
  filter: PropTypes.bool,
  /** Array or nodes , or nodes */
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default withStyles(styles)(Pill);
