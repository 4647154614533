import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../icon/Icon';
import FlatButton from '../buttons/FlatButton';
import Tooltip from '../tooltip/Tooltip';

const ThreadMessageErrInfo = ({
  messageReason,
  onDelete,
  retrySendMessage,
  tooltip,
  deleteTxt,
  retryTxt
}) => (
  <div
    id="error-message-info"
    className="message-error-component d-flex align-items-center text-muted justify-content-end"
  >
    <div className="text-red mr-2">{messageReason}</div>
    <Tooltip body={tooltip} fullWidth>
      <Icon icon="BtnHelp" color="muted" />
    </Tooltip>
    <FlatButton className="mx-5" onClick={onDelete}>
      {deleteTxt}
    </FlatButton>
    <FlatButton onClick={retrySendMessage}>{retryTxt}</FlatButton>
  </div>
);

ThreadMessageErrInfo.propTypes = {
  messageReason: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  retrySendMessage: PropTypes.func.isRequired,
  tooltip: PropTypes.string.isRequired,
  deleteTxt: PropTypes.string.isRequired,
  retryTxt: PropTypes.string.isRequired
};

export default ThreadMessageErrInfo;
