import React, { Component } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

class ListItem extends Component {
  handleListItemClick = e => {
    e.stopPropagation();
    if (this.props.onClick) {
      this.props.onClick(this.props.value);
    }
  };
  render() {
    const {
      className,
      style,
      children,
      dense,
      onClick,
      isSelected,
      selectedClassName,
      fullWidth,
      ...props
    } = this.props;
    return (
      <div
        role="presentation"
        className={cn(
          'd-flex',
          'transition-bg-color',
          { 'w-fill': fullWidth },
          { 'my-2': !dense },
          { clickable: onClick },
          { [selectedClassName]: isSelected },
          className
        )}
        onClick={this.handleListItemClick}
        style={style}
        {...props}
      >
        {children}
      </div>
    );
  }
}
ListItem.defaultProps = {
  className: '',
  style: {},
  dense: false,
  value: undefined,
  onClick: null,
  isSelected: false,
  selectedClassName: '',
  fullWidth: true
};
ListItem.propTypes = {
  /** The list item inner component to render */
  children: PropTypes.node.isRequired,
  /** Additional class to add to the list item component */
  className: PropTypes.string,
  /** Additional style to add to the list item component */
  style: PropTypes.shape(),
  /** Indicator whether the list should be dense or not */
  dense: PropTypes.bool,
  /** Value to pass on list click (only relevant for clickable list items) */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape()
  ]),
  /** CB for handling list item click event */
  onClick: PropTypes.func,
  /** Indicator whether or not the list item is selected */
  isSelected: PropTypes.bool,
  /** Class for the selected state */
  selectedClassName: PropTypes.string,
  /** Indicator whether or not list item should be full width */
  fullWidth: PropTypes.bool
};
export default ListItem;
