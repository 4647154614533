import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import noop from 'lodash/noop';
import isEmpty from 'lodash/isEmpty';
import LabelDropdownInputField from './LabelDropdownInputField';
import withCollapse from '../../collapsibles/withCollapse';
import DropdownInputField from '../DropdownInputField';
import withDropdown from '../withDropdown';
import DropdownMenu from '../DropdownMenu';
import MenuItem from '../../menu/MenuItem';
import { AnchorDirectionType } from '../../../../constants/enums';

const LabelDropdown = ({
  options, toggleCollapse, isCollapsed, placeholder, className, style, name, searchable,
  selectedItem, query, onSearchChange, clearSelectedItem, onSelectItem, displayField,
  isFetching, fetchNextPage, pageCount, currentPage, searchInputValue, clearQuery,
  anchorDirection, closeDropdown,
}) => (
  <div className={cn('relative flex-column', className)} style={style} name={name}>
    <LabelDropdownInputField
      label={selectedItem[displayField] || selectedItem.label}
      onClick={toggleCollapse}
      isOpen={!isCollapsed}
      placeholder={placeholder}
    />
    <DropdownMenu
      anchorDirection={anchorDirection}
      onDismiss={closeDropdown}
      pageCount={pageCount}
      onLoadMore={fetchNextPage}
      currentPage={currentPage}
      isFetching={isFetching}
      className={cn('max-width-280', searchable ? 'min-width-280' : 'min-width-140')}
      isEmptyState={!options.length}
      isCollapsed={isCollapsed}
      topBar={searchable ?
        (<DropdownInputField
          noAction
          className="b-0 bb-1"
          inputClassName={cn({ 'text-blue': !isEmpty(selectedItem) })}
          onQueryChange={onSearchChange}
          query={query}
          value={searchInputValue}
          onClearQuery={clearQuery}
          onClearSelected={clearSelectedItem}
          searchable
        />) : null}
    >
      {options.map(item =>
        <MenuItem
          key={item.value}
          value={item}
          isSelected={item.value === selectedItem.value}
          onClick={onSelectItem}
        >{item.label}
        </MenuItem>)}
    </DropdownMenu>
  </div>
);

LabelDropdown.defaultProps = {
  searchable: false,
  placeholder: 'Select Value...',
  defaultValue: {},
  onSearchQueryChange: null,
  className: '',
  style: {},
  displayField: 'label',
  onLoadMore: noop,
  isFetching: false,
  pageCount: 0,
  value: undefined,
  anchorDirection: AnchorDirectionType.Start,
  onChange: noop,
  onSelect: noop,
};
LabelDropdown.propTypes = {
  /** The dropdown options to show - Structure ({
   label: {Node} - the label to present,
   value: {String|number} - unique value to be sent to the server,
  name: {String} -  (required if label is custom node) value to send if label is component
   - shown in input once selected,
  })
   */
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  /** Indicator whether or not the input field is searchable */
  searchable: PropTypes.bool,
  /** Placeholder for input field */
  placeholder: PropTypes.string,
  /** Initial value to set to dropdown */
  defaultValue: PropTypes.shape(),
  /** Mandatory if searchable - callback for on input change */
  onSearchQueryChange: PropTypes.func,
  /** Callback function to be called on value changed - Deprecated */
  onSelect: PropTypes.func,
  /** Callback function to be called on value changed */
  onChange: PropTypes.func,
  /** Additional class for dropdown root */
  className: PropTypes.string,
  /** Additional style for dropdown root */
  style: PropTypes.shape(),
  /**  field to display for selected item */
  displayField: PropTypes.string,
  /** Callback function to fetch more items - required for infinite scroll */
  onLoadMore: PropTypes.func,
  /** Total page count for pagination - required to allow infinite scroll */
  pageCount: PropTypes.number,
  /** Indicator whether or not we are currently fetching value for infinite scroll */
  isFetching: PropTypes.bool,
  /** Value to set for the dropdown to make controlled
   * auto sets if full dropdown item, if string -
   * will only set once the value exists in the dropdown options
   */
  value: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string, PropTypes.number]),
  /** Set the direction of the dropdown anchor */
  anchorDirection: PropTypes.oneOfType([
    AnchorDirectionType.Center,
    AnchorDirectionType.Start,
    AnchorDirectionType.End,
  ]),
};

export const LabelDropdownComponent = LabelDropdown;
export default withCollapse(withDropdown(LabelDropdown));
