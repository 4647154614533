import React, { Component } from 'react';
/**
 * Create a wrapper compose to support Accordion functionality
 * @param ComposedComponent
 * @returns {{Component}}
 */
const withCollapse = ComposedComponent =>
  class extends Component {
    constructor(props) {
      super(props);
      this.state = { show: false };
    }

    closeCollapse = () => {
      this.setState({ show: false });
    };

    openCollapse = event => {
      if (event) {
        event.stopPropagation();
      }
      this.setState({ show: true });
    };

    toggleCollapse = event => {
      if (event) {
        event.stopPropagation();
      }
      this.setState(prevState => ({ show: !prevState.show }));
    };
    render() {
      return (
        <ComposedComponent
          {...this.props}
          isCollapsed={!this.state.show}
          closeCollapse={this.closeCollapse}
          openCollapse={this.openCollapse}
          toggleCollapse={this.toggleCollapse}
        />
      );
    }
  };

export default withCollapse;
