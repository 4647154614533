import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { push as historyPush } from 'connected-react-router';
import { connect } from 'react-redux';
import { isEmpty, map, path } from 'ramda';

import useTranslations from '@guestyci/localize/useTranslations';

import { MOBILE } from 'constants/css/breakpoints';
import {getGuestsCountForReservation, getListingPageUrl} from 'utils/helpers';
import actions from 'redux/actions';

import Layout from 'App/components/Layout/Layout.component';
import Header from 'App/components/Header/Header.component';
import Footer from 'App/components/Footer/Footer.component';
import MailToString from 'App/components/MailToString/MailToString.component';
import { mainPageIntlId } from 'utils/intlIds';

import { Reservations } from './Main.page.styled';
import Listing from './components/Listing/Listing.component';

const { FETCH_QUOTES_REQUEST } = actions;

const FOOTER_BACKGROUND = 'var(--white)';
const FOOTER_COLOR = 'var(--grayish-blue)';

const Main = ({
  breakpoint,
  getQuotes,
  history,
  quotes,
  match: { params },
  push,
}) => {
  const translations = [
    {
      id: mainPageIntlId('havent_found'),
      d: 'Haven’t found what you are looking for?',
    },
    {
      id: mainPageIntlId('contact_us'),
      d: 'contact us',
    },
    {
      id: mainPageIntlId('for_more_offers'),
      d: 'For more offers please contact us',
    },
  ];

  const [haveNotFoundText, contactUsText, forMoreOffersText] = useTranslations(translations);

  const isMobile = breakpoint === MOBILE;
  const quotesId = params.id;

  const expireAt = path(['expireAt'], quotes);
  const filters = path(['filters'], quotes);
  const guest = path(['meta', 'guest'], quotes);
  const reservations = path(['meta', 'reservations'], quotes);
  const simplListings = map(
    (res) => res.listing,
    path(['meta', 'reservations'], quotes) || [],
  );
  const email = path(['meta', 'integration', 'incomingEmail'], quotes);
  const inquiry = path(['inquiry'], reservations?.[0])
  const guestsCount = getGuestsCountForReservation(inquiry)
  
  useEffect(() => {
    getQuotes(quotesId);
  }, [getQuotes, quotesId]);

  return (
    <Layout withSizeTracker>
      {!isEmpty(quotes) && quotesId ? (
        <Fragment>
          <Header
            expireAt={expireAt}
            filters={filters}
            guest={guest}
            history={history}
            listings={simplListings}
            guestsCount={guestsCount}
          />
          <Reservations className="d-flex flex-column w-100">
            {reservations.map((reservation, i) => (
              <Listing
                {...reservation}
                breakpoint={breakpoint}
                key={i}
                onClick={() => history.push(
                  getListingPageUrl({
                    quotesId,
                    listingId: path(['listing', '_id'], reservation),
                  }),
                )
                }
              />
            ))}
          </Reservations>
          <Footer
            className={
              isMobile
                ? 'position-fixed zindex-10 font-size-lg pb-10'
                : 'position-fixed zindex-10'
            }
            onClick={() => {}}
            background={FOOTER_BACKGROUND}
            color={FOOTER_COLOR}
          >
            {!isMobile ? (
              <Fragment>
                {forMoreOffersText}
                <MailToString email={email} />
              </Fragment>
            ) : (
              <div className="d-flex flex-column align-items-center">
                <div>{haveNotFoundText}</div>
                <div>
                  {contactUsText}
                  <MailToString email={email} />
                </div>
              </div>
            )}
          </Footer>
        </Fragment>
      ) : null}
    </Layout>
  );
};

Main.propTypes = {
  breakpoint: PropTypes.string.isRequired,
  push: PropTypes.func.isRequired,
  getQuotes: PropTypes.func,
  quotes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  breakpoint: state.layout.breakpoint,
  quotes: state.quotes.quotes,
});

const mapDispatchToProps = (dispatch) => ({
  getQuotes: (id) => dispatch({ type: FETCH_QUOTES_REQUEST, payload: { id } }),
  push: (_path) => dispatch(historyPush(_path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Main));
