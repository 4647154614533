import React, { Component } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import withStyles from 'react-jss';

const styles = {
  menuItem: {
    '&:last-child': {
      marginBottom: '0 !important',
    },
  },
};

class MenuItem extends Component {
  handleMenuItemClick = () => {
    if (this.props.onClick) {
      this.props.onClick(this.props.value);
    }
  };
  render() {
    const {
      className, style, children, dense, onClick, isSelected, selectedClassName, classes, id, name,
    } = this.props;
    return (
      <div
        role="presentation"
        className={cn('text-truncate d-block text-gray-dark fill-hover-blue text-hover-blue transition-bg-color', { 'mb-3': !dense }, classes.menuItem, { clickable: onClick }, { [selectedClassName]: isSelected }, className)}
        onClick={this.handleMenuItemClick}
        style={style}
        id={id}
        name={name}
      >{children}
      </div>
    );
  }
}
MenuItem.defaultProps = {
  className: '',
  style: {},
  dense: false,
  value: undefined,
  onClick: null,
  isSelected: false,
  selectedClassName: 'text-blue',
  id: '',
  name: '',
};
MenuItem.propTypes = {
  /** The list item inner component to render */
  children: PropTypes.node.isRequired,
  /** Additional class to add to the list item component */
  className: PropTypes.string,
  /** Additional style to add to the list item component */
  style: PropTypes.shape(),
  /** Indicator whether the list should be dense or not */
  dense: PropTypes.bool,
  /** Value to pass on list click (only relevant for clickable list items) */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.shape()]),
  /** CB for handling list item click event */
  onClick: PropTypes.func,
  /** Indicator whether or not the list item is selected */
  isSelected: PropTypes.bool,
  /** Class for the selected state */
  selectedClassName: PropTypes.string,
  /** id for menu item  */
  id: PropTypes.string,
  /** name for menu item */
  name: PropTypes.string,
};
export default withStyles(styles)(MenuItem);
