import validate from 'validate.js';
import { path } from 'ramda';

validate.validators.stripeValidator = (value) => path(['error', 'code'], value) ||
  ((!value || path(['empty'], value)) && 'required') ||
  undefined;

validate.validators.invoiceTelValidator = ({ value, isValid } = {}) => {
  if (!isValid) {
    return value ? 'Format is not valid' : 'Required';
  }
};

export default validate;
