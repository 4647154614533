import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'react-jss';
import cn from 'classnames';
import * as Icons from '../../../icons/index';

const styles = {
  icon: {
    minWidth: props => props.width,
    minHeight: props => props.height,
  },
};

const Icon = ({
  width, height, color, className, icon, style, noFill, classes,
}) => {
  const SelectedIcon = Icons[icon];
  if (!SelectedIcon) {
    return null;
  }

  return (
    <SelectedIcon
      width={width}
      height={height}
      className={cn(
        { 'transition-all': color && !noFill },
        className,
        noFill ? 'fill-inherit' : [`fill-${color}`],
        classes.icon,
      )}
      style={style}
    />
  );
};

Icon.defaultProps = {
  width: 20,
  height: 20,
  color: '',
  className: '',
  style: {},
  noFill: false,
};
Icon.propTypes = {
  /** Icon width in pixels */
  width: PropTypes.number,
  /** Icon height in pixels */
  height: PropTypes.number,
  /** Icon fill color - see style guide colors for options */
  color: PropTypes.string,
  /** Class to add to the Icon component */
  className: PropTypes.string,
  /** Style to add to Icon component */
  style: PropTypes.shape(),
  /** The string name of the Icon to use. Also possible to pass without the icon prop */
  icon: PropTypes.string.isRequired,
  /** boolean - decide whether to disable default fill color */
  noFill: PropTypes.bool,
};

export default withStyles(styles)(Icon);
