import React from 'react';
import cn from 'classnames';
import Icon from '../../icon/Icon';

const sizes = { sm: 25, md: 40, lg: 55 };
const IconAvatar = ({
  icon, size = 'md', disabled, color = 'gray',
}) => (
  <div className={cn('icon-avatar', 'flex-center', `avatar avatar-${size}`, { disabled })}>
    <Icon color={color} icon={icon} height={sizes[size]} width={sizes[size]} />
  </div>
);

export default IconAvatar;
