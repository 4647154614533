const AIRBNB_REDIRECT_URL = 'https://mailer.staging.guesty.com/proxy/integration/integration/airbnb/redirect';
const API_URL = 'https://mailer.staging.guesty.com/api/v2';
const MAILER_URL = 'https://mailer.staging.guesty.com/api/v2';
const INTEGRATION_SERVICE_URL = 'https://mailer.staging.guesty.com/proxy/integration';
const COMMUNICATION_SERVICE_URL = 'https://mailer.staging.guesty.com/proxy/communication/v2';
const LEAGCY_URL = '//dashboard-staging-6bc80.firebaseapp.com';
const API_GATEWAY = 'https://staging-apigw.guesty.com';
const AIRBNB_CLIENT_ID = '5or2sm27irlmdz09uoucevdzi';
const AIRBNB_SCOPE = ['vr', 'messages_read', 'messages_write'].join();

export default {
  AIRBNB_REDIRECT_URL,
  MAILER_URL,
  INTEGRATION_SERVICE_URL,
  API_URL,
  LEAGCY_URL,
  COMMUNICATION_SERVICE_URL,
  API_GATEWAY,
  AIRBNB_CLIENT_ID,
  AIRBNB_SCOPE,
};
