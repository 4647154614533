import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import WindowSizeTracker from 'utils/WindowSizeTracker';
import { LayoutTag } from './Layout.component.styled';
import LogoTag from '../LogoTag/LogoTag';

const Layout = ({ isLogo = true, children, className, multipleBackground = true, withSizeTracker, layoutRef, quotes }) => {
  return (
    <LayoutTag
      className={`d-flex flex-column overflow-auto ${className || ''}`}
      multipleBackground={multipleBackground}
      {...(layoutRef ? { ref: layoutRef } : {})}
    >
      {withSizeTracker && <WindowSizeTracker />}
      {isLogo && <LogoTag quotes={quotes} />}
      {children}
    </LayoutTag>
  );
};

Layout.propTypes = {
  children: PropTypes.object,
  className: PropTypes.string,
  multipleBackground: PropTypes.bool,
  withSizeTracker: PropTypes.bool,
  layoutRef: PropTypes.object,
  quotes: PropTypes.object,
};

const mapStateToProps = state => ({
  quotes: state.quotes.quotes,
});

export default connect(
  mapStateToProps,
)(Layout);
