import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import Avatar from '../avatarStructures/avatar/Avatar';
import RoundIcon from '../icon/RoundIcon';
import { nameToInitials } from '../../../utils/commonUtility';

const ThreadMessageIcon = ({
  isAutoMessage, thumbnail, byGuest, byGSE, byUs, byChannel, avatarName,
}) => (
  <div className={cn('d-flex', { 'mr-3': !byUs, 'ml-3': byUs })}>
    {!!isAutoMessage &&
    <RoundIcon className="thread-round-icon" bgColor="gray-lighter" color="gray-dark" icon="BtnAutomsg" iconWidth={22} iconHeight={22} />}
    {!!byChannel && <RoundIcon className="by-channel" bgColor="red" color="white" icon="IcoAirbnb" iconWidth={22} iconHeight={23} />}
    {!!byGSE && <RoundIcon className="by-GSE" bgColor="blue-dark" color="white" icon="BtnGuestySymbol" iconWidth={20} iconHeight={20} />}
    {((!!byUs && !isAutoMessage && !byGSE && !byChannel) || !!byGuest) && <Avatar className="msg-by-user" size="md" src={thumbnail} text={nameToInitials(avatarName)} isUser />}
  </div>
);

ThreadMessageIcon.defaultProps = {
  isAutoMessage: false,
  thumbnail: '',
  byGuest: false,
  byGSE: false,
  byUs: false,
  byChannel: false,
};

ThreadMessageIcon.propTypes = {
  isAutoMessage: PropTypes.bool,
  thumbnail: PropTypes.string,
  byGuest: PropTypes.bool,
  byGSE: PropTypes.bool,
  byUs: PropTypes.bool,
  byChannel: PropTypes.bool,
};
export default ThreadMessageIcon;
