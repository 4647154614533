import React from 'react';
import withStyles from 'react-jss';
import PropTypes from 'prop-types';
import { size } from 'lodash';
import cn from 'classnames';
import Icon from '../icon/Icon';

const threadMessageStyle = {
  automatedMessage: {
    backgroundImage:
      'repeating-linear-gradient(-67.5deg, transparent, transparent 7px, rgba(255, 255, 255, 0.4) 7px, rgba(255, 255, 255, 0.4) 10px)'
  }
};

const ThreadMessageBody = ({
  body,
  attachments,
  isAutoMessage,
  byUs,
  errorMessage,
  bgColor,
  openLink,
  classes
}) => (
  <div
    className={cn(
      'align-items-center mb-2 px-4 py-3 font-size-md',
      { 'by-guest': !byUs },
      { 'by-us': byUs },
      { 'border border-red': !!errorMessage },
      `bg-${bgColor}`,
      isAutoMessage ? `autoMsg ${classes.automatedMessage}` : null
    )}
  >
    <span
      dangerouslySetInnerHTML={{
        __html: body ? body.replace(/\n/g, '<br/>') : null
      }}
    />
    {!!size(attachments) && (
      <div className="mt-4">
        {attachments.map(attachment => (
          <div
            key={attachment.url}
            role="presentation"
            className="attachment hover-section-blue d-flex align-items-center clickable mb-2"
            onClick={() => openLink(attachment.url)}
          >
            <Icon
              icon={attachment.icon}
              height={30}
              width={26}
              className="mr-2"
              color="gray-dark"
            />
            <span className="text-gray-dark">{attachment.name}</span>
          </div>
        ))}
      </div>
    )}
  </div>
);
ThreadMessageBody.defaultProps = {
  isAutoMessage: false,
  byUs: true,
  bgColor: null,
  errorMessage: false
};

ThreadMessageBody.propTypes = {
  body: PropTypes.node.isRequired,
  attachments: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isAutoMessage: PropTypes.bool,
  byUs: PropTypes.bool,
  bgColor: PropTypes.string,
  openLink: PropTypes.func.isRequired,
  errorMessage: PropTypes.bool
};

export default withStyles(threadMessageStyle)(ThreadMessageBody);
