import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import cn from 'classnames';
import DropdownInputField from './DropdownInputField';
import withCollapse from '../collapsibles/withCollapse';
import withDropdown from './withDropdown';
import DropdownMenu from './DropdownMenu';
import MenuItem from '../menu/MenuItem';

const Dropdown = ({
  options,
  searchable,
  toggleCollapse,
  isCollapsed,
  selectedItem,
  query,
  name,
  placeholder,
  className,
  style,
  onSearchChange,
  onSelectItem,
  clearSelectedItem,
  isFetching,
  fetchNextPage,
  pageCount,
  currentPage,
  searchInputValue,
  clearQuery,
  noClear,
  closeDropdown,
  disabled
}) => (
  <div
    className={cn('d-flex-fill relative flex-column', className)}
    style={style}
    name={name}
  >
    <DropdownInputField
      onQueryChange={onSearchChange}
      value={searchInputValue}
      query={query}
      name={name}
      searchable={searchable}
      onIconClick={toggleCollapse}
      isOpen={!isCollapsed}
      placeholderValue={placeholder}
      onClearQuery={clearQuery}
      onClearSelected={clearSelectedItem}
      noClear={noClear}
      disabled={disabled}
    />
    <DropdownMenu
      pageCount={pageCount}
      onDismiss={closeDropdown}
      isEmptyState={!options.length}
      currentPage={currentPage}
      onLoadMore={fetchNextPage}
      isFetching={isFetching}
      isCollapsed={isCollapsed}
    >
      {options.map(item => (
        <MenuItem
          key={item.value}
          value={item}
          isSelected={item.value === selectedItem.value}
          onClick={onSelectItem}
        >
          {item.label}
        </MenuItem>
      ))}
    </DropdownMenu>
  </div>
);
Dropdown.defaultProps = {
  searchable: false,
  placeholder: 'Select',
  defaultValue: {},
  onSearchQueryChange: null,
  className: '',
  style: {},
  name: '',
  onLoadMore: noop,
  isFetching: false,
  pageCount: 0,
  value: undefined,
  noClear: false,
  onChange: noop,
  onSelect: noop,
  disabled: false
};
Dropdown.propTypes = {
  /** The dropdown options to show - Structure ({
   label: {Node} - the label to present,
   value: {String|number} - unique value to be sent to the server,
  name: {String} - required if value to present if label is component-shown in input once selected,
  })
   */
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  /** Indicator whether or not the input field is searchable */
  searchable: PropTypes.bool,
  /** Placeholder for input field */
  placeholder: PropTypes.string,
  /** Initial value to set to dropdown */
  defaultValue: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
  /** Mandatory if searchable - callback for on input change */
  onSearchQueryChange: PropTypes.func,
  /** Callback function to be called on value changed - Deprecated */
  onSelect: PropTypes.func,
  /** Callback function to be called on value changed */
  onChange: PropTypes.func,
  /** Additional class for dropdown root */
  className: PropTypes.string,
  /** Additional style for dropdown root */
  style: PropTypes.shape(),
  /** form element name */
  name: PropTypes.string,
  /** Callback function to fetch more items - required for infinite scroll */
  onLoadMore: PropTypes.func,
  /** Total page count for pagination - required to allow infinite scroll */
  pageCount: PropTypes.number,
  /** Indicator whether or not we are currently fetching value for infinite scroll */
  isFetching: PropTypes.bool,
  /** Indicator whether or not we are disabling the clear button */
  noClear: PropTypes.bool,
  /** Value to set for the dropdown to make controlled
   * auto sets if full dropdown item, if string -
   * will only set once the value exists in the dropdown options
   */
  value: PropTypes.oneOfType([
    PropTypes.shape(),
    PropTypes.string,
    PropTypes.number
  ]),
  /** Indicator for disabled status */
  disabled: PropTypes.bool
};

export const DropdownComponent = Dropdown;
export default withCollapse(withDropdown(Dropdown));
