import React from 'react';
import PropTypes from 'prop-types';
import AccordionChevron from '../../collapsibles/AccordionChevron';
import Row from '../../../layout/row/Row';

const LabelDropdownInputField = ({
  label, isOpen, placeholder, onClick,
}) => (
  <div role="presentation" className="py-2 clickable" onClick={onClick}>
    <Row spacing="sm" align="center">
      {label ? (<div className="text-blue overflow-ellipsis label-selected-item">{label}</div>) : (<div className="text-gray-dark overflow-ellipsis">{placeholder}</div>) }
      <AccordionChevron isOpen={isOpen} />
    </Row>
  </div>
);

LabelDropdownInputField.defaultProps = {
  label: null,
  placeholder: 'Select',
};
LabelDropdownInputField.propTypes = {
  label: PropTypes.node,
  placeholder: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};
export default LabelDropdownInputField;

