import React, { Component } from 'react';
/**
 * Create a wrapper compose to support Popover functionality
 * @param ComposedComponent
 * @returns {{Component}}
 */
const withPopover = ComposedComponent =>

  class extends Component {
    constructor(props) {
      super(props);
      this.state = { show: false };
    }

    closePopover = () => {
      this.setState({ show: false });
    };

    openPopover = (event) => {
      if (event) {
        event.stopPropagation();
      }

      this.setState({ show: true });
    };

    togglePopover = (event) => {
      if (event) {
        event.stopPropagation();
      }

      this.setState(prevState => ({ show: !prevState.show }));
    };
    render() {
      return (
        <ComposedComponent
          {...this.props}
          isPopoverOpen={this.state.show}
          closePopover={this.closePopover}
          openPopover={this.openPopover}
          togglePopover={this.togglePopover}
        />
      );
    }
  };

export default withPopover;
