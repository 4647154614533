import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import withHover from '../../../compose/withHover';

class CheckboxButton extends Component {
  onCheckboxClick = () => {
    const { value, name, id } = this.props;
    this.props.onChange(value, name, id);
  };

  render() {
    const {
      className, checked, children, onHoverEnter, onHoverLeave, borderRight, isHovering,
    } = this.props;
    return (
      <div
        className={cn(
          'flex-center', 'height-46', 'width-46', 'bg-gray-lightest',
        {
          'bg-white': checked,
          'br-1': borderRight,
          'border-gray-light': borderRight,
          'fill-blue': checked || isHovering,
          'text-blue': checked || isHovering,
          'text-gray-dark': !checked,
        }, className,
        )}
        onKeyDown={this.onRadioButtonClick}
        onClick={this.onCheckboxClick}
        onMouseEnter={onHoverEnter}
        onMouseLeave={onHoverLeave}
        disabled={false}
        role="button"
      >
        {children}
      </div>
    );
  }
}

CheckboxButton.defaultProps = {
  checked: false,
  value: undefined,
  name: undefined,
  id: undefined,
  className: '',
  borderRight: false,
};

CheckboxButton.propTypes = {
  /** Set the radioButton checked attribute to show it as current */
  checked: PropTypes.bool,
  /** children will be rendered inside the radio item */
  children: PropTypes.node.isRequired,
  /** The radioButton value to be passed when clicked */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.shape()]),
  /** value will be return when click on this item */
  onChange: PropTypes.func.isRequired,
  /** value will be return when click on this item */
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** identifier will be return when click on this element */
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** classes to be added to the component */
  className: PropTypes.string,
  /** An indicator if the checkbox has borderRight */
  borderRight: PropTypes.bool,
};

export default withHover(CheckboxButton);
