import React from "react";
import cn from "classnames";
import PropTypes from "prop-types";

import TextField from "@guestyci/foundation/TextField";
import { FixedRaisedButton } from "@guestyci/atomic-design/dist/v2/components";
import useTranslations from "@guestyci/localize/useTranslations";
import OutlineButton from "@guestyci/foundation/OutlineButton";
import Icon from "@guestyci/foundation/Icon";
import { ReactComponent as BtnLeft } from "@guestyci/foundation/icons/BtnLeft.svg";
import { Row } from "@guestyci/foundation/Layout";
import Tooltip from "@guestyci/foundation/Tooltip";

import { useExpirationDate } from "hooks/useExpirationDate";
import {
  convertCurrencySymbol,
  getFormattedTimeDifference,
  noop,
} from "utils/helpers";
import { EXPIRED_PAGE } from "constants/route.constants";
import {
  offerExpiresTranslation,
  paymentAmountTranslation,
} from "utils/translations";
import { invoicePageFooterIntlId } from "utils/intlIds";
import {
  ExpirationPart,
  ExpiresTag,
  ExpiresTitle,
  FooterContent,
  FooterTag,
  TotalPriceTag,
} from "./Footer.styled";

const Footer = ({
  currency,
  disabled,
  expiration,
  isMobile,
  onNextClick,
  onBackClick,
  totalPrice,
  activeStep,
  history,
  isProcessingInvoice,
  isPaymentRequired,
  isProcessingActive,
}) => {
  const liveExpiration = useExpirationDate(expiration, () =>
    history.push(EXPIRED_PAGE)
  );
  const translations = [
    offerExpiresTranslation,
    paymentAmountTranslation,
    {
      id: invoicePageFooterIntlId("submit_payment"),
      d: "Submit payment",
    },
    {
      id: invoicePageFooterIntlId("add_payment_info_button_label"),
      d: "Add payment info",
    },
    {
      id: invoicePageFooterIntlId("back_button_label"),
      d: "Back",
    },
    {
      id: invoicePageFooterIntlId("submit_button_label"),
      d: "Submit details",
    },
  ];

  const [
    offerExpiresCaption,
    totalPriceCaption,
    submitPaymentCaption,
    addPaymentInfoCaption,
    backButtonCaption,
    submitButtonCaption,
  ] = useTranslations(translations);

  const nextButtonCaption =
    activeStep === 0 ? addPaymentInfoCaption : submitPaymentCaption;

  return (
    <FooterTag className="d-flex flex-column w-100 position-fixed zindex-10">
      {isMobile && expiration && (
        <ExpirationPart className="d-flex align-items-center justify-content-center mb-1 w-100 font-size-lg">
          {offerExpiresCaption}
          &nbsp;
          {getFormattedTimeDifference(liveExpiration)}
        </ExpirationPart>
      )}
      <FooterContent className="d-flex align-items-center justify-content-between w-100">
        <OutlineButton onClick={onBackClick}>
          <Row align="center" spacing={2}>
            <Icon height={12} width={12} svg={BtnLeft} />
            <TextField>{backButtonCaption}</TextField>
          </Row>
        </OutlineButton>
        {!isMobile && expiration && (
          <div className="d-flex flex-column">
            <ExpiresTitle className="d-flex justify-content-start font-size-lg font-weight-bold">
              {offerExpiresCaption}
            </ExpiresTitle>
            <ExpiresTag className="d-flex justify-content-start font-size-21">
              {getFormattedTimeDifference(liveExpiration)}
            </ExpiresTag>
          </div>
        )}

        <TotalPriceTag className="d-flex align-items-baseline">
          <Row spacing={2}>
            <TextField variant="h4" color="dark" bold>
              {totalPriceCaption}
            </TextField>
            <TextField variant="h4" color="dark" bold>
              {convertCurrencySymbol(currency)}
              {totalPrice}
            </TextField>
          </Row>
        </TotalPriceTag>
        <Tooltip
          body={
            isPaymentRequired &&
            !isProcessingActive &&
            "Payments not available, contact your host"
          }
        >
          <FixedRaisedButton
            disabled={disabled || (isPaymentRequired && !isProcessingActive)}
            onClick={onNextClick}
            isLoading={isProcessingInvoice}
          >
            {isPaymentRequired ? nextButtonCaption : submitButtonCaption}
          </FixedRaisedButton>
        </Tooltip>
      </FooterContent>
    </FooterTag>
  );
};

Footer.propTypes = {
  color: PropTypes.string,
  text: PropTypes.string,
  onNextClick: PropTypes.func,
  onBackClick: PropTypes.func,
};

Footer.defaultProps = {
  onNextClick: noop,
  onBackClick: noop,
};

export default Footer;
