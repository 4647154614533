import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import FlatButton from '../buttons/FlatButton';

class Tab extends Component {
  onTabClick = () => {
    const { tabIndex, value } = this.props;
    this.props.onClick(tabIndex, value);
  };

  render() {
    const { title, className, disabled, isActive, value } = this.props;
    return (
      <FlatButton
        value={value}
        className={cn(className, 'text-uppercase', 'p-0', 'tab-item', {
          'text-blue': isActive
        })}
        onClick={this.onTabClick}
        disabled={disabled}
      >
        {title}
      </FlatButton>
    );
  }
}

Tab.defaultProps = {
  children: null,
  disabled: false,
  value: undefined,
  link: false
};

Tab.propTypes = {
  /** The tab title */
  title: PropTypes.node.isRequired,
  /** Set tab content to show when active */
  children: PropTypes.node,
  /** Indicator whether or not the tab is disabled */
  disabled: PropTypes.bool,
  /** The tab value to be passed when clicked */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape()
  ]),
  /** Set the tab as link to auto navigate to route (no external routes) */
  link: PropTypes.bool
};

export default Tab;
