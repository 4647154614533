import React from 'react';
import PropTypes from 'prop-types';

const CalendarTooltipPrice = ({ price }) => (
  <div className="font-weight-bold font-size-md">{price}</div>
);

CalendarTooltipPrice.propTypes = {
  price: PropTypes.string.isRequired,
};
export default CalendarTooltipPrice;
