import React from 'react';
import PropTypes from 'prop-types';

const mapMessageToBlock = (block) => {
  const blockMap = {
    m: 'Blocked manually',
    bd: 'Blocked by availability settings',
    abl: 'Blocked by annual booking limit',
    a: 'Blocked by multi unit',
    bw: 'Blocked by availability settings',
  };
  return blockMap[block];
};

const CalendarTooltipBlock = ({ block }) => (
  <div className="text-left font-weight-bold font-size-md text-white">{mapMessageToBlock(block)}</div>
);

CalendarTooltipBlock.propTypes = {
  block: PropTypes.string.isRequired,
};
export default CalendarTooltipBlock;
