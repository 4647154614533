import React, { Component } from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import cn from 'classnames';
import { uuid } from '../../../../utils/commonUtility';
import './checkbox.scss';
import Row from '../../../layout/row/Row';
import Icon from '../../icon/Icon';

class Checkbox extends Component {
  constructor(props) {
    super(props);
    this.state = { id: props.id || uuid() };
  }

  handleCheckboxClick = e => {
    const { onChange, value, name, id, disabled } = this.props;

    e.stopPropagation();
    if (onChange && !disabled) {
      this.props.onChange(value, name || id);
    }
  };

  render() {
    const {
      disabled,
      style,
      className,
      inputClassName,
      checked,
      inputStyle,
      name,
      value,
      children,
      checkedIcon,
      icon
    } = this.props;
    const { id } = this.state;
    return (
      <div
        className={`checkbox-container transition-all ${
          disabled ? 'disabled' : 'clickable'
        } ${className}`}
        style={style}
      >
        <input
          type="checkbox"
          id={id}
          onClick={this.handleCheckboxClick}
          onChange={noop}
          disabled={disabled}
          checked={checked}
          className={cn('checkbox', inputClassName)}
          style={inputStyle}
          name={name}
          value={value}
        />
        <label
          className={cn(
            'fill-muted text-muted ',
            { 'fill-hover-blue text-hover-blue': !disabled },
            { 'fill-blue text-blue': !disabled && checked }
          )}
          htmlFor={id}
        >
          <Row fullWidth align="center" spacing="sm">
            {icon && checked ? checkedIcon : icon}
            {!icon && (
              <Icon
                icon={checked ? 'BtnCheckboxOn' : 'BtnCheckboxOff'}
                noFill
                height={15}
                width={15}
              />
            )}
            {children && <div className="checkbox-children">{children}</div>}
          </Row>
        </label>
      </div>
    );
  }
}
Checkbox.defaultProps = {
  children: null,
  disabled: false,
  icon: null,
  checkedIcon: null,
  value: '',
  name: '',
  className: '',
  style: {},
  inputClassName: '',
  inputStyle: {},
  id: undefined,
  onChange: null,
  checked: false
};
Checkbox.propTypes = {
  /** Boolean flag used to set the checkbox as checked or not */
  checked: PropTypes.bool,
  /** Function to toggle the checkbox */
  onChange: PropTypes.func,
  /** The children for the checkbox (string or html element) */
  children: PropTypes.node,
  /** Boolean indicator to toggle disabled for the checkbox */
  disabled: PropTypes.bool,
  /** React icon element for the custom icon */
  icon: PropTypes.node,
  /** React icon element for the custom checked icon */
  checkedIcon: PropTypes.node,
  /** The value to pass on checkbox click */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape()
  ]),
  /** Checkbox name */
  name: PropTypes.string,
  /** Additional class to add to the checkbox */
  className: PropTypes.string,
  /** Additional style to add to the checkbox */
  style: PropTypes.shape(),
  /** Additional class to add to the checkbox input level */
  inputClassName: PropTypes.string,
  /** Additional style to add to the checkbox input level */
  inputStyle: PropTypes.shape(),
  /** The uuid for the checkbox */
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
export default Checkbox;
