import styled from 'styled-components';

export const CloseButton = styled.div`
  background: var(--white);
  border-radius: 21px 0 0 21px;
  height: 37px;
  position: absolute;
  right: 0;
  top: 30px;
  width: 73px;
  z-index: 1;
`;

export const GalleryBtn = styled.div`
  background: var(--white);
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 30px;
  top: 30px;
  z-index: 1;
`;

export const GalleryWrapper = styled.div`
  background: var(--white);
`;
