import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import currencySymbols from '../../../utils/currencySymbols';
import { CalendarDayStatus } from '../../../constants/enums';

const reservedBlocks = new Set(['b', 'r', 'o', 'sr']);

const hasActiveDates = activeDates =>
  !isEmpty(activeDates) && activeDates.start && activeDates.end;
/**
 * Turn date to yyyy-mm-dd format
 * @param d {Date}  Date object
 * @returns {string}
 */
export const toISODateString = d => {
  let month = d.getMonth() + 1;
  if (month < 10) month = `0${month}`;
  let date = d.getDate();
  if (date < 10) date = `0${date}`;
  return `${d.getFullYear()}-${month}-${date}`;
};

export const subtractDay = day => new Date(day.getTime() - 86400000);

export const addDay = day => new Date(day.getTime() + 86400000);

/**
 *  Returns reservation status and reservation id of the day before
 * @param calendar  {Object}  Calendar object
 * @param day {Date}  Day to check
 * @returns {status, id, block}
 * @private
 */
export const previousDayStatus = (calendar, day) => {
  const prevStatus = {};
  const prev = calendar[toISODateString(subtractDay(day))];

  if (prev) {
    prevStatus.status = prev.status;
    prevStatus.block = prev.blocks;
    if (prevStatus.status !== 'available' && prev.reservation) {
      prevStatus.id = prev.reservation._id;
    }
  }
  return { ...prevStatus };
};
/**
 *  Returns reservation status and reservation id of the next day
 * @param calendar  {Object}  Calendar object
 * @param day {Date}  Day to check
 * @returns {status, id, block}
 * @private
 */
export const nextDayStatus = (calendar, day) => {
  const nextStatus = {};
  const next = calendar[toISODateString(addDay(day))];
  if (next) {
    nextStatus.status = next.status;
    nextStatus.block = next.blocks;
    if (nextStatus.status !== 'available' && next.reservation) {
      nextStatus.id = next.reservation._id;
    }
  }
  return { ...nextStatus };
};

export const getGuestDetails = reservation => ({
  name: reservation.guest.name || reservation.guest.fullName || '',
  avatar: reservation.guest.avatar
});

/**
 * Get the reservation title from the reservation details
 * @param code  {String}  The reservation confirmation code
 * @param source  {String}  The order source
 * @param isOwner {Boolean} Indicator whether or not we are in owner blocked
 * @param isSR  {Boolean} Indicator whether or not we are in blocked by sr
 * @returns {string} - the title
 */
export const getReservationTitle = (code, source, isOwner, isSR) => {
  if (isOwner) {
    return 'Owner stay';
  }
  if (isSR) {
    return `Blocked by Res. ${code} (${source})`;
  }
  return `Res. ${code} (${source})`;
};

// Get currency symbol + add coma to big numbers
export const getCurrencySymbol = (currency, amount) => {
  const currencySymbol = Object.keys(currencySymbols).includes(currency)
    ? currencySymbols[currency].symbol +
      amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    : currency + amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return currencySymbol;
};

/**
 * Convert month to string
 * @param month {Number}  Month number
 * @returns {String} the month as string
 * @private
 */
export const getMonthName = month => {
  const months = {
    0: 'January',
    1: 'February',
    2: 'March',
    3: 'April',
    4: 'May',
    5: 'June',
    6: 'July',
    7: 'August',
    8: 'September',
    9: 'October',
    10: 'November',
    11: 'December'
  };
  return months[month];
};

/**
 * Get block type from the block object
 * @param blocks  {Object}  The blocks object
 * @returns {String} - the block type string
 * @private
 */
export const getBlockType = blocks => {
  const { bd, abl, sr, bw, o, m, a, b, r } = blocks;
  if (b) {
    return 'booked';
  }
  if (r) {
    return 'reserved';
  }
  if (bd || abl || sr || bw) {
    return 'autoblock';
  } else if (o) {
    return 'ownerblock';
  } else if (m || a) {
    return 'unavailable';
  }
  return null;
};

/**
 * Check if two day blocks are similar
 * @param block1
 * @param block2
 * @returns {boolean}
 */
export const isSameBlock = (block1, block2) => {
  if ((block1.r && block2.r) || (block1.b && block2.b)) {
    return true;
  }
  return getBlockType(block1) === getBlockType(block2);
};

/**
 * Check whether or not the block is reserved or not
 * @param blocks  {Object}  The blocks object
 * @returns {boolean}
 * @private
 */
export const isBlockReserved = blocks =>
  blocks &&
  Object.keys(blocks).some(key => blocks[key] && reservedBlocks.has(key));

export const isLastDay = (prevId, nowId) => prevId && nowId !== prevId;
export const isFirstDay = (prevId, nowId, nextId) =>
  nowId && nextId && nextId === nowId && nowId !== prevId;

export const checkReservationDetails = (calendar, day, activeDates) => {
  let cost;
  let type;
  let guest;
  let block;
  let startStyles;
  let endStyles;
  let lastDay;
  let dayStatus;
  let isOwner = false;
  let isSR = false;
  let isAvailable = false;
  let startBlockStyle;
  let endBlockStyle;
  let isReserved = false;
  let unavailableBlocks = [];
  let color = 'muted';
  let availableNotes;
  let link;
  let startOfBlock = false;
  let showPrice = false;
  let startOpacity = hasActiveDates(activeDates) ? 0.3 : 1;
  let endOpacity = hasActiveDates(activeDates) ? 0.3 : 1;
  const previousDay = previousDayStatus(calendar, day);

  const nextDay = nextDayStatus(calendar, day);
  const calObj = (calendar && calendar[toISODateString(day)]) || {};
  const { reservation } = calObj;
  const id = reservation ? reservation._id : null;

  if (!isEmpty(calObj)) {
    const { status } = calObj;
    dayStatus = status;
    isAvailable = dayStatus === CalendarDayStatus.Available;
    block = calObj.blocks;
    isReserved = isBlockReserved(block);
    if (!isReserved) {
      showPrice = true;
      cost = getCurrencySymbol(calObj.currency, calObj.price);
      if (calObj.note) {
        availableNotes = calObj.note;
      }
      unavailableBlocks = Object.keys(block).filter(
        key => !reservedBlocks.has(key) && block[key]
      );
    }

    lastDay = isLastDay(previousDay.id, id, nextDay.id);
    if (!isEmpty(reservation)) {
      guest = reservation.guest && getGuestDetails(reservation);
      link = id ? `https://app.guesty.com/reservations/${id}/guests` : null;
    }

    if (status === 'unavailable' && block) {
      startBlockStyle = getBlockType(block);
    }
    if (block && block.o) {
      isOwner = true;
    }
    if (block && block.sr) {
      isSR = true;
    }

    if (
      (status === 'unavailable' || status === 'available') &&
      previousDay.block
    ) {
      endBlockStyle = getBlockType(previousDay.block);
    }

    if (status !== 'available') {
      if (
        status !== previousDay.status ||
        !isSameBlock(previousDay.block, block)
      ) {
        startOfBlock = true;
        type = 'start';
      } else if (lastDay) {
        startOfBlock = true;
        type = 'start';
      } else {
        startOfBlock = false;
        type = 'middle';
      }
      startStyles = startBlockStyle
        ? `${type} ${startBlockStyle}`
        : `${type} ${status}`;
    }
    if (
      lastDay ||
      (previousDay.status !== 'available' &&
        (status !== previousDay.status ||
          !isSameBlock(previousDay.block, block)))
    ) {
      type = 'end';
      endStyles = !endBlockStyle
        ? `${type} ${previousDay.status}`
        : `${type} ${endBlockStyle}`;
    }
    if (
      hasActiveDates(activeDates) &&
      moment(day)
        .startOf('day')
        .diff(activeDates.start, 'days') >= 0 &&
      moment(activeDates.end).diff(day, 'days') >= 0
    ) {
      const activeFirstDay =
        moment(day)
          .startOf('day')
          .diff(activeDates.start, 'days') === 0;
      const activeLastDay =
        moment(activeDates.end)
          .endOf('day')
          .diff(day, 'days') === 0;

      if (activeLastDay) {
        startOpacity = 0.3;
        endOpacity = 1;
      } else if (activeFirstDay) {
        startOpacity = 1;
        endOpacity = 0.3;
      } else {
        startOpacity = 1;
        endOpacity = 1;
      }

      if (
        (!activeLastDay || activeFirstDay) &&
        status !== CalendarDayStatus.Available
      ) {
        color = 'black';
      }
    }

    if (status === 'ownerblock') {
      color = 'white';
    }
  }

  return {
    id,
    showPrice,
    cost,
    block,
    isReserved,
    unavailableBlocks,
    link,
    isAvailable,
    startOfBlock,
    availableNotes,
    guest,
    startOpacity,
    endOpacity,
    startStyles,
    endStyles,
    dayStatus,
    isOwner,
    isSR,
    color,
    reservation
  };
};

export const stringToDate = stringDate =>
  moment(stringDate).format('MM/D/YY, h:mm A');

/**
 * Get color from reservation status
 * @param status  {String}  Reservation Status
 * @returns {string}  - the color to display
 */
export const colorFromStatus = status => {
  switch (status) {
    case CalendarDayStatus.Reserved:
      return 'yellow';
    case CalendarDayStatus.Confirmed:
      return 'green';
    default:
      return 'white';
  }
};

/**
 * Check if the date is valid
 * @param d1  {Date}  Date to compare larger
 * @param d2  {Date}  Date to compare smaller
 * @returns {boolean}
 */
export const isInvalidPlannedDate = (d1, d2) =>
  new Date(d1).getTime() > new Date(d2).getTime();

/**
 * Set the stay date for the calendar day
 * @param date  {String}  The checkin/out date
 * @param time  {String}  The planned arrival/departure time
 * @return {string} Formatted string
 */
export const getStayDateTime = (date, time) => {
  let dateString = date;
  if (time) {
    dateString = `${date.split('T')[0]} ${time}`;
  }
  return moment(dateString, 'YYYY-MM-DD hh:mm A').format('MM/D/YY, h:mm A');
};
/**
 * Format the day Date object to calendar needs
 * @param day {Date}  The date in question
 * @param isShort {Boolean} Should show shorten mode
 * @returns {number | *}
 */
export const formatCalendarDay = (day, isShort = false) => {
  let date = day.getDate();
  const month = day.getMonth();
  if (date < 10) {
    date = `0${date}`;
  }
  if (!isShort && date === '01') {
    date = `${getMonthName(month)} ${date}`;
  }
  return date;
};

export const formatCalendarMonth = day => {
  let month = day.getMonth();
  if (month < 10) {
    month = `0${month}`;
  }
  return month;
};

export const makeTitleCase = (string = '') => {
  const name = string.toLowerCase().split(' ');
  for (let i = 0; i < name.length; i += 1) {
    name[i] = name[i].charAt(0).toUpperCase() + name[i].slice(1);
  }
  return name.join(' ');
};

export const getInitials = name =>
  name
    .match(/\b(\w)/g)
    .join('')
    .toUpperCase();
