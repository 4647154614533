import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { setEnv } from '@guestyci/atomic-design/dist/resource/';
import LocalizationProvider from '@guestyci/localize/LocalizationProvider';

import '@guestyci/foundation/style/styles.css';

import App from 'App';
import registerServiceWorker from './registerServiceWorker';
import configureStore, { history } from './store';

const store = configureStore();
const cluster = process.env.REACT_APP_CLUSTER;
setEnv(cluster);

const init = () => {
  ReactDOM.render(
    <Provider store={store}>
    <ConnectedRouter history={history}>
      <Router>
        <LocalizationProvider>
          <App />
        </LocalizationProvider>
      </Router>
    </ConnectedRouter>
  </Provider>,

    document.getElementById('root'),
  );
};

init();
registerServiceWorker();
