import React, { Component } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import moment from 'moment';
import { get, size } from 'lodash';
import './threadMessage.scss';
import ThreadMessageIcon from './ThreadMessageIcon';
import MessageBody from './ThreadMessageBody';
import ThreadMessageErrInfo from './ThreadMessageError';
import ThreadMessageInfo from './ThreadMessageInfo';

const fileTypes = ['Png', 'Pdf', 'Xls', 'Gif', 'Doc', 'Jpg', 'Mp4'];

class ThreadMessage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bgColor: this.getBgColor(props.sentBy, props.module),
      byGuest: props.sentBy === 'guest',
      byGSE: props.sentBy === 'guesty',
      byChannel:
        (!props.sentBy || props.sentBy === 'channel') &&
        !!get(props.module, 'action'),
      thumbnail: this.getThumbnail(props.sentBy),
      byUs: this.isByUs(),
      fullName: this.getFullName(props.sentBy),
      date: this.getDateStr(new Date(), new Date(props.createdAt)),
      moduleType: get(props.module, 'type')
    };
  }

  componentDidUpdate(prevProps) {
    const dataUpdated =
      prevProps.guest._id !== this.props.guest._id ||
      prevProps.id !== this.props.id;
    if (dataUpdated) {
      this.init();
    }
  }

  getThumbnail = sentBy => {
    const { isAutomatic, from, module, guest } = this.props;

    if (isAutomatic) return 'BtnAutomsg';
    else if (sentBy === 'guest') return get(guest, 'picture.thumbnail');
    else if (sentBy === 'host' || get(module, 'type') === 'note')
      return get(from, 'picture.thumbnail');
    else if (sentBy === 'guesty') return 'gseIcon';
    else if (get(module, 'action')) return 'airBnbIcon';
    return null;
  };

  getBgColor = (sentBy, module) => {
    if (module.type === 'note') return 'yellow-light';
    return 'gray-lightest';
  };

  getFullName = sentBy => {
    const { from, isAutomatic, module, guest } = this.props;

    if (sentBy === 'guest') return get(guest, 'fullName');
    else if (sentBy === 'guesty') return 'Guesty’s communication service';
    else if (sentBy === 'channel') return 'Airbnb';
    else if (sentBy === 'host' && !isAutomatic) return get(from, 'fullName');
    else if (!sentBy && get(module, 'action')) return 'airbnb';
    else if (isAutomatic) return 'Automated message';
    return get(from, 'fullName') || 'Not Available';
  };

  getDateStr = (now, then) => {
    const nowDate = now.getDate();
    const thenDate = then.getDate();
    const thenMoment = moment(then);
    let dayStr;

    if (thenDate === nowDate - 1) {
      dayStr = 'Yesterday';
    } else {
      dayStr = thenMoment.format('MMM D');
    }

    const hourStr = thenMoment.format('hh:mm A');

    return `${dayStr} at ${hourStr}`;
  };

  getIconType = item => {
    if (!item) return 'Jpg';
    const itemType = item.split('.')[1];
    return fileTypes.indexOf(itemType) > -1 ? itemType : 'Jpg';
  };

  prepareAttachments = attachments => {
    if (!size(attachments)) return [];
    return attachments.map(item => ({
      url: item.attachmentUrl,
      name: item.origFileName || item.contentName || 'unnamed file',
      icon: this.getIconType(item.origFileName)
    }));
  };

  init() {
    const { sentBy, module } = this.props;

    this.setState({
      bgColor: this.getBgColor(sentBy, module),
      byGuest: sentBy === 'guest',
      byGSE: sentBy === 'guesty',
      byChannel: (!sentBy || sentBy === 'channel') && !!get(module, 'action'),
      thumbnail: this.getThumbnail(sentBy),
      byUs: this.isByUs(),
      fullName: this.getFullName(sentBy),
      date: this.getDateStr(new Date(), new Date(this.props.createdAt)),
      moduleType: get(module, 'type')
    });
  }

  isByUs = () => {
    const { sentBy, module } = this.props;
    return (
      sentBy !== 'guest' && sentBy !== 'third party' && !get(module, 'action')
    );
  };

  openLink = url => {
    if (!url) return;
    const win = window.open(
      url.replace(
        /^(?:https?:\/\/)?(?:www\.)?/i,
        `${window.location.protocol}//`
      ),
      '_blank'
    );
    win.focus();
  };

  retrySendMessage = () => {
    const { id, onRetry } = this.props;
    onRetry(id);
  };

  deleteMessage = () => {
    const { id, onDelete } = this.props;
    onDelete(id);
  };

  render() {
    const {
      bgColor,
      byGuest,
      byGSE,
      byChannel,
      thumbnail,
      byUs,
      date,
      fullName,
      moduleType
    } = this.state;
    const {
      attachments,
      isAutomatic,
      className,
      body,
      error,
      deleteTxt,
      retryTxt,
      reasonTxt
    } = this.props;

    return (
      <div
        className={cn(
          'threadMessageBubble d-flex',
          { 'flex-row-reverse by-us': byUs },
          className
        )}
      >
        <ThreadMessageIcon
          avatarName={fullName}
          isAutoMessage={isAutomatic}
          thumbnail={thumbnail}
          byChannel={byChannel}
          byUs={byUs}
          byGSE={byGSE}
          byGuest={byGuest}
        />
        <div className="d-flex flex-column flex-1">
          <MessageBody
            errorMessage={error}
            body={body}
            attachments={this.prepareAttachments(attachments)}
            isAutoMessage={isAutomatic}
            byUs={byUs}
            bgColor={bgColor}
            openLink={this.openLink}
          />
          {error ? (
            <ThreadMessageErrInfo
              messageReason={reasonTxt}
              tooltip={
                <ThreadMessageInfo
                  fullName={fullName}
                  date={date}
                  byUs={byUs}
                  moduleType={moduleType}
                  fromErrorInfo
                />
              }
              retrySendMessage={this.retrySendMessage}
              onDelete={this.deleteMessage}
              retryTxt={retryTxt}
              deleteTxt={deleteTxt}
            />
          ) : (
            <ThreadMessageInfo
              fullName={fullName}
              date={date}
              byUs={byUs}
              moduleType={moduleType}
            />
          )}
        </div>
      </div>
    );
  }
}

ThreadMessage.defaultProps = {
  attachments: null,
  isAutomatic: false,
  guest: {}
};

ThreadMessage.propTypes = {
  /** The message body, can be a string or an html node. */
  body: PropTypes.node.isRequired,
  /** Array of objects that contain the imgs */
  attachments: PropTypes.arrayOf(PropTypes.shape()),
  /** Bool, flag for automatic messages */
  isAutomatic: PropTypes.bool,
  /** String, name if the module which the message sent  */
  module: PropTypes.shape().isRequired,
  /** Object, metadata about the message sender. */
  from: PropTypes.shape().isRequired,
  /** String, by who message was sent */
  sentBy: PropTypes.string.isRequired,
  /** String, time that message was sent */
  sentAt: PropTypes.string.isRequired,
  /** String, item id */
  id: PropTypes.string.isRequired,
  /** object, item id */
  guest: PropTypes.shape(),
  /** Bool, if the message is with error */
  error: PropTypes.bool.isRequired,
  /** function, retry send the specific message */
  onRetry: PropTypes.func.isRequired,
  /** function, delete the unsent message */
  onDelete: PropTypes.func.isRequired,
  /** string, txt for delete btn */
  deleteTxt: PropTypes.string.isRequired,
  /** string, txt for retry btn */
  retryTxt: PropTypes.string.isRequired,
  /** String, err reason */
  reasonTxt: PropTypes.string.isRequired
};

export default ThreadMessage;
