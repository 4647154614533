import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import BaseButton from './BaseButton';
import Icon from '../icon/Icon';
import { Theme } from '../../../constants/enums';
import Tooltip from '../tooltip/Tooltip';

const lightThemeClasses = 'fill-white fill-hover-blue-dark-theme';
const IconButton = ({
  icon, onClick, className, style, disabled, iconClassName, iconStyle, type,
  iconWidth, iconHeight, theme, name, id, tooltip, tooltipPlacement,
}) => (
  <Tooltip body={tooltip} placement={tooltipPlacement} id={id}>
    <BaseButton onClick={onClick} name={name} className={className} variant="icon" style={style} disabled={disabled} id={id}>
      <Icon
        className={cn(iconClassName, { 'fill-hover-blue': !disabled }, { 'fill-gray': disabled }, { [lightThemeClasses]: theme === Theme.Light })}
        style={iconStyle}
        icon={icon}
        width={iconWidth}
        height={iconHeight}
        color={type === 'hint' ? 'muted' : 'gray-dark'}
      />
    </BaseButton>
  </Tooltip>
);

IconButton.defaultProps = {
  className: '',
  style: {},
  disabled: false,
  iconWidth: 20,
  iconHeight: 20,
  iconClassName: '',
  iconStyle: {},
  theme: 'dark',
  id: undefined,
  name: undefined,
  tooltip: null,
  tooltipPlacement: 'top',
  type: 'icon',
};

IconButton.propTypes = {
  /** Button click callback function */
  onClick: PropTypes.func.isRequired,
  /** Button indicator whether disabled or not */
  disabled: PropTypes.bool,
  /** Button additional classes */
  className: PropTypes.string,
  /** Button additional styling */
  style: PropTypes.shape(),
  /** The specified Icon from the Guesty Icon bank */
  icon: PropTypes.string.isRequired,
  /** The specified Icon height */
  iconHeight: PropTypes.number,
  /** The specified Icon width */
  iconWidth: PropTypes.number,
  /** The specified Icon className */
  iconClassName: PropTypes.string,
  /** The specified Icon Style */
  iconStyle: PropTypes.shape(),
  /** Defined the theme for the button component */
  theme: PropTypes.oneOf(['dark', 'light']),
  /** Add name prop to the button itself to pass on click */
  name: PropTypes.string,
  /** Add oid prop to the button itself to pass on click */
  id: PropTypes.string,
  /* Tooltip body to show over icon button */
  tooltip: PropTypes.node,
  /* tooltip placement according to the Tooltip component */
  tooltipPlacement: PropTypes.string,
  /* Set the icon color when not selected to hint color or icon(regular) */
  type: PropTypes.oneOf(['icon', 'hint']),
};

export default IconButton;
