import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import withStyles from 'react-jss';
import Col from '../col/Col';
import Row from '../row/Row';

const styles = {
  children: {
    justifyContent: 'inherit',
    alignItems: 'inherit',
    width: '100%'
  }
};
const Section = ({
  className,
  style,
  children,
  col,
  width,
  dense,
  innerClassName,
  classes,
  flex,
  bgColor
}) => (
  <div
    className={cn(
      'p-6',
      width ? `width-${width}` : 'w-fill',
      { 'd-flex': flex },
      { [`bg-${bgColor}`]: bgColor },
      className
    )}
    style={style}
  >
    {col ? (
      <Col className={cn(classes.children, innerClassName)} dense={dense}>
        {children}
      </Col>
    ) : (
      <Row className={cn(classes.children, innerClassName)} dense={dense}>
        {children}
      </Row>
    )}
  </div>
);

Section.defaultProps = {
  bgColor: null,
  className: '',
  style: {},
  col: false,
  children: null,
  width: null,
  dense: false,
  innerClassName: '',
  flex: false
};

Section.propTypes = {
  /** Background color for section */
  bgColor: PropTypes.string,
  /** Additional class for top level */
  className: PropTypes.string,
  /** Indicator whether or not to render section as column */
  col: PropTypes.bool,
  /** Additional styling for root level */
  style: PropTypes.shape(),
  /** Width to specify to section - otherwise full width */
  width: PropTypes.number,
  /** Child components inside a section */
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  /** Indicator to set the children as dense (no inherited margins) */
  dense: PropTypes.bool,
  /** Additional class name for Col/Row child components */
  innerClassName: PropTypes.string,
  /** Boolean to set section a flex */
  flex: PropTypes.bool
};

export const SectionComponent = Section;
export default withStyles(styles)(Section);
