import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { intl, warningHandler, locales } from './i18nUtility';
import LocalizationContext from './LocalizationContext';
import memoize from '../../utils/memoize';

const lang = window.localStorage.getItem('lang') || 'en-US';

class LocalizationProvider extends Component {
  constructor(props) {
    super(props);
    this.state = { isLocalizationReady: false };
  }
  componentDidMount() {
    const { language } = this.props;
    this.init(language);
  }

  componentDidUpdate(prevProps) {
    const { language } = this.props;
    if (prevProps.language !== language) {
      this.init(language);
    }
  }

  getIsEnglish = memoize((language = '') => language.split('-')[0] === 'en');

  init(language) {
    const activeLanguage = language || lang;
    this.loadLocales(activeLanguage);
    moment.locale(activeLanguage);
  }

  loadLocales(language) {
    this.setState({ isLocalizationReady: false });
    intl
      .init({
        currentLocale: language,
        locales,
        warningHandler,
        fallbackLocale: 'en-US'
      })
      .then(() => {
        this.setState({ isLocalizationReady: true });
      });
  }

  render() {
    const { children, language } = this.props;
    const { isLocalizationReady } = this.state;
    const isEnglish = this.getIsEnglish(language);
    return (
      <LocalizationContext.Provider
        value={{ language: language || lang, isEnglish }}
      >
        {isLocalizationReady && children}
      </LocalizationContext.Provider>
    );
  }
}

LocalizationProvider.defaultProps = {
  language: undefined
};

LocalizationProvider.propTypes = {
  language: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]).isRequired
};
export default LocalizationProvider;
