import React, { useState } from 'react';
import cn from 'classnames';
import Checkbox from '@guestyci/foundation/Checkbox';
import TextField from '@guestyci/foundation/TextField';
import createStyles from '@guestyci/foundation/createStyles';

const useStyles = createStyles(theme => ({
  input: {
    '& > span': {
      paddingLeft: '1px !important',
    }
  }
}));

export default ({
  input,
  meta,
  ...props
}) => {
  const [checked, setChecked] = useState(!!input.value);
  const onChange = () => {
    input.onChange(!checked);
    setChecked(!checked);
  };
  const isInvalid = meta.error && meta.touched;
  const classes = useStyles();

  return (<Checkbox
    {...input}
    checked={checked}
    onChange={onChange}
    value={input.name}
    className={classes.input}
  >
    <TextField className={cn({ 'color-red': isInvalid })}>
      {props.children}
    </TextField>
  </Checkbox>);
};
