import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import './formGroup.scss';

const FormGroup = ({
  title, children, inline, className, style,
}) => (
  <div className={cn('form-group-wrapper d-flex-fill flex-column', className)} style={style}>
    {title &&
    <div className="text-muted font-size-md form-group-title mb-4">
      {title}
    </div>
    }
    <div className={cn('form-group-body', 'd-flex-fill', { 'flex-column': !inline })}>
      {children}
    </div>
  </div>
);
FormGroup.defaultProps = {
  inline: false,
  className: '',
  style: {},
};

FormGroup.propTypes = {
  /** Form Group inner children */
  children: PropTypes.node.isRequired,
  /** Form Group Title */
  title: PropTypes.string.isRequired,
  /** Indicator whether form should be column or row */
  inline: PropTypes.bool,
  /** Class  to be added to FormGroup */
  className: PropTypes.string,
  /** Style to add to FormGroup */
  style: PropTypes.shape(),
};

export default FormGroup;
