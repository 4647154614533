import React from 'react';
import PropTypes from 'prop-types';
import { StayStatus } from '../../../../constants/enums';
import Tooltip from '../../tooltip/Tooltip';
import BaseStayIndicator from './BaseStayIndicator';

const tooltips = {
  [StayStatus.Reserved]: 'Reserved',
  [StayStatus.Planned]: 'Upcoming stay',
  [StayStatus.Staying]: 'Currently staying',
  [StayStatus.Complete]: 'Checked out',
};

const StayIndicator = ({
  status,
  className,
  style,
  dark,
  id,
  fullWidth,
}) => (
  <Tooltip body={tooltips[status]} id={id} containerClassName="d-flex-fill">
    <BaseStayIndicator
      dark={dark}
      status={status}
      style={style}
      className={className}
      fullWdith={fullWidth}
    />
  </Tooltip>
);
StayIndicator.defaultProps = {
  status: StayStatus.Planned,
  className: '',
  style: {},
  dark: false,
  id: undefined,
  fullWidth: false,
};

StayIndicator.propTypes = {
  /** Stay status - Enum (StayStatus- [Reserved, Planned, Staying, Complete,]) */
  status: PropTypes.oneOf([
    StayStatus.Planned,
    StayStatus.Staying,
    StayStatus.Complete,
    StayStatus.Reserved]),
  /** Additional class to add to StayIndicator */
  className: PropTypes.string,
  /** Additional style to add to StayIndicator */
  style: PropTypes.shape(),
  /** Set theme to dark */
  dark: PropTypes.bool,
  /** Id for the stay indicator component */
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Set the stay indicator to fill parent component width */
  fullWidth: PropTypes.bool,
};

export default StayIndicator;
