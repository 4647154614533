import React, { Component } from 'react';

import { uuid } from '../../../utils/commonUtility';

function initUUIDsForChildren(children) {
  return children.map(() => uuid());
}

function initOptions(children, isMulti) {
  const uuids = initUUIDsForChildren(children);

  let checkedFlag = false;
  const childrenStructure = new Map(children.map((child, index) => {
    if (child.props.checked) {
      checkedFlag = true;
    }

    return [uuids[index], { child, checked: child.props.checked, uid: uuids[index] }];
  }));


  if (!isMulti && !checkedFlag && childrenStructure.size !== 0) {
    childrenStructure.set(
      childrenStructure.keys().next().value,
      { ...childrenStructure.values().next().value, checked: true },
    );
  }

  return childrenStructure;
}

function selectedKeyOnInit(data) {
  const children = Array.from(data.values());
  if (!children.length) {
    return null;
  }
  const child = children.find(val => val.checked);

  if (!child) {
    return children[0].uid;
  }

  return child.uid;
}

/**
 * When the user passes a child you'll get it as an
 * object and not as array of objects, this function fix the structure.
 * @param children
 * @returns {*[]}
 */
function checkChildrenSize(children) {
  return Array.isArray(children) ? children : [children];
}

/**
 * Create a wrapper compose to support multiple selection functionality
 * @param ComposedComponent
 * @returns {{Component}}
 */
const withSelectionGroup = ComposedComponent => class extends Component {
  static getDerivedStateFromProps(props, state) {
    let newState = null;
    if (
      props.children &&
      props.children.length > 0 &&
      state.options.size !== props.children.length
    ) {
      newState = { options: initOptions(checkChildrenSize(props.children), props.isMulti) };
    }
    return newState;
  }

  constructor(props) {
    super(props);
    const data = initOptions(checkChildrenSize(props.children), props.isMulti);
    this.state = {
      options: data,
      lastChecked: props.isMulti ? '' : selectedKeyOnInit(data),
    };
  }

  getNumberOfChecked = () => {
    const { options } = this.state;
    const checkedItems = [];
    if (this.props.isMulti) {
      options.forEach((value) => {
        if (value.checked) {
          checkedItems.push(value.child.props.value);
        }
      });
    }

    return checkedItems.length > 0 ? checkedItems : undefined;
  };

  handleOptionClick = (optionId) => {
    const { options, lastChecked } = this.state;
    const { isMulti = false } = this.props;

    if (!isMulti) {
      options.set(lastChecked, { ...options.get(lastChecked), checked: false });
      options.set(optionId, { ...options.get(optionId), checked: true });
    } else {
      const state = options.get(optionId).checked;
      options.set(optionId, { ...options.get(optionId), checked: !state });
    }

    this.setState({ options, lastChecked: optionId });
  };

  render() {
    return (
      <div>
        <ComposedComponent
          {...this.props}
          options={this.state.options}
          onOptionClicked={this.handleOptionClick}
          selectedCheckboxesWhenMulti={this.getNumberOfChecked()}
        />
      </div>
    );
  }
};

export default withSelectionGroup;
