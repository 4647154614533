import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import noop from 'lodash/noop';
import isEmpty from 'lodash/isEmpty';
import withCollapse from '../../collapsibles/withCollapse';
import DropdownInputField from '../DropdownInputField';
import withDropdown from '../withDropdown';
import IconButton from '../../buttons/IconButton';
import DropdownMenu from '../DropdownMenu';
import MenuItem from '../../menu/MenuItem';
import { AnchorDirectionType } from '../../../../constants/enums';

const IconDropdown = ({
  width, height, icon, options, toggleCollapse, isCollapsed, className, style, searchable,
  selectedItem, query, onSearchChange, clearSelectedItem, onSelectItem, persistSelection,
  isFetching, fetchNextPage, pageCount, currentPage, searchInputValue, clearQuery, iconClassName,
  menuClassName, anchorDirection, closeDropdown,
}) => (
  <div className={cn('relative flex-column', className)} style={style}>
    <IconButton onClick={toggleCollapse} icon={icon} iconWidth={width} iconHeight={height} className={iconClassName} iconClassName={cn('fill-hover-black', isCollapsed ? 'fill-light-gray' : 'fill-blue')} />
    <DropdownMenu
      anchorDirection={anchorDirection}
      onDismiss={closeDropdown}
      pageCount={pageCount}
      onLoadMore={fetchNextPage}
      currentPage={currentPage}
      isFetching={isFetching}
      className={cn(menuClassName, 'max-width-280', searchable ? 'min-width-280' : 'min-width-140')}
      isEmptyState={!options.length}
      isCollapsed={isCollapsed}
      topBar={searchable ?
        (<DropdownInputField
          noAction
          className="b-0 bb-1"
          inputClassName={cn({ 'text-blue': !isEmpty(selectedItem) })}
          onQueryChange={onSearchChange}
          query={query}
          value={searchInputValue}
          onClearQuery={clearQuery}
          onClearSelected={clearSelectedItem}
          searchable
        />) : null}
    >
      {options.map(item =>
        <MenuItem
          key={item.value}
          value={item}
          isSelected={persistSelection && item.value === selectedItem.value}
          onClick={onSelectItem}
        >{item.label}
        </MenuItem>)}
    </DropdownMenu>
  </div>
);

IconDropdown.defaultProps = {
  width: 20,
  height: 20,
  searchable: false,
  defaultValue: {},
  onSearchQueryChange: null,
  className: '',
  style: {},
  persistSelection: false,
  onLoadMore: noop,
  isFetching: false,
  pageCount: 0,
  value: undefined,
  iconClassName: '',
  menuClassName: '',
  anchorDirection: AnchorDirectionType.Start,
  onChange: noop,
  onSelect: noop,
};
IconDropdown.propTypes = {
  /** The dropdown options to show - Structure ({
    label: {Node} - the label to present,
    value: {String|number} - unique value to be sent to the server,
    name: {String} - optional value to send if label is component,
  })
   */
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  /** Indicator whether or not the dropdown is searchable */
  searchable: PropTypes.bool,
  /** Initial value to set to dropdown */
  defaultValue: PropTypes.shape(),
  /** Mandatory if searchable - callback for on input change */
  onSearchQueryChange: PropTypes.func,
  /** Callback function to be called on value changed - Deprecated */
  onSelect: PropTypes.func,
  /** Callback function to be called on value changed */
  onChange: PropTypes.func,
  /** Additional class for dropdown root */
  className: PropTypes.string,
  /** Additional style for dropdown root */
  style: PropTypes.shape(),
  /** Icon width */
  width: PropTypes.number,
  /** Icon height */
  height: PropTypes.number,
  /** The specified Icon from the Guesty Icon bank */
  icon: PropTypes.string.isRequired,
  /** If selectde item should me mark as selected */
  persistSelection: PropTypes.bool,
  /** Callback function to fetch more items - required for infinite scroll */
  onLoadMore: PropTypes.func,
  /** Total page count for pagination - required to allow infinite scroll */
  pageCount: PropTypes.number,
  /** Indicator whether or not we are currently fetching value for infinite scroll */
  isFetching: PropTypes.bool,
  /** Value to set for the dropdown to make controlled
   * auto sets if full dropdown item, if string -
   * will only set once the value exists in the dropdown options
   */
  value: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string, PropTypes.number]),
  /** Classname to override icon button styles */
  iconClassName: PropTypes.string,
  /** Classname to override menu styles - default sets margin from top to 10 */
  menuClassName: PropTypes.string,
  /** Set the direction of the dropdown anchor */
  anchorDirection: PropTypes.oneOfType([
    AnchorDirectionType.Center,
    AnchorDirectionType.Start,
    AnchorDirectionType.End,
  ]),
};

export const IconDropdownComponent = IconDropdown;
export default withCollapse(withDropdown(IconDropdown));
