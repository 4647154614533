import { put, takeLatest, call } from 'redux-saga/effects';
import { path } from 'ramda';
import Resource from '@guestyci/agni';

import { createToken, getClientSecret, handleCardSetup } from 'services/stripe';
import { geBaseUrl, QUOTES_URL, SAND_WORM_URL, WITHOUT_PAYMENT_URL } from 'constants/url.constants';
import { SUCCESS_UNPAID_BOOKING_PAGE, SUCCESS_PAID_BOOKING_PAGE } from 'constants/route.constants';
import { PAYMENT_METHOD_FAILED_DIALOG } from 'constants/dialogs.constants';

import ActionTypes from 'redux/actions';
import { parseErrorResponse } from 'utils/helpers';
import { takeOneAndBlock } from './quotes.saga';

const {
  FETCH_INVOICE_DATA_REQUEST,
  FETCH_LISTING_REQUEST,
  FETCH_QUOTES_FAILURE,
  FETCH_QUOTES_SUCCESS,
  PROCESS_INVOICE_FAILURE,
  PROCESS_INVOICE_REQUEST,
  PROCESS_INVOICE_INPROGRESS,
  SHOW_DIALOG,
} = ActionTypes;

const { api } = Resource.create({
  baseURL : geBaseUrl(),
})

export function* getInvoiceData(action) {
  try {
    const quotesId = path(['payload', 'quotesId'], action);
    const listingId = path(['payload', 'listingId'], action);

    const { data } = yield call(api.get, `${QUOTES_URL}/${quotesId}`);

    yield put({ type: FETCH_QUOTES_SUCCESS, payload: data });
    yield put({
      type: FETCH_LISTING_REQUEST,
      payload: { quotesId, listingId },
    });
  } catch (error) {
    yield put({ type: FETCH_QUOTES_FAILURE });
  }
}

export function* processBooking(action) {
  const {
    accountId,
    data: {
      reservation: { reservationId, listingId },
      guest: { firstName, lastName },
      providerType,
      inquiryData,
      isPaymentRequired,
      conversationId,
      isRequestToBook,
    },
    history,
    paymentProviderId,
    quotesId,
    stripe,
    billingDetails,
    reusePaymentMethod,
    submitForm,
  } = action.payload;

  const data = { reservationId };
  if (paymentProviderId) {
    data.paymentProviderId = paymentProviderId;
  }

  yield put({ type: PROCESS_INVOICE_INPROGRESS, payload: true });

  if (isPaymentRequired) {
    try {
      if (providerType === 'amaryllis') {
        const tokenizationResult = yield call(submitForm);
        if (!tokenizationResult?._id) {
          yield put({ type: PROCESS_INVOICE_INPROGRESS, payload: false });
          return;
        }
        data.paymentMethodId = tokenizationResult._id;
      } else if (providerType === 'stripe') {
        const {
          data: { useNPM: isStripeSCAon },
        } = yield call(api.get, `${QUOTES_URL}/${quotesId}`);

        if (isStripeSCAon) {
          const {
            data: { client_secret: clientSecret },
          } = yield call(getClientSecret, { accountId, listingId });
          const { err, setupIntent } = yield call(handleCardSetup, {
            clientSecret,
            stripe,
            billingDetails,
          });

          if (!err && setupIntent) {
            data.token = setupIntent.payment_method;
          } else {
          // Stripe.js: failed to handle card setup'
            yield put({ type: SHOW_DIALOG, name: PAYMENT_METHOD_FAILED_DIALOG });
            yield put({ type: PROCESS_INVOICE_INPROGRESS, payload: false });
            return;
          }
        } else {
          const { token: stripeToken } = yield call(createToken, {
            stripe,
            billingDetails,
          });
          if (stripeToken) {
            data.token = stripeToken.id;
          } else {
          // Stripe.js: failed to create token
            yield put({ type: SHOW_DIALOG, name: PAYMENT_METHOD_FAILED_DIALOG });
            yield put({ type: PROCESS_INVOICE_INPROGRESS, payload: false });
            return;
          }
        }
      }
    } catch (error) {
      console.warn(error);
      yield put({ type: SHOW_DIALOG, name: PAYMENT_METHOD_FAILED_DIALOG });
      yield put({ type: PROCESS_INVOICE_INPROGRESS, payload: false });
      return;
    }
  }

  if (data.token) {
    data.reuse = reusePaymentMethod
  }

  try {
    if (inquiryData) {
      const { inquiryId, ratePlanId, bookerId, source } = inquiryData;

      if (isPaymentRequired) {
        yield call(api.put, `${QUOTES_URL}/${quotesId}${SAND_WORM_URL}`, {
          ...data,
          accountId,
          inquiryId,
          ratePlanId,
          bookerId,
          source,
          guest: { firstName, lastName },
          isRequestToBook
        });
      } else {
        yield call(api.put, `${QUOTES_URL}/${quotesId}${WITHOUT_PAYMENT_URL}`, {
          reservationId,
          accountId,
          inquiryId,
          ratePlanId,
          bookerId,
          source,
          conversationId,
          guest: { firstName, lastName },
          isRequestToBook,
        });
      }

    } else {
      yield call(api.put, `${QUOTES_URL}/${quotesId}`, data);
    }

    // we need to pass history via action cause push from saga womnt trigger rerendee
    // due to wrapped into connect route component, see:
    // https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/guides/redux.md
    yield history.push(isPaymentRequired ? SUCCESS_PAID_BOOKING_PAGE : SUCCESS_UNPAID_BOOKING_PAGE);
  } catch (error) {
    console.warn(error);
    yield put({ type: PROCESS_INVOICE_FAILURE, payload: parseErrorResponse(error) });
  }
}

export default [
  takeOneAndBlock(PROCESS_INVOICE_REQUEST, processBooking),
  takeOneAndBlock(FETCH_INVOICE_DATA_REQUEST, getInvoiceData),
];
