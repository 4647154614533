import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import CSSTransition from '../transiton/CSSTransition';

class Portal extends Component {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
  }

  componentDidMount() {
    document.body.appendChild(this.el);
  }

  componentWillUnmount() {
    document.body.removeChild(this.el);
  }

  render() {
    const {
      children, withState, isOpen, animation, className,
    } = this.props;
    if (!withState) {
      return ReactDOM.createPortal(children, this.el);
    }
    return ReactDOM.createPortal(
      <CSSTransition animation={animation} className={className}>
        {isOpen && children}
      </CSSTransition>
      , this.el,
    );
  }
}

Portal.defaultProps = {
  withState: false,
  isOpen: false,
  animation: null,
  className: '',
};
Portal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  withState: PropTypes.bool,
  isOpen: PropTypes.bool,
  animation: PropTypes.string,
  className: PropTypes.string,
};

export default Portal;

