import cn from 'classnames';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Editor, EditorState, RichUtils } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import { stateToHTML } from 'draft-js-export-html';

import 'draft-js/dist/Draft.css';

import InputLabel from '../InputLabel';
import RichTextEditorToolbar from './RichTextEditorToolbar';
import withFocus from '../../../compose/withFocus';
import { customStyleMap } from './richTextEditorConf';
import { Theme } from '../../../../constants/enums';

import './richTextEditor.scss';

class RichTextEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editorState: props.value
        ? this.htmlToEditorState(props.value)
        : EditorState.createEmpty(),
    };

    this.draftRef = null;
  }

  componentDidMount() {
    if (this.props.autoFocus) {
      this.draftjsRef.focus();
    }
  }

  componentDidUpdate(prevProps) {
    const { value: newValue } = this.props;
    const { value: oldValue } = prevProps;

    if (
      oldValue !== newValue &&
      newValue !== this.editorStateToHtml(this.state.editorState)
    ) {
      this.setEditorState(this.htmlToEditorState(newValue));
    }
  }

  onChange = (editorState) => {
    this.setState({ editorState });
    this.props.onChange(this.editorStateToHtml(editorState));
  };

  setEditorState = (editorState) => {
    this.setState({ editorState });
  }

  setDraftRef = (ref) => {
    this.draftjsRef = ref;
  };

  handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.onChange(newState);
      return true;
    }

    return false;
  };

  toggleInlineType = (inlineType) => {
    const { editorState } = this.state;
    this.onChange(RichUtils.toggleInlineStyle(editorState, inlineType));
  };

  toggleBlockType = (blockType) => {
    const { editorState } = this.state;
    this.onChange(RichUtils.toggleBlockType(editorState, blockType));
  };

  htmlToEditorState = html => EditorState.createWithContent(stateFromHTML(html));

  editorStateToHtml = (state) => {
    const contentState = state.getCurrentContent();
    if (!contentState.hasText()) {
      return '';
    }
    const html = stateToHTML(contentState, {
      defaultBlockTag: 'div',
      inlineStyles: customStyleMap,
    });
    return html.replace(/[\n\r]/g, '');
  };

  render() {
    const {
      label,
      disabled,
      containerStyle,
      placeholder,
      tooltip,
      showToolbar,
      maxHeight,
      onBlur,
      onFocus,
      theme,
      className,
    } = this.props;

    const { editorState } = this.state;

    return (
      <div className="d-flex-fill flex-column input-wrapper">
        {label && <InputLabel label={label} tooltip={tooltip} />}
        <div
          className={cn(
            'd-flex-fill flex-column b-1',
            theme === Theme.Light
              ? 'border-gray-lightest bg-white'
              : 'border-gray-light bg-gray-lightest',
            'flex-start-center pos-relative',
            { 'bg-gray-lighter': disabled },
            className,
          )}
          style={containerStyle}
        >
          {showToolbar && (
            <RichTextEditorToolbar
              key={0}
              theme={theme}
              toggleInlineType={this.toggleInlineType}
              toggleBlockType={this.toggleBlockType}
              editorState={editorState}
            />
          )}
          <div
            className={cn(
              'd-flex-fill',
              'px-6',
              'py-3',
              { [`max-height-${maxHeight}`]: maxHeight },
              theme === Theme.Light ? 'bg-white' : 'bg-gray-lightest',
              'overflow-y-auto',
            )}
          >
            <Editor
              ref={this.setDraftRef}
              placeholder={placeholder}
              editorState={editorState}
              onChange={this.onChange}
              readOnly={disabled}
              handleKeyCommand={this.handleKeyCommand}
              customStyleMap={customStyleMap}
              onFocus={onFocus}
              onBlur={onBlur}
            />
          </div>
        </div>
      </div>
    );
  }
}

RichTextEditor.defaultProps = {
  value: undefined,
  onChange: noop,
  placeholder: '',
  label: null,
  tooltip: null,
  disabled: false,
  showToolbar: true,
  className: '',
  maxHeight: 250,
  theme: Theme.Dark,
  autoFocus: false,
};

RichTextEditor.propTypes = {
  /** Initial text value */
  value: PropTypes.string,
  /** On change trigger function to update on text change */
  onChange: PropTypes.func,
  /** TextArea Placeholder */
  placeholder: PropTypes.string,
  /** A label to show on top of the input */
  label: PropTypes.string,
  /** Add tooltip help icon and message - tooltip - String help message */
  tooltip: PropTypes.string,
  /** Indicator for disabled status */
  disabled: PropTypes.bool,
  /** Indicator whether or not to show the toolbar */
  showToolbar: PropTypes.bool,
  /** Classes to add */
  className: PropTypes.string,
  /** Max height for component */
  maxHeight: PropTypes.number,
  /** Set theme for component */
  theme: PropTypes.oneOf([Theme.Light, Theme.Dark]),
  /** Boolean to define if should be auto focused */
  autoFocus: PropTypes.bool,
};

export const RichTextEditorComponent = RichTextEditor;
export default withFocus(RichTextEditor);
