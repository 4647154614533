import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import moment from 'moment';
import MenuAutoPosition from '../../menu/MenuAutoPosition';
import Menu from '../../menu/Menu';
import ItemSelector from './ItemSelector';
import withCollapse from '../../collapsibles/withCollapse';
import TimePickerInputField from './TimePickerInputField';
import ClickOutsideListener from '../../../utils/clickOutListener/ClickOutsideListener';

const paddNumber = num => {
  if (num < 10) {
    return `0${num}`;
  }
  return num;
};

const HOURS12 = [...Array(12).keys()].map(i => i + 1);
const MINUTES = [...Array(60).keys()];
const AMPM = ['AM', 'PM'];

class TimePicker extends PureComponent {
  constructor(props) {
    super(props);
    const initialValue = props.value || props.defaultValue;
    const { hours, minutes, ampm } = this.getTimeObj(initialValue);
    this.state = {
      minutes,
      hours,
      ampm
    };
  }

  componentDidMount() {
    if (this.props.defaultValue || this.props.value) {
      this.onChange(this.state.hours, this.state.minutes, this.state.ampm);
    }
  }

  onChange = (hours = HOURS12[11], minutes = MINUTES[0], ampm = AMPM[0]) => {
    const { id, name, onChange } = this.props;
    this.setState({ hours, minutes, ampm }, () => {
      onChange(
        this.getTimeStr(this.state.hours, this.state.minutes, this.state.ampm),
        id || name
      );
    });
  };

  onHoursChange = hour => {
    const hours = HOURS12[hour];
    this.onChange(
      hours,
      this.state.minutes || MINUTES[0],
      this.state.ampm || AMPM[0]
    );
  };

  onMinutesChange = minute => {
    const minutes = MINUTES[minute];
    this.onChange(
      this.state.hours || HOURS12[11],
      minutes,
      this.state.ampm || AMPM[0]
    );
  };

  onAmpmChange = ampm => {
    const _ampm = AMPM[ampm];
    this.onChange(
      this.state.hours || HOURS12[11],
      this.state.minutes || MINUTES[0],
      _ampm
    );
  };
  getHours = time => {
    const timeHour = time.hour();
    if (timeHour === 12 || (timeHour === 0 && time.format('A') === 'AM')) {
      return 12;
    }
    return time.hour() % 12;
  };

  getTimeObj = timeStr => {
    if (!timeStr) {
      return {};
    }
    let time = moment(timeStr, 'hh:mm A');
    if (!time.isValid()) {
      time = moment(`01-01-1970 ${timeStr}`, 'MM-DD-YYYY hh:mm A');
    }
    return {
      hours: this.getHours(time),
      minutes: time.minute(),
      ampm: time.format('A')
    };
  };

  getTimeStr = (h, m, ampm) => {
    if (h === undefined || h === null) {
      return '';
    }
    return `${paddNumber(h)}:${paddNumber(m)} ${ampm}`;
  };

  clearTime = () => {
    this.setState({ hours: null, minutes: null, ampm: null });
    this.onChange(null, null, null);
  };

  reset = () => {
    const { defaultValue } = this.props;
    const time = this.getTimeObj(defaultValue);
    this.setState({ ...time });
    this.onChange(time.hours, time.minutes, time.ampm);
  };

  render() {
    const {
      disabled,
      placeholder,
      className,
      style,
      resetable,
      toggleCollapse,
      isCollapsed,
      defaultValue,
      minutesStep,
      name,
      noClear,
      closeCollapse,
      id
    } = this.props;
    const { ampm, hours, minutes } = this.state;
    const hoursIndex = hours ? hours - 1 : 11;
    return (
      <div
        className={cn('flex-end relative d-flex-fill', className)}
        style={style}
      >
        <TimePickerInputField
          onClick={toggleCollapse}
          onClear={this.clearTime}
          onReset={this.reset}
          name={name}
          id={id}
          isOpen={!isCollapsed}
          resetable={resetable}
          defaultValue={defaultValue}
          disabled={disabled}
          placeholder={placeholder}
          value={this.getTimeStr(hours, minutes, ampm)}
          noClear={noClear || resetable}
        />
        <MenuAutoPosition trigger={!isCollapsed}>
          <ClickOutsideListener onClickOutside={closeCollapse}>
            <Menu
              className="bg-white p-3 flex-center"
              collapse={isCollapsed}
              row
              dense
            >
              <ItemSelector
                index={hoursIndex}
                values={HOURS12}
                onChange={this.onHoursChange}
                renderValue={paddNumber}
              />
              <ItemSelector
                index={minutes}
                values={MINUTES}
                step={minutesStep}
                onChange={this.onMinutesChange}
                renderValue={paddNumber}
              />
              <ItemSelector
                index={ampm === 'PM' ? 1 : 0}
                values={['AM', 'PM']}
                onChange={this.onAmpmChange}
              />
            </Menu>
          </ClickOutsideListener>
        </MenuAutoPosition>
      </div>
    );
  }
}

TimePicker.defaultProps = {
  value: '',
  disabled: false,
  defaultValue: '',
  placeholder: 'Select',
  className: '',
  style: {},
  resetable: false,
  minutesStep: 1,
  showError: false,
  noClear: false,
  name: '',
  id: ''
};

TimePicker.propTypes = {
  /** Controlled value to be set - format 'hh:mm A' */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Indicator whether or not the input is disabled */
  disabled: PropTypes.bool,
  /** CallBack for on value change */
  onChange: PropTypes.func.isRequired,
  /** Default value for input (also resets to value) - format 'hh:mm A' */
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Placeholder for input level */
  placeholder: PropTypes.string,
  /** Additional class for root level */
  className: PropTypes.string,
  /** additional style for root level */
  style: PropTypes.shape(),
  /** Show the reset option for the input (resets to defaultValue) */
  resetable: PropTypes.bool,
  /** define the increacment/decreacment step on the minutes selector */
  minutesStep: PropTypes.number,
  /** Show error icon */
  showError: PropTypes.bool,
  /** Indicator whether or not we are disabling the clear button */
  noClear: PropTypes.bool,
  /** Input level name */
  name: PropTypes.string,
  /** Input level id */
  id: PropTypes.string
};
export const TimePickerComponent = TimePicker;
export default withCollapse(TimePicker);
