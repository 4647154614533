import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import IconButton from '../buttons/IconButton';
import CSSTransition from '../../utils/transiton/CSSTransition';
import Icon from '../icon/Icon';
import Checkbox from '../selectionControls/checkbox/Checkbox';

class InputStarDeleteActionAddOn extends PureComponent {
  handleStarClick = () => {
    this.props.onStar(this.props.id || this.props.name);
  };

  handleTrashClick = () => {
    this.props.onTrash(this.props.id || this.props.name);
  };
  render() {
    const { show, isPrimary, disabled } = this.props;
    return (
      <React.Fragment>
        <CSSTransition animation="fade" className="absolute right-0 h-fill flex-center bg-gray-lightest">
          {(show || isPrimary) &&
            <div className="px-4 flex-center">
              {!isPrimary && <IconButton
                iconHeight={18}
                type="hint"
                iconWidth={15}
                disabled={disabled}
                onClick={this.handleTrashClick}
                icon="BtnTrash"
                className=" p-0 input-addon-trash-action"
              />}
              <Checkbox
                className="input-addon-star-action pl-4"
                checked={isPrimary}
                onChange={this.handleStarClick}
                disabled={disabled}
                inputClassName="mr-0"
                icon={<Icon width={15} height={14} className="fill-hover-blue" color="muted" icon="BtnStarOff" />}
                checkedIcon={<Icon color="blue" width={15} height={14} icon="BtnStarOn" />}
              />
            </div>}
        </CSSTransition>
      </React.Fragment>
    );
  }
}

InputStarDeleteActionAddOn.defaultProps = {
  show: false,
  isPrimary: false,
  onTrash: noop,
  onStar: noop,
  disabled: false,
  id: undefined,
  name: '',
};

InputStarDeleteActionAddOn.propTypes = {
  show: PropTypes.bool,
  isPrimary: PropTypes.bool,
  onTrash: PropTypes.func,
  onStar: PropTypes.func,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
export default InputStarDeleteActionAddOn;
