import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'react-jss';
import cn from 'classnames';
import noop from 'lodash/noop';
import isNumber from 'lodash/isNumber';
import ActiveTabBottomBorder from './ActiveTabBottomBorder';
import ExpandingSearchBar from '../searchBar/ExpandingSearchBar';
import Fade from '../../utils/transiton/Fade';
import { isEmpty } from '../../../utils/commonUtility';
import memoize from '../../../utils/memoize';

const styles = {
  expandableSearchBar: {
    top: 2
  }
};
class Tabs extends Component {
  constructor(props) {
    super(props);
    this.tabsRef = React.createRef();
    this.state = {
      activeTabIndex: !isEmpty(props.initialValue)
        ? props.initialValue
        : this.initActiveTabIndex(props.children),
      borderWidth: 0,
      position: null,
      showTabs: true
    };
  }

  componentDidMount() {
    const { initialValue, value } = this.props;
    if (!isEmpty(initialValue) || !isEmpty(value)) {
      this.setControlledPosition(initialValue || value);
    } else {
      setTimeout(() => this.setBorderWidth(), 300);
    }
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    if (!isEmpty(value) && value !== prevProps.value) {
      this.setControlledPosition(value);
    }
  }
  setControlledPosition = memoize(value => {
    const tabs = this.tabsRef.current.getElementsByClassName('tab-item');
    const index = Array.from(tabs).findIndex(
      tab => tab.value === String(value)
    );
    if (index > -1) {
      this.setPosition(index);
    }
  });

  setPosition = activeTabIndex => {
    this.setState({ activeTabIndex }, () => {
      this.setBorderWidth();
    });
  };
  setBorderWidth = () => {
    const tabs = this.tabsRef.current.getElementsByClassName('tab-item');
    const activeChild = tabs[this.state.activeTabIndex];
    this.setState({
      borderWidth: activeChild.offsetWidth,
      position: activeChild.offsetLeft
    });
  };

  initActiveTabIndex = children => {
    let activeIndex = 0;
    React.Children.map(children, (child, index) => {
      if (child.props.active && !child.props.disabled) {
        activeIndex = index;
      }
    });
    return activeIndex;
  };

  changeTab = (activeTabIndex, value = {}) => {
    this.setPosition(activeTabIndex);
    if (this.props.onChange || this.props.onChangeTab) {
      const tabProps = { value, activeTabIndex };
      if (this.props.onChange) {
        this.props.onChange(tabProps);
      }
      if (this.props.onChangeTab) {
        this.props.onChangeTab(tabProps);
      }
    }
  };

  toggleTabs = isSearchOpen => {
    this.setState({ showTabs: !isSearchOpen });
  };

  isActiveIndex = index => {
    const { activeTabIndex } = this.state;
    return index === activeTabIndex;
  };

  render() {
    const {
      children,
      className,
      style,
      onSearchSubmit,
      withSearch,
      noBorderBottom,
      noBorder,
      classes,
      activeTabContentClassName,
      fullWidth
    } = this.props;
    const { activeTabIndex, borderWidth, position, showTabs } = this.state;
    const activeTabContent =
      children[activeTabIndex] && children[activeTabIndex].props.children;
    const borderClass = !(noBorder || noBorderBottom)
      ? 'bb-2 border-gray-light'
      : '';
    return (
      <div
        className={cn('d-flex-fill', 'flex-column', className)}
        ref={this.tabsRef}
        style={style}
      >
        <div className={cn('d-flex', withSearch && borderClass)}>
          <Fade
            mountOnEnter
            show={showTabs}
            className={cn('relative', { 'w-fill': fullWidth })}
          >
            <div
              className={cn(
                'tabs-wrapper d-flex text-uppercase min-height-50 h-fill text-gray-dark',
                !withSearch && borderClass
              )}
            >
              {React.Children.map(children, (child, index) =>
                React.cloneElement(child, {
                  onClick: this.changeTab,
                  className: index !== children.length - 1 ? 'mr-5' : '',
                  tabIndex: index,
                  isActive: this.isActiveIndex(index)
                })
              )}
            </div>
            {!!(isNumber(activeTabIndex) && isNumber(position)) && (
              <ActiveTabBottomBorder
                width={borderWidth}
                style={withSearch ? { bottom: -2 } : null}
                position={position}
              />
            )}
          </Fade>
          {withSearch && (
            <ExpandingSearchBar
              onSubmit={onSearchSubmit}
              containerClassName={classes.expandableSearchBar}
              onToggleSearch={this.toggleTabs}
            />
          )}
        </div>
        {activeTabContent && (
          <div className={cn('mt-6', activeTabContentClassName)}>
            {activeTabContent}
          </div>
        )}
      </div>
    );
  }
}

Tabs.defaultProps = {
  onChange: noop,
  onChangeTab: noop,
  className: '',
  style: {},
  withSearch: false,
  onSearchSubmit: noop,
  noBorder: false,
  noBorderBottom: false,
  initialValue: null,
  value: null,
  activeTabContentClassName: '',
  fullWidth: false
};

Tabs.propTypes = {
  /** The tabs children to render */
  children: PropTypes.node.isRequired,
  /** Callback for on tab change payload:{ activeTabIndex, value (as passed in tab) } */
  onChange: PropTypes.func,
  /** Deprecated - user onChange in stead, Callback for tab change */
  onChangeTab: PropTypes.func,
  /** Additional class to tabs root */
  className: PropTypes.string,
  /** Additional style to tabs root */
  style: PropTypes.shape(),
  /** Indicator whether or not we want a search option on the tabs */
  withSearch: PropTypes.bool,
  /** Callback function to apply on search submit */
  onSearchSubmit: PropTypes.func,
  /** Indicator whether or not to show the border for the tabs - DEPRECATED */
  noBorder: PropTypes.bool,
  /** Indicator whether or not to show the bottom line for the tabs */
  noBorderBottom: PropTypes.bool,
  /** initial tab index value to be active */
  initialValue: PropTypes.number,
  /** Controlled value for tab index - setting this will make component controlled */
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** Additional class to wrapper of children */
  activeTabContentClassName: PropTypes.string,
  /** Boolean indicator for parent full width */
  fullWidth: PropTypes.bool
};

export const TabsComponent = Tabs;
export default withStyles(styles)(Tabs);
