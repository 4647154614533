import { invoicePageInvoiceIntlId } from 'utils/intlIds';

export const translations = [
  {
    id: invoicePageInvoiceIntlId('pay_info_title'),
    d: 'Add Payment info',
  },
  {
    id: invoicePageInvoiceIntlId('toggle_label'),
    d: 'Same as guest',
  },
  {
    id: invoicePageInvoiceIntlId('cc_details_section_title'),
    d: 'Payment details',
  },
  {
    id: invoicePageInvoiceIntlId('cc_cardholder_name_section_title'),
    d: 'Card holder name',
  },
  {
    id: invoicePageInvoiceIntlId('cc_address_section_title'),
    d: 'Billing address',
  },
];
