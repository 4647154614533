import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import withStyles from 'react-jss';
import { isFunction } from 'lodash';
import Avatar from '../avatar/Avatar';
import TextField from '../../textField/TextField';
import Row from '../../../layout/row/Row';
import Col from '../../../layout/col/Col';

const styles = {
  text: {
    width: ({ size }) => `calc(100% - ${size === 'sm' ? 30 : 45}px)`,
  },
};
class Listing extends Component {
  handleListingClick = () => {
    if (isFunction(this.props.onClick) && !this.props.disabled) {
      this.props.onClick(this.props.name);
    }
  };

  render() {
    const {
      img, title = '', subTitle, size, className, style, onClick, disabled, isMultiUnit, classes,
    } = this.props;

    return (
      <div
        role="presentation"
        className={cn('flex-start-center w-inherit transition-color', className, { 'text-hover-blue clickable': !disabled && isFunction(onClick) }, { 'disabled-light': disabled })}
        style={style}
        onClick={this.handleListingClick}
      >
        <Row align="center" fullWidth justify="start" spacing="sm">
          <Avatar
            listing
            bordered={isMultiUnit}
            src={img}
            size={size}
          />
          <Col dense className={classes.text}>
            <TextField size={size === 'md' ? 'lg' : 'md'} className="overflow-ellipsis">{title}</TextField>
            {subTitle && <TextField size={size} className="listing-subtitle  overflow-ellipsis">{subTitle}</TextField>}
          </Col>
        </Row>
      </div>
    );
  }
}

Listing.defaultProps = {
  img: '',
  subTitle: '',
  size: 'md',
  className: '',
  style: {},
  onClick: null,
  disabled: false,
  isMultiUnit: false,
};

Listing.propTypes = {
  /** Image url for listing */
  img: PropTypes.string,
  /** Listing full name */
  title: PropTypes.string.isRequired,
  /** Listing address */
  subTitle: PropTypes.string,
  /** avatar icon size */
  size: PropTypes.oneOf(['sm', 'md']),
  /** Additional class name for listing */
  className: PropTypes.string,
  /** Additional style for listing */
  style: PropTypes.shape(),
  /** CB function for listing click */
  onClick: PropTypes.func,
  /** Indicator whether or not the listing component is disabled */
  disabled: PropTypes.bool,
  /** Indicator whether or not we are in multi unit */
  isMultiUnit: PropTypes.bool,
};

export default withStyles(styles)(Listing);
