import { Resource } from '../../resource/';
import { API_REQUEST, apiError, apiSuccess } from '../actions/api';

// eslint-disable-next-line import/prefer-default-export
export const apiMiddleware = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type.includes(API_REQUEST)) {
    const { url, method, feature } = action.meta;
    const { payload: body } = action;
    const resource = new Resource();

    switch (method) {
      case 'GET':
        resource.get(url, body)
          .then(response => response.data)
          .then(response => dispatch(apiSuccess({ response, feature })))
          .catch((error, response) => dispatch(apiError({ error, response, feature })));
        break;
      case 'DELETE':
        resource.delete(url)
          .then(response => response.data)
          .then(response => dispatch(apiSuccess({ response, feature })))
          .catch((error, response) => dispatch(apiError({ error, response, feature })));
        break;
      case 'POST':
        resource.post(url, body)
          .then(response => response.data)
          .then(response => dispatch(apiSuccess({ response, feature })))
          .catch((error, response) => dispatch(apiError({ error, response, feature })));
        break;
      case 'PUT':
        resource.put(url, body)
          .then(response => response.data)
          .then(response => dispatch(apiSuccess({ response, feature })))
          .catch((error, response) => dispatch(apiError({ error, response, feature })));
        break;
      case 'PATCH':
        resource.patch(url, body)
          .then(response => response.data)
          .then(response => dispatch(apiSuccess({ response, feature })))
          .catch((error, response) => dispatch(apiError({ error, response, feature })));
        break;
      default:
        throw new Error('apiMiddleware - method is mandatory');
    }
  }
};
