import http from 'axios';
import URL from 'url-parse';
// import { decode } from 'jsonwebtoken';
import { allConfigs } from '../../config';
import base from '../../config/base';
// import { featureToggle } from '../featureToggle';

[
  'post',
  'put',
  'patch',
].forEach((method) => {
  http.defaults.headers[method]['Content-Type'] = 'application/json';
});

const getToken = () => new URL(window.location.search, true).query.token || JSON.parse(window.localStorage.getItem('token'));

// const featureToggleInit = async (token, featureToggleConfig) => {
//   try {
//     const { accountId } = decode(token);
//     return await featureToggle.default.init(featureToggleConfig, accountId);
//   } catch (e) {
//     console.error('[featureToggle] Failed to fetch accountId from token while init ', e);
//   }
//   return undefined;
// };

async function setEnv(env) {
  const thisConfig = { ...base, ...allConfigs[env] };
  window.env = env;
  window.baseURL = thisConfig.API_URL || base.API_URL;

  // const { FEATUER_TOGGLE } = thisConfig;
  // if (!('featureToggle' in window)) window.featureToggle =
  // await featureToggleInit(getToken(), FEATUER_TOGGLE);
}

class Resource {
  http;
  interceptors;

  constructor(options = {}) {
    this.http = http.create(options);

    // adding CSRF header to all requests
    this.http.defaults.headers.common['g-aid-cs'] = 'G-89C7E-9FB65-B6F69';

    const token = getToken();
    http.defaults.baseURL = window.baseURL || base.API_URL;
    http.defaults.headers.common.Authorization = `Bearer ${token}`;

    if (!options.disableInterceptors) {
      this.http.interceptors.response.handlers.push(...http.interceptors.response.handlers);
      this.http.interceptors.request.handlers.push(...http.interceptors.request.handlers);
    }

    this.interceptors = this.http.interceptors;
  }

  async get(url, params = {}, configs = {}) {
    const result = await this.http.get(url, { params, ...configs });
    return result;
  }

  async post(url, data = {}, params = {}, configs = {}) {
    const result = await this.http.post(url, data, { params, ...configs });
    return result;
  }

  async patch(url, data = {}, params = {}, configs = {}) {
    const result = await this.http.patch(url, data, { params, ...configs });
    return result;
  }

  async put(url, data = {}, params = {}, configs = {}) {
    const result = await this.http.put(url, data, { params, ...configs });
    return result;
  }

  async delete(url, params = {}, configs = {}) {
    const result = await this.http.delete(url, { params, ...configs });
    return result;
  }

  async head(url, params = {}, configs = {}) {
    const result = await this.http.head(url, { params, ...configs });
    return result;
  }
}

// redirect to logout in case of 401
http.interceptors.response.use(
  response => response,
  (error) => {
    const { response: { status } = {} } = error;
    if (status === 401) window.location.replace('/auth/logout');
    return Promise.reject(error);
  },
);

export { Resource, http, setEnv };
