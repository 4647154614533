import styled from 'styled-components';
import { mobile, tablet } from 'constants/css/breakpoints';

export const Reservations = styled.div`
  box-sizing: border-box;
  padding: 30px 120px 75px 120px;
  @media ${tablet} {
    padding: 30px 40px 75px 40px;
  };
  @media ${mobile} {
    padding: 30px 20px 130px 20px;
  };
`;
