import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icon from './Icon';

const roundIconStyle = {
  borderRadius: '100%',
};

const iconSizes = {
  sm: 25,
  md: 40,
  lg: 55,
};

const RoundIcon = ({
  iconWidth, iconHeight, color, bgColor, size,
  className, icon, containerClassName, style, containerStyle,
}) => (
  <div
    className={cn('flex-center', `icon-wrapper-${size}`, { [`bg-${bgColor}`]: bgColor }, containerClassName)}
    style={{ ...roundIconStyle, ...containerStyle }}
  >
    <Icon
      width={iconWidth || iconSizes[size]}
      height={iconHeight || iconSizes[size]}
      icon={icon}
      color={color}
      style={style}
      className={cn(className, 'icon-round')}
    />
  </div>
);

RoundIcon.defaultProps = {
  size: 'md',
  iconWidth: null,
  iconHeight: null,
  className: '',
  containerClassName: '',
  bgColor: '',
  color: '',
  containerStyle: {},
  style: {},
};

RoundIcon.propTypes = {
  /** Round Icon component size - allowing (xs, md, lg) */
  size: PropTypes.oneOf(['xs', 'md', 'lg']),
  /** Icon width  - defaults to Icon width default */
  iconWidth: PropTypes.number,
  /** Icon height  - defaults to Icon height default */
  iconHeight: PropTypes.number,
  /** Icon className  */
  className: PropTypes.string,
  /** Icon style */
  style: PropTypes.shape(),
  /** Icon container className  */
  containerClassName: PropTypes.string,
  /** Icon container style  */
  containerStyle: PropTypes.shape(),
  /** Round Icon background color */
  bgColor: PropTypes.string,
  /** inner Icon fill color - defaults to Icon color default  */
  color: PropTypes.string,
  /** Round Icon Icon name - available from the icon library */
  icon: PropTypes.string.isRequired,
};

export default RoundIcon;
