import { merge } from 'ramda';
import ActionTypes from 'redux/actions';

const {
  FETCH_QUOTES_SUCCESS,
  FETCH_LISTING_SUCCESS,
} = ActionTypes;

const initialState = {
  quotes: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_QUOTES_SUCCESS:
      return merge(state, {
        quotes: action.payload,
      });
    case FETCH_LISTING_SUCCESS:
      return merge(state, {
        listing: action.payload,
      });

    default:
      return state;
  }
};
