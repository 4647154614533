import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import noop from 'lodash/noop';
import StrapPopover from 'reactstrap/lib/Popover';
import { uuid } from '../../../utils/commonUtility';
import { Theme } from '../../../constants/enums';
import './popover.scss';

class Popover extends Component {
  constructor(props) {
    super(props);
    this.state = { id: props.id || uuid() };
  }
  render() {
    const {
      children,
      className,
      body,
      style,
      theme,
      containerClassName,
      placement,
      offset,
      isOpen,
      onClose,
      fullWidth,
      ...props
    } = this.props;
    const { id } = this.state;
    return (
      <div className={containerClassName}>
        <span id={`Popover-${id}`} className={containerClassName}>
          {children}
        </span>
        <StrapPopover
          target={`Popover-${id}`}
          isOpen={isOpen && !!body}
          toggle={onClose}
          className={cn(
            'popover-container',
            { 'mw-100': fullWidth },
            { 'dark-theme': theme === Theme.Dark },
            { 'error-theme': theme === Theme.Error },
            className
          )}
          innerClassName="box-shadow-heavy border-radius-2 font-size-md p-2"
          style={style}
          placement={placement}
          offset={offset}
          {...props}
        >
          {body}
        </StrapPopover>
      </div>
    );
  }
}
Popover.defaultProps = {
  className: '',
  id: undefined,
  theme: 'dark',
  placement: 'top',
  offset: undefined,
  containerClassName: '',
  body: null,
  onClose: noop,
  fullWidth: false
};
Popover.propTypes = {
  /** Bool - Boolean defining whether or not to toggle the popover */
  isOpen: PropTypes.bool.isRequired,
  /** Node - the component wrapped by the tooltip */
  children: PropTypes.node.isRequired,
  /** String || React Element - The tooltip body */
  body: PropTypes.node,
  /** onClose  - Function to support the popover toggle */
  onClose: PropTypes.func,
  /** String - Set the tooltip trigger id */
  id: PropTypes.string,
  /** String - Additional tooltip component class */
  className: PropTypes.string,
  /** Set the theme for the tooltip */
  theme: PropTypes.oneOf(['dark', 'light', 'error']),
  /** Popover placement supporting (top, bottom, left, right ) => <pos>-start, <pos>-end */
  placement: PropTypes.string,
  /** set custom offset to tooltip arrow */
  offset: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Add class to popover root level */
  containerClassName: PropTypes.string,
  /** Set the popover content to fit to its children full width - by default 280 */
  fullWidth: PropTypes.bool
};
export default Popover;
