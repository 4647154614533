import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import LocalizationContext from '../LocalizationContext';
import { MomentFormatTypes } from '../localeData';
import { memoize } from '../../../utils/commonUtility';

const getDateFormat = memoize((format, isEnglish) => {
  switch (format) {
    case MomentFormatTypes.Date:
      return isEnglish ? 'll' : 'L';
    case MomentFormatTypes.FullDate:
      return isEnglish ? 'lll' : 'L LT';
    case MomentFormatTypes.MMMM_YYYY:
      return 'MMMM YYYY';
    case MomentFormatTypes.MMM_YYYY:
      return 'MMM YYYY';
    case MomentFormatTypes.ShortDate:
      return 'L';
    default:
      return '';
  }
});

const FormattedDate = ({ format, date }) => {
  const momentDate = moment(date);
  return momentDate.isValid() ? (
    <LocalizationContext.Consumer>
      {({ isEnglish }) => momentDate.format(getDateFormat(format, isEnglish))}
    </LocalizationContext.Consumer>
  ) : (
    <Fragment>Invalid date</Fragment>
  );
};
FormattedDate.defaultProps = {
  format: MomentFormatTypes.Date
};
FormattedDate.propTypes = {
  /** date value is required */
  date: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  /** Date format to custom show moment (shortDate, date, fullDate, mmmm yyyyy, mmm yyyyy) */
  format: PropTypes.oneOf([
    MomentFormatTypes.ShortDate,
    MomentFormatTypes.Date,
    MomentFormatTypes.FullDate,
    MomentFormatTypes.MMMM_YYYY,
    MomentFormatTypes.MMM_YYYY
  ])
};
export default FormattedDate;
