import React from 'react';
import PropTypes from 'prop-types';

import Col from '../../../layout/col/Col';
import TextField from '../../textField/TextField';

const PersonPopover = ({ title, subtitle, size }) => (
  <Col dense>
    <TextField size={size === 'md' ? 'lg' : 'md'}>{title} </TextField>
    <TextField size={size}>{subtitle}</TextField>
  </Col>
);

const propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
};

PersonPopover.propTypes = propTypes;
export default PersonPopover;
