import React, { PureComponent } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import moment from 'moment';
import IconButton from '../buttons/IconButton';

class CalendarNavBar extends PureComponent {
  handlePrevClick = () => {
    if (this.props.onPreviousClick) {
      this.props.onPreviousClick();
    }
  };
  handleNextClick = () => {
    if (this.props.onNextClick) {
      this.props.onNextClick();
    }
  };
  render() {
    const { className, style, previousMonth } = this.props;
    return (
      <div className={cn('flex-center d-flex-fill', className)} style={style}>
        <IconButton
          onClick={this.handlePrevClick}
          className="p-2"
          icon="BtnLeft"
          iconWidth={6}
          iconHeight={11}
        />
        <div className="font-size-lg mt-1 text-center min-width-115 text-muted mx-2">
          {moment(previousMonth)
            .add(1, 'M')
            .format('MMMM YYYY')}
        </div>
        <IconButton
          onClick={this.handleNextClick}
          className="p-2"
          icon="BtnRight"
          iconWidth={6}
          iconHeight={11}
        />
      </div>
    );
  }
}

CalendarNavBar.defaultProps = {
  className: '',
  style: {},
  onPreviousClick: null,
  previousMonth: undefined,
  onNextClick: null
};

CalendarNavBar.propTypes = {
  onPreviousClick: PropTypes.func,
  onNextClick: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.shape(),
  previousMonth: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
    PropTypes.number
  ])
};

export default CalendarNavBar;
