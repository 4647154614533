import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isObject from 'lodash/isObject';
import cn from 'classnames';
import IntlTelInput from 'react-intl-tel-input';
import InputStarDeleteActionAddOn from '../InputStarDeleteActionAddon';
import withHover from '../../../compose/withHover';
import './telInput.scss';

class TelInput extends Component {
  constructor(props) {
    super(props);
    const value = this.getInitialValue(props);
    this.state = {
      value,
      formatted: value
    };
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    const prevVal = !isObject(prevProps.value)
      ? prevProps.value
      : prevProps.value.value;
    const val = !isObject(value) ? value : value.value;
    const { formatted } = this.state;
    if (val !== prevVal && val !== formatted && val !== this.state.value) {
      this.setPhoneNumber(val);
    }
  }

  onChangePhoneNumber = (isPhoneNumberValid, newValue, object, formatted) => {
    const { id, name, onChange } = this.props;
    this.setState({ value: newValue, formatted });
    onChange({ value: formatted, isValid: isPhoneNumberValid }, id || name);
  };

  onSelectFlag = (value, object, formatted, isPhoneNumberValid) => {
    const { id, name, onChange } = this.props;
    onChange({ value: formatted, isValid: isPhoneNumberValid }, id || name);
  };

  setPhoneNumber(val) {
    this.setState({ formatted: val, value: val });
  }

  getInitialValue = props => {
    if (props.defaultValue) {
      return isObject(props.defaultValue)
        ? props.defaultValue.value
        : props.defaultValue;
    }
    return isObject(props.value) ? props.value.value : props.value;
  };

  customPlaceholder = placeholder => `e.g. ${placeholder}`;

  render() {
    const {
      className,
      style,
      withActions,
      onTrashClick,
      onStarClick,
      isPrimary,
      inputClassName,
      onHoverEnter,
      onHoverLeave,
      isHovering,
      disabled,
      name,
      id
    } = this.props;

    const { value } = this.state;

    return (
      <div
        className={cn('d-flex', 'action-input height-46', className)}
        onMouseEnter={onHoverEnter}
        onMouseLeave={onHoverLeave}
        style={style}
      >
        <IntlTelInput
          fieldId={id}
          fieldName={name}
          disabled={disabled}
          value={value}
          onSelectFlag={this.onSelectFlag}
          containerClassName="intl-tel-input w-fill"
          inputClassName={cn(
            'flex-1 h-100',
            inputClassName,
            { 'action-input': withActions },
            'form-control',
            'w-fill'
          )}
          onPhoneNumberChange={this.onChangePhoneNumber}
          formatOnInit={false}
          customPlaceholder={this.customPlaceholder}
          format
        />
        <InputStarDeleteActionAddOn
          isPrimary={isPrimary}
          disabled={disabled}
          show={withActions && isHovering}
          onStar={onStarClick}
          onTrash={onTrashClick}
          name={name}
          id={id}
        />
      </div>
    );
  }
}

TelInput.defaultProps = {
  onChange: () => {},
  onTrashClick: () => {},
  onStarClick: () => {},
  onBlur: () => {},
  withActions: false,
  isPrimary: false,
  className: '',
  inputClassName: '',
  style: {},
  disabled: false,
  value: '',
  defaultValue: ''
};

TelInput.propTypes = {
  /** Cb for input change - params: {value: String, isValid: Boolean}, id|name {String | number} */
  onChange: PropTypes.func,
  /**  Cb for clicking on the trash icon */
  onTrashClick: PropTypes.func,
  /** Cb for clicking on the star (set primary) icon */
  onStarClick: PropTypes.func,
  /** Indicator whether to show the star/trash actions */
  withActions: PropTypes.bool,
  /** Boolean setting the email as primary */
  isPrimary: PropTypes.bool,
  /** Additional class to the top component level */
  className: PropTypes.string,
  /** Input level addition class */
  inputClassName: PropTypes.string,
  /** Additional style to the top component level */
  style: PropTypes.shape(),
  /** Boolean disable/enable component */
  disabled: PropTypes.bool,
  /** on Blur call back function */
  onBlur: PropTypes.func,
  /** value for controlled component */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  /** default value for component */
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()])
};

/** Work around to fix storybook HOC propTypes bug  - DO NOT IMPORT THIS */
export const TelComponent = TelInput;
export default withHover(TelInput);
