import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const Note = ({ message, className, px }) => (
  <Fragment>
    {message ? (
      <div className={cn('note overflow-y-auto b-1 border-gray-light font-size-md py-3 bg-yellow-light min-height-50 max-height-200', `px-${px}`, className)}>
        {message}
      </div>
    ) : null
    }
  </Fragment>
);

Note.defaultProps = {
  message: '',
  className: '',
  px: 6,
};

Note.propTypes = {
  /** Additional class name for Note */
  className: PropTypes.string,
  /** Note message, if none provided will not render anything */
  message: PropTypes.string,
  /** padding x for note - ( 6===30 9===45) */
  px: PropTypes.oneOf([6, 9]),
};

export default Note;
