const AIRBNB_REDIRECT_URL = 'https://mailer.staging4.guesty.com/proxy/integration/integration/airbnb/redirect';
const MAILER_URL = 'https://mailer.staging4.guesty.com/api/v2';
const INTEGRATION_SERVICE_URL = 'https://mailer.staging4.guesty.com/proxy/integration';
const COMMUNICATION_SERVICE_URL = 'https://mailer.staging4.guesty.com/proxy/communication/v2';
const API_URL = 'https://mailer.staging4.guesty.com/api/v2';
const LEAGCY_URL = '//dashboard-staging4.firebaseapp.com';
const API_GATEWAY = 'https://staging4-apigw.guesty.com';

export default {
  AIRBNB_REDIRECT_URL,
  MAILER_URL,
  INTEGRATION_SERVICE_URL,
  API_URL,
  LEAGCY_URL,
  COMMUNICATION_SERVICE_URL,
  API_GATEWAY,
};
