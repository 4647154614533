import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import currencySymbols from '../../../../utils/currencySymbols';

const Currency = ({ code, value }) => {
  const { symbol = code, right = false } = get(currencySymbols, code, {});
  const number = Number(value);
  const isNegative = number < 0;
  const absNumber = Math.abs(number);
  return (<Fragment>{isNegative ? '-' : ''}{!right && symbol}{value && absNumber}{right && symbol}</Fragment>);
};

Currency.defaultProps = {
  value: null,
  code: '',
};
Currency.propTypes = {
  /** Currency code as defined in currencySymbols */
  code: PropTypes.string,
  /** Value to render before or after to currency */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
export default Currency;
export { currencySymbols };
