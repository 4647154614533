import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import withStyle from 'react-jss';

const styles = {
  step: {
    minWidth: '22px',
    minHeight: '22px',
    borderRadius: '50%',
  },
};
const Step = ({
  children, active, color, classes,
}) => (
  <div
    className={cn(
      'flex-center',
      'steps-step',
      'font-size-md',
      'b-1',
      `text-${color}`,
      classes.step,
      {
        [`bg-${color}`]: active,
        'text-white': active,
      },
    )}
  >
    {children}
  </div>
);

Step.defaultProps = {
  color: 'blue',
};

Step.propTypes = {
  /** active will show if current stip is active */
  active: PropTypes.bool.isRequired,
  /** children will be the data inside the step circle */
  children: PropTypes.string.isRequired,
  /** color for the text content and background when it is current */
  color: PropTypes.string,
};

/** Work around to fix storybook HOC propTypes bug  - DO NOT IMPORT THIS */
export const StepComponent = Step;
export default withStyle(styles)(Step);
