import React from 'react';
import Bullet from '../indicators/bullet/Bullet';

const CalendarDaySMBody = ({ day, isHovering, hasNote }) => (
  <React.Fragment>
    <div className="day">{day}</div>
    <div className="w-100 text-start">
      {hasNote && <Bullet className="calendar-day-bullet" color={isHovering ? 'blue' : 'muted'} />}
    </div>
  </React.Fragment>
);

export default CalendarDaySMBody;
