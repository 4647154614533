import { merge } from 'ramda';
import ActionTypes from 'redux/actions';
import { DESKTOP, MOBILE, sm } from 'constants/css/breakpoints';

const {
  FETCH_ACCOUNTS_DATA_FAILURE,
  FETCH_LISTING_FAILURE,
  FETCH_QUOTES_FAILURE,
  PROCESS_INVOICE_FAILURE,
  SET_BREAKPOINT,
} = ActionTypes;

const initialState = {
  breakpoint: window && window.innerWidth <= sm ? MOBILE : DESKTOP,
  error: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_BREAKPOINT:
      return merge(state, {
        breakpoint: action.payload,
      });

    case FETCH_ACCOUNTS_DATA_FAILURE:
    case FETCH_LISTING_FAILURE:
    case FETCH_QUOTES_FAILURE:
    case PROCESS_INVOICE_FAILURE:
      return merge(state, {
        error: true,
        errorMessage: action.payload,
      });

    default:
      return state;
  }
};
