import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import InputLabel from '../../inputs/InputLabel';
import FormFieldComponent from './FormFieldComponent';
import './formField.scss';
import memoize from '../../../../utils/memoize';
import { required as requiredFn } from '../../../../tools/validators';

const buildValidators = memoize((validations, required) => {
  return required ? [...validations, requiredFn] : validations;
});

const isRequired = memoize(
  (validations, required) =>
    required || validations.some(validator => validator.name === 'required')
);

const FormField = ({
  children,
  label,
  tooltip,
  className,
  style,
  name,
  validate,
  field,
  fieldClassName,
  required,
  ...props
}) => {
  const Field = field;
  const validations = buildValidators(validate, required);
  const isFieldRequired = isRequired(validate, required);
  return (
    <div className={cn('relative form-field w-fill', className)} style={style}>
      {label && <InputLabel label={label} tooltip={tooltip} />}
      <Field
        name={name}
        validate={validations}
        className={fieldClassName}
        component={FormFieldComponent}
        required={isFieldRequired}
        {...props}
      >
        {children}
      </Field>
    </div>
  );
};
FormField.defaultProps = {
  label: '',
  tooltip: '',
  className: '',
  style: {},
  fieldClassName: '',
  validate: [],
  required: false
};

FormField.propTypes = {
  /** form field name to validate accordingly */
  name: PropTypes.string.isRequired,
  /** the redux form field props - required due to redux-form limitations */
  field: PropTypes.shape().isRequired,
  /** Form field level validations to run - to use required, use the boolean required prop */
  validate: PropTypes.arrayOf(PropTypes.func),
  /** Form element to render */
  children: PropTypes.node.isRequired,
  /** form field label */
  label: PropTypes.string,
  /** Form field label tooltip */
  tooltip: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  /** Form field root level additional class */
  className: PropTypes.string,
  /** Form field root level additional class */
  style: PropTypes.shape(),
  /** Form field component level additional class */
  fieldClassName: PropTypes.string,
  /** Boolean indicator whether or not field is required */
  required: PropTypes.bool
};

export const FieldComponent = FormField;

export default FormField;
