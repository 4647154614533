import React from 'react';
import PropTypes from 'prop-types';
import Col from '../../layout/col/Col';
import RaisedButton from '../buttons/RaisedButton';

const ActionPopoverBody = ({
  buttonText, onClick, body,
}) => (
  <Col spacing={2}>
    <div className="text-left">{body}</div>
    <div className="flex-end">
      <RaisedButton onClick={onClick}>{buttonText}</RaisedButton>
    </div>
  </Col>
);

ActionPopoverBody.propTypes = {
  /** Sets the text of the button */
  buttonText: PropTypes.string.isRequired,
  /** Callback function - called when clicking the tooltip action */
  onClick: PropTypes.func.isRequired,
  /** Sets the body (content) of the tooltip F.E: text to be displayed */
  body: PropTypes.node.isRequired,
};

export default ActionPopoverBody;
