export const MOBILE = 'MOBILE';
export const TABLET = 'TABLET';
export const LAPTOP = 'LAPTOP';
export const DESKTOP = 'DESKTOP';
export const LANDSCAPE_MODE_TABLETS = 'LANDSCAPE_MODE_TABLETS';

export const sm = 576;
export const md = 992;
export const lg = 1200;
export const xxl = 1550; 

export const mobile = `(max-width: ${sm}px)`;
export const tablet = `(min-width: ${sm}px) and (max-width: ${md}px )`;
export const laptop = `(min-width: ${md}px) and (max-width: ${lg}px )`;
export const desktop = `(min-width: ${md}px)`;
export const landScapeModeTablets = `(min-width: ${lg}px) and (max-width: ${xxl}px)`; 
