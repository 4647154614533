/*eslint-disable */
function Enum(a) {
  let i = Object.keys(a).reduce((o, k) => ((o[a[k]] = k), o), {});
  return Object.freeze(
    Object.keys(a).reduce((o, k) => ((o[k] = a[k]), o), v => i[v])
  );
}

export const StoryStatus = Enum({
  Verified: '\u2713',
  Deprecated: '\u26A0',
  Failed: '\u00D7'
});

export const Theme = Enum({
  Light: 'light',
  Dark: 'dark',
  Error: 'error'
});

export const StayStatus = Enum({
  Planned: 'pre',
  Staying: 'curr',
  Complete: 'post',
  Reserved: 'reserved'
});

export const CalendarDayStatus = Enum({
  Booked: 'booked',
  Reserved: 'reserved',
  Available: 'available',
  Unavailable: 'unavailable',
  Confirmed: 'confirmed'
});

export const DropdownButtonColors = Enum({
  primary: 'blue',
  success: 'green',
  warning: 'yellow',
  danger: 'red',
  secondary: 'gray-dark'
});

export const DatePickerType = Enum({
  Single: 'single',
  Range: 'range'
});

export const DateDisplayFormat = 'MMM D, YYYY';

export const ActionTooltipType = Enum({
  Button: 'button',
  Link: 'link'
});

export const AnchorDirectionType = Enum({
  Left: 'left',
  Center: 'center',
  Right: 'right',
  EndCenter: 'end center',
  StartCenter: 'start center',
  Start: 'start',
  End: 'end'
});

export const MenuSlideAnchorDirectionType = Enum({
  Top: 'top',
  Bottom: 'bottom'
});
