import React from 'react';
import PropTypes from 'prop-types';

import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';

const DEFAULT_ZOOM = 13;

const DEFAULT_OPTIONS = {
  MAP_URL: 'https://maps.googleapis.com/maps/api/js?v=3.exp&key=',
  MARKER: {
    PATH: 'M-0.0006900000000005235,-27 C-5.523121,-27 -10,-22.5240517 -10,-17.000689700000002 C-10,-9.727448299999999 -0.5634999999999994,0 0.2879310000000004,0 C1.1351720000000007,0 10.000017,-10.5844655 10.000017,-17.000689700000002 C9.999552000000001,-22.5240517 5.522672,-27 -0.0006900000000005235,-27 L-0.0006900000000005235,-27 Z M-0.048171999999999215,-13.6582759 C-2.2156209999999996,-13.6582759 -3.971552,-15.3671897 -3.971552,-17.4769138 C-3.971552,-19.5852414 -2.216086,-21.2960172 -0.048171999999999215,-21.2960172 C2.1197409999999994,-21.2960172 3.876137999999999,-19.5852414 3.876137999999999,-17.4769138 C3.876137999999999,-15.3671897 2.1197409999999994,-13.6582759 -0.048171999999999215,-13.6582759 L-0.048171999999999215,-13.6582759 Z',
    OPACITY: {
      CIRCLE: 0,
      NO_CIRCLE: 1,
    },
    STROKE_COLOR: {
      CIRCLE: 'transparent',
      NO_CIRCLE: 'white',
    },
    STROKE_WEIGHT: 1.5,
    POSITION: {
      lat: -34.397,
      lng: 150.644,
    },
  },
  CIRCLE: {
    RADIUS: 300,
    OPACITY: 0.35,
    STROKE_WEIGHT: 1,
  },
};

const MapComponent = ({ position = {} }) => (
  <GoogleMap
    defaultZoom={DEFAULT_ZOOM}
    defaultCenter={position || DEFAULT_OPTIONS.MARKER.POSITION}
    disableDefaultUI
  >
    <Marker
      position={position || DEFAULT_OPTIONS.MARKER.POSITION}
      icon={{
        path: DEFAULT_OPTIONS.MARKER.PATH,
        fillColor: '#000',
        // fillOpacity: DEFAULT_OPTIONS.MARKER.OPACITY.CIRCLE : DEFAULT_OPTIONS.MARKER.OPACITY.NO_CIRCLE,
        scale: 1,
        // strokeColor: circle ? DEFAULT_OPTIONS.MARKER.STROKE_COLOR.CIRCLE : DEFAULT_OPTIONS.MARKER.STROKE_COLOR.NO_CIRCLE,
        strokeWeight: DEFAULT_OPTIONS.MARKER.STROKE_WEIGHT,
      }}
    />
  </GoogleMap>
);

MapComponent.propTypes = {
  lat: PropTypes.number,
  lng: PropTypes.number,
};

MapComponent.defaultProps = {
  lat: -34.397,
  lng: 150.644,
};

const WrappedMap = withScriptjs(withGoogleMap(MapComponent));

export default (props) => <WrappedMap
  isMarkerShown
  googleMapURL={`${DEFAULT_OPTIONS.MAP_URL}${process.env.REACT_APP_GOOGLE_MAPS_KEY || ''}`}
  loadingElement={<div style={{ height: '100%' }} />}
  containerElement={<div className="pt-6" style={{ height: '400px' }} />}
  mapElement={<div style={{ height: '100%' }} />}
  {...props}
/>;
