import React from 'react';
import intl from '@guestyci/react-intl-universal';
/**
 * Create a wrapper compose to support localization
 * @param ComposedComponent
 * @returns {{Component}}
 */


const withLocalization = ComposedComponent => props => (
  <ComposedComponent {...props} intl={intl} />
);

export default withLocalization;

