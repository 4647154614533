/* eslint-disable no-undef */
// import 'react-intl-tel-input/dist/libphonenumber';
import { isEmpty } from '../../utils/commonUtility';
import {
  END_DATE_REQUIRED,
  INVALID_EMAIL,
  INVALID_PHONE,
  INVALID_PHONE_10_DIGITS,
  INVALID_PHONE_NUMBER,
  MAX_3_DIGITS,
  maxLengthMessage,
  maxMessage,
  minLengthMessage,
  minMessage,
  NOT_ZERO,
  ONLY_ALPHA_NUMERIC,
  REQUIRED,
  START_DATE_REQUIRED,
} from '../../constants/validationMessages';

const required = value => (!isEmpty(value) ? undefined : REQUIRED);
const maxLength = max => value =>
  (value && value.length > max ? maxLengthMessage(max) : undefined);
const minLength = min => value =>
  (value && value.length < min ? minLengthMessage(min) : undefined);
const min = minVal => value =>
  (!isEmpty(value) && value < minVal ? minMessage(minVal) : undefined);
const max = maxVal => value =>
  (!isEmpty(value) && value > maxVal ? maxMessage(maxVal) : undefined);
const email = value =>
  (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? INVALID_EMAIL
    : undefined);
const alphaNumeric = value =>
  (value && /[^a-zA-Z0-9 ]/i.test(value)
    ? ONLY_ALPHA_NUMERIC
    : undefined);
const threeDigitsPastDecimalPoint = value =>
  (value && !/^-?\d+(\.\d{1,3})?\s*$/i.test(value)
    ? MAX_3_DIGITS
    : undefined);
const notZero = value => (value && !/^(?!0$).*/i.test(value)
  ? NOT_ZERO
  : undefined);
const phoneNumber = value =>
  (value && !/^(0|[1-9][0-9]{9})$/i.test(value)
    ? INVALID_PHONE_10_DIGITS
    : undefined);
const googlePhoneNumber = value => (value && !/^\+[0-9]{1,3}.[0-9,-]{4,14}(?:x.+)?$/.test(value)
  ? INVALID_PHONE
  : undefined);

const phone = value => (value && value.isValid ? undefined : INVALID_PHONE_NUMBER);
const rangePickerValidation = (value = {}) => {
  if (isEmpty(value) || (!value.start && !value.end)) {
    return REQUIRED;
  }
  if (!value.start) {
    return START_DATE_REQUIRED;
  }
  if (!value.end) {
    return END_DATE_REQUIRED;
  }
  return undefined;
};

export {
  notZero,
  required,
  email,
  phoneNumber,
  alphaNumeric,
  maxLength,
  minLength,
  min,
  max,
  threeDigitsPastDecimalPoint,
  googlePhoneNumber,
  rangePickerValidation,
  phone,
};
