import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const Divider = ({ vertical, horizontal, className, style, color, width }) => (
  <div
    className={cn(
      className,
      { bt: horizontal },
      { [`bw-${width}`]: width },
      { bl: vertical },
      { [`border-${color}`]: color },
      'w-fill'
    )}
    style={style}
  />
);

Divider.defaultProps = {
  color: 'gray-light',
  vertical: false,
  horizontal: true,
  className: '',
  style: {},
  width: 1
};
Divider.propTypes = {
  color: PropTypes.string,
  /** Set divider as vertical */
  vertical: PropTypes.bool,
  /** Set divider as horizontal - by default */
  horizontal: PropTypes.bool,
  /** Set additional class names to divider */
  className: PropTypes.string,
  /** Set style to divider */
  style: PropTypes.shape(),
  /** Border width */
  width: PropTypes.number
};

export default Divider;
