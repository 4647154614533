import React from 'react';
import PropTypes from 'prop-types';
import ActionTooltipBody from './ActionTooltipBody';
import Tooltip from '../Tooltip';
import { ActionTooltipType } from '../../../../constants/enums';

const ActionTooltip = ({
  actionType,
  actionText,
  actionHref,
  href,
  placement,
  children,
  body,
  delay,
  theme,
  onAction,
  id,
  className,
  containerClassName,
}) => (
  <Tooltip
    placement={placement}
    className={className}
    id={id}
    containerClassName={containerClassName}
    body={<ActionTooltipBody
      actionType={actionType}
      actionText={actionText}
      actionHref={href || actionHref}
      body={body}
      onAction={onAction}
    />}
    autoHide={false}
    delay={delay}
    theme={theme}
  >
    {children}
  </Tooltip>
);

ActionTooltip.defaultProps = {
  className: '',
  id: undefined,
  theme: 'dark',
  placement: 'top',
  offset: 5,
  containerClassName: '',
  delay: { show: 0, hide: 250 },
  actionHref: '',
  href: '',
  onAction: (() => {}),
  actionType: ActionTooltipType.Link,
};

ActionTooltip.propTypes = {
  /** Sets the type of the action */
  actionType: PropTypes.oneOf([ActionTooltipType.Button, ActionTooltipType.Link]),
  /** Sets the text of the action */
  actionText: PropTypes.string.isRequired,
  /** Sets the href of the action (link) - DEPRECATED - use href */
  actionHref: PropTypes.string,
  /** click href for link type */
  href: PropTypes.string,
  /** Node - the component wrapped by the tooltip */
  children: PropTypes.node.isRequired,
  /** String - Set the tooltip trigger id */
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** String - Additional tooltip component class */
  className: PropTypes.string,
  // /** String - Additional tooltip component class */
  containerClassName: PropTypes.string,
  /** Set the theme for the tooltip */
  theme: PropTypes.oneOf(['dark', 'light', 'error']),
  /** Popover placement supporting (top, bottom, left, right ) => <pos>-start, <pos>-end */
  placement: PropTypes.string,
  /** set custom offset to tooltip arrow */
  offset: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Set show/hide delay for dialog - structure {show, hide} or number */
  delay: PropTypes.oneOfType([
    PropTypes.shape({ show: PropTypes.number, hide: PropTypes.number }),
    PropTypes.number,
  ]),
  /** Callback function - called when clicking the tooltip action */
  onAction: PropTypes.func,
  /** Sets the body (content) of the tooltip F.E: text to be displayed */
  body: PropTypes.node.isRequired,
};

export default ActionTooltip;
