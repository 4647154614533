import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import isNumber from 'lodash/isNumber';
import TextareaAutosize from 'react-textarea-autosize';
import noop from 'lodash/noop';
import InputLabel from '../InputLabel';
import { Theme } from '../../../../constants/enums';

class TextArea extends Component {
  constructor(props) {
    super(props);
    this.state = { charCount: props.maxLength };
    this.textAreaRef = React.createRef();
  }
  _isValid = inputLength =>
    !isNumber(this.props.maxLength) || this.props.maxLength - inputLength >= 0;

  _calcCharCount = input => {
    if (isNumber(this.props.maxLength)) {
      return this.props.maxLength - input.length;
    }
    return undefined;
  };

  handleInputChange = event => {
    const { value } = event.currentTarget;
    const { id, name } = this.props;
    if (this._isValid(value.length)) {
      this.setState({ charCount: this._calcCharCount(value) });
      if (this.props.onChange) {
        this.props.onChange(value, id || name);
      }
    }
  };

  render() {
    const { charCount } = this.state;
    const {
      value,
      maxLength,
      containerStyle,
      tooltip,
      label,
      disabled,
      className,
      rows,
      maxRows,
      theme,
      containerClassName,
      autoFocus,
      required,
      placeholder,
      ...props
    } = this.props;
    return (
      <div className="d-flex-fill flex-column input-wrapper">
        {label && <InputLabel label={label} tooltip={tooltip} />}
        <div
          className={cn(
            'd-flex-fill flex-start-center pos-relative border-gray-lighter b-1',
            containerClassName,
            theme === Theme.Light
              ? 'border-gray-lightest bg-white'
              : 'bg-gray-lightest border-gray-light',
            { 'bg-gray-lighter': disabled }
          )}
          style={containerStyle}
        >
          <TextareaAutosize
            {...props}
            placeholder={placeholder}
            autoFocus={autoFocus}
            ref={this.textAreaRef}
            maxRows={maxRows}
            rows={rows}
            value={value}
            disabled={disabled}
            maxLength={maxLength}
            onChange={this.handleInputChange}
            className={cn(
              'w-100 border-0 py-3 px-4 min-height-50 font-size-md text-black',
              theme === Theme.Light ? 'bg-white' : 'bg-gray-lightest',
              className
            )}
          />
          {isNumber(maxLength) && (
            <span className="textarea-charcount font-size-md text-muted pos-absolute mr-2 right-0 bottom-0">
              {charCount}
            </span>
          )}
        </div>
      </div>
    );
  }
}

TextArea.defaultProps = {
  maxLength: null,
  value: undefined,
  onChange: noop,
  placeholder: '',
  label: null,
  tooltip: '',
  disabled: false,
  rows: 1,
  maxRows: 5,
  theme: Theme.Dark,
  containerClassName: '',
  autoFocus: false
};

TextArea.propTypes = {
  /** Controlled text area value */
  value: PropTypes.string,
  /** On change trigger function to update on text change */
  onChange: PropTypes.func,
  /** The max length for the text area input */
  maxLength: PropTypes.number,
  /** TextArea Placeholder */
  placeholder: PropTypes.string,
  /** A label to show on top of the input */
  label: PropTypes.string,
  /** Add tooltip help icon and message - tooltip - String help message */
  tooltip: PropTypes.string,
  /** Indicator for disabled status */
  disabled: PropTypes.bool,
  /** Row number to set for the textarea */
  rows: PropTypes.number,
  /** max number of rows for expandable text area */
  maxRows: PropTypes.number,
  /** Define theme for text area - possible values = [Theme.Light, Theme.Dark] */
  theme: PropTypes.oneOfType([Theme.Light, Theme.Dark]),
  /** Container level class name */
  containerClassName: PropTypes.string,
  /** Boolean to define if should be auto focused */
  autoFocus: PropTypes.bool
};
export default TextArea;
