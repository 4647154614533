import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'react-jss';
import ActionButton from '../../buttons/ActionButton';

const styles = {
  actionButton: {
    width: 45,
    height: 23,
  },
  btnTop: {
    borderRadius: '0px !important',
    borderTopRightRadius: '2px !important',
    borderWidth: '1px 1px 1px 0px !important',
  },
  btnBottom: {
    borderRadius: '0px !important',
    borderBottomRightRadius: '2px !important',
    borderWidth: '0px 1px 1px 0px !important',
  },
};
const NumberPickerActionButtons = ({
  classes, onIncrement, onDecrement, disableMax, disableMin,
}) => (
  <div className="flex-center bl-1 border-gray-light flex-column">
    <ActionButton
      onClick={onIncrement}
      iconWidth={10}
      iconHeight={10}
      disabled={disableMax}
      icon="BtnArrowUp"
      className={`${classes.actionButton} ${classes.btnTop}`}
    />
    <ActionButton
      onClick={onDecrement}
      iconWidth={10}
      iconHeight={10}
      disabled={disableMin}
      icon="BtnArrowDown"
      className={`${classes.actionButton} ${classes.btnBottom}`}
    />
  </div>
);

NumberPickerActionButtons.defaultProps = {
  disableMin: false,
  disableMax: false,
};

NumberPickerActionButtons.propTypes = {
  onIncrement: PropTypes.func.isRequired,
  onDecrement: PropTypes.func.isRequired,
  disableMax: PropTypes.bool,
  disableMin: PropTypes.bool,
};

export default withStyles(styles)(NumberPickerActionButtons);

