const API_URL = '//dashboard-mailer.production.guesty.com/api/v2';
const STRIPE_API_KEY = 'pk_live_P0FSIEtbwU1GSvgvEM3DYuUZ';
const SEGMENT_KEY = 'VHYB66nCHXQOu4IXfjXf1AB7ElqpmB6p';
const AIRBNB_REDIRECT_URL = 'https://mailer.production.guesty.com/proxy/integration/integration/airbnb/redirect';
const MAILER_URL = 'https://dashboard-mailer.production.guesty.com/api/v2';
const INTEGRATION_SERVICE_URL = 'https://mailer.production.guesty.com/proxy/integration';
const COMMUNICATION_SERVICE_URL = 'https://mailer.production.guesty.com/proxy/communication/v2';
const LEAGCY_URL = '//dashboard-legacy.firebaseapp.com';
const AIRBNB_SCOPE = ['vr', 'messages_read', 'messages_write'].join();
const AIRBNB_CLIENT_ID = '616ylmcl2t784e7a66g8wf8fp';
const API_GATEWAY = 'https://production-apigw.guesty.com';

const FEATUER_TOGGLE = {
  core: {
    authorizationKey: 'hhq5f6gnsj75jqe12ov0k0r536o1ucg2f3b0',
    labelsEnabled: true,
  },
  scheduler: {
    featuresRefreshRate: 180, // 180 sec
    segmentsRefreshRate: 360, // 360 sec
    metricsRefreshRate: 600, // 60 sec
    impressionsRefreshRate: 360, // 60 sec
    eventsPushRate: 360, // 60 sec
    eventsQueueSize: 500, // 500 items
  },
  startup: {
    requestTimeoutBeforeReady: 1.5, // 1500 ms
    retriesOnFailureBeforeReady: 3, // 3 times
    readyTimeout: 5, // 5 sec
  },
  debug: false,
};

export default {
  API_URL,
  SEGMENT_KEY,
  AIRBNB_REDIRECT_URL,
  MAILER_URL,
  INTEGRATION_SERVICE_URL,
  LEAGCY_URL,
  AIRBNB_SCOPE,
  AIRBNB_CLIENT_ID,
  COMMUNICATION_SERVICE_URL,
  FEATUER_TOGGLE,
  STRIPE_API_KEY,
  API_GATEWAY,
};
