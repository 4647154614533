// blue
export const blue = '#30abe5';
export const blueBorder = '#239ed8';
export const blueDark = '#0073ad';
export const blueHover = '#085174';
export const blueContact = '#a8d5eb';
export const blueLight = '#d4ecf7';
export const blueLightBorder = '#48bdf3';
export const blueDarkTheme = '#4bbdf3';
// gray scale
export const black = '#272b2d';
export const blackDark = '#000000';
export const grayDarkest = '#515c61';
export const grayDarker = '#556268';
export const grayDark = '#7a8489';
export const muted = '#aeb6ba';
export const gray = '#d1d8db';
export const grayLight = '#e3e8eb';
export const grayLighter = '#eef1f3';
export const grayLightest = '#f4f6f7';
export const white = '#ffffff';

// basic
export const green = '#72d28f';
export const greenDark = '#4aa666';
export const yellow = '#ffbe00';
export const yellowLight = '#ffe9ab';
export const yellowDark = '#dfa600';
export const red = '#ff5f5f';
export const redDark = '#e02a2a';
export const purple = '#a562cd';
