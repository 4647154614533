import React from 'react';
import PropTypes from 'prop-types';
import ListItem from '../list/ListItem';
import CalendarTooltipNote from './CalendarTooltipNote';
import CalendarTooltipPrice from './CalendarTooltipPrice';
import List from '../list/List';
import CalendarTooltipBlock from './CalendarTooltipBlock';
import CalendarTooltipReserved from './CalendarTooltipReserved';

const CalendarTooltip = ({ price, note, blocks, isReserved, data }) => (
  <List className="calendar-tooltip-list">
    {!isReserved && note && (
      <ListItem dense className="calendar-tooltip-item">
        <CalendarTooltipNote note={note} />
      </ListItem>
    )}
    {isReserved && <CalendarTooltipReserved data={data} />}
    {!isReserved &&
      blocks.map(block => (
        <ListItem key={block} dense className="calendar-tooltip-item">
          <CalendarTooltipBlock block={block} />
        </ListItem>
      ))}
    {!isReserved && price && (
      <ListItem dense className="calendar-tooltip-item">
        <CalendarTooltipPrice price={price} />
      </ListItem>
    )}
  </List>
);

CalendarTooltip.defaultProps = {
  price: undefined,
  note: undefined,
  blocks: [],
  isReserved: false,
  data: {}
};
CalendarTooltip.propTypes = {
  price: PropTypes.string,
  note: PropTypes.string,
  blocks: PropTypes.arrayOf(PropTypes.string),
  isReserved: PropTypes.bool,
  data: PropTypes.shape()
};
export default CalendarTooltip;
