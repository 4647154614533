import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'react-jss';
import isEmpty from 'lodash/isEmpty';
import { isNumber, parseNumber } from '../../../../utils/commonUtility';
import Input from '../../inputs/Input';
import NumberPickerActionButtons from './NumberPickerActionButtons';
import PickerResetWrapper from '../PickerResetWrapper';

const styles = {
  resetBtn: {
    borderLeftWidth: '0 !important',
  },
  input: {
    borderRightWidth: '0 !important',
  },
};

class NumberPicker extends PureComponent {
  constructor(props) {
    super(props);
    const initialValue = parseNumber(props.value || props.defaultValue, '');
    this.state = {
      value: initialValue,
      disableMax: isNumber(initialValue) && isNumber(props.max) && initialValue >= props.max,
      disableMin: isNumber(initialValue) && isNumber(props.min) && initialValue <= props.min,
    };
  }

  handleValueChange(value) {
    const { onChange, id, name } = this.props;
    onChange(parseNumber(value, null), id || name);
  }

  handleIncrementClick = () => {
    const { max, step } = this.props;
    const { value } = this.state;
    const val = +value || 0;
    const newVal = parseNumber(val + step);
    if (max === null || (isNumber(max) && newVal <= max)) {
      this.setState({
        value: newVal,
        disableMin: false,
        disableMax: isNumber(max) && newVal >= max,
      }, () => this.handleValueChange(this.state.value));
    }
  };
  handleDecrementClick = () => {
    const { min, step } = this.props;
    const { value } = this.state;
    const val = +value || 0;
    const newVal = parseNumber(val - step);
    if (min === null || (isNumber(min) && newVal >= min)) {
      this.setState({
        value: newVal,
        disableMax: false,
        disableMin: isNumber(min) && newVal <= min,
      }, () => this.handleValueChange(this.state.value));
    }
  };

  handleInputChange = (value) => {
    const { min, max } = this.props;
    if (isEmpty(value) || this.isValid(value)) {
      this.setState({
        value,
        disableMax: isNumber(max) && value >= max,
        disableMin: isNumber(min) && value <= min,
      }, () => this.handleValueChange(value));
    }
  };

  isValid(value) {
    const { min, max, required } = this.props;
    if (!required && (value === '' || value === null)) {
      return true;
    }
    if (isNumber(value)) {
      const val = parseNumber(value);
      return ((!isNumber(min) || val >= min) &&
        (!isNumber(max) || val <= max) &&
        this.isValidFloatingPoint(val));
    }
    return false;
  }

  isValidFloatingPoint = (value) => {
    if (value === Math.floor(value)) {
      return true;
    }
    const decValue = value.toString().split('.')[1];
    const decPoints = decValue ? decValue.length : 0;
    return this.props.floatingPoint >= decPoints;
  };


  reset = () => {
    const { max, min, defaultValue } = this.props;
    this.setState({
      value: defaultValue,
      disableMax: isNumber(max) && defaultValue >= max,
      disableMin: isNumber(min) && defaultValue <= min,
    });
    this.handleValueChange(defaultValue);
  };

  render() {
    const {
      disabled, placeholder, inputClassName, inputStyle, className,
      style, resetable, defaultValue, classes, name, id, required, suffix,
    } = this.props;
    const { value, disableMax, disableMin } = this.state;
    return (
      <PickerResetWrapper
        className={className}
        style={style}
        resetable={resetable}
        disabled={defaultValue === value}
        id={id}
        onReset={this.reset}
      >
        <Input
          suffix={suffix}
          required={required}
          name={name}
          id={id}
          value={value}
          disabled={disabled}
          placeholder={placeholder}
          onChange={this.handleInputChange}
          className={inputClassName}
          containerClassName={classes.input}
          style={inputStyle}
        />
        <NumberPickerActionButtons
          disableMax={disabled || disableMax}
          disableMin={disabled || disableMin}
          onIncrement={this.handleIncrementClick}
          onDecrement={this.handleDecrementClick}
        />
      </PickerResetWrapper>
    );
  }
}

NumberPicker.defaultProps = {
  value: '',
  disabled: false,
  defaultValue: null,
  min: null,
  max: null,
  step: 1,
  floatingPoint: 0,
  placeholder: '',
  inputClassName: '',
  inputStyle: {},
  className: '',
  style: {},
  resetable: false,
  id: undefined,
  name: '',
  suffix: null,
};

NumberPicker.propTypes = {
  /** Controlled value to be set */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Indicator whether or not the input is disabled */
  disabled: PropTypes.bool,
  /** CallBack for on value change */
  onChange: PropTypes.func.isRequired,
  /** Default value for input (also resets to value) */
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Minimum allowed value */
  min: PropTypes.number,
  /** Maximum allowed value */
  max: PropTypes.number,
  /** Amount to increment / decrement on each button click
   * - also allows the support do decimal point */
  step: PropTypes.number,
  /** Placeholder for input level */
  placeholder: PropTypes.string,
  /** Additional class for input level */
  inputClassName: PropTypes.string,
  /** Additional style for input level */
  inputStyle: PropTypes.shape(),
  /** Additional class for root level */
  className: PropTypes.string,
  /** additional style for root level */
  style: PropTypes.shape(),
  /** Show the reset option for the input (resets to defaultValue) */
  resetable: PropTypes.bool,
  /** id indicator for field */
  id: PropTypes.string,
  /** name indicator for field */
  name: PropTypes.string,
  /** Suffix to locate at the end of the number picker */
  suffix: PropTypes.node,
  /** Floating point allowed for number picker */
  floatingPoint: PropTypes.number,
};
export const NumberPickerComponent = NumberPicker;
export default withStyles(styles)(NumberPicker);
