import React from 'react';
import cn from 'classnames';
import withStyle from 'react-jss';
import PropTypes from 'prop-types';

import Icon from '../../icon/Icon';
import Spinner from '../../loaders/Spinner';

const style = {
  outerSize: {
    padding: '12px',
    width: 'inherit',
  },
};

const IndicationIcon = ({ isValidating, isValid, classes }) => (
  <div className={cn('flex-center', 'bg-gray-lightest', classes.outerSize)} >
    {isValidating ? <Spinner size={22} /> :
    <Icon
      icon={isValid ? 'IcoApproved' : 'IcoDeclined'}
      width={21}
      height={21}
      color={isValid ? 'green' : 'red'}
    />}
  </div>);


IndicationIcon.propTypes = {
  isValidating: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
};

export default withStyle(style)(IndicationIcon);
