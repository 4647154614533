import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import noop from 'lodash/noop';
import Menu from '../menu/Menu';
import MenuAutoPosition from '../menu/MenuAutoPosition';
import InfiniteScroll from '../infiniteScroll/InfiniteScroll';
import { AnchorDirectionType } from '../../../constants/enums';
import ClickOutsideListener from '../../utils/clickOutListener/ClickOutsideListener';
import TextField from '../textField/TextField';

const DropdownMenu = ({
  topBar, isCollapsed, className, isEmptyState, children, onDismiss,
  onLoadMore, isFetching, pageCount, currentPage, anchorDirection,
}) => (
  <MenuAutoPosition className={cn('nowrap', className)} trigger={!isCollapsed} anchorDirection={anchorDirection}>
    <ClickOutsideListener onClickOutside={onDismiss}>
      <Menu className="bg-white overflow-y-hidden" dense collapse={isCollapsed}>
        {topBar}
        <Menu dense maxHeight={210}>
          <InfiniteScroll
            className="p-4"
            onLoadMore={onLoadMore}
            pageCount={pageCount}
            height={210}
            offset={0}
            animation={isEmptyState ? null : 'fade-quick'}
            initialPage={currentPage}
            isFetching={isFetching}
          >
            {children}
            {isEmptyState && <TextField className="options-empty"> No results found.</TextField>}
          </InfiniteScroll>
        </Menu>
      </Menu>
    </ClickOutsideListener>
  </MenuAutoPosition>
);

DropdownMenu.defaultProps = {
  topBar: null,
  isCollapsed: true,
  className: '',
  isEmptyState: false,
  onLoadMore: noop,
  isFetching: false,
  pageCount: 0,
  currentPage: 0,
  anchorDirection: AnchorDirectionType.Start,
};

DropdownMenu.propTypes = {
  topBar: PropTypes.node,
  isEmptyState: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  isCollapsed: PropTypes.bool,
  className: PropTypes.string,
  onLoadMore: PropTypes.func,
  onDismiss: PropTypes.func.isRequired,
  pageCount: PropTypes.number,
  isFetching: PropTypes.bool,
  currentPage: PropTypes.number,
  anchorDirection: PropTypes.oneOfType([
    AnchorDirectionType.Center,
    AnchorDirectionType.Left,
    AnchorDirectionType.Right,
    AnchorDirectionType.StartCenter,
    AnchorDirectionType.EndCenter,
    AnchorDirectionType.Start,
  ]),
};
export default DropdownMenu;
