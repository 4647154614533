import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import noop from 'lodash/noop';
import Icon from '../icon/Icon';
import RaisedButton from '../buttons/RaisedButton';

const EmptyState = ({
  className,
  style,
  iconName,
  title,
  description,
  iconWidth,
  iconHeight,
  onClick,
  buttonLabel
}) => (
  <div
    className={cn('flex-center flex-column h-fill max-width-450', className)}
    style={style}
  >
    <Icon
      className="mb-5"
      icon={iconName}
      height={iconHeight}
      width={iconWidth}
    />
    <div
      className={cn(
        'text-muted font-size-xl text-align-center overflow-ellipsis mb-2 w-fill max-width-450'
      )}
    >
      {title}
    </div>
    <div className="text-muted font-size-lg text-align-center w-fill">
      {description}
    </div>
    {buttonLabel ? (
      <div className="mt-4">
        <div className="d-flex justify-content-center">
          <RaisedButton onClick={onClick}>{buttonLabel}</RaisedButton>
        </div>
      </div>
    ) : null}
  </div>
);

EmptyState.defaultProps = {
  iconWidth: 100,
  iconHeight: 100,
  className: '',
  buttonLabel: '',
  style: {},
  onClick: noop
};

EmptyState.propTypes = {
  /** Additional class name for person */
  className: PropTypes.string,
  /** Additional style name to root level */
  style: PropTypes.shape(),
  /** Icon - chooses the appropriate icon by its name */
  iconName: PropTypes.string.isRequired,
  /** Button click callback function */
  onClick: PropTypes.func,
  /** sets the icon's width */
  iconWidth: PropTypes.number,
  /** sets the icon's height */
  iconHeight: PropTypes.number,
  /** Title - sets the text of the title */
  title: PropTypes.string.isRequired,
  /** buttonLabel - sets the button label of the button */
  buttonLabel: PropTypes.string,
  /** Description - sets the element of the description */
  description: PropTypes.oneOfType([PropTypes.node, PropTypes.element])
    .isRequired
};

export default EmptyState;
