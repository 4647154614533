import React, { Component } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import './switch.scss';
import { uuid } from '../../../../utils/commonUtility';
import Row from '../../../layout/row/Row';
import TextField from '../../textField/TextField';

class Switch extends Component {
  constructor(props) {
    super(props);
    this.state = { id: props.id || uuid() };
  }
  toggle = ({ target }) => {
    const { onChange, id, name } = this.props;
    onChange(target.checked, id || name);
  };

  render() {
    const {
      label, textClassName, on, isDisabled, className, disabled,
    } = this.props;
    const { id } = this.state;
    return (
      <Row spacing="sm" className={cn('switch w-inherit', className)}>
        <label htmlFor={id} className={cn('switch', { disabled: (disabled || isDisabled) })}>
          <input id={id} type="checkbox" disabled={(disabled || isDisabled)} onChange={this.toggle} checked={on} />
          <span className="slider" />
        </label>
        {label && <TextField color={on ? 'blue' : 'muted'} className={cn('switch-label', textClassName)}>{label}</TextField>}
      </Row>
    );
  }
}

Switch.propTypes = {
  label: PropTypes.string,
  /** Switch status if it's on or off */
  on: PropTypes.bool.isRequired,
  /** classes to provide for the component */
  className: PropTypes.string,
  /** callback function to call when switch state change */
  onChange: PropTypes.func.isRequired,
  /** String, class for the text */
  textClassName: PropTypes.string,
  /** name for component */
  name: PropTypes.string,
  /** id for component */
  id: PropTypes.string,
  /** Indicator whether or not is disabled */
  disabled: PropTypes.bool,
  /** Indicator whether or not switch is disabled - Deprecated - use disabled */
  isDisabled: PropTypes.bool,
};

Switch.defaultProps = {
  className: '',
  label: null,
  textClassName: '',
  name: '',
  id: undefined,
  disabled: false,
  isDisabled: false,
};

export default Switch;
