import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'react-jss';
import cn from 'classnames';
import isFunction from 'lodash/isFunction';
import Avatar from '../avatar/Avatar';
import { nameToInitials } from '../../../../utils/commonUtility';
import PersonBadge from './PersonBadge';
import PersonReturningGuestPill from './PersonReturningGuestPill';
import Col from '../../../layout/col/Col';
import Row from '../../../layout/row/Row';
import TextField from '../../textField/TextField';
import Popover from '../../popover/Popover';
import PersonPopover from './PersonPopover';
import withOverflowPopoverState from '../../popover/withOverflowPopoverState';

const styles = {
  text: {
    width: ({ size }) => `calc(100% - ${size === 'sm' ? 30 : 45}px)`,
  },
};

class Person extends PureComponent {
  handlePersonClick = () => {
    if (isFunction(this.props.onClick) && !this.props.disabled) {
      this.props.onClick(this.props.name);
    }
  };

  render() {
    const {
      img,
      name = '',
      isUser,
      subTitle,
      size,
      className,
      style,
      onClick,
      disabled,
      badge,
      returningGuest,
      description,
      descriptionColor,
      classes,
      showPopover,
    } = this.props;

    const descriptionText = description || subTitle;

    return (
      <Col dense fullWidth className={className}>
        <div
          role="presentation"
          className={cn(
            'person-container transition-color',
            { 'text-hover-blue clickable': !disabled && isFunction(onClick) },
            { 'disabled-light': disabled },
          )}
          style={style}
          onClick={this.handlePersonClick}
        >
          <Row align="center" fullWidth justify="start" spacing="sm">
            <Popover
              placement="top-start"
              isOpen={showPopover}
              body={
                <PersonPopover
                  title={name}
                  subtitle={descriptionText}
                  size={size}
                />
              }
            >
              <Avatar
                text={nameToInitials(name)}
                src={img}
                isUser={isUser}
                size={size}
                badge={badge && <PersonBadge />}
              />
            </Popover>

            <Col dense className={classes.text}>
              <TextField
                size={size === 'md' ? 'lg' : 'md'}
                className="overflow-ellipsis text-left"
              >
                {name}
              </TextField>
              {descriptionText && (
                <TextField
                  color={descriptionColor}
                  size={size}
                  className="person-subtitle text-left overflow-ellipsis m-0"
                >
                  {descriptionText}
                </TextField>
              )}
            </Col>
          </Row>
        </div>
        {returningGuest && (
          <PersonReturningGuestPill
            className={size === 'sm' ? 'ml-7' : 'ml-10'}
          />
        )}
      </Col>
    );
  }
}

Person.defaultProps = {
  img: '',
  isUser: false,
  subTitle: '',
  size: 'md',
  className: '',
  style: {},
  onClick: null,
  disabled: false,
  badge: false,
  returningGuest: false,
  description: '',
  descriptionColor: null,
};

Person.propTypes = {
  /** Image url for person */
  img: PropTypes.string,
  /** Person full name */
  name: PropTypes.string.isRequired,
  /** Indicator whether or not the person is a GSC */
  isUser: PropTypes.bool,
  /** bottom line text for the user */
  subTitle: PropTypes.string,
  /** bottom line text for the user */
  description: PropTypes.string,
  /** bottom line text color */
  descriptionColor: PropTypes.string,
  /** avatar icon size */
  size: PropTypes.oneOf(['sm', 'md']),
  /** Additional class name for person */
  className: PropTypes.string,
  /** Additional style for person */
  style: PropTypes.shape(),
  /** CB function for person click */
  onClick: PropTypes.func,
  /** Indicator whether or not the person component is disabled */
  disabled: PropTypes.bool,
  /** Indicator whether or not to show  Badge on person avatar */
  badge: PropTypes.bool,
  /** Indicator whether or not to show  returning guest pill on person */
  returningGuest: PropTypes.bool,
};

export default withStyles(styles)(withOverflowPopoverState(Person));
