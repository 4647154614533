import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'react-jss';
import cn from 'classnames';
import './row.scss';

const spacer = {
  sm: 7,
  md: 20
};
const styles = {
  row: {
    '& > * ': {
      marginRight: props => spacer[props.spacing]
    }
  }
};
const Row = ({
  className,
  style,
  children,
  dense,
  fullWidth,
  classes,
  align,
  justify,
  wrap
}) => (
  <div
    className={cn(
      'd-flex',
      { [`row-wrapper ${classes.row}`]: !dense },
      { 'w-fill': fullWidth },
      { [`align-items-${align}`]: align },
      { [`justify-content-${justify}`]: justify },
      { 'flex-row-wrap': wrap },
      className
    )}
    style={style}
  >
    {children}
  </div>
);

Row.defaultProps = {
  className: '',
  spacing: 'md',
  style: {},
  children: null,
  dense: false,
  fullWidth: false,
  align: 'inherit',
  justify: 'inherit',
  wrap: false
};

Row.propTypes = {
  /** Additional classes for row */
  className: PropTypes.string,
  /** Additional classes for row */
  align: PropTypes.string,
  /** Additional classes for row */
  justify: PropTypes.string,
  /** Additional styles for row */
  style: PropTypes.shape(),
  /** Children for row wrapper */
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  /** Boolean whether or not we are in dense (no margins) mode */
  dense: PropTypes.bool,
  /** Boolean whether or not we are in full width mode */
  fullWidth: PropTypes.bool,
  /** Amount of spacing between child elements -
   *  sm - 7 md - 20. By default in forms 20 is enforced.
   *  generally:
   *  sm - used for spacing inner components
   *  md - used for spacing larger structures
   *  Otherwise md is set by default
   */
  spacing: PropTypes.oneOf(['sm', 'md']),
  /** Set row as wrap to fit content */
  wrap: PropTypes.bool
};

export const RowComponent = Row;
export default withStyles(styles)(Row);
