import PropTypes from 'prop-types';
import React, { Component } from 'react';
import withStyles from 'react-jss';

import ActionButton from '../../buttons/ActionButton';
import RichTextEditorToolbarItem from './RichTextEditorToolbarItem';
import {
  grayDark,
  grayLight,
  grayLightest,
  white,
} from '../../../../themes/colors';
import { Theme } from '../../../../constants/enums';

const getBackgroundColorFromProps = (props) => {
  const { active, theme } = props;

  if (active) {
    return theme === Theme.Light ? grayLightest : grayLight;
  }

  return theme === Theme.Light ? white : grayLightest;
};

const getBorderFromProps = props => `1px solid ${props.theme === Theme.Light ? grayLightest : grayLight}`;
const styles = {
  actionButton: {
    width: 25,
    height: 25,
    border: 'none',
    backgroundColor: getBackgroundColorFromProps,
    padding: '0',
    '&:hover': {
      border: getBorderFromProps,
    },
  },
  actionButtonIcon: {
    fill: grayDark,
  },
};

class RichTextEditorToolbarActionButton extends Component {
  handleOnMouseDown = (e) => {
    e.preventDefault();

    const { type } = this.props;
    this.props.onToggle(type);
  };

  render() {
    const {
      icon, iconWidth, iconHeight, classes,
    } = this.props;

    return (
      <RichTextEditorToolbarItem>
        <ActionButton
          className={classes.actionButton}
          icon={icon}
          onMouseDown={this.handleOnMouseDown}
          iconWidth={iconWidth}
          iconHeight={iconHeight}
          iconClassName={classes.actionButtonIcon}
        />
      </RichTextEditorToolbarItem>
    );
  }
}

RichTextEditorToolbarActionButton.defaultProps = {
  iconWidth: 20,
  iconHeight: 20,
};

RichTextEditorToolbarActionButton.propTypes = {
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  iconHeight: PropTypes.number,
  iconWidth: PropTypes.number,
  editorState: PropTypes.shape().isRequired,
  type: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
};

export default withStyles(styles)(RichTextEditorToolbarActionButton);
