import styled from 'styled-components';
import { mobile, tablet } from 'constants/css/breakpoints';

// Sole purpose of this wrapper is for the content to safely have margins in a flexed parent
export const InvoiceContentWrapper = styled.div``;

export const InvoiceContent = styled.div`
  background: white;
  box-shadow: 0 20px 60px 0 rgba(39, 43, 45, 0.2) !important; /* important is to add selector weight in FF */
  margin: 55px 120px 160px 120px;
  @media ${mobile} {
    margin: 55px 20px 150px 20px;
  };
  @media ${tablet} {
    margin: 55px 20px 130px 20px;
  };
`;

export const InvoiceTag = styled.div`
  margin-left: 38.5%;
  
  button {
    background-color: inherit;
  }
  @media ${mobile} {
    margin-left: 0;
  };
`;
