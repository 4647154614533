import Visa from 'payment-icons/min/flat/visa.svg';
import MasterCard from 'payment-icons/min/flat/mastercard.svg';
import Discover from 'payment-icons/min/flat/discover.svg';
import AmericanExpress from 'payment-icons/min/flat/amex.svg';
import DinersClub from 'payment-icons/min/flat/diners.svg';
import JCB from 'payment-icons/min/flat/jcb.svg';
import UnionPay from 'payment-icons/min/flat/unionpay.svg';

import BtnExport from './BtnExport.svg';
import BtnImport from './BtnImport.svg';
import BtnAccount from './BtnAccount.svg';
import BtnAdd from './BtnAdd.svg';
import BtnApprove from './BtnApprove.svg';
import BtnArchive from './BtnArchive.svg';
import BtnArrowDown from './BtnArrowDown.svg';
import BtnArrowUp from './BtnArrowUp.svg';
import BtnAssign from './BtnAssign.svg';
import BtnAttachment from './BtnAttachment.svg';
import BtnBulkArticles from './BtnBulkArticles.svg';
import BtnCabs from './BtnCabs.svg';
import BtnCalendar from './BtnCalendar.svg';
import BtnCheckboxOff from './BtnCheckboxOff.svg';
import BtnCheckboxOn from './BtnCheckboxOn.svg';
import BtnColumns from './BtnColumns.svg';
import BtnCopy from './BtnCopy.svg';
import BtnDelete from './BtnDelete.svg';
import BtnDeleteMobile from './BtnDeleteMobile.svg';
import BtnDetails from './BtnDetails.svg';
import BtnDetailsOnOff from './BtnDetailsOnOff.svg';
import BtnDragDrop from './BtnDragDrop.svg';
import BtnEdit from './BtnEdit.svg';
import BtnExpand from './BtnExpand.svg';
import BtnFilters from './BtnFilters.svg';
import BtnGoTo from './BtnGoTo.svg';
import BtnGuests from './BtnGuests.svg';
import BtnGuestyLogo from './BtnGuestyLogo.svg';
import BtnGuestyName from './BtnGuestyName.svg';
import BtnGuestySymbol from './BtnGuestySymbol.svg';
import BtnHelp from './BtnHelp.svg';
import BtnHelpCenter from './BtnHelpCenter.svg';
import BtnInbox from './BtnInbox.svg';
import BtnInboxExpand from './BtnInboxExpand.svg';
import BtnInboxOpen from './BtnInboxOpen.svg';
import BtnLeft from './BtnLeft.svg';
import BtnLinks from './BtnLinks.svg';
import BtnLogout from './BtnLogout.svg';
import BtnMap from './BtnMap.svg';
import BtnMarkRead from './BtnMarkRead.svg';
import BtnMessage from './BtnMessage.svg';
import BtnNights from './BtnNights.svg';
import BtnNotifications from './BtnNotifications.svg';
import BtnNotificationsBadge from './BtnNotificationsBadge.svg';
import BtnParking from './BtnParking.svg';
import BtnPayment from './BtnPayment.svg';
import BtnPhone from './BtnPhone.svg';
import BtnPrinter from './BtnPrinter.svg';
import BtnProperty from './BtnProperty.svg';
import BtnRadioOff from './BtnRadioOff.svg';
import BtnRadioOn from './BtnRadioOn.svg';
import BtnReservation from './BtnReservation.svg';
import BtnReservationMobile from './BtnReservationMobile.svg';
import BtnReset from './BtnReset.svg';
import BtnRichAlign from './BtnRichAlign.svg';
import BtnRichBold from './BtnRichBold.svg';
import BtnRichBullets from './BtnRichBullets.svg';
import BtnRichDirection from './BtnRichDirection.svg';
import BtnRichFont from './BtnRichFont.svg';
import BtnRichItali from './BtnRichItali.svg';
import BtnRichLink from './BtnRichLink.svg';
import BtnRichNumbers from './BtnRichNumbers.svg';
import BtnRichRemoveStyle from './BtnRichRemoveStyle.svg';
import BtnRichTextColor from './BtnRichTextColor.svg';
import BtnRichTextSize from './BtnRichTextSize.svg';
import BtnRichUnderline from './BtnRichUnderline.svg';
import BtnSavedReply from './BtnSavedReply.svg';
import BtnSearch from './BtnSearch.svg';
import BtnSearchMobile from './BtnSearchMobile.svg';
import BtnServices from './BtnServices.svg';
import BtnSettings from './BtnSettings.svg';
import BtnShare from './BtnShare.svg';
import BtnSnooze from './BtnSnooze.svg';
import BtnSort from './BtnSort.svg';
import BtnStarOff from './BtnStarOff.svg';
import BtnStarOn from './BtnStarOn.svg';
import btnSummary from './btnSummary.svg';
import BtnSupport from './BtnSupport.svg';
import BtnTime from './BtnTime.svg';
import BtnToCsv from './BtnToCsv.svg';
import BtnToggleOff from './BtnToggleOff.svg';
import BtnToggleOn from './BtnToggleOn.svg';
import BtnTrash from './BtnTrash.svg';
import BtnUnexpand from './BtnUnexpand.svg';
import BtnWeather from './BtnWeather.svg';
import IcoArrowDown from './IcoArrowDown.svg';
import IcoArrowUp from './IcoArrowUp.svg';
import IcoAddress from './IcoAddress.svg';
import IcoAirbnb from './IcoAirbnb.svg';
import IcoApproved from './IcoApproved.svg';
import IcoAvatarAutoMessage from './IcoAvatarAutoMessage.svg';
import IcoAvatarNoName from './IcoAvatarNoName.svg';
import IcoAvatarProperty from './IcoAvatarProperty.svg';
import IcoBedBunk from './IcoBedBunk.svg';
import IcoBedDouble from './IcoBedDouble.svg';
import IcoBedMat from './IcoBedMat.svg';
import IcoBedSingle from './IcoBedSingle.svg';
import IcoBedSofa from './IcoBedSofa.svg';
import IcoTip from './IcoTip.svg';
import IcoEmptystatePayments from './IcoEmptystatePayments.svg';
import IcoEmptystateAccessAcount from './IcoEmptystateAccessAcount.svg';
import IcoEmptystateArchived from './IcoEmptystateArchived.svg';
import IcoEmptystateColumns from './IcoEmptystateColumns.svg';
import IcoEmptystateFavs from './IcoEmptystateFavs.svg';
import IcoEmptystateInactivePropertoes from './IcoEmptystateInactivePropertoes.svg';
import IcoEmptystateInbox from './IcoEmptystateInbox.svg';
import IcoEmptystateInboxTickets from './IcoEmptystateInboxTickets.svg';
import IcoEmptystateLinks from './IcoEmptystateLinks.svg';
import IcoEmptystateMaintenance from './IcoEmptystateMaintenance.svg';
import IcoEmptystateNotifications from './IcoEmptystateNotifications.svg';
import IcoEmptystateSearch from './IcoEmptystateSearch.svg';
import IcoEmptystateSearchResults from './IcoEmptystateSearchResults.svg';
import IcoEmptystateSharedReports from './IcoEmptystateSharedReports.svg';
import IcoEmptystateSnoozed from './IcoEmptystateSnoozed.svg';
import IcoEmptystateViews from './IcoEmptystateViews.svg';
import IcoEmptystateExtraInfo from './IcoEmptystateExtraInfo.svg';
import IcoEmptystateListEmpty from './IcoEmptystateListEmpty.svg';
import IcoEmptystateListFull from './IcoEmptystateListFull.svg';
import IcoFilterBoolean from './IcoFilterBoolean.svg';
import IcoFilterDate from './IcoFilterDate.svg';
import IcoFilterNumber from './IcoFilterNumber.svg';
import IcoFilterPeople from './IcoFilterPeople.svg';
import IcoFilterSelection from './IcoFilterSelection.svg';
import IcoFilterString from './IcoFilterString.svg';
import IcoFilterTime from './IcoFilterTime.svg';
import IcoFinderBoard from './IcoFinderBoard.svg';
import IcoMap from './IcoMap.svg';
import IcoMultiRate from './IcoMultiRate.svg';
import IcoNotice from './IcoNotice.svg';
import IcoNoticeOutline from './IcoNoticeOutline.svg';
import IcoPayment from './IcoPayment.svg';
import IcoPaymentFailed from './IcoPaymentFailed.svg';
import IcoPaymentsCash from './IcoPaymentsCash.svg';
import IcoPaymentsCreditCard from './IcoPaymentsCreditCard.svg';
import IcoPaymentsEcheck from './IcoPaymentsEcheck.svg';
import IcoPaymentsExpenses from './IcoPaymentsExpenses.svg';
import IcoPaymentsIncome from './IcoPaymentsIncome.svg';
import IcoPaymentsNetIncome from './IcoPaymentsNetIncome.svg';
import IcoPaymentsOwnersRevenue from './IcoPaymentsOwnersRevenue.svg';
import IcoPaymentsStripe from './IcoPaymentsStripe.svg';
import IcoPaymentCash from './IcoPaymentCash.svg';
import IcoPaymentCreditCard from './IcoPaymentCreditCard.svg';
import IcoPaymentRefunded from './IcoPaymentRefunded.svg';
import IcoCollectPaymentRecord from './IcoCollectPaymentRecord.svg';
import IcoCollectPaymentAH from './IcoCollectPaymentAH.svg';
import IcoCollectPaymentCharge from './IcoCollectPaymentCharge.svg';
import IcoRateStrategy from './IcoRateStrategy.svg';
import IcoReservationCompleted from './IcoReservationCompleted.svg';
import IcoReservationCurrently from './IcoReservationCurrently.svg';
import IcoReservationPlanned from './IcoReservationPlanned.svg';
import IcoReservationSummaryGeneral from './IcoReservationSummaryGeneral.svg';
import IcoReservationSummaryStatusReserved from './IcoReservationSummaryStatusReserved.svg';
import IcoReservationSummaryStatusV from './IcoReservationSummaryStatusV.svg';
import IcoReservationSummaryStatusX from './IcoReservationSummaryStatusX.svg';
import IcoTasks from './IcoTasks.svg';
import BtnInfo from './BtnInfo.svg';
import BtnRate from './BtnRate.svg';
import IcoAvatarGSC from './IcoAvatarGSC.svg';
import IcoEmptystateNoMessages from './IcoEmptystateNoMessages.svg';
import IcoEmptystateAutoMessages from './IcoEmptystateAutoMessages.svg';
import IcoNotificationAirbnb from './IcoNotificationAirbnb.svg';
import IcoNotificationGuesty from './IcoNotificationGuesty.svg';
import IcoNotificationPayment from './IcoNotificationPayment.svg';
import IcoNotificationProperty from './IcoNotificationProperty.svg';
import IcoNotificationReservation from './IcoNotificationReservation.svg';
import IcoNotificationTask from './IcoNotificationTask.svg';
import IcoPopupAdd from './IcoPopupAdd.svg';
import IcoPopupConnect from './IcoPopupConnect.svg';
import IcoPopupImport from './IcoPopupImport.svg';
import IcoPopupSync from './IcoPopupSync.svg';
import BtnConnect from './BtnConnect.svg';
import BtnMarketplace from './BtnMarketplace.svg';
import BtnPublish from './BtnPublish.svg';
import BtnRefresh from './BtnRefresh.svg';
import IcoConnectChannels from './IcoConnectChannels.svg';
import IcoEmptystateMarketplace from './IcoEmptystateMarketplace.svg';
import IcoPopupPublish from './IcoPopupPublish.svg';
import IcoDeclined from './IcoDeclined.svg';
import IcoCleaner from './IcoCleaner.svg';
import IcoGeneralNote from './IcoGeneralNote.svg';
import BtnAllAcountsListings from './BtnAllAcountsListings.svg';
import BtnLeftSmall from './BtnLeftSmall.svg';
import BtnRight from './BtnRight.svg';
import BtnRightSmall from './BtnRightSmall.svg';
import BtnTimeFill from './BtnTimeFill.svg';
import BtnYieldAvailabilty from './BtnYieldAvailabilty.svg';
import BtnYieldGaps from './BtnYieldGaps.svg';
import BtnYieldHolidays from './BtnYieldHolidays.svg';
import BtnYieldRecurring from './BtnYieldRecurring.svg';
import BtnYieldSeasons from './BtnYieldSeasons.svg';
import Accessibility from './accessibility.svg';
import Aircondition from './air_condition.svg';
import Baby from './baby.svg';
import Bath from './bath.svg';
import Bbq from './bbq.svg';
import Beach from './beach.svg';
import Bed from './bed.svg';
import Breakfast from './breakfast.svg';
import Buzzer from './buzzer.svg';
import Cats from './cats.svg';
import Chair from './chair.svg';
import Checkin from './checkin.svg';
import Cleaning from './cleaning.svg';
import Cmdetector from './CM_detector.svg';
import Coffee from './coffee.svg';
import Console from './console.svg';
import Dishes from './dishes.svg';
import Dishwasher from './dishwasher.svg';
import Dogs from './dogs.svg';
import Doorman from './doorman.svg';
import Dryer from './dryer.svg';
import Elevator from './elevator.svg';
import Essentials from './essentials.svg';
import Ev from './ev.svg';
import Events from './events.svg';
import Familyfriendly from './family_friendly.svg';
import Fireextinguisher from './fire_extinguisher.svg';
import Fireplace from './fireplace.svg';
import Firstaid from './first_aid.svg';
import Garden from './garden.svg';
import Guard from './guard.svg';
import Gym from './gym.svg';
import Hairdryer from './hair_dryer.svg';
import Hangers from './hangers.svg';
import Heating from './heating.svg';
import Hottub from './hot_tub.svg';
import Hotwater from './hot_water.svg';
import Internet from './internet.svg';
import Iron from './iron.svg';
import Keypad from './keypad.svg';
import Kitchen from './kitchen.svg';
import Laptop from './laptop.svg';
import Lockbox from './lockbox.svg';
import Locker from './locker.svg';
import Longterm from './long_term.svg';
import Luggage from './luggage.svg';
import Microwave from './microwave.svg';
import Otherpets from './other_pets.svg';
import Oven from './oven.svg';
import Parking from './parking.svg';
import Patio from './patio.svg';
import Petsallowed from './pets_allowed.svg';
import Petslive from './pets_live.svg';
import Pool from './pool.svg';
import Privateentrance from './private_entrance.svg';
import Privatelivingroom from './private_livingroom.svg';
import Refrigerator from './refrigerator.svg';
import Safetycard from './safety_card.svg';
import Shades from './shades.svg';
import Shampoo from './shampoo.svg';
import Smartlock from './smartlock.svg';
import Smokedetector from './smoke_detector.svg';
import Smoking from './smoking.svg';
import Stove from './stove.svg';
import Table from './table.svg';
import Tv from './tv.svg';
import Washer from './washer.svg';
import Wheelchair from './wheelchair.svg';
import Wifi from './wifi.svg';
import BtnAutomsg from './BtnAutomsg.svg';
import BtnActions from './BtnActions.svg';
import BtnDownload from './BtnDownload.svg';

import Doc from './IcoFileDOC.svg';
import Gif from './IcoFileGIF.svg';
import Jpg from './IcoFileJPG.svg';
import Mp4 from './IcoFileMP4.svg';
import Pdf from './IcoFilePDF.svg';
import Png from './IcoFilePNG.svg';
import Xls from './IcoFileXLS.svg';
import BtnSidebarClose from './BtnSidebarClose.svg';
import BtnSidebarOpen from './BtnSidebarOpen.svg';
import FontSize from './FontSize.svg';
import FontColor from './FontColor.svg';
import Bold from './Bold.svg';
import Italic from './Italic.svg';
import Bullets from './Bullets.svg';
import Numbers from './Numbers.svg';
import Underline from './Underline.svg';
import IcoAvatarListingMD from './IcoAvatarListingMD.svg';
import IcoAvatarListingSM from './IcoAvatarListingSM.svg';
import BtnHide from './BtnHide.svg';
import BtnShow from './BtnShow.svg';
import IcoCheckinFormSent from './IcoCheckinFormSent.svg';
import IcoCheckinFormView from './IcoCheckinFormView.svg';

export {
  Visa,
  MasterCard,
  Discover,
  AmericanExpress,
  DinersClub,
  JCB,
  UnionPay,
  Doc,
  Gif,
  Jpg,
  Mp4,
  Pdf,
  Png,
  Xls,
  BtnAutomsg,
  BtnActions,
  BtnDownload,
  BtnImport,
  BtnExport,
  BtnAccount,
  BtnAdd,
  BtnApprove,
  BtnArchive,
  BtnArrowDown,
  BtnArrowUp,
  BtnAssign,
  BtnAttachment,
  BtnBulkArticles,
  BtnCabs,
  BtnCalendar,
  BtnCheckboxOff,
  BtnCheckboxOn,
  BtnColumns,
  BtnCopy,
  BtnDelete,
  BtnDeleteMobile,
  BtnDetails,
  BtnDetailsOnOff,
  BtnDragDrop,
  BtnEdit,
  BtnExpand,
  BtnFilters,
  BtnGoTo,
  BtnGuests,
  BtnGuestyLogo,
  BtnGuestyName,
  BtnGuestySymbol,
  BtnHelp,
  BtnHelpCenter,
  BtnInbox,
  BtnInboxExpand,
  BtnInboxOpen,
  BtnLeft,
  BtnLinks,
  BtnLogout,
  BtnMap,
  BtnMarkRead,
  BtnMessage,
  BtnNights,
  BtnNotifications,
  BtnNotificationsBadge,
  BtnParking,
  BtnPayment,
  BtnPhone,
  BtnPrinter,
  BtnProperty,
  BtnRadioOff,
  BtnRadioOn,
  BtnReservation,
  BtnReservationMobile,
  BtnReset,
  BtnRichAlign,
  BtnRichBold,
  BtnRichBullets,
  BtnRichDirection,
  BtnRichFont,
  BtnRichItali,
  BtnRichLink,
  BtnRichNumbers,
  BtnRichRemoveStyle,
  BtnRichTextColor,
  BtnRichTextSize,
  BtnRichUnderline,
  BtnSavedReply,
  BtnSearch,
  BtnSearchMobile,
  BtnServices,
  BtnSettings,
  BtnShare,
  BtnSnooze,
  BtnSort,
  BtnStarOff,
  BtnStarOn,
  btnSummary,
  BtnSupport,
  BtnTime,
  BtnToCsv,
  BtnToggleOff,
  BtnToggleOn,
  BtnTrash,
  BtnUnexpand,
  BtnWeather,
  BtnYieldAvailabilty,
  BtnYieldGaps,
  BtnYieldHolidays,
  BtnYieldRecurring,
  BtnYieldSeasons,
  IcoArrowDown,
  IcoArrowUp,
  IcoAddress,
  IcoAirbnb,
  IcoApproved,
  IcoAvatarAutoMessage,
  IcoAvatarNoName,
  IcoAvatarProperty,
  IcoBedBunk,
  IcoBedDouble,
  IcoBedMat,
  IcoBedSingle,
  IcoBedSofa,
  IcoTip,
  IcoEmptystatePayments,
  IcoEmptystateAccessAcount,
  IcoEmptystateArchived,
  IcoEmptystateColumns,
  IcoEmptystateFavs,
  IcoEmptystateInactivePropertoes,
  IcoEmptystateInbox,
  IcoEmptystateInboxTickets,
  IcoEmptystateLinks,
  IcoEmptystateMaintenance,
  IcoEmptystateNotifications,
  IcoEmptystateSearch,
  IcoEmptystateSearchResults,
  IcoEmptystateSharedReports,
  IcoEmptystateSnoozed,
  IcoEmptystateViews,
  IcoEmptystateExtraInfo,
  IcoEmptystateListEmpty,
  IcoEmptystateListFull,
  IcoFilterBoolean,
  IcoFilterDate,
  IcoFilterNumber,
  IcoFilterPeople,
  IcoFilterSelection,
  IcoFilterString,
  IcoFilterTime,
  IcoFinderBoard,
  IcoMap,
  IcoMultiRate,
  IcoNotice,
  IcoNoticeOutline,
  IcoPayment,
  IcoPaymentFailed,
  IcoPaymentsCash,
  IcoPaymentsCreditCard,
  IcoPaymentsEcheck,
  IcoPaymentsExpenses,
  IcoPaymentsIncome,
  IcoPaymentsNetIncome,
  IcoPaymentsOwnersRevenue,
  IcoPaymentsStripe,
  IcoCollectPaymentRecord,
  IcoCollectPaymentAH,
  IcoCollectPaymentCharge,
  IcoPaymentCash,
  IcoPaymentCreditCard,
  IcoPaymentRefunded,
  IcoRateStrategy,
  IcoReservationCompleted,
  IcoReservationCurrently,
  IcoReservationPlanned,
  IcoReservationSummaryGeneral,
  IcoReservationSummaryStatusReserved,
  IcoReservationSummaryStatusV,
  IcoReservationSummaryStatusX,
  IcoTasks,
  BtnInfo,
  BtnRate,
  IcoAvatarGSC,
  IcoEmptystateNoMessages,
  IcoEmptystateAutoMessages,
  IcoNotificationAirbnb,
  IcoNotificationGuesty,
  IcoNotificationPayment,
  IcoNotificationProperty,
  IcoNotificationReservation,
  IcoNotificationTask,
  IcoPopupAdd,
  IcoPopupConnect,
  IcoPopupImport,
  IcoPopupSync,
  IcoAvatarListingMD,
  IcoAvatarListingSM,
  BtnConnect,
  BtnMarketplace,
  BtnPublish,
  BtnRefresh,
  IcoConnectChannels,
  IcoEmptystateMarketplace,
  IcoPopupPublish,
  IcoDeclined,
  IcoCleaner,
  IcoGeneralNote,
  BtnAllAcountsListings,
  BtnLeftSmall,
  BtnRight,
  BtnRightSmall,
  BtnTimeFill,
  Accessibility,
  Aircondition,
  Baby,
  Bath,
  Bbq,
  Beach,
  Bed,
  Breakfast,
  Buzzer,
  Cats,
  Chair,
  Checkin,
  Cleaning,
  Cmdetector,
  Coffee,
  Console,
  Dishes,
  Dishwasher,
  Dogs,
  Doorman,
  Dryer,
  Elevator,
  Essentials,
  Ev,
  Events,
  Familyfriendly,
  Fireextinguisher,
  Fireplace,
  Firstaid,
  Garden,
  Guard,
  Gym,
  Hairdryer,
  Hangers,
  Heating,
  Hotwater,
  Hottub,
  Internet,
  Iron,
  Keypad,
  Kitchen,
  Laptop,
  Lockbox,
  Locker,
  Longterm,
  Luggage,
  Microwave,
  Otherpets,
  Oven,
  Parking,
  Patio,
  Petsallowed,
  Petslive,
  Pool,
  Privateentrance,
  Privatelivingroom,
  Refrigerator,
  Safetycard,
  Shades,
  Shampoo,
  Smartlock,
  Smokedetector,
  Smoking,
  Stove,
  Table,
  Tv,
  Washer,
  Wheelchair,
  Wifi,
  BtnSidebarClose,
  BtnSidebarOpen,
  FontSize,
  FontColor,
  Bold,
  Italic,
  Bullets,
  Numbers,
  Underline,
  BtnShow,
  BtnHide,
  IcoCheckinFormSent,
  IcoCheckinFormView
};
