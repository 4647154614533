import React, { Component } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import IconButton from '../../buttons/IconButton';

class ItemSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      index: props.index || 0,
    };
  }

  onIncrease = () => {
    const { step } = this.props;
    const index = (this.state.index + step) % this.props.values.length;
    this.setState({ index });
    this.props.onChange(index);
  };

  onDecrease = () => {
    const { step, values } = this.props;
    const index = ((this.state.index - step) + values.length) % values.length;
    this.setState({ index });
    this.props.onChange(index);
  };

  render() {
    const { index } = this.state;
    const { renderValue, values } = this.props;
    const rawValue = values[index];
    const value = renderValue ? renderValue(rawValue) : rawValue;
    return (
      <div
        className={cn('fill-hover-blue flex-row-wrap flex-center align-items-center')}
        style={{ width: 35 }}
      >
        <IconButton
          onClick={this.onIncrease}
          icon="BtnArrowUp"
          iconWidth={9}
          iconHeight={5}
          style={{ paddingBottom: 4 }}
        />
        <span className={cn('text-gray-dark')} style={{ fontSize: 12 }}>{value}</span>
        <IconButton
          onClick={this.onDecrease}
          icon="BtnArrowDown"
          iconWidth={9}
          iconHeight={5}
          style={{ paddingTop: 0 }}
        />
      </div>
    );
  }
}

ItemSelector.defaultProps = {
  onChange() {},
  step: 1,
  values: [],
  renderValue: null,
};

ItemSelector.propTypes = {
  onChange: PropTypes.func,
  step: PropTypes.number,
  values: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  renderValue: PropTypes.func,
};

export default ItemSelector;
