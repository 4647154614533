import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import withStyles from 'react-jss';
import { Modal, ModalBody } from 'reactstrap';
import DialogHeader from './DialogHeader';
import DialogFooter from './DialogFooter';
import DialogXHeader from './DialogXHeader';

const styles = {
  modalContent: {
    minHeight: '250px !important ',
    maxHeight: '620px !important'
  }
};

class Dialog extends Component {
  handleConfirmClick = () => {
    if (this.props.onConfirm) {
      this.props.onConfirm(this.props.value);
    }
  };

  handleCloseClick = () => {
    if (this.props.onClose) {
      this.props.onClose(this.props.value);
    }
  };
  render() {
    const {
      isOpen,
      title,
      headerRightComponent,
      footerLeftComponent,
      showCancel,
      isSubmitting,
      type,
      confirmTitle,
      cancelTitle,
      disabled,
      children,
      noFooter,
      onConfirm,
      bodyClassName,
      classes,
      centered
    } = this.props;
    const isXDialog = !title && noFooter;
    return (
      <Modal
        centered={centered}
        isOpen={isOpen}
        contentClassName={cn(
          'box-shadow-center-heavy',
          classes.modalContent,
          isXDialog ? 'pb-6 pl-9 pt-4 pr-4' : 'px-9'
        )}
      >
        {title && (
          <DialogHeader
            type={type}
            title={title}
            rightComponent={headerRightComponent}
          />
        )}
        {isXDialog && <DialogXHeader onClick={this.handleCloseClick} />}
        <ModalBody
          className={cn(
            isXDialog ? 'pr-5 pl-0 pt-0 pb-0' : 'p-0',
            bodyClassName
          )}
        >
          {children}
        </ModalBody>
        {!noFooter && (
          <DialogFooter
            disabled={disabled}
            isSubmitting={isSubmitting}
            showCancel={showCancel}
            showConfirm={!!onConfirm}
            cancelTitle={cancelTitle}
            confirmTitle={confirmTitle}
            onCancel={this.handleCloseClick}
            onConfirm={this.handleConfirmClick}
            leftComponent={footerLeftComponent}
          />
        )}
      </Modal>
    );
  }
}

Dialog.defaultProps = {
  title: '',
  headerRightComponent: null,
  footerLeftComponent: null,
  onConfirm: undefined,
  confirmTitle: 'Save',
  cancelTitle: 'Cancel',
  disabled: false,
  value: null,
  noFooter: false,
  isSubmitting: false,
  showCancel: true,
  bodyClassName: '',
  type: 'primary',
  centered: false
};

Dialog.propTypes = {
  /** Indicator whether or not the dialog should be open */
  isOpen: PropTypes.bool.isRequired,
  /** Dialog body element */
  children: PropTypes.node.isRequired,
  /** CallBack function to dismiss dialog */
  onClose: PropTypes.func.isRequired,
  /** Header title for dialog */
  title: PropTypes.string,
  /** Dialog header right element */
  headerRightComponent: PropTypes.node,
  /** Dialog footer left element */
  footerLeftComponent: PropTypes.node,
  /** Dialog confirm title for footer confirm button */
  confirmTitle: PropTypes.string,
  /** Dialog cancel title for footer cancel button */
  cancelTitle: PropTypes.string,
  /** Callback for the confirm button click -
   * not defining this will cause the confirm button to not render */
  onConfirm: PropTypes.func,
  /** Indicator whether or not the dialog confirm button is disabled */
  disabled: PropTypes.bool,
  /** Payload to pass on dialog callback click */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape()
  ]),
  /** Indicator whether or not to remove the footer */
  noFooter: PropTypes.bool,
  /** Indicator whether or not to show the primary button in loading state */
  isSubmitting: PropTypes.bool,
  /** Indicator whether or not to show the cancel button on the footer */
  showCancel: PropTypes.bool,
  /** Additional class name to body */
  bodyClassName: PropTypes.string,
  /** Set dialog type (error, primary) */
  type: PropTypes.oneOf(['error', 'primary']),
  /** Indicator whether or not to center the modal */
  centered: PropTypes.bool
};

export const DialogComponent = Dialog;
export default withStyles(styles)(Dialog);
