import React, { Component } from 'react';
import { isEmpty, isUndefined, isObject, debounce, get } from 'lodash';
import cn from 'classnames';

const isEqualValue = (prevValue, value) => {
  if (isObject(prevValue) && isObject(value)) {
    return prevValue.value === value.value;
  }
  return prevValue === value;
};
/**
 * Create a wrapper compose to support dropdown functionality
 * @param ComposedComponent
 * @returns {{Component}}
 */
const withDropdown = ComposedComponent => class extends Component {
  constructor(props) {
    super(props);
    this.dropdownRef = React.createRef();
    this.state = {
      selectedItem: {}, query: '', currentPage: props.intialPage || 0, searchInputValue: '',
    };
  }

  componentDidMount() {
    const value = this.props.value || this.props.defaultValue;
    this.setSelectedItemControlledValue(value, this.props.options);
  }

  componentDidUpdate(prevProps) {
    if (!isEqualValue(prevProps.defaultValue, this.props.defaultValue) &&
      isEmpty(this.state.selectedItem)) {
      this.setSelectedItemControlledValue(this.props.defaultValue, this.props.options);
    }

    if (!isEqualValue(prevProps.value, this.props.value) &&
      !isEqualValue(this.props.value, this.state.selectedItem)) {
      this.setSelectedItemControlledValue(this.props.value, this.props.options);
    }
  }

  setSelectedItemControlledValue = (defaultValue, options = []) => {
    let value = {};
    if (!isUndefined(defaultValue)) {
      if (!isObject(defaultValue) && !isEmpty(options)) {
        value = options.find(option => option.value === defaultValue) || {};
      } else {
        value = defaultValue;
      }
    }
    this.setSelectedItem(value);
  };

  setSelectedItem(item = {}) {
    this.setState({
      selectedItem: item || {},
      searchInputValue: isObject(get(item, 'label')) ? item.name : get(item, 'label'),
    });
  }

  searchQueryChange(val) {
    const { id, name, onSearchQueryChange } = this.props;
    this.setState({ query: val, currentPage: 0 });
    if (onSearchQueryChange) {
      this.handleSearchQueryChange(val, id || name);
    }
  }

  handleSearchQueryChange = debounce((val, identifier) => {
    this.props.onSearchQueryChange(val, identifier);
  }, 500);

  handleClickOutside = () => {
    if (!this.props.isCollapsed) {
      this.props.closeCollapse();
      if (!isEmpty(this.state.query)) {
        this.searchQueryChange('');
      }
    }
  };

  handleLoadMore = (page) => {
    this.setState({ currentPage: page });
    this.props.onLoadMore(page, this.state.query);
  };

  handleSelectedItemChange = (value) => {
    const {
      id, name, onSelect, onChange,
    } = this.props;
    if (onSelect) {
      onSelect(value, id || name);
    } else if (onChange) {
      onChange(value, id || name);
    }
  };

  handleSelectItem = (item = {}) => {
    const { closeCollapse } = this.props;
    closeCollapse();
    this.setSelectedItem(item);
    this.handleSelectedItemChange(item.value);
  };

  clearSelectedItem = () => {
    this.handleSelectedItemChange('');
    this.setSelectedItem({});
  };

  clearQuery = () => {
    this.searchQueryChange('');
  };

  handleSearchChange = (e) => {
    const query = e.currentTarget.value;
    const { isCollapsed, openCollapse } = this.props;
    if (isCollapsed) {
      openCollapse();
    }
    this.searchQueryChange(query);
  };

  render() {
    const { containerClassName, onChange, ...props } = this.props;
    return (
      <div ref={this.dropdownRef} className={cn('d-flex w-inherit dropdown-wrapper', containerClassName)}>
        <ComposedComponent
          {...props}
          {...this.state}
          onSearchChange={this.handleSearchChange}
          clearSelectedItem={this.clearSelectedItem}
          onSelectItem={this.handleSelectItem}
          onChange={this.handleSelectedItemChange}
          fetchNextPage={this.handleLoadMore}
          clearQuery={this.clearQuery}
          closeDropdown={this.handleClickOutside}
        />
      </div>
    );
  }
};

export default withDropdown;
