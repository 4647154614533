import React, { Component } from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import cn from 'classnames';
import InputLabel from './InputLabel';
import './input.scss';

const containerStyle = {
  height: 46,
  borderRadius: 2,
  outline: 'none'
};

class Input extends Component {
  handleInputChange = e => {
    const { target } = e;
    const { id, name } = this.props;
    if (this.props.onChange) {
      this.props.onChange(target.value, id || name);
    }
  };
  render() {
    const {
      value,
      className,
      style,
      placeholder,
      disabled,
      suffix,
      containerClassName,
      label,
      tooltip,
      type,
      onChange,
      required,
      ...props
    } = this.props;
    return (
      <div className="d-flex-fill flex-column input-wrapper">
        {label && <InputLabel label={label} tooltip={tooltip} />}
        <div
          className={cn(
            'd-flex-fill flex-start-center border-gray-lighter b-1',
            `bg-gray-${disabled ? 'lighter' : 'lightest'}`,
            containerClassName
          )}
          style={containerStyle}
        >
          <input
            onChange={this.handleInputChange}
            type={type}
            value={value}
            className={cn(
              'py-2',
              'px-4',
              'd-flex-fill',
              'bg-transparent',
              'b-0',
              'font-size-md',
              className
            )}
            disabled={disabled}
            style={style}
            placeholder={placeholder}
            {...props}
          />
          {suffix && (
            <div className="font-size-md input-suffix cursor-regular pr-4 h-fill flex-center">
              {suffix}
            </div>
          )}
        </div>
      </div>
    );
  }
}

Input.defaultProps = {
  onChange: noop,
  value: undefined,
  placeholder: '',
  className: '',
  style: undefined,
  suffix: null,
  label: undefined,
  tooltip: undefined,
  type: 'text',
  disabled: false,
  containerClassName: ''
};

Input.propTypes = {
  /** Controlled input value to be set to input */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Function to be called on input value change -
   params value: {String}, id|name :{String|number}
   */
  onChange: PropTypes.func,
  /** Input type */
  type: PropTypes.string,
  /** Input Placeholder */
  placeholder: PropTypes.string,
  /** Input class */
  className: PropTypes.string,
  /** Input style */
  style: PropTypes.shape(),
  /** String to be shown after the input */
  suffix: PropTypes.node,
  /** A label to show on top of the input */
  label: PropTypes.string,
  /** Add tooltip help icon and message - tooltip - String help message */
  tooltip: PropTypes.string,
  /** Indicator for disabled status */
  disabled: PropTypes.bool,
  /** Additional class for input wrapper level */
  containerClassName: PropTypes.string
};
export default Input;
