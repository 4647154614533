import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';
import TextField from '@guestyci/foundation/TextField';
import Col from '@guestyci/foundation/Layout/Col';
import FormGroup from '@guestyci/foundation/FormGroup';

import CreditCardNumber from '../Stripe/CreditCardNumber';
import CreditCardExpiry from '../Stripe/CreditCardExpiry';
import CreditCardCVC from '../Stripe/CreditCardCVC';

const CardDetails = ({
  creditCardNumberText,
  expDateText,
  cvcText,
  title
}) => {
  return (
    <Col spacing={3}>
      <TextField variant="h3">{title}</TextField>
      <Col spacing={4}>
        <Col>
          <Field
            name="creditCardNumber"
            placeholder={creditCardNumberText}
            label={creditCardNumberText}
            component={CreditCardNumber}
          />
        </Col>
        <FormGroup horizontal>
          <Col breakpoints={{ xs: 6 }}>
            <Field
              name="creditCardExpiry"
              component={CreditCardExpiry}
              label={expDateText}
              placeholder="MM / YY"
            />
          </Col>
          <Col breakpoints={{ xs: 6 }}>
            <Field
              name="creditCardCVC"
              placeholder={cvcText}
              label={cvcText}
              component={CreditCardCVC}
              type="password"
            />
          </Col>
        </FormGroup>
      </Col>
    </Col>
  );
}

CardDetails.propTypes = {
  creditCardNumberText: PropTypes.string.isRequired,
  expDateText: PropTypes.string.isRequired,
  cvcText: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default CardDetails;
